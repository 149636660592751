import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import {
  evaluation_categorie_critere_service,
  evaluation_critere_groupee_critere_service,
  evaluation_critere_groupee_service,
} from "../../../services/PersonalEvaluationService";
import CritereToBeAssignedTable from "./CritereToBeAssignedTable";
import { useQuery } from "./CritereGroupeeCritereFiltre";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const CritereGroupeeCritereForm = ({
  isOpen,
  current_critere,
  handleClose,
}) => {
  const query = useQuery();
  const { Toast, showToast } = useToast();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [categoryCriteres, setCategoryCriteres] = useState([]);
  const [groupesCritere, setGroupeCriteres] = useState([]);
  const [critere_actuel, setCritereActuel] = useState({
    ID_CRITERE_GROUPEE_CRITERE: 0,
    ID_CRITERE: 0,
    QUESTION: "",
    ID_CRITERE_GROUPEE: 0,
    NOM_CRITERE_GROUPEE: "",
  });
  const [filtre, setFiltre] = useState({
    ID_CATEGORY: 0,
    ID_CRITERE_GROUPEE: query.get("id_groupe"),
    mot_cle: "",
  });

  const getInsertableCurrentCritereGroupeeCritere = () => {
    return {
      ID_CRITERE_GROUPEE_CRITERE: critere_actuel.ID_CRITERE_GROUPEE_CRITERE,
      ID_CRITERE: critere_actuel.ID_CRITERE,
      ID_CRITERE_GROUPEE: critere_actuel.ID_CRITERE_GROUPEE,
    };
  };

  const reinitialiseCurrentCritere = () => {
    setCritereActuel({
      ID_CRITERE_GROUPEE_CRITERE: 0,
      ID_CRITERE: 0,
      QUESTION: "",
      ID_CRITERE_GROUPEE: 0,
      NOM_CRITERE_GROUPEE: "",
    });
  };

  const handleLoadGroupeCritere = () => {
    evaluation_critere_groupee_service
      .getCritereGroupeeList(filtre)
      .then((res) => {
        setGroupeCriteres(res.data || []);
      });
  };

  const handleChangeCategoryCritere = (new_value) => {
    if (new_value) {
      setCritereActuel({
        ...critere_actuel,
        ID_CATEGORY: new_value.ID_CATEGORY,
        NOM_CATEGORY: new_value.NOM_CATEGORY,
      });
      setFiltre({ ...filtre, ID_CATEGORY: new_value.ID_CATEGORY });
    } else {
      setCritereActuel({ ...critere_actuel, ID_CATEGORY: 0, NOM_CATEGORY: "" });
      setFiltre({ ...filtre, ID_CATEGORY: 0 });
    }
  };

  const handleGroupeCritereChange = (new_value) => {
    if (new_value) {
      setCritereActuel({
        ...critere_actuel,
        ID_CRITERE_GROUPEE: new_value.ID_CRITERE_GROUPEE,
        NOM_CRITERE_GROUPEE: new_value.NOM_CRITERE_GROUPEE,
      });
      setFiltre({
        ...filtre,
        ID_CRITERE_GROUPEE: new_value.ID_CRITERE_GROUPEE,
      });
    } else {
      setCritereActuel({
        ...critere_actuel,
        ID_CRITERE_GROUPEE: 0,
        NOM_CRITERE_GROUPEE: "",
      });
      setFiltre({ ...filtre, ID_CRITERE_GROUPEE: 0 });
    }
  };

  const handleDialogClose = () => {
    handleClose();
    reinitialiseCurrentCritere();
  };

  const handleSaveCritere = () => {
    if (
      getInsertableCurrentCritereGroupeeCritere().ID_CRITERE_GROUPEE_CRITERE > 0
    ) {
      handleUpdateCritere(getInsertableCurrentCritereGroupeeCritere());
    } else {
      handleInsertCritere(getInsertableCurrentCritereGroupeeCritere());
    }
  };

  const handleInsertCritere = () => {
    evaluation_critere_groupee_critere_service
      .insertCritereGroupeeCritere(getInsertableCurrentCritereGroupeeCritere())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
        }
      });
  };
  const handleUpdateCritere = () => {
    evaluation_critere_groupee_critere_service
      .updateCritereGroupeeCritere(getInsertableCurrentCritereGroupeeCritere())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
        }
      });
  };

  const handleRefreshCritereCategoryList = () => {
    evaluation_categorie_critere_service
      .get_evaluation_category_critere_list(filtre)
      .then((res) => {
        console.log("category", res);
        if (res && res.data) {
          setCategoryCriteres(res.data);
        }
        //          showToast(TYPE_NOTIFICATION.ERROR, res.message);
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  const handleRealoadCompsOfTheForm = () => {
    handleRefreshCritereCategoryList();
  };

  useEffect(() => {
    if (current_critere) {
      setCritereActuel(current_critere);
    }
  }, [current_critere, critere_actuel.ID_CATEGORY]);

  useEffect(() => {
    handleRealoadCompsOfTheForm();
    handleLoadGroupeCritere();
  }, []);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"90vh"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {critere_actuel.ID_CRITERE_GROUPEE_CRITERE === 0
                ? "Assigner groupe de critere"
                : "Modifier groupe de critere"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ overflow: "auto" }}
            >
              <Grid flexDirection={"column"} mt={0.5}>
                <Typography className="form-control">
                  Categories des criteres
                </Typography>

                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                >
                  <Grid flexGrow={1}>
                    <AutocompleteCombobox
                      limitTags={2}
                      fullWidth
                      label="Categorie de critere *"
                      data={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "NOM_CATEGORY",
                        "ID_CATEGORY"
                      )}
                      valeur={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "NOM_CATEGORY",
                        "ID_CATEGORY"
                      ).find(
                        (category_critere) =>
                          category_critere.ID_CATEGORY ===
                          critere_actuel.ID_CATEGORY
                      )}
                      onChange={handleChangeCategoryCritere}
                    />
                  </Grid>
                </Grid>
                <Typography className="form-control">
                  Groupe de critere *
                </Typography>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Groupe de critere *"
                  data={adaptDataValueToAutocompleteData(
                    groupesCritere,
                    "NOM_CRITERE_GROUPEE",
                    "ID_CRITERE_GROUPEE"
                  )}
                  valeur={adaptDataValueToAutocompleteData(
                    groupesCritere,
                    "NOM_CRITERE_GROUPEE",
                    "ID_CRITERE_GROUPEE"
                  ).find(
                    (groupe) =>
                      groupe.ID_CRITERE_GROUPEE === query.get("id_groupe")
                  )}
                  onChange={handleGroupeCritereChange}
                />
              </Grid>
              <Grid mt={3} height={"40vh"}>
                <CritereToBeAssignedTable filtre={filtre} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Divider variant="middle" />
              {/*  <LoadingButton
              loading={submitLoad}
              size='small'
              className='form_control' type='submit'
              onClick={handleSaveCritere}
              variant='contained'>Enregistrer </LoadingButton> */}
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default CritereGroupeeCritereForm;
