import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlFilterForm =
  process.env.REACT_APP_API_URL + "/WebsiteRestController/";
const endPoint = "BanqueDeCV";

const BanqueDeCvService = {
  async list(filtre) {
    let filter = { ...filtre };
    if (filter.isCVIntern) {
      filter.mot_cle = "";
    } else {
      filter.direction_id = "";
      filter.service_id = "";
    }

    try {
      const result = await axios.get(
        `${apiUrl}/` + endPoint + `/getcandidatepersons/`,
        {
          params: {
            filtre: filter,
          },
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
  },

  async showCvByUserId(u_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/` + endPoint + `/getcvbyemployeeid/`,
        {
          params: {
            user_id: u_id,
          },
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
  },
  async downloadUploadedFiles(usr) {
    return await axios
      .get(`${apiUrl}/` + endPoint + `/getCompressedFileById/`, {
        params: { user_id: usr.id_identite },
        responseType: "blob",
      })
  },
  
  async showDomaineCompetence() {
    try {
      const result = await axios.get(`${apiUrlFilterForm}/domaine/`, {});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
  },
  async ShowSousdomaine(domaine) {
    try {
      const result = await axios.get(
        `${apiUrlFilterForm}/sousdomaine/` + domaine
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
  async showCvByUserIdexterne(u_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/` + endPoint + `/getcvbyemployeeidexterne/`,
        {
          params: {
            user_id: u_id,
          },
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
  },
  async showAllOpenPostedPosition() {
    try {
      // const result = await axios.get(`${apiUrlFilterForm}/offreEmploieActive/`);
      const result = await axios.get(
        `${apiUrl}/` + endPoint + `/offreEmploieActive/`
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
};
export default BanqueDeCvService;
