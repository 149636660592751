import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import Evolution from './Evolution';
import VueGlobale from './VueGlobale';
import AppTabPanes from '../shared/AppTabPanes';
import PerformanceForm from './PerformanceForm';

const PerformanceList = () => {
  const comps = [
    /* { label: "Performance", component: <Evaluation360 /> }, */
    /* { label: "Votre performance", component: <PerformanceForm /> }, */
    { label: "Evolution", component: <Evolution /> },
    { label: "Vue chronologique", component: <VueGlobale /> },
  ];
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppTabPanes comps={comps} />
    </MainContainer>
  )
}

export default PerformanceList