import axios from "axios";

const apiMISUrl = process.env.REACT_APP_API_MISURL;
const apiUrl=process.env.REACT_APP_API_URL;
const LocalisationMisService = {
    // async getActivite(region, district, commune) {
    //     try {
    //         const url = `${apiMISUrl}/getListeActivite/${encodeURIComponent(region)}/${encodeURIComponent(district)}/${encodeURIComponent(commune)}`;
    //         const result = await axios.get(url);
    //         return result.data; 
    //     } catch (error) {
    //         if (error.response) {
    //             console.error('Error response:', error.response.data); 
    //             return error.response.data;
    //         } else {
    //             console.error('Error:', error.message); 
    //             return { error: 'An error occurred while fetching activities.' }; 
    //         }
    //     }
    // },
    
    async  getActivite(region, district, commune) {
        const url = `${apiMISUrl}/getListeActivite`;   
        try {
            const result = await axios.post(url,{commune:commune,district:district,region:region});
            if (result) {
              return result.data;
            }
          }
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
    },
    async getFinancement() {
        try {
            const result = await axios.get(`${apiMISUrl}/getFinancement`);  
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getAllDirection() {
        try {
            const result = await axios.get(`${apiUrl}/direction`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getAllDirectionMIS() {
        try {
            const result = await axios.get(`${apiMISUrl}/directions`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    
    async getRegionsByDirection(id_direction) {
        try {
            const result = await axios.get(`${apiMISUrl}/regions`, {
                params: {
                    direction: id_direction
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDistrictByRegion(id_region) {
        try {
            const result = await axios.get(`${apiMISUrl}/districts`, {
                params: {
                    region: id_region
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCommunesByDistrict(id_district) {
        try {
            const result = await axios.get(`${apiMISUrl}/communes`, {
                params: {
                    district: id_district
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFokontantByCommune(id_commune) {
        try {
            const result = await axios.get(`${apiMISUrl}/fokontanys`, {
                params: {
                    commune: id_commune
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDistrictRegion(region) {
        try {
            const result = await axios.get(`${apiMISUrl}/districtnom`, {
                params: {
                    region: encodeURIComponent(region)
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCommuneDistrict(region) {
        try {
            const result = await axios.get(`${apiMISUrl}/communename`, {
                params: {
                    region: encodeURIComponent(region)
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export default LocalisationMisService;