import { Paper } from "@material-ui/core";
import {
  CardHeader,
  Grid,
  Table,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const EvaluationCommentaireGeneral = () => {
  return (
    <Grid>
      <Grid>
        <CardHeader
          title={
            "Le niveau du rendement du Consultant est la somme des évaluations des Sections "
          }
          titleTypographyProps={{
            variant: "h6",
            color: "primary",
            gutterBottom: true,
          }}
          sx={{ mb: -2 }}
        />
        <Grid display="flex" justifyContent={"center"}>
          <table border="1">
            <tr>
              <th>Section</th>
              <th>Note</th>
              <th>Note max</th>
            </tr>
            <tr>
              <td>Section A</td>
              <td>17</td>
              <td>25</td>
            </tr>
            <tr>
              <td>Section B</td>
              <td>18</td>
              <td>25</td>
            </tr>
            <tr>
              <td>Total general</td>
              <td>25</td>
              <td>50</td>
            </tr>
          </table>
        </Grid>
        <Grid mt={2}>
          <CardHeader
            title={"Classification finale"}
            titleTypographyProps={{
              variant: "h6",
              color: "primary",
              gutterBottom: true,
            }}
            sx={{ mb: -2 }}
          />
          <Grid display="flex" justifyContent={"center"}>
            <Grid>
              <Paper>
                <Grid p={1}>
                  <Typography variant="h6">Note: 25 / 50</Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid ml={1}>
              <Paper>
                <Grid p={1}>
                  <Typography variant="h6">Satisfaisant</Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <CardHeader
          title={"Commentaires du consultant(e) sur l'entrevue"}
          titleTypographyProps={{
            variant: "h6",
            color: "primary",
            gutterBottom: true,
          }}
          sx={{ mb: -2 }}
        />
        <Typography>Commentaires</Typography>
        <TextField
          fullWidth
          multiline
          sx={{ mr: 3, mt: 1 }}
          rows={5}
        ></TextField>
      </Grid>
      <Grid>
        <CardHeader
          title={
            "Commentaires généraux du Directeur et superviseur hiérarchique"
          }
          titleTypographyProps={{
            variant: "h6",
            color: "primary",
            gutterBottom: true,
          }}
          sx={{ mb: -2 }}
        />
        <Typography>Points forts du consultant</Typography>
        <TextField
          fullWidth
          multiline
          sx={{ mr: 3, mt: 1 }}
          rows={2}
        ></TextField>
        <Typography>Points à améliorer</Typography>
        <TextField
          fullWidth
          multiline
          sx={{ mr: 3, mt: 1 }}
          rows={2}
        ></TextField>
        <Typography>Évaluation générale </Typography>
        <TextField
          fullWidth
          multiline
          sx={{ mr: 3, mt: 1 }}
          rows={2}
        ></TextField>
        <Typography>Actions à mener pour améliorer la performance</Typography>
        <TextField
          fullWidth
          multiline
          sx={{ mr: 3, mt: 1 }}
          rows={2}
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default EvaluationCommentaireGeneral;
