import React, { useState, useEffect }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import PlanningMissionTable from "./PlanningMissionTable";
import Planning from "../../tools/planning";
import RequestValidationService from "../../../services/RequestValidationService";
import { useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PlanningService from "../../../services/PlanningService";
import moment from "moment";
import { Alert, AlertTitle } from '@mui/material';
import Message from "../../tools/Message";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function PlanningMissionList() {
  const navigate = useNavigate();
  const classes = useStyles();
  function handleClick() {
    navigate("/mission/planning_mission/add");
  }

  function handleplanning() {
    setOpen(true);
  }

  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez allez valider la planification du mission de la date du',
  });
  const [periodevalide,setPeriodevalide]=useState({});
  const { openDialog, messageDialog } = confirmationDialog;
  const [opens, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [checkplanning,setCheckplanning]=useState(false);

  const handleClose = () => {
    setOpen(false);
  };



  const handleConfirme = () => {
    const datedebut=periodevalide.date_debut;
    const datefin=periodevalide.date_fin;
    const periodeid=periodevalide.periode_id;
    PlanningService.sendplanning(datedebut,datefin,periodeid).then((result) => {
      if(result){
        setMessage({...message, open: true, color: 'green', messageInfo:"Succès"});
        setOpen(false);
        window.location.reload();
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: 'Aucun planning n\'est disponible entre ces dates'});
      }
    });
  };

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
 function confirmDialog()
 {
    const result=RequestValidationService.save();
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
 }
 function cancelDialog() {
  setConfirmationDialog({ ...confirmationDialog, openDialog: false });
}

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  useEffect(() => {
    PlanningService.getperiodeactive().then((result) => {
       setPeriodevalide(result.data);
    });
    PlanningService.checkplanning().then((result) => {
      if(result){
        if (result.data===1) {
          setCheckplanning(true);
        }
        else{
          setCheckplanning(false);
        }
      }else{
       
      }
    });
  }, []);
  return (
    <MainContainer {...headerDataMission}>
     <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
     <Planning openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >
          <EditNoteIcon style={{ marginRight: '8px' }} />
           Planifier une mission
        </Button>
         <Spacer x={5} />
         <Button variant="contained" color="primary" onClick={handleplanning} style={{ fontWeight: 600 }} disabled={checkplanning} >
          <DateRangeIcon style={{ marginRight: '8px' }} />
           Envoyer planification
        </Button>
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <PlanningMissionTable />
        </Paper>
        </Grid>
      </Grid>
      <div>
        <Dialog
          open={opens}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <p>Voulez-vous vraiment envoyer votre planification du mission de la date du</p>
              <p>Date début : <b style={{color:'black'}}>{moment(periodevalide.date_debut).format('DD/MM/YYYY')}</b> </p>
              <p>Date fin : <b style={{color:'black'}}>{moment(periodevalide.date_fin).format('DD/MM/YYYY')}</b></p>
              <>
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  Assurez-vous de bien saisir toute votre plannification entre ces deux date car une fois envoyé, vous ne pouvez pas ajouter d'autres
                </Alert>
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleConfirme}>CONFIRMER</Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>ANNULER</Button>
          </DialogActions>
        </Dialog>
      </div>  
    </MainContainer>
  );
}
