import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const FonctionService = {
    async saveFonction(name, code,desc, id) {
        try {
           const result = id ?
            await axios.put(`${apiUrl}/fonction/${id}`, {
             id:id,
             name:name,
             code:code,
             description:desc,
          })
          :
            await axios.post(`${apiUrl}/fonction`, {
             name:name,
             code:code,
             description:desc,
          });
          if (result) {
             return result.data;
          }
         } catch (error) {
           if (error.response) {
             return error.response.data;
            }
          }
   } ,

   async fonctionById(id){
    try {
        const result = await axios.get(`${apiUrl}/fonction/id/${id}` );
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,
    async getfonction(){
        try {
            const result = await axios.get(`${apiUrl}/fonction`  );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
    ,
    async deleteFonction(id){
        try {
            const result = await axios.delete(`${apiUrl}/fonction/${id}`  );
            if (result) {
               return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        },
        async getById(service_id) {
          try {
              const result = await axios.get(`${apiUrl}/fonction/getFunctionByidService/${service_id}`);
              if (result) {
                  return result.data;
              }
          }
          catch (error) {
              if (error.response) {
                  return error.response.data;
              }
          }
      },
      async getfonctionBydirserv(direction,service){
        try {
            const result = await axios.post(`${apiUrl}/fonction/fonctionbydirserv` ,
              {
                direction,
                service
              }
             );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
    ,
}
export default FonctionService;