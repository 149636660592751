import { DataGrid } from '@material-ui/data-grid';
import { Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import QuestionnaireForm from './QuestionnaireForm';
import { evaluation_questionnaire_service } from '../../../services/PersonalEvaluationService';
import LoadingSimpleBackdrop from '../../tools/LoadingSimpleBackdrop';
import { exportToExcel } from '../../../helpers/ExportHelpers';

const QuestionnaireTable = ({ openForm, onCloseForm, handleOpenDialog, excelExport, filtre }) => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [selected_saison, setSelectedSaison] = useState(null);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);

  const handleFormClose = () => {
    onCloseForm();
  }

  const handleOpenFormDIalog = () => {
    handleOpenDialog();
  }

  const handleLoadDataToTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_questionnaire_service.getQuestionnaireList(filtre)
      .then(res => {
        setOpenLoadingSimpleBackdrop(false);
        setQuestionnaires(res.data || []);
      })

  }

  useEffect(() => {
    handleLoadDataToTable();
    if (excelExport > 0) {
      exportToExcel(questionnaires, columns, "Liste des questionnaires " + new Date());
    }
  }, [filtre, excelExport]);

  const columns = [
    {
      field: 'name',
      headerName: 'Fonctions',
      width: 500,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'NOM_CATEGORY',
      headerName: 'Groupe de critères ',
      width: 400,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'QUESTION',
      headerName: 'Critères',
      width: 450,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'NOM_SESSION',
      headerName: 'Session de l\'evaluation ',
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'DATE_DEBUT',
      headerName: 'Debut de la session',
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'DATE_FIN',
      headerName: 'Fin de la session',
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'NOTE_MIN',
      headerName: 'Note minimale',
      width: 170,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'NOTE_MAX',
      headerName: 'Note maximale',
      width: 175,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <QuestionnaireForm current_saison={selected_saison} handleClose={handleFormClose} isOpen={openForm} />
      <DataGrid
        rows={questionnaires}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_QUESTIONNAIRE + "~" + row.ID_CATEGORY + "~" + row.ID_CRITERE + "~" + row.ID_SAISON}
      />
    </>
  )
}
export default QuestionnaireTable