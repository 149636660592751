import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import TDRElaborationTable from "./TDRElaborationTable";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Title from "../../../components/Title";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import TDRService from "../../../services/TDRService";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function PlanningMissionList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [rows,setRows]=useState([]);
  const [note,setNote]=useState([]);
  const [searchtdr,setSearchtdr]=useState('');
  const [aff,setAff]=useState(false);
  const generateYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const [selectedYear, setSelectedYear] = useState(null);

  const handleChange2 = (event, newValue) => {
    setSelectedYear(newValue);
    setForm({ ...form, annee: newValue });
  };
  const [form,setForm]=useState(
    {
      statut:'Nouveau',
      mois:'',
      annee:''
    }
  );

  const years = generateYears(2024)
  const months = [
    { label: 'Janvier', value: 1 },
    { label: 'Février', value: 2 },
    { label: 'Mars', value: 3 },
    { label: 'Avril', value: 4 },
    { label: 'Mai', value: 5 },
    { label: 'Juin', value: 6 },
    { label: 'Juillet', value: 7 },
    { label: 'Août', value: 8 },
    { label: 'Septembre', value: 9 },
    { label: 'Octobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Décembre', value: 12 },
  ];
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [status,setStatus]=useState({ label: 'Nouveau', id: 'Nouveau' });
  const statut = [
    { label: 'Aucun', id: 'Aucun' },
    { label: 'Nouveau', id: 'Nouveau' },
    { label: 'En attente de validation', id: 'En attente de validation' },
    { label: 'Réfusé', id: 'Refusé' },
    { label: 'Validé', id: 'Validé' },
  ];

  const handleChange = (event, newValue) => {
    setSelectedMonth(newValue);
    setForm({ ...form, mois: newValue.value });
  };

  const handleChangesearch = (event) => {
    setSearchtdr(event.target.value);
  };

  const handleChangeStatut = (event, newValue) => {
    setStatus(newValue);
    setForm({ ...form, statut: newValue.id });
  };
  
  function handleClick() {
    navigate("/mission/tdr/add");
  }

    const fetchData = async () => {
      setOpenLoadingSimpleBackdrop(true);
      try {
        const result = await TDRService.getlistTDR(form);
        if (result && result.data) {
          setRows(result.data.map(row => ({ ...row, id: row.tdr_mission_id })));
          setNote(result.data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setOpenLoadingSimpleBackdrop(false);
      }
    };

    useEffect(() => {
      fetchData();
    }, []); 

    const handlefilter = () => {
      fetchData();
    };

    const fetchsearch= async ()=>{
      setAff(true);
      setOpenLoadingSimpleBackdrop(true);
      try {
        const result = await TDRService.getlistsearch(searchtdr);
        if (result && result.data) {
          setRows(result.data.map(row => ({ ...row, id: row.tdr_mission_id })));
          setNote(result.data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setOpenLoadingSimpleBackdrop(false);
      }
    }

  return (
    <MainContainer {...headerDataMission}>
      <Spacer y={2} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={2.5}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                style={{ fontWeight: 600, marginTop: "30px", width: "100%" }}
              >
                 <EditNoteIcon style={{ marginRight: '8px' }} />
                Élaboration TDR
              </Button>
            </Grid>
            <Grid item xs={1.5} >
               <Button
                  variant="contained"
                  color="primary"
                  onClick={handlefilter}
                  style={{ fontWeight: 600, marginTop: "30px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <SearchIcon style={{ marginRight: '8px' }} />
                  Filtrer
                </Button>
            </Grid>
            <Grid item xs={4} >
            </Grid>
            <Grid item xs={2} >
                <TextField
                    fullWidth
                    id="tdr"
                    size="small"
                    variant="outlined"
                    style={{
                      paddingTop:'30px'
                    }}
                    placeholder="Entrez l'intitulé TDR"
                    name="tdr"
                    onChange={handleChangesearch}
                 />
            </Grid>
            <Grid item xs={2} >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchsearch}
                  style={{ fontWeight: 600, marginTop: "30px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <SearchIcon style={{ marginRight: '8px' }} />
                  Rechercher
                </Button>
            </Grid>
            <Grid item xs={12} >
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                 value={status}
                onChange={handleChangeStatut}
                options={statut}
                getOptionLabel={(option) => option.label}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Statut"
                    variant="outlined"
                    size="small"
                    placeholder="Statut"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                  value={selectedYear}
                  onChange={handleChange2}
                  options={years}
                  getOptionLabel={(option) => option.toString()}  
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisissez une année"
                      variant="outlined"
                      size="small" 
                    />
                  )}
                  style={{ width: '100%' }}
                />
              </Grid>
            <Grid item xs={4} >
              <Autocomplete
                value={selectedMonth}
                onChange={handleChange}
                options={months}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Choisissez un mois"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
           
          </Grid>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Title>
                  Elaboration TDR
                </Title>
              </Grid>
            <Grid item xs={12}>
              <TDRElaborationTable notes={note} row={rows} valid={aff}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    </MainContainer>
  );
}
