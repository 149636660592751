import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import PlanningMissionService from "../../../services/PlanningMissionService";
import FonctionService from "../../../services/FonctionService";
import MissionService from "../../../services/MissionService";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import { AntTab, AntTabs } from "../../../components/ant-tab";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import PlanningMissionDetailsAdd from "./PlanningMissionDetailsAdd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNavigate } from "react-router-dom";
import {Checkbox, ListItemText } from '@mui/material';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Chip} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Stack } from '@mui/material';


function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    // maxWidth: 750,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: "left",
  },
  labelPosition1: {
    float: "right",
  },
  text: {
    width: 370,
  },
}));

function PlanningMissionAdd(props) {
  const noteStyle = {
    backgroundColor: '#d4edda', 
    color: '#155724',            
    borderRadius: '5px',
    border: '1px solid #c3e6cb',
    maxWidth: '100%',
    textAlign: 'center',
    margin: '0' 
  };

  const [direction, setDirection] = useState([]);
  const [service, setService] = useState([]);
  const [intervenant, setIntervenant] = useState([]);

  const [pdirection, setPdirection] = useState([]);
  const [pservice, setPservice] = useState([]);
  const [pintervenant, setPintervenant] = useState([]);
  const [pfonction,setPfonction]=useState([]);
  const [loading, setLoading] = useState(false);
  
  const [direction_id, setDirection_id] = useState(0);
  const [service_id, setService_id] = useState(0);

  const [pdirection_id, setPdirection_id] = useState(0);
  const [pservice_id, setPservice_id] = useState(0);
  const [pfonction_id,setPfonction_id]=useState([]);

  const [isValidMission_title, setIsValidMission_title] = useState(false);
  const [isValidCategory_mission_id, setIsValidCategory_mission_id] = useState(false);
  const [category, setCategory] = useState([]);
  const [isValidTransport_type, setIsValidTransport_type] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  const missionId = segments[segments.length - 1];
  const [typeintervenants,setTypeintervenant]=useState("uni");
  const [participant,setParticipants]=useState([]);
  const [tags, setTags] = useState([]);
  const [partnership, setPartnership] = useState('');
  const [intervenants, setIntervenants] = useState('');
  const [tagsintervenant, setTagsintervenant] = useState([]);
    
  const [form, setForm] = useState({
    mission_planning_id:"",
    category_mission_id: {},
    mission_title: "",
    transport_type: "",
    user_id:[],
    participant:[],
    observation:"",
    partnership:[],
    intervenants:[],
    list_user:[],
    grouppar:[],
    lieu:'',
    direction_dest:{},
    date_depart:"",
    date_retour:""
  });
  
  const options = [
    { label: 'Direction', value: 'direction' },
    { label: 'Service', value: 'service' },
    { label: 'Fonction', value: 'fonction' }
  ];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
 const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [selectedOptions, setSelectedOptions] = useState([]);

    // Fonction pour gérer les changements dans l'autocomplete
    const handleOptionChange = (event, newValue) => {
      setForm({ ...form, grouppar: newValue });
      setSelectedOptions(newValue);
    };

  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  const selectAllOption = {
    user_id: 'select_all',
    first_name: 'Sélectionner',
    last_name: 'tout'
  };

  function handleChangeDirdestination(event, value){
     setForm({ ...form, direction_dest: value });
  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && partnership.trim() !== '') {
      event.preventDefault();
      
      const trimmedPartnership = partnership.trim();
    
      if (!tags.includes(trimmedPartnership)) {
        const updatedTags = [...tags, trimmedPartnership];
        setTags(updatedTags);
        
        setForm(prevForm => ({ ...prevForm, partnership: updatedTags }));
      }
      setPartnership('');
    }
  };
  

  const handleChangepartenachip = (event) => {
    setPartnership(event.target.value);
  };

  const handleKeyDownintervenantsautre = (event) => {
    if (event.key === 'Enter' && intervenants.trim() !== '') {
      event.preventDefault();
      
      const trimmedPartnership = intervenants.trim();
    
      if (!tagsintervenant.includes(trimmedPartnership)) {
        const updatedTags = [...tagsintervenant, trimmedPartnership];
        setTagsintervenant(updatedTags);
        setForm(prevForm => ({ ...prevForm, intervenants: updatedTags }));
      }
      setIntervenants('');
    }
  };
  

  const handleChangeintervenantsautre = (event) => {
    setIntervenants(event.target.value);
  }

  
  const handleDeleteintervenant = (tagToDelete) => {
    const updatedTags = tagsintervenant.filter(tag => tag !== tagToDelete);
  
    setTagsintervenant(updatedTags);
    setForm(prevForm => ({ ...prevForm, intervenants: updatedTags }));
  };

  const handleDelete = (tagToDelete) => {
    const updatedTags = tags.filter(tag => tag !== tagToDelete);
  
    setTags(updatedTags);
    setForm(prevForm => ({ ...prevForm, partnership: updatedTags }));
  };

  function handleChangeDirection(event, value){

    const directionIds = value.map(item => parseInt(item.direction_id, 10));
    setDirection_id(value);
    PlanningMissionService.getService(directionIds).then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
  }

  function handleChangepDirection(event, value){
    const directionIds = value.map(item => parseInt(item.direction_id, 10));
    setPdirection_id(value);
    PlanningMissionService.getService(directionIds).then((result) => {
      if (result && result.data) {
        setPservice(result.data);
      }
    });
  }

  function handleChangeService(event, value){
    setService_id(value);
  }

  function handleChangepService(event, value){
    setPservice_id(value);
  }
  
  function handleChangepfonction(event, value){
    const tb = value.map(ts => ts.function_id);
    setPfonction_id(tb);
  }
  

  function handleChangeIntervenants(event, value){
    setForm({ ...form, user_id: value });
  }


  function handleChangelistuser(event, value){
    if (value.some(option => option.user_id === 'select_all')) {
     // setSelectedOptions(intervenant);
      setForm({ ...form, list_user: participant });
    } else {
      setForm({ ...form, list_user: value });
    }
  
  }

  const MISSION_PLANNING_TEMP = JSON.parse(
    localStorage.getItem("MISSION_PLANNING_TEMP")
  );


  useEffect(() => {
    const serviceIds = Array.isArray(service_id) ? service_id.map(item => parseInt(item.service_id, 10)) : [];
    const directionIds = Array.isArray(direction_id) ? direction_id.map(item => parseInt(item.direction_id, 10)) : [];
    PlanningMissionService.getIntervenant(directionIds,serviceIds).then((result) => {
      if (result && result.data) {
        setIntervenant(result.data);
      }
    });
  }, [direction_id, service_id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; 
  };

  useEffect(() => { 
    const serviceIds = Array.isArray(pservice_id) ? pservice_id.map(item => parseInt(item.service_id, 10)) : [];
    const directionIds = Array.isArray(pdirection_id) ? pdirection_id.map(item => parseInt(item.direction_id, 10)) : [];
    MissionService.participants(directionIds,serviceIds,pfonction_id).then((result) => {
        if (result && result.data) {
          setParticipants(result.data);
        }
      });
  }, [pdirection_id, pservice_id,pfonction_id]);

  
  useEffect(() => { 
    const serviceIds = Array.isArray(pservice_id) ? pservice_id.map(item => parseInt(item.service_id, 10)) : [];
    const directionIds = Array.isArray(pdirection_id) ? pdirection_id.map(item => parseInt(item.direction_id, 10)) : [];
    FonctionService.getfonctionBydirserv(directionIds,serviceIds).then((result) => {
      if (result && result.data) {
          setPfonction(result.data);
      }
    });
  }, [pdirection_id, pservice_id]);

  useEffect(() => {
    setLoading(true);
    PlanningMissionService.getCategory().then((result) => {
      if (result && result.data) {
        setCategory(result.data);
        setLoading(false);
      }
    });
  
    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });

    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setPdirection(result.data);
      }
    });
    FonctionService.getfonction().then((result) => {
      if (result && result.data) {
        setPfonction(result.data);
      }
    });
   

   if (missionId!=="add") {
    PlanningMissionService.getdetails(missionId).then((result) => {
      if(result.status){
        
          PlanningMissionService.getDirection().then((res) => {
            if (res.status && result.data) {
            setForm(
                {
                  mission_planning_id:result.data[0].mission_planning_id,
                  category_mission_id: result.data[0].categorie,
                  mission_title: result.data[0].mission_title,
                  transport_type: result.data[0].transport,
                  user_id:result.data[0].users,
                  observation:result.data[0].observation,
                  partnership:result.data[0].partnership,
                  intervenants:result.data[0].intervenant,
                  lieu:result.data[0].lieu,
                  date_depart:formatDate(result.data[0].date_begin),
                  date_retour:formatDate(result.data[0].date_end),
                  direction_dest :res.data.find(ts => ts.direction_id === result.data[0].direction_id)
      
                }
              );
            }
            setTags(JSON.parse(result.data[0].partnership));
            setTagsintervenant(JSON.parse(result.data[0].intervenant));
            setTypeintervenant(result.data[0].categtype);
          });
          
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      })
      .catch((err) => {
        // setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
   }
    
  }, []);

  function handleClick() {
    
   if(form.mission_planning_id!=="")
    {  
      PlanningMissionService.updateMissionPlanning(form).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
          navigate("/mission/planning_mission");
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
    else {
      PlanningMissionService.saveMissionPlanning(form).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
          navigate("/mission/planning_mission");
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
    
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  function handleChangeCategory(event, value) {
    if (Array.isArray(value)) {
      const isGroupe = value.some(item => item.type === "groupe");
      setTypeintervenant(isGroupe ? "groupe" : "uni");
    } else {
      setTypeintervenant(value.type === "groupe" ? "groupe" : "uni");
    }
    setForm({ ...form, category_mission_id: value });
  }
  


  function handleCancel() {
    navigate("/mission/planning_mission");
  }
  return (
    <MainContainer {...headerDataMission}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        )}
      <Spacer y={2} />
      <Paper className={classes.root} style={{width:"100%"}}>
        <AntTabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary" 
        >
          <AntTab label="Planification" />
        </AntTabs>
        <TabPanel value={value} index={0} >
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <div style={noteStyle}>
                 <p>Si une catégorie est une catégorie groupée, cela signifie que plusieurs personnes participent à la mission. 
                  Il y a à la fois un intervenant principal et des participants.</p>            
              </div>
            </Grid>
            <Grid item xs={12}>
              <fieldset>
                <legend>Objet de la mission</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Catégorie</label>
                  </Grid>
                  <Grid item xs={10}>
                    <Autocomplete
                      multiple
                      onChange={handleChangeCategory}
                      options={category}
                      getOptionLabel={(option) => {
                        let label = option.name;
                        if (option.type === "groupe") {
                            label += ` (catégorie groupée)`;
                        }
                        return label;
                       }}
                      value={Array.isArray(form.category_mission_id) ? form.category_mission_id : []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          label="Catégorie"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    /> 
                    {isValidCategory_mission_id && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Objet de la mission</label>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      multiline rows={5}
                      id="outlined-basic"
                      size="small"
                      label="Objet de la mission"
                      variant="outlined"
                      value={form.mission_title}
                      name="mission_title"
                      onChange={handleChange}
                    />
                    {isValidMission_title && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  {/* <Grid item xs={2}>
                    <label className={classes.labelPosition}>Objet</label>
                  </Grid>
                  <Grid item xs={10}>
                      <TextField 
                      fullWidth id="outlined-basic" F
                      multiline rows={5} size="small" 
                      label="Objet" F
                      variant="outlined" 
                      value={form.observation} 
                      name="observation" 
                      onChange={handleChange} />
                  </Grid> */}
                </Grid>
                <br />
              </fieldset>
            </Grid>
            <Grid item xs={12}>
              <fieldset>
                <legend>Date de planification et lieu de destination de la mission</legend>
                <br />
                    <Grid container spacing={3}>
                         <Grid item xs={3}>
                            <label className={classes.labelPosition}>Lieu de destination</label>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label=""
                              variant="outlined"
                              value={form.lieu}
                              name="lieu"
                              onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <label className={classes.labelPosition}>Direction de destination</label>
                        </Grid>
                        <Grid item xs={9}>
                             <Autocomplete
                                onChange={handleChangeDirdestination}
                                value={direction.find(option => 
                                  option.code === form.direction_dest?.code 
                                ) || null}
                                name="direction_dest"
                                options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Direction de detination"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                                )}
                              />
                        </Grid>
                        <Grid item xs={12}>
                          <div style={noteStyle}>
                            <p>Assurez-vous de prendre en compte le temps de trajet lors de l'ajout des dates.</p>            
                          </div>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <label className={classes.labelPosition}>Date de départ</label>
                        </Grid>
                        <Grid item xs={9}>
                              <TextField
                                    fullWidth
                                    id="date_depart"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    value={form.date_depart}
                                    name="date_depart"
                                    onChange={handleChange}
                                  />
                        </Grid>
                        <Grid item xs={3}>
                            <label className={classes.labelPosition}>Date de retour</label>
                        </Grid>
                        <Grid item xs={9}>
                              <TextField
                                    fullWidth
                                    id="date-retour"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    value={form.date_retour}
                                    name="date_retour"
                                    onChange={handleChange}
                                  />
                        </Grid>
                     </Grid>
                </fieldset>
            </Grid>  
            {typeintervenants==="uni"&&
              <>
                  <Grid item xs={12}>
                    <fieldset>
                        <legend>Missionaires</legend><br/>
                        <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <label className={classes.labelPosition}>Direction</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                              fullWidth
                              multiple
                              onChange={handleChangeDirection}
                              options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Direction"
                                  size="small"
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                />
                              )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <label>Service</label>
                        </Grid>
                        <Grid item xs={10}>
                          <Autocomplete
                            fullWidth
                            multiple
                            onChange={handleChangeService}
                            options={service} 
                            getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Service"
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: "search" }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                            <label className={classes.labelPosition}>Missionaires</label>
                        </Grid>
                        <Grid item xs={10}>
                          <Autocomplete multiple id="tags-outlined" options={intervenant} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                            filterSelectedOptions
                            onChange={handleChangeIntervenants}
                            value={form.user_id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label="Missionaires"
                                variant="outlined"
                                placeholder="Missionaires"
                              />
                            )} 
                          />
                        </Grid>
                        </Grid><br />
                    </fieldset>
                    </Grid>
              </>
            }
            {typeintervenants==="groupe"&&
              <>
                <Grid item xs={12}>
                    <fieldset>
                        <legend>Intervenants</legend><br/>
                        <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <label className={classes.labelPosition}>Direction</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                               multiple
                              onChange={handleChangeDirection}
                              options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Direction"
                                  size="small"
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                />
                              )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <label>Service</label>
                        </Grid>
                        <Grid item xs={10}>
                          <Autocomplete
                           multiple
                            onChange={handleChangeService}
                            options={service} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Service"
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: "search" }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                            <label className={classes.labelPosition}>Intervenants</label>
                        </Grid>
                        <Grid item xs={10}>
                          <Autocomplete multiple id="tags-outlined" options={intervenant} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                            filterSelectedOptions
                            onChange={handleChangeIntervenants}
                            value={form.user_id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label="Intervenants"
                                variant="outlined"
                                placeholder="Intervenants"
                              />
                            )} 
                          />
                        </Grid>
                        </Grid><br />
                    </fieldset>
                    </Grid>
                    <Grid item xs={12}>
                      <fieldset>
                        <legend>Autre(s) intervenant(s)</legend><br />
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label="Autres intervenants"
                              variant="outlined"
                              value={intervenants}
                              name="intervenants"
                              onChange={handleChangeintervenantsautre}
                              onKeyDown={handleKeyDownintervenantsautre}
                              placeholder=" Appuyer sur la touche Entrée avant d'ajouter un nouveau intervenant"
                            />
                          </Grid>
                          <Grid item xs={12}>

                          <Stack direction="row" spacing={1} flexWrap="wrap">
                              {tagsintervenant.map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  onDelete={() => handleDeleteintervenant(tag)}
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                        <br />
                      </fieldset>
                    </Grid>
                    <Grid item xs={12}>
                      <fieldset>
                      <legend>Participants</legend><br/>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <div style={noteStyle}>
                            <p>Pour choisir les participants, veuillez sélectionner l'une des options suivantes :</p>
                            <ul>
                              <li style={{textAlign:'left'}}>Direction</li>
                              <li style={{textAlign:'left'}}>Direction et Service</li>
                              <li style={{textAlign:'left'}}>Direction et Fonction</li>
                              <li style={{textAlign:'left'}}>Fonction</li>
                            </ul>
                        </div>
                        </Grid>
                          <Grid item xs={3}>
                              <label className={classes.labelPosition}>Direction</label>
                          </Grid>
                          <Grid item xs={9}>
                              <Autocomplete
                                multiple
                                onChange={handleChangepDirection}
                                options={pdirection} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Direction"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                                )}
                              />
                          </Grid>
                          <Grid item xs={3}>
                             <label className={classes.labelPosition}>Service</label>
                          </Grid>
                          <Grid item xs={9}>
                            <Autocomplete
                              multiple
                              onChange={handleChangepService}
                              options={pservice} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Service"
                                  size="small"
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}>Fonction</label>
                          </Grid>
                          <Grid item xs={9}>
                            <Autocomplete
                              multiple
                              onChange={handleChangepfonction}
                              options={pfonction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Fonction"
                                  size="small"
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                />
                              )}
                            />
                            </Grid>
                            <Grid item xs={3}>
                              <label className={classes.labelPosition}>Participants</label>
                            </Grid>
                            <Grid item xs={9}>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={[selectAllOption, ...participant]}
                                disableCloseOnSelect
                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                value={form.list_user}
                                renderTags={(values) =>
                                  values.map((value) => (
                                    <Chip
                                      key={value.user_id}
                                      label={`${value.first_name} ${value.last_name}`}
                                    />
                                  ))
                                }
                                getOptionSelected={(option, value) => option.user_id === value.user_id}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {`${option.first_name} ${option.last_name}`}
                                  </React.Fragment>
                                )}
                                style={{ width: '100%' }} 
                                onChange={handleChangelistuser}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Participants"
                                    placeholder="Favorites"
                                    fullWidth  
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <label className={classes.labelPosition}>Groupé l'affichage par</label>
                            </Grid>
                            <Grid item xs={9}>
                              <Autocomplete
                                multiple
                                id="tags-outlined-group"
                                options={options}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                onChange={handleOptionChange}
                                value={selectedOptions}
                                style={{ width: '100%' }} 
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth  
                                    id="outlined-basic-group"
                                    size="small"
                                    label="Groupé l'affichage par"
                                    variant="outlined"
                                    placeholder=""
                                  />
                                )}
                              />
                            </Grid>
                        </Grid>
                      </fieldset>
                    </Grid>
              </>
            }
           
           <Grid item xs={12}>
                    <fieldset>
                      <legend>Autre(s) participant(s) possible</legend><br />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            size="small"
                            label="Autres participants"
                            variant="outlined"
                            value={partnership}
                            name="partnership"
                            onChange={handleChangepartenachip}
                            onKeyDown={handleKeyDown}
                            placeholder=" Appuyer sur la touche Entrée avant d'ajouter un nouveau participant"
                          />
                        </Grid>
                        <Grid item xs={12}>

                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            {tags.map((tag, index) => (
                              <Chip
                                key={index}
                                label={tag}
                                onDelete={() => handleDelete(tag)}
                              />
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                      <br />
                    </fieldset>
                  </Grid>
            <Grid item xs={12}>
              <fieldset>
                <legend>Moyen de transport</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}> Moyen de transport</label>
                  </Grid>
                  <Grid item xs={10}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Moyen de transport</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Moyen de transport"
                      value={form.transport_type}
                      name="transport_type"
                      onChange={handleChange}
                    >
                      <MenuItem value="A Déterminer">À déterminer</MenuItem>
                      <MenuItem value="voiture FID">Voiture</MenuItem>
                      <MenuItem value="Avion">Avion</MenuItem>
                      <MenuItem value="Moto">Moto</MenuItem>
                      <MenuItem value="Bateau">Bateau</MenuItem>
                    </Select>
                  </FormControl>

                    </Grid>
                    {isValidTransport_type && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                </Grid>
                <br />
              </fieldset>
            </Grid>
            
            <Grid item xs={12}>
              <Button
                className={classes.labelPosition1}
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={{ fontWeight: 600 }}
              >
                ENREGISTRER
              </Button>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCancel}
                style={{ fontWeight: 600 }}
              >
                ANNULER
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {MISSION_PLANNING_TEMP !== null && <PlanningMissionDetailsAdd />}
          {MISSION_PLANNING_TEMP === null && (
            <Alert severity="warning">
              Veuillez remplir les informations nécessaires dans la
              Planification de la Mission svp !
            </Alert>
          )}
        </TabPanel>
      </Paper>
    </MainContainer>
  );
}

export default PlanningMissionAdd;