import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';


const useStyles = makeStyles((theme) => ({
  labelPosition: {
    float: 'left',
    fontWeight: 'bold'
  },
}));

export default function PlanningMissionSummary(props) {
  const classes = useStyles();
  const openDialogPlanningMissionSummary = props.openDialogPlanningMissionSummary;
  const dataPlanningMissionSummary = props.dataPlanningMissionSummary;
  const lien = props.lien;
  const closeDialogPlanningMissionSummary = props.closeDialogPlanningMissionSummary;
  const validateDialogPlanningMissionSummary = props.validateDialogPlanningMissionSummary;
  const cancelDialogPlanningMissionSummary = props.cancelDialogPlanningMissionSummary;
  return (
    <div>
      <Dialog open={openDialogPlanningMissionSummary} onClose={closeDialogPlanningMissionSummary} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" >{"Résumé de votre planning de mission"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ height: '450px' }} id="alert-dialog-description">
            <Grid container item spacing={3} xs={8}>
              <Grid item xs={4}><br />
                <label className={classes.labelPosition}>Référence : </label>
              </Grid>
              <Grid item xs={8}><br />
                <label>{dataPlanningMissionSummary.reference}</label>
              </Grid>
              <Grid item xs={4}>
                <label className={classes.labelPosition}>Intitulé : </label>
              </Grid>
              <Grid item xs={8}>
                <label>{dataPlanningMissionSummary.mission_title}</label>
              </Grid>
              {/* <Grid item xs={4}>
                <label className={classes.labelPosition}>Transport: </label>
              </Grid>
              <Grid item xs={8}>
                <label>{dataPlanningMissionSummary.transport_type}</label>
              </Grid>
              <Grid item xs={4}>
                <label className={classes.labelPosition}>Observation: </label>
              </Grid>
              <Grid item xs={8}>
                <label>{dataPlanningMissionSummary.observation}</label>
              </Grid> */}
              <Grid item xs={12}>
                    <Typography variant="body1" align="center" >Généralité Destination :</Typography>
              </Grid>
              <Grid item xs={12}>
                    {dataPlanningMissionSummary.destination && dataPlanningMissionSummary.destination.length > 0 ? 
                          <TableContainer component={Paper} style={{width:"500px"}}>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Destination</TableCell>
                                          <TableCell>Activité</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {dataPlanningMissionSummary.destination.map((row) => (
                                          <TableRow>
                                              <TableCell>{format(new Date(row.date_begin), 'dd/MM/yyyy')}-{format(new Date(row.date_end), 'dd/MM/yyyy')}</TableCell>
                                              <TableCell>
                                                  {row.depart === null ? 
                                                      row.commune
                                                      : row.depart+"-"+ row.arriver
                                                  }
                                              </TableCell>
                                              <TableCell>
                                                    {row.activite.length === 0 ? 
                                                        "" :
                                                        row.activite.map((dr, index) => (
                                                            <span key={index}>{dr.libelle}{index !== row.activite.length - 1 && ', '}</span>
                                                        ))
                                                    }
                                                </TableCell>


                                          </TableRow>
                                      ))}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                        : ''
                    }
                </Grid>
              {/* <Grid item xs={5}>
                <label className={classes.label}>Voir pdf:</label>
              </Grid>
              <Grid item xs={7}>
                <label className={classes.label1}><Link to={"/PdfRender/" + lien}>{lien}.pdf</Link></label>
              </Grid> */}
            </Grid><br />
            <label>{"La demande de validation de cette mission sera envoyé pour validation de votre supérieur hiérarchique."}</label><br />
            <label>{"Confirmez-vous votre action ? "}</label>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={cancelDialogPlanningMissionSummary} >ANNULER</Button>
          <Button variant="contained" color="primary" onClick={validateDialogPlanningMissionSummary} >VALIDER</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
