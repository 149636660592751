import { Grid } from '@mui/material'
import EvolutionGraph from './EvolutionGraph'

const Evolution = () => {
  return (
    <Grid height={"69vh"}>
      <EvolutionGraph />
    </Grid>
  )
}

export default Evolution