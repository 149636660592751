import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { useParams } from "react-router-dom";
import Message from "../../tools/Message";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { useNavigate } from 'react-router-dom';
import PlanningMissionService from "../../../services/PlanningMissionService";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 800,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function CopieMailAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({user_id:[],dir:{},diru:{}});
  const [direction,setDirection]=useState([]);
  const [directionu,setDirectionu]=useState([]);
  const [dirdetailu,setDirDetailsu]=useState([]);
  const [serviceu,setServiceu]=useState([]);
  const [service_idu,setService_idu]=useState([]);

  const [dirdetail,setDirDetails]=useState([]);
  const [service,setService]=useState([]);
  const [service_id,setService_id]=useState([]);
  const [utilisateur,setUtilisateur]=useState([]);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const personal_config_id = params.id;
  const navigate = useNavigate();

  function handleChangeDirdestination(event, value) {
    setDirDetails(value);
    setForm({ ...form, dir: value });
   
    const id=parseInt(value.direction_id);
    PlanningMissionService.getService([id]).then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
  }

  function handleChangeDirdestinationu(event, value) {
    setDirDetailsu(value);
    setForm({ ...form, diru: value });
   
    const id=parseInt(value.direction_id);
    PlanningMissionService.getService([id]).then((result) => {
      if (result && result.data) {
        setServiceu(result.data);
      }
    });
  }

  function handleChangeService(event, value){
    setService_id(value);
  }

  function handleChangeServiceu(event, value){
    setService_idu(value);
    setForm({ ...form, servu: value });
  }

  useEffect(() => {
    const serviceIds = Array.isArray(service_id) ? service_id.map(item => parseInt(item.service_id, 10)) : [];
    const directionIds = [dirdetail.direction_id];
    PlanningMissionService.getIntervenant(directionIds,serviceIds).then((result) => {
      if (result && result.data) {
        setUtilisateur(result.data);
      }
    });
  }, [dirdetail, service_id]);

  useEffect(() => {
      PlanningMissionService.getDirection().then((result) => {
          if (result && result.data) {
              setDirection(result.data);
          }
      });

      PlanningMissionService.getDirection().then((result) => {
        if (result && result.data) {
            setDirectionu(result.data);
        }
      });
    if(personal_config_id) {
    }
  }, []);

  function handleClick(){   
      CategoryMissionService.insertcopie(form.type,form.diru.direction_id,service_id,form.dir.direction_id,service_idu.service_id,form.user_id).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_mail");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
      });
    
   }
    

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }



  function handleChangeIntervenants(event, value){
    setForm({ ...form, user_id: value });
  }

  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <label className={classes.labelPosition}>Type de l'envoie *</label>
          </Grid>
          <Grid item xs={10}>
          <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">
                        Type de l'envoie
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Type de la catégorie"
                          value={form.type}
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value="avant">Avant la validation finale</MenuItem>
                          <MenuItem value="apres">Après la validation finale</MenuItem>
                          <MenuItem value="au moment">Au moment de l'envoi pour validation</MenuItem>
                        </Select>
                      </FormControl>
          </Grid>
          <Grid item xs={2}>
            <label className={classes.labelPosition}>Direction des missionaires *</label>
          </Grid>
          <Grid item xs={10}>
                      <Autocomplete
                        onChange={handleChangeDirdestinationu}
                        name="directionu"
                        options={directionu}
                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
          </Grid>
           <Grid item xs={2}>
                    <label>Service des missionaires </label>
            </Grid>
            <Grid item xs={10}>
                  <Autocomplete
                      fullWidth
                      onChange={handleChangeServiceu}
                      options={serviceu} 
                      getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          fullWidth
                          label="Service"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                      />
                      )}
                 />
          </Grid>
          <Grid item xs={2}>
            <label className={classes.labelPosition}>Direction en copie *</label>
          </Grid>
          <Grid item xs={10}>
                      <Autocomplete
                        onChange={handleChangeDirdestination}
                        name="direction"
                        options={direction}
                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
          </Grid>
           <Grid item xs={2}>
                    <label>Service en copie * </label>
            </Grid>
            <Grid item xs={10}>
                  <Autocomplete
                      fullWidth
                      multiple
                      onChange={handleChangeService}
                      options={service} 
                      getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          fullWidth
                          label="Service"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                      />
                      )}
                 />
          </Grid>
          <Grid item xs={2}>
              <label className={classes.labelPosition}>Uilisateurs en copie *</label>
          </Grid>
          <Grid item xs={10}>
              <Autocomplete multiple id="tags-outlined" options={utilisateur} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                    filterSelectedOptions
                    onChange={handleChangeIntervenants}
                    value={form.user_id}
                    renderInput={(params) => (
                    <TextField
                    {...params}
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label="Utilisateurs"
                    variant="outlined"
                    placeholder="Utilisateurs"
                      />
                    )} 
                    />
            </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default CopieMailAdd;