import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Moment from "moment";
import { extendMoment } from "moment-range";
import ValidationPlanningService from "../../../services/ValidationPlanningService";
import PlanningService from "../../../services/PlanningService";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Message from "../../tools/Message";
import Alert from "@material-ui/lab/Alert";

import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

//import Switch from '@mui/material/Switch';
//const label = { inputProps: { 'aria-label': 'Switch demo' } };
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    maxWidth: 500,
    elevation: 0,
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function ValidationSettingAdd(props) {
  const navigate = useNavigate();
  const moment = extendMoment(Moment);
  const params = useParams();
  const idValidationPlanning = params.id
    ? params.id
    : "";
  const [form, setForm] = useState({
    date_start: " ",
    date_end: " ",
    libelle_planning_interval_parameter: " ",
    date_start_notification: " ",
    is_active: " ",
    next_parameter_id: " ",
    periodicity: " ",
    module_type_id:[],
  });
  const [failedLogin, setFailedLogin] = useState(false);

  const [date_start, setDate_start] = useState(null);
  const [date_end, setDate_end] = useState(null);
  const [delta, setDelta] = useState();
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [
    libelle_planning_interval_parameter,
    setLibelle_planning_interval_parameter,
  ] = useState(null);
  const [date_start_notification, setDate_start_notification] = useState(null);
  const [periodicity, setPeriodicity] = useState(null);
  const classes = useStyles();
  const [isValidDateEnd, setIsValidDateEnd] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");



  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;


  function handleBeginDateChange(newValue)
  {
      setDate_start(newValue.target.value);
    setForm({ ...form, date_start: newValue.target.value });
  }
  function handleEndDateChange(e) {
    const datefin = e.target.value;
    if (date_start > datefin) {
      setIsValidDateEnd(false);
    } else {
      setIsValidDateEnd(true);
      setForm({ ...form, date_end: datefin });
      setDate_end(datefin);
    }
  }
  function handleLibelle(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setLibelle_planning_interval_parameter(e.target.value);
  }
  function handleDateNotification(e) {
    setForm({ ...form, date_start_notification: e.target.value});
    setDate_start_notification(e.target.value);
  }


  function handlePeriodicity(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setPeriodicity(e.target.value);

  }


  function handleRadioBtnIsActiveChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleSaveParameterInterval() {
    PlanningService.periode(form).then((res) => {
      if (res.status) {
        setMessage({
          ...message,
          open: true,
          color: "green",
          messageInfo: res.message,
        });
        navigate("/mission_periode");
      } else {

        setErrorMessage("responseErrorMessage");
        setAlertSeverity("error");
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: res.message,
        });
      }
    })
      .catch((err) => {
        setFailedLogin(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "Une erreur est survenue",
        });
      });
  }

  useEffect(() => {
    if (idValidationPlanning) {
      PlanningService.periodeId(
        idValidationPlanning
      ).then((info) => {
        const resultsbeginDate = info.data.date_debut;
        const BeginDate = resultsbeginDate.split(' ');
        const resultsEndDate = info.data.date_fin;
        const endDate = resultsEndDate.split(' ');
        const resultsNotifDate = info.data.date_alerte;
        const notifDate = resultsNotifDate.split(' ');
        setForm({
          date_start: BeginDate[0],
          date_end: endDate[0],
          libelle_planning_interval_parameter:
          info.data.description,
          data_start_notification: notifDate[0],
          is_active: info.data.active,
          id: info.data.periode_id,
          periodicity: info.data.nbr_jour,
        });
      });
    }
  }, []);
  function handleCancel() {
    navigate("/mission_periode");
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveParameterInterval}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      {/* <Title>Validation Planning</Title> */}
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.label}>Date de début{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label=""
                  variant="outlined"
                  type="date"
                  value={form.date_start}
                  name="date_start"
                  onChange={handleBeginDateChange}
                />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Date fin{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
              <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label=""
                  variant="outlined"
                  type="date"
                  value={form.date_end}
                  name="date_end"
                  onChange={handleEndDateChange}
                />
            {!isValidDateEnd && <><Spacer y={2} /> <Alert severity="error">La date de début doit être inférieur à la date fin</Alert></>}
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Description:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.libelle_planning_interval_parameter}
              onChange={handleLibelle}
              name="libelle_planning_interval_parameter"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Date d'alerte{bullRed}:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label=""
                  variant="outlined"
                  type="date"
                  value={form.date_start_notification}
                  name="date_start_notification"
                  onChange={handleDateNotification}
                />
          </Grid>
          {idValidationPlanning !== "" &&
            <>

              <Grid item xs={4}>
                <label className={classes.label}>Activer:</label>
              </Grid>

              <Grid item xs={8}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    defaultValue="0"
                    name="is_active"
                    row
                    value={form.is_active}
                    onChange={handleRadioBtnIsActiveChange}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Oui" />
                    <FormControlLabel value="false" control={<Radio />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          }

          <Grid item xs={4}>
            <label className={classes.label}>Nombre du jour pour le rappel{bullRed}:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nombre du jour pour le rappel"
              variant="outlined"
              value={form.periodicity}
              onChange={handlePeriodicity}
              name="periodicity"
            />
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer >
  );
}
export default ValidationSettingAdd;
