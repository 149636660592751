import React, { useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import Alert from "@material-ui/lab/Alert";
import PlanningMissionService from "../../../services/PlanningMissionService";
import TDRService from "../../../services/TDRService";
import LocalisationMisService from "../../../services/LocalisationMisService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Message from "../../tools/Message";
import { FormControl, InputLabel, Select, MenuItem, useStepContext } from '@mui/material';
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import Title from "../../../components/Title"; 
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from "@material-ui/core";
import moment from "moment";
import MissionService from "../../../services/MissionService";
import DateFnsUtils from '@date-io/date-fns';
import { format, isBefore } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import { DeleteOutlineRounded, CreateTwoTone} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600
    },
    labelPosition1: {
        float: 'right',
    },
});
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

function AgendaMission(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [type,setType]=useState('DR');
    const [directionInMis, setDirectionInMis] = useState([]);
    const [direction, setDirection] = useState([]);
    const [regionInMis, setRegionInMis] = useState([]);
    const [districtInMis, setdistrictInMis] = useState([]);
    const [communeInMis, setcommuneInMis] = useState([]);
    const [activite,setActivite]=useState([]);
    const [isValidDate, setIsValidDate] = useState(false);
    const [isautre,setAutres]=useState(false);
    const [title,setTitle]=useState("");
    const [lieu,setLieu]=useState("");
    const [financement,setFinancement]=useState([]);
    const [error, setError] = useState('');
    const [loading,setLoading]=useState(false);
  
    const formatTime = (date) => {
      return date ? format(new Date(date), 'HH:mm a') : 'N/A';
    };
    const options = [
        { value: 'DR', label: 'Délais de route' },
        { value: 'DM', label: 'Mission' },
      ];

      const [form, setForm] = useState({
        category_mission_id: {},
        mission_title: "",
        direction_destination: {},
        directionmis:null,
        region_destination: {},
        district_destination: {},
        commune_destination: "",
        localisation_destination: "",
        transport_type: "",
        mission_begin_date: "",
        mission_end_date: "",
        common: "",
        location: "",
        type_activity_id: [],
        funding_id: [],
        activite:[],
        financement:{},
        activiteautre:"",
        heure_depart:null,
        heure_arriver:null,
        typeactive:''
      });

      const handleAdd =()=>{
        
        if (type==="DR") {
         
          if (route.date_depart===''|| route.date_retour==='' || route.heure_arriver==='' || route.heure_depart==='' || route.lieu_arriver==='' || route.lieu_depart==='') {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Veuillez completer les informations" });
          }
          else{
            setTableData([...tableData, route]);
            setRoute({
              date_depart: '',
              date_retour: '',
              lieu_depart: '',
              lieu_arriver:'',
              heure_depart:null,
              heure_arriver:null
            });
          }
         
        }
        else{
          if (form.mission_begin_date===''|| form.mission_end_date==='' || form.heure_arriver==='' || form.heure_depart==='') {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Veuillez completer les informations" });
          }
          else{
              setTableData2([
              ...tableData2,
                  {
                      commune_destination: form.commune_destination,
                      direction_destination: form.direction_destination,
                      directionmis:form.directionmis,
                      district_destination: form.district_destination,
                      localisation_destination: form.localisation_destination,
                      region_destination: form.region_destination,
                      mission_begin_date: form.mission_begin_date,
                      mission_end_date: form.mission_end_date,
                      activite:form.activite,
                      activiteautre:form.activiteautre,
                      heure_depart:form.heure_depart,
                      heure_arriver:form.heure_arriver,
                      typeactive:form.typeactive
                  }
                ]);
                setForm(prevForm => ({
                  ...prevForm,
                  commune_destination: "",
                  direction_destination:{},
                  district_destination: {},
                  localisation_destination: '',
                  region_destination: {},
                  mission_begin_date: '',
                  mission_end_date: '',
                  activite:[],
                  activiteautre:'',
                  heure_depart:null,
                  heure_arriver:null,
                  typeactive:''
                }));
                setType('DM');
          }
            
        
        }
      }
    

    const [message, setMessage] = React.useState({
      open: false,
      color: "green",
      messageInfo: "",
    });
    const { open, color, messageInfo } = message;
  

      const [route, setRoute] = useState({
        date_depart: '',
        date_retour: '',
        lieu_depart: '',
        lieu_arriver:'',
        heure_depart:null,
        heure_arriver:null
    });
    const [tableData, setTableData] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [errorheure,setErrorheure]=useState('');
    const [errordate,setErrorDate]=useState('');
    const [errordate2,setErrorDate2]=useState('');
    const [sugg,setSugg]=useState('');
   

    const handletype=(e,newvalue)=>{
      setType(newvalue.value);
    }
    function isBeforeOrEqual(date1, date2) {
      return date1 <= date2;
  }

    const handleChangeheure = (date, fieldName) => {
      setRoute(prevRoute => {
        const newRoute = {
          ...prevRoute,
          [fieldName]: date,
        };
  
        if (newRoute.heure_depart && newRoute.heure_arriver) {
          // if (isBefore(newRoute.heure_depart, newRoute.heure_arriver)) {
          //   setErrorheure(''); 
          // } else {
          //   setErrorheure('L\'heure de départ doit être inférieure à l\'heure d\'arrivée.');
          // }
        }
  
        return newRoute;
      });
    };

    const handleChangeheureform = (date, fieldName) => {
      setForm(prevForm => {
        const newForm = {
          ...prevForm,
          [fieldName]: date,
        };
  
        if (newForm.heure_depart && newForm.heure_arriver) {
          // if (isBefore(newForm.heure_arriver , newForm.heure_depart)) {
          //   setError(''); 
            
          // } else {
          //   setError('L\'heure de départ doit être inférieure à l\'heure d\'arrivée.');
          // }
        }
        return newForm;
      });
    };
  
    
    const handleChangeroute = (e) => {
      const { name, value } = e.target;
  
      // Mise à jour de l'état avec la nouvelle valeur
      setRoute(prevRoute => {
        const newRoute = { ...prevRoute, [name]: value };
  
        // Validation des dates
        if (newRoute.date_depart && newRoute.date_retour) {
          const dateDepart = new Date(newRoute.date_depart);
          const dateRetour = new Date(newRoute.date_retour);
  
          if (isBeforeOrEqual(dateDepart, dateRetour)) {
            setErrorDate(''); 
          } else {
            setErrorDate('La date de départ doit être antérieure à la date de retour.');
          }
        }
  
        return newRoute;
      });
    };
  
   
    const handleChangetitle = (e) => {
      setTitle(e.target.value);
    };

    const handleChangelieu = (e) => {
      setLieu(e.target.value);
    };

    const handleconfirm =()=>{
      setLoading(true);
      if (title.trim() === "") {
        setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez ajouter un titre'});
        setLoading(false);
      } else {
        MissionService.insertagenda(title,lieu,tableData,tableData2).then((result) => {
          if (result) {
            setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
            setLoading(false);
            setTimeout(function () {navigate("/mission/agendalist"); }, 4000);
          }
          else{
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
            setLoading(false);
          }
        });
      }
    
    }
    const handleChange = (ev) => {
      const { name, value } = ev.target;
       setForm({ ...form,[name]: value });
   }

    function handleChangeDistrict(event, value) {
      setForm({ ...form, district_destination: value.nom_district });
      LocalisationMisService.getCommunesByDistrict(value.id_district).then((result) => {
        if (result && result.data) {
          setcommuneInMis(result.data);
        }
      });
    }
  
  
    const handleChangeActivite= (selectedValues) => {  
      setForm({ ...form, activite:selectedValues});
      selectedValues.forEach(element => {
        if (element.libelle_type_activite==='Autres') {
          setAutres(true);
        }
      });
    }
    const handleChangefinancement= (selectedValues) => {  
      setForm({ ...form, financement:selectedValues});
    }
    
  
    function handleChangeCommune(event, value) {
        setForm({ ...form, commune_destination: value.nom_commune });
    }

    function handleChangeRegion(event, value) {
      setForm({ ...form, region_destination: value.nom_region });
      LocalisationMisService.getDistrictByRegion(value.id_region).then((result) => {
        if (result && result.data) {
          setdistrictInMis(result.data);
        }
      });
    }

    const handleDelete = (index) => {
      setTableData(prevData => prevData.filter((_, i) => i !== index));
    };

    const handleModif = (index) =>{
      
      const dataToModify = tableData[index]; 
      setRoute({
          date_depart: dataToModify.date_depart,
          date_retour: dataToModify.date_retour,
          lieu_depart: dataToModify.lieu_depart,
          lieu_arriver: dataToModify.lieu_arriver,
          heure_depart: dataToModify.heure_depart,
          heure_arriver: dataToModify.heure_arriver
      });
       setType('DR');
       setTableData(prevData => prevData.filter((_, i) => i !== index));  
    }


    const handleModifDM = (index) =>{
      
      const dataToModify = tableData2[index]; 
      setForm({
        commune_destination:dataToModify.commune_destination,
        direction_destination:dataToModify.direction_destination,
        district_destination: dataToModify.district_destination,
        localisation_destination: dataToModify.localisation_destination,
        region_destination: dataToModify.region_destination,
        mission_begin_date: dataToModify.mission_begin_date,
        mission_end_date: dataToModify.mission_end_date,
        activite:dataToModify.activite,
        activiteautre:dataToModify.activiteautre,
        heure_depart:dataToModify.heure_depart,
        heure_arriver:dataToModify.heure_arriver,
        typeactive:dataToModify.typeactive
       });
       setType('DM');
       setTableData2(prevData => prevData.filter((_, i) => i !== index));  
    }

    const handleDeleteMission = (index) => {
      setTableData2(prevData => prevData.filter((_, i) => i !== index));
    };
    

    useEffect(() => {
      LocalisationMisService.getActivite(form.region_destination,form.district_destination,form.commune_destination).then((result) => {
        if (result ) {
          setActivite(result);
        }
      });
    }, [form.commune_destination]);


    useEffect(() => {
      LocalisationMisService.getAllDirection().then((result) => {
        if (result && result.data) {
          setDirection(result.data);
        }
      });

      LocalisationMisService.getAllDirectionMIS().then((result) => {
        if (result && result.data) {
           setDirectionInMis(result.data);
        }
      });
      
      LocalisationMisService.getFinancement().then((result) => {
        if (result ) {
          setFinancement(result);
        }
      });

      MissionService.getsugestion().then((result)=>{
        if (result ) { 
          setSugg(result.data);
        }
      })

    }, []);

    function handleChangeDate(ev) {

      const { name, value } = ev.target;

    // Mise à jour de l'état avec la nouvelle valeur
    setForm(prevForm => {
      const newForm = { ...prevForm, [name]: value };

      // Validation des dates
      if (newForm.mission_begin_date && newForm.mission_end_date) {
        const dateDepart = new Date(newForm.mission_begin_date);
        const dateRetour = new Date(newForm.mission_end_date);

        if (isBeforeOrEqual(dateDepart, dateRetour)) {
          setErrorDate2(''); 
        } else {
          setErrorDate2('La date de départ doit être antérieure à la date de retour.');
        }
      }

      return newForm;
    });
    }

    function handleCloseMessage(){
      setMessage({...message, open: false});
    }
    function handleChangeDirection(event, value) {
      setForm({ ...form, direction_destination: value});
    }

    function handleChangeDirectionMIS(event, value) {

      setForm({ ...form, directionmis: value});
      LocalisationMisService.getRegionsByDirection(value.code).then((result) => {
        if (result && result.data) {
          setRegionInMis(result.data);
        }
      });
    }



    return(
        <MainContainer {...headerDataMission}>
        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <Title>Agenda</Title>
        <Spacer y={2} />
        <Grid item xs={12}>
            <fieldset>
                <legend>Généralité Destination</legend>
                <br />
                <Grid container spacing={3}>
                        <Grid item xs={2}>
                             <label className={classes.labelPosition}>Intitulé :</label>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label="Intitulé"
                              variant="outlined"
                              value={title}
                              name="title"
                              placeholder={sugg || ""}
                              onChange={handleChangetitle}
                            />
                          </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            {(tableData.length>0 || tableData2.length>0) &&
                              <>
                                <TableHead>
                                    <TableRow>
                                      <TableCell>Date</TableCell>
                                      <TableCell>Heure</TableCell>
                                      <TableCell>Lieu</TableCell>
                                      <TableCell>Activité</TableCell>
                                      <TableCell>Autre Activité</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                              </> 
                               
                            }
                           
                            <TableBody>
                                {tableData.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{moment(data.date_depart).format('DD/MM/YYYY')} - {moment(data.date_retour).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell> {formatTime(data.heure_depart)} - {formatTime(data.heure_arriver)}</TableCell>
                                        <TableCell>{data.lieu_depart} - {data.lieu_arriver}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Button onClick={(e) => {
                                                e.stopPropagation(); 
                                                handleModif(index);
                                            }}>
                                                <CreateTwoTone style={{ color: "black" }} />
                                            </Button>
                                            <Button onClick={(e) => {
                                                e.stopPropagation(); 
                                                handleDelete(index);
                                            }}>
                                                <DeleteOutlineRounded style={{ color: "red" }} />
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                ))}
                                 {tableData2.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{moment(data.mission_begin_date).format('DD/MM/YYYY')} - {moment(data.mission_end_date).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell> {formatTime(data.heure_depart)} - {formatTime(data.heure_arriver)}</TableCell>
                                        <TableCell>{data.direction_destination.name}</TableCell>
                                       <TableCell>
                                       {data.activite.length > 0 && data.activite.map((act, indexs) => {
                                            return (
                                                <span key={indexs}>
                                                    {indexs > 0 && " - "} 
                                                    {typeof act.libelle_type_activite === 'string' ? act.libelle_type_activite : 'Invalid data'}
                                                </span>
                                            );
                                        })}

                                        </TableCell>
                                        <TableCell>{data.activiteautre}</TableCell>
                                        <TableCell>
                                             
                                             <Button onClick={(e) => {
                                                e.stopPropagation(); 
                                                handleModifDM(index);
                                              }}>
                                                <CreateTwoTone style={{ color: "black" }} />
                                            </Button>
                                           <Button onClick={(e) =>
                                            {
                                              e.stopPropagation(); 
                                              handleDeleteMission(index)
                                            }}>
                                                    <DeleteOutlineRounded style={{ color: "red" }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                      </TableContainer>
                  </Grid>
                    <Grid item xs={2}>
                      <label className={classes.labelPosition}>Type</label>
                    </Grid>
                    <Grid items xs={10}>
                       <Autocomplete
                            id="demo-select-small"
                            options={options}
                            value={options.find(option => option.value === type) || null}
                            onChange={handletype}
                            getOptionLabel={(option) => option.label}
                            size="small"
                            style={{ margin: "10px" }}
                            renderInput={(params) => <TextField {...params} label="Type" variant="outlined" size="small" />}
                        />
                    </Grid>
                    {type==="DR" &&
                       <>   
                                 <Grid item xs={2}  container>
                                  <Typography variant="body2">Date :</Typography>
                                </Grid>
                                <Grid item xs={5}  >
                                  <Typography variant="body2" className={classes.labelPosition}>Date de départ</Typography>
                                  <TextField
                                    fullWidth
                                    id="date-depart"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    value={route.date_depart}
                                    name="date_depart"
                                    onChange={handleChangeroute}
                                  />
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="body2" className={classes.labelPosition}>Date d'arrivé</Typography>
                                  <TextField
                                    fullWidth
                                    id="date-retour"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    value={route.date_retour}
                                    name="date_retour"
                                    onChange={handleChangeroute}
                                  />
                                </Grid>
                                {errordate && (
                                  <Grid item xs={12} justifyContent="center" align="center"s>
                                    <Typography variant="body2" color="error">
                                      {errordate}
                                    </Typography>
                                  </Grid>
                                )}
                                <Grid item xs={2}  container>
                                  <Typography variant="body2">Heure :</Typography>
                                </Grid>
                                <Grid item xs={5} >
                                  <Typography variant="body2" className={classes.labelPosition}>Heure de départ</Typography>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="time-retour"
                                        label=""
                                        fullWidth    
                                        value={route.heure_depart} 
                                        onChange={(time) => handleChangeheure(time, 'heure_depart')}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change time',
                                        }}
                                      />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="body2" className={classes.labelPosition}>Heure d’arrivée</Typography>
                                  <MuiPickersUtilsProvider  style={{width:'100%'}} utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                      margin="normal"
                                      id="time-depart"
                                      label=""
                                      fullWidth  
                                      value={route.heure_arriver} 
                                      onChange={(time) => handleChangeheure(time, 'heure_arriver')}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                    />
                                    
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                {errorheure && (
                                  <Grid item xs={12} justifyContent="center" align="center"s>
                                    <Typography variant="body2" color="error">
                                      {errorheure}
                                    </Typography>
                                  </Grid>
                                )}
                                <Grid item xs={2}  container>
                                  <Typography variant="body2">Lieu :</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography variant="body2" className={classes.labelPosition}>Départ</Typography>
                                  <TextField
                                    fullWidth
                                    id="lieu-depart"
                                    size="small"
                                    variant="outlined"
                                    value={route.lieu_depart}
                                    name="lieu_depart"
                                    onChange={handleChangeroute}
                                  />
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="body2" className={classes.labelPosition}>Vers</Typography>
                                  <TextField
                                    fullWidth
                                    id="lieu-arriver"
                                    size="small"
                                    variant="outlined"
                                    value={route.lieu_arriver}
                                    name="lieu_arriver"
                                    onChange={handleChangeroute}
                                  />
                                </Grid>
                       </>
                    }
                    {type==="DM"&&
                        <>
                     
                        <Grid item xs={2}>
                          <label className={classes.labelPosition}>Du</label>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            size="small"
                            label=""
                            variant="outlined"
                            type="date"
                            value={form.mission_begin_date}
                            name="mission_begin_date"
                            onChange={handleChangeDate}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <center>
                            <label>à</label>
                          </center>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            size="small"
                            label=""
                            variant="outlined"
                            type="date"
                            value={form.mission_end_date}
                            name="mission_end_date"
                            onChange={handleChangeDate}
                          />
                        </Grid>
                        {errordate2 && (
                                  <Grid item xs={12} justifyContent="center" align="center"s>
                                    <Typography variant="body2" color="error">
                                      {errordate2}
                                    </Typography>
                                  </Grid>
                                )}
                          <Grid item xs={2}  container>
                                  <Typography variant="body2">Heure :</Typography>
                                </Grid>
                                <Grid item xs={5} >
                                  <Typography variant="body2" className={classes.labelPosition}>Heure de début de l’activité</Typography>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    
                                    <KeyboardTimePicker
                                      margin="normal"
                                      id="time-retour"
                                      label=""
                                      fullWidth    
                                      value={form.heure_depart} 
                                      onChange={(time) => handleChangeheureform(time, 'heure_depart')}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="body2" className={classes.labelPosition}>Heure de fin de l'activité</Typography>
                                  <MuiPickersUtilsProvider  style={{width:'100%'}} utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                      margin="normal"
                                      id="time-depart"
                                      label=""
                                      fullWidth  
                                      value={form.heure_arriver} 
                                      onChange={(time) => handleChangeheureform(time, 'heure_arriver')}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                     />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                {error && (
                                  <Grid item xs={12} justifyContent="center" align="center"s>
                                    <Typography variant="body2" color="error">
                                      {error}
                                    </Typography>
                                  </Grid>
                                )}
                            <Grid item xs={2}>
                              <label className={classes.labelPosition}>Type d'activité</label>
                            </Grid>
                            <Grid item xs={10}>
                              <FormControl fullWidth>
                                  <InputLabel id="activity-select-label">Choisir une activité</InputLabel>
                                  <Select
                                    labelId="activity-select-label"
                                    id="activity-select"
                                    value={form.typeactive}
                                    name="typeactive"
                                    onChange={handleChange}
                                    label="Choisir une activité"
                                  >
                                    <MenuItem value="mis">Activité MIS</MenuItem>
                                    <MenuItem value="hors_mis">Activité hors MIS</MenuItem>
                                  </Select>
                                </FormControl>
                            </Grid>
                        <Grid  item xs={12}>
                          {form.typeactive==="mis"&&
                          <fieldset>
                             <legend>Activité MIS</legend>
                            <Grid item xs={2}>
                            <label className={classes.labelPosition}>Direction</label>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                              <Autocomplete
                                onChange={handleChangeDirectionMIS}
                                options={directionInMis || []} 
                                value={form.directionmis || null} 
                                getOptionLabel={(option) => option ? `${option.libelle}` : ''} 
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Direction"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                                )}
                              />
                            </Grid>
                            {regionInMis.length>0 &&
                              <>
                                <Grid item xs={2}>
                                  <label className={classes.labelPosition}>Region</label>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:'10px'}}>
                                  <Autocomplete
                                    onChange={handleChangeRegion}
                                    options={regionInMis}
                                    value={regionInMis.find(option => option.nom_region === form.region_destination) || null}
                                    getOptionLabel={(option) => option.nom_region}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        label="Region"
                                        size="small"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: "search" }}
                                      />
                                    )}
                                  />
                                </Grid>
                               </>
                            }
                            {districtInMis.length>0 &&
                               <>   
                            <Grid item xs={2}>
                              <label className={classes.labelPosition}>District{bullRed}</label>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                            <Autocomplete
                                onChange={handleChangeDistrict}
                                options={districtInMis}
                                value={districtInMis.find(option => option.nom_district === form.district_destination) || null}
                                getOptionLabel={(option) => option.nom_district}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="outlined-basic"
                                    label="District"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                                )}
                              />
                            </Grid>
                            </>
                            }
                            {communeInMis.length>0 &&
                             <>
                            
                            <Grid item xs={2}>
                              <label className={classes.labelPosition}>Commune</label>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                            <Autocomplete
                                onChange={handleChangeCommune}
                                options={communeInMis}
                                value={communeInMis.find(option => option.nom_commune === form.commune_destination) || null}
                                getOptionLabel={(option) => option.nom_commune}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Commune"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                                )}
                              />
                            </Grid>
                            </>
                            }
                            {activite.length>0 && 
                                <>  
                                <Grid item xs={2}>
                                  <label className={classes.labelPosition}>
                                    Activité
                                  </label>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:'10px'}}>
                                <Autocomplete
                                      multiple
                                      onChange={(event, value) => handleChangeActivite(value)} 
                                      options={activite} 
                                      getOptionLabel={(option) => option.libelle_type_activite}
                                      noOptionsText="Aucun résultat trouvé"
                                      filterOptions={(options, { inputValue }) => { 
                                          return options.filter((option) =>
                                              option && option.libelle_type_activite.toLowerCase().includes(inputValue.toLowerCase())
                                          );
                                      }}
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              fullWidth
                                              id="outlined-basic"
                                              label="Activité"
                                              size="small"
                                              variant="outlined"
                                              InputProps={{ ...params.InputProps, type: "search" }}
                                              placeholder="Recherche ici" 
                                          />
                                      )}
                                  />
                                </Grid>
                                </>
                            }
                             {financement.length>0 &&
                              <>
                            
                            <Grid item xs={2}>
                              <label className={classes.labelPosition}>
                                FInancement
                              </label>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                              <Autocomplete
                                  multiple
                                  onChange={(event, value) => handleChangefinancement(value)} 
                                  options={financement} 
                                  getOptionLabel={(option) => option.code_financement}
                                  noOptionsText="Aucun résultat trouvé"
                                  filterOptions={(options, { inputValue }) => { 
                                      return options.filter((option) =>
                                          option.code_financement.toLowerCase().includes(inputValue.toLowerCase())
                                      );
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          fullWidth
                                          id="outlined-basic"
                                          label="Financement"
                                          size="small"
                                          variant="outlined"
                                          InputProps={{ ...params.InputProps, type: "search" }}
                                          placeholder="Recherche ici" 
                                      />
                                  )}
                              />
                              </Grid>
                              </>
                            }
                            {/* <Grid item xs={2}>
                              <label className={classes.labelPosition}>Rubrique sécurité</label>
                            </Grid>
                            <Grid items xs={10}>
                                <TextField
                                      fullWidth
                                      id="outlined-basic"
                                      size="small"
                                      label="Rubrique sécurité"
                                      variant="outlined"
                                      value="Zone blanche"
                                      name="activiteautre"
                                      InputProps={{
                                          readOnly: true,
                                      }}
                                  />
                            </Grid> */}
                        </fieldset>
                         }
                         {form.typeactive==="hors_mis"&&
                           <fieldset>
                              <legend>Activité hors mis</legend>
                              <Grid item xs={2}>
                              <label className={classes.labelPosition}>Direction</label>
                              </Grid>
                              <Grid item xs={12} style={{marginBottom:'10px'}}>
                                <Autocomplete
                                  onChange={handleChangeDirection}
                                  options={direction}
                                 // value={directionInMis.find(option => option.direction_id === form.direction_destination) || null}
                                 value={form.direction_destination ||null}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Direction"
                                      size="small"
                                      variant="outlined"
                                      InputProps={{ ...params.InputProps, type: "search" }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <label className={classes.labelPosition}>
                                    Activité separé par virgule
                                  </label>
                              </Grid>
                              <Grid item xs={12} style={{marginBottom:'10px'}}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label="Autre activité"
                                variant="outlined"
                                value={form.activiteautre}
                                name="activiteautre"
                                onChange={handleChange}
                              />
                              </Grid>
                              <Grid item xs={2}>
                              <label className={classes.labelPosition}>
                                FInancement
                              </label>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                              <Autocomplete
                                  multiple
                                  onChange={(event, value) => handleChangefinancement(value)} 
                                  options={financement} 
                                //  value={form.financement || []}
                                  getOptionLabel={(option) => option.code_financement}
                                  noOptionsText="Aucun résultat trouvé"
                                  filterOptions={(options, { inputValue }) => { 
                                      return options.filter((option) =>
                                          option.code_financement.toLowerCase().includes(inputValue.toLowerCase())
                                      );
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          fullWidth
                                          id="outlined-basic"
                                          label="Financement"
                                          size="small"
                                          variant="outlined"
                                          InputProps={{ ...params.InputProps, type: "search" }}
                                          placeholder="Recherche ici" 
                                      />
                                  )}
                              />
                            </Grid>
                           </fieldset>
                         }
                        </Grid>
                      </>
                    }

                      <Grid item xs={2}>
                      </Grid>
                      <Grid item xs={10}>
                          <Button variant="outlined"   color="primary"  className={classes.labelPosition1} onClick={handleAdd}>Ajouter </Button>
                      </Grid>
                </Grid>
                <br />
              </fieldset>
            </Grid>
            <Spacer y={2} />
            <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12}>
                    <div className={classes.labelPosition1}>
                    <Grid container item xs={12} >
                      <LoadingButton
                          onClick={handleconfirm}
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                      >
                          ENREGISTRER
                      </LoadingButton>
                    </Grid>
                    </div>        
                </Grid>
            </Grid>
        </MainContainer>
    );
}
export default AgendaMission;
