import { DialogTitle, Paper } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_categorie_critere_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const CategoryCritereForm = ({
  isOpen,
  current_category_critere,
  handleClose,
}) => {
  const { showToast, Toast } = useToast();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [category_critere, setCategoryCritere] = useState({
    ID_CATEGORY: 0,
    NOM_CATEGORY: "",
  });

  const handleSubmit = () => {
    if (isAllFieldOk()) {
      if (category_critere.ID_CATEGORY > 0) {
        handleUpdateCategory();
      } else {
        handleInsertCategory();
      }
    }
  };
  const isAllFieldOk = () => {
    let ok = false;
    if (!category_critere.NOM_CATEGORY) {
      ok = false;
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Le nom de la catégori est obligatoire"
      );
    } else {
      if (category_critere.NOM_CATEGORY.trim() === "") {
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Le nom de la catégori est obligatoire"
        );
        ok = false;
      }
      ok = true;
    }
    return ok;
  };

  const handleInsertCategory = () => {
    evaluation_categorie_critere_service
      .insert_evaluation_category(category_critere)
      .then((res) => {
        if (res && res.success) {
          handleClose();
          clearAllFields();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleUpdateCategory = () => {
    evaluation_categorie_critere_service
      .updateEvaluationCategory(category_critere)
      .then((res) => {
        if (res && res.success) {
          handleClose();
          clearAllFields();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  useEffect(() => {
    if (current_category_critere) {
      setCategoryCritere(current_category_critere);
    }
  }, [current_category_critere]);

  const clearAllFields = () => {
    setCategoryCritere({ ID_CATEGORY: 0, NOM_CATEGORY: "" });
  };

  const handleChangeCategoryCritere = (evt) => {
    const { name, value } = evt.target;
    if (value) setCategoryCritere({ ...category_critere, [name]: value });
  };

  const handleDialogClose = () => {
    handleClose();
    clearAllFields();
  };
  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {category_critere.ID_CATEGORY > 0
                ? "Modifier Categorie critère"
                : "Nouvelle Categorie critère"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid flexDirection={"row"} mt={0.5}>
                <TextField
                  size="small"
                  fullWidth
                  label="Nom de la catégorie"
                  name="NOM_CATEGORY"
                  value={category_critere.NOM_CATEGORY}
                  onChange={(e) => handleChangeCategoryCritere(e)}
                />
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSubmit}
                variant="contained"
              >
                Valider
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default CategoryCritereForm;
