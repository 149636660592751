import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { FindInPageTwoTone } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import { Done, Clear } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationDialogAppui from "../../tools/ConfirmationDialogAppui";
import ConfirmationDialogueRequest from "../../tools/ConfirmDialogueRequest";
import RefusedDialog from "../../tools/RefusedDialog";
import { useNavigate } from "react-router-dom";
import LoadingSimpleBackdrop from "../../tool-accepted/LoadingSimpleBackdrop";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../../../components/Title";
import { createTheme } from "@material-ui/core/styles";
import AcceptedHelpService from "../../../services/AcceptedHelpService";
import RefusedRequestService from "../../../services/RefusedRequestService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TDRService from "../../../services/TDRService";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const backgroundColor =
      theme.palette.mode === 'dark' ? '#376331' : '#FFFF62';
    return {
      root: {
        '& .MuiDataGrid-cell--editable': {
          backgroundColor,
        },
      },
    };
  },
  { defaultTheme },
);
function MyValidation(props) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [requestSummaryId, setRequestSummaryId] = useState({});
  const [rows, setRows] = useState([]);
  const [refused,setRefused]=useState('');
  const classes = useStyles();
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [openDialogRequestSummary, setOpenDialogRequestSummary] = React.useState(false);
  const [openvalidate, setOpenvalidate] = useState(false);
  const [statut_val,setStatut_val]=useState({ label: 'En attente de validation', id: 'En attente de validation' });
  const statut = [
    { label: 'Aucun', id: 'Aucun' },
    { label: 'En attente de validation', id: 'En attente de validation' },
    { label: 'Réfusé', id: 'Réfusé' },
    { label: 'Accepté', id: 'Accepté' },
  ];

  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openrefused, setOpenRefused] = useState(false);
  const [openobserv,setOpenobserv]=useState(false);
  const [rowvalidate,setRowValidate]=useState({});
  const [detail,setDetail]=useState({});


  const handleClose = () => {
    setOpenvalidate(false);
  };

  const handleCloseObservation = () => {
    setOpenobserv(false);
  };
  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    AcceptedHelpService.getAll(statut_val.id).then((result) => {
      if (result && result.data) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.id,
          }))
        );

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
    setOpenLoadingSimpleBackdrop(false);
  }, [statut_val]);

  const handlevalidate=(row)=>()=>{
     setRowValidate(row);
     setOpenvalidate(true);
     

     TDRService.requestID(row.mission_request_id).then((result) => {
      if (result && result.data) {
           setDetail(result.data);
      } else {
         setDetail({});
      }
    });
  }

  function confirmDialog() {    
   AcceptedHelpService.updateAccepted(requestSummaryId.mission_request_id,requestSummaryId.lastvalidation,requestSummaryId.type_validation,requestSummaryId.number_validation,requestSummaryId.file_id,requestSummaryId.user_id,'','')
     .then((res) => {
       var responseErrorMessage = res.message;
       if (res.status) {
         setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
         setOpenDialog(false);
         setRows(
           res.data.map((row) => ({
             ...row,
             id: row.id,
           }))
         );
         navigate("/acceptedHelp");
        
       } else {
         navigate("/acceptedHelp");
         setOpenDialog(false);
         setMessage({ ...message, openMessage: true, color: 'red', messageInfo: responseErrorMessage });
       }
     })
     .catch((err) => {
       setOpenDialog(false);
       setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
     });
 }


  const validateFile = (row) => () => {
    setRequestSummaryId(row);
    if (row.lastvalidation === "true") {
      if (row.type_validation === "interne") {
        setOpenvalidate(false);
        setOpenDialogRequestSummary(true);
        setOpenDialog(true);
        setOpenLoadingSimpleBackdrop(true);
        
      } else if (row.type_validation === "externe") {
        setOpenvalidate(false);
        setOpenDialog(false);
        setOpenobserv(true);
      } else {
        confirmDialog(); 
      }
    } else {
      confirmDialog(); 
    }
    
  }


  const handleAnnuler = (row) => () => {
    setOpenvalidate(false);
    setRequestSummaryId(row);
    setOpenRefused(true);
    setOpenLoadingSimpleBackdrop(true);
  }


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };

  const columns = [
    // {
    //   field: 'number',
    //   headerName: 'N°',
    //   width: 250,
    // },
    {
      field: 'type_mission_request',
      headerName: 'Type de module',
      width: 250,
    },
    {
      field: 'reason',
      headerName: 'Raison',
      width: 250,
    },
    {
      field: 'name_demandeur',
      headerName: 'Nom du demandeur',
      width: 250,
    },
    {
      field: 'date',
      headerName: 'Date désiré',
      width: 150,
    },
    {
      field: 'number_day',
      headerName: 'Nombre de jour',
      width: 200,
    },
    {
      field: "type_mission_request",
      headerName: "Type",
      width: 200,
      editable: true,
    },
    {
      field: "mission_request_user_required_state",
      headerName: "Status",
      width: 200,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <span>

          {params.row.mission_request_user_required_state !== "ACCEPTÉ" && params.row.mission_request_user_required_state !== "REFUSÉ" &&
            <Button
              onClick={handlevalidate(params.row)}
              title="Validé la demande d'appui"
            >
              <FindInPageTwoTone style={{ color: "black" }} />
            </Button>
          }
        </span>
      ),
      editable: true,
    },
  ];
  const [ConfirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Souhaitez-vous vraiment envoyer la demande d\'appui  ?',
  });
  const { messageDialog } = ConfirmationDialog;
  function closeDialog() {
    setOpenDialog(false);
  }

  function closeDialogrefused() {
    setOpenRefused(false);
    navigate("/acceptedHelp");
    setOpenLoadingSimpleBackdrop(false);
  }

  const handleInput = (event) => {
    setRefused(event.target.value);
  };

  const handleValidaterefused = () => {
    
    RefusedRequestService.refusedvalidate(requestSummaryId.mission_request_id,refused)
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setRows(
            res.data.map((row) => ({
              ...row,
              id: row.id,
            }))
          );
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          setOpenDialog(false);
          navigate("/acceptedHelp");
          setOpenLoadingSimpleBackdrop(false);
         
        } else {
          navigate("/acceptedHelp");
          setOpenDialog(false);
          setOpenLoadingSimpleBackdrop(false);
          setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Error failed" });
        }
      })
      .catch((err) => {
        setOpenDialog(false);
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });
      setOpenRefused(false);
  };

 
  function cancelDialogObservation() {
    setOpenobserv(false);
    setOpenLoadingSimpleBackdrop(false);
  }

  function cancelDialog() {
    // setConfirmationDialog({ ...ConfirmationDialog, openDialog: false });
    setOpenDialog(false);
    setOpenLoadingSimpleBackdrop(false);
  }

  const handleCloseRequestSummary = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpenDialogRequestSummary(false);
    window.location.reload(false);

  }

  const handleChangeTypeStatut = (event, newValue) => {
    setStatut_val(newValue); 
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <React.Fragment>
      <ConfirmationDialogAppui openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog}  detail={requestSummaryId}/>
      <ConfirmationDialogueRequest openDialog={openobserv}  closeDialog={handleCloseObservation} cancelDialog={cancelDialogObservation}  detail={requestSummaryId}/>
      <RefusedDialog openrefused={openrefused}  handleCloserefused={closeDialogrefused} onChangeInputValue={handleInput}  handleValidaterefused={handleValidaterefused}/>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
          <Grid item xs={3}>
                  <Autocomplete
                    id="tags-outlined"
                    options={statut}
                    getOptionLabel={(option) => option.label}
                   onChange={handleChangeTypeStatut}
                    value={statut_val}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Statut"
                        variant="outlined"
                        placeholder="Statut"
                      />
                    )}
                  />
                </Grid>
                <br/><br/>
      <Title>Liste des demandes d'appui</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          className={classes.root}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          isCellEditable={(params) => params.row.is_priority == "1"}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },

          }}
        />
      </div>
      <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openvalidate}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Validation demande d'appui
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid item container spacing={2}>
                   <Grid item  xs={4}>
                       Réference:
                    </Grid>
                    <Grid item  xs={8}>
                      {detail.mission_request_reference}
                    </Grid>
                    <Grid item  xs={4}>
                       Raison:
                    </Grid>
                    <Grid item  xs={8}>
                      {detail.reason}
                    </Grid>
                    <Grid item  xs={4}>
                       Description:
                    </Grid>
                    <Grid item  xs={8}>
                      {detail.description}
                    </Grid>
                    <Grid item  xs={4}>
                       Nombre de jour:
                    </Grid>
                    <Grid item  xs={8}>
                      {detail.number_day}
                    </Grid>
                    <Grid item  xs={4}>
                       Date desiré:
                    </Grid>
                    <Grid item  xs={8}>
                      {detail.date}
                    </Grid>
                    <Grid item  xs={4}>
                     Demande adressée à :
                    </Grid>
                    <Grid item  xs={8}>
                    {
                        detail.demande === "direction" ? (
                          detail.name_direction
                        ) : detail.demande === "service" ? (
                          `${detail.name_direction} - ${detail.name_service}`
                        ) : detail.user && detail.user.length > 0 ? (
                          detail.user.map((ts, index) => (
                            `${ts.user_name}${index !== detail.user.length - 1 ? ', ' : ''}`
                          )).join('')
                        ) : (
                          ''
                        )
                      }

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                  onClick={validateFile(rowvalidate)}
                  title="Validé la demande d'appui"
                  color="primary"
                  variant="contained"
                >
                  <Done style={{ color: "green" }} />Valider
                </Button>

                <Button onClick={handleAnnuler(rowvalidate)} title="Refusé la demande" color="secondary"
                  variant="contained">
                  <CloseIcon style={{ color: "red" }} /> Refuser
                </Button>
            </DialogActions>
          </BootstrapDialog>
      </div>
    </React.Fragment>
  );
}
export default MyValidation;

