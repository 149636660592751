import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'

import AutoEvaluationForm from './AutoEvaluationForm';
import EvaluationDuSuperieur from './EvaluationDuSuperieur';
import Evaluation360 from './Evaluation360';
import AppTabPanes from '../shared/AppTabPanes';

const ReponseList = () => {
  const comps = [
    /* { label: "Performance", component: <Evaluation360 /> }, */
    { label: "Autoevaluation", component: <AutoEvaluationForm /> },
    /* { label: "Suivi de l'valuation", component: <EvaluationDuSuperieur /> }, */
  /*   { label: "Evaluation à 360°", component: <Evaluation360 /> }, */
  ];
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppTabPanes comps = {comps} />
    </MainContainer>
  )
}

export default ReponseList