import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Spacer from '../../../components/Spacer';
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { useNavigate } from 'react-router-dom';
import CopieMailTable from './CopieMailTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
}));


export default function CopieMailList() {

  const navigate = useNavigate();
  const classes = useStyles();

  const handleClick = () => {
    navigate("/mission_mail/add");
  };

  return (
    
    <MainContainer {...headerDataSettings}>  
      <Spacer y={2} />
      <Grid container spacing={3}>
         <Grid item xs={12}>
           <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >CREER</Button>
         </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <CopieMailTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
