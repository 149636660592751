import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import EmployeeListFiltre from './EmployeeListFiltre';

const MesSubordonneesList = () => {
  
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <EmployeeListFiltre title={"Liste de toutes mes subordonnées"} />
    </MainContainer>
  )
}

export default MesSubordonneesList