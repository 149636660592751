import { getRandomColor, TYPE_GRAPH } from "./PerformanceGraph";
  export  const data_graphes = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: true,
                tension: 0,
                pointRadius: 5,
                //fill: chartType === 'radar',
                borderColor: getRandomColor(false),
                backgroundColor: getRandomColor(true),
                //backgroundColor: chartType === 'radar' ? getRandomColor(true) : 'rgba(0,0,0,0)',
            },
            {
                label: 'My second Dataset',
                data: [15, 25, 40, 75, 80, 10, 68],
                fill: true,
                tension: 0,
                pointRadius: 5,
                //fill: chartType === 'radar',
                borderColor: getRandomColor(false),
                backgroundColor: getRandomColor(true),
                //backgroundColor: chartType === 'radar' ? getRandomColor(true) : 'rgba(0,0,0,0)',
            },
        ]
    };

    export const adaptDataToGraph = (datas, chartType, abscisse, ordonnees) => {
        // Générer les labels (axe des abscisses) dynamiquement
        const labels = Array.from(new Set(datas.map(item => item[abscisse]))).sort();
    
        // Générer les datasets (axe des ordonnées) dynamiquement
        const datasets = Array.from(new Set(datas.map(item => item[ordonnees]))).sort().map((datasetName, idx) => {
            // Filtrer les données correspondant à un dataset spécifique
            const filteredData = datas.filter(item => item[ordonnees] === datasetName);
    
            return {
                label: datasetName,
                data: labels.map(label => {
                    const match = filteredData.find(data => data[abscisse] === label);
                    return match ? match.NOTE_MOYENNE : null; // Remplacez `NOTE_MOYENNE` si nécessaire
                }),
                fill: true,
                tension: 0,
                spanGaps: true,
                pointRadius: 5,
                borderColor: getRandomColor(false),
                backgroundColor: getRandomColor(chartType !== TYPE_GRAPH.bar),
            };
        });
    
        return {
            labels: labels,
            datasets: datasets,
        };
    };
    

   