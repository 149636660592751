import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CodePinDialog from "./CodePinDialog";
import AcceptRequestService from "../../services/AcceptRequestService";
import Alert from "@material-ui/lab/Alert";
import Loading from "../../components/loading";
import Message from "../tools/Message";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function ValidateRequest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    mdp: "",
  });
  const [openCodePin, setOpenCodePin] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [errorMdpMessage, setErrorMdpMessage] = useState('');
  const [inputValue, setInputValue] = useState("");
  const [failedCodePin, setFailedCodePin] = useState(false);
  const [errorMessageFailedCodePin, setErrorMessageFailedCodePin] = useState(false);
  const open = props.openValidateRequest;
  const handleCloseValidateRequest = props.handleCloseValidateRequest;
  const validation_id = props.validation_id;
  const module_type_id = props.module_type_id;
  const file_validation_request_id = props.file_validation_request_id;
  const user_requested_id = props.user_requested_id;
  // Samuela: set loading while fetching api data
  const [isLoading, setLoading] = useState(false);

  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const { openMessage, color, messageInfo } = message;

  useEffect(() => { }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleClickPassword() {

    if (form.mdp.trim() == "") {
      setError(true);
      setErrorMdpMessage("Mot de passe requis!!");
    } else {
      setLoading(true);
      AcceptRequestService.verifyMdp(form.mdp, module_type_id).then((result) => {
        var validation_with_observation = (localStorage.getItem("observation")) ? JSON.parse(localStorage.getItem("observation")) : "";        
        if (result.status) {
          var _validation_number = parseInt(result.validationNumber);
          switch (_validation_number) {
            case 1:
              AcceptRequestService.doValidationRequestSimple(validation_id, module_type_id, file_validation_request_id, user_requested_id, validation_with_observation).then((result) => {
                if (result.status) {
                  setMessage({
                    ...message,
                    openMessage: true,
                    color: "green",
                    messageInfo: result.message,
                  });
                  setLoading(false);
                  setTimeout(function () { navigate("/me/validations"); }, 2000);
                  setStep(2);
                  localStorage.removeItem("observation");
                  window.location.reload();
                } else {
                  setError(true);
                  setErrorMdpMessage(result.message);
                  setLoading(false);
                }
              }).catch((err) => {
                setError(true);
                setErrorMdpMessage('Une erreur s\'est produite');
                setLoading(false);
              });
              break;
            case 2:
              console.log("ato2");
              setStep(2);
              setOpenCodePin(true);
              setLoading(false);
              break;
            default:
              console.log("ato3");
              setStep(2);
              setOpenCodePin(true);
              setLoading(false);
              break;
          }
        } else {
          setError(true);
          setErrorMdpMessage(result.message);
          setLoading(false);
        }
      }).catch((err) => {
        setError(true);
        setErrorMdpMessage('Une erreur s\'est produite');
        setLoading(false);
      });
    }
  }

  const handleCloseCodePin = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenCodePin(false);
  }

  function handleValidateCodePin() {
    if (inputValue.trim() == "") {
      setFailedCodePin(true);
      setErrorMessageFailedCodePin("Veuillez saisir votre code PIN");
    } else {

      AcceptRequestService.doValidationRequest(inputValue, validation_id, module_type_id, file_validation_request_id, user_requested_id).then((result) => {
        if (result.status) {
          setMessage({
            ...message,
            openMessage: true,
            color: "green",
            messageInfo: result.message,
          });
          setTimeout(function () { navigate("/me/validations"); }, 2000);
          window.location.reload();
        } else {
          setFailedCodePin(true);
          setErrorMessageFailedCodePin(result.message);
        }
      }).catch((err) => {
        setFailedCodePin(true);
        setErrorMessageFailedCodePin('Une erreur s\'est produite');
      });

    }
  }

  function handleFocusPassword() {
    setError(false);
  }


  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  return (
    <>

      <Message
        open={openMessage}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <CodePinDialog openCodePin={openCodePin} handleCloseCodePin={handleCloseCodePin}
        validation_id={validation_id}
        module_type_id={module_type_id}
        file_validation_request_id={file_validation_request_id}
        onChangeInputValue={setInputValue}
        handleValidateCodePin={handleValidateCodePin}
        failedCodePin={failedCodePin}
        errorMessageFailedCodePin={errorMessageFailedCodePin}
        user_requested_id={user_requested_id}
      />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseValidateRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation - Etape 1
        </DialogTitle>
          <br />
          <div className={classes.root}>
            <div>
              <h3 className={classes.step1Title}>Votre mot de passe </h3>
            </div>
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Votre mot de passe"
                  variant="outlined"
                  value={form.mdp}
                  name="mdp"
                  type="password"
                  onChange={handleChange}
                  onFocus={handleFocusPassword}
                  // inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12}>

                <Spacer y={3} />
                {/* Samuela: set loading while fetching api data */}
                {isLoading && <Loading />}
                {error && (
                  <Alert severity="error">{errorMdpMessage}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseValidateRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleClickPassword} color="secondary" variant="contained" >
                    Valider
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </>
  );
}
