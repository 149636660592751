import { useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import UserService from "../../../services/UserService";
import EvaluationExaminationForm from "./EvaluationExaminationForm";
import EvaluationExaminationFormResume from "./EvaluationExaminationFormResume";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from '../../../helpers/ExportHelpers';


const EmployeeListTable = ({ openForm, onCloseForm, filtre, handleOpenDialog, excelExport, title }) => {
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);
  const [selected_person, setSelectedPerson] = useState(null);
  const [formEvaluationOpen, setFormEvaluationOpen] = useState(false);
  const [formResumeOpen, setFormResumeOpen] = useState(false);
  const [user_list, setUserList] = useState([]);

  const handleRefreshSubordinateList = () => {
    UserService.getAllOfMySubordinate(filtre)
      .then(res => {
        setUserList(res.data || []);
      })
  }
  const handleRefreshColleagueList = () => {
    setOpenLoadingSimpleBackdrop(true);
    UserService.getAllOfMyColleaguesInSameService(filtre)
      .then(res => {
        setOpenLoadingSimpleBackdrop(false);
        setUserList(res.data || []);
      })
  }

  const refreshDataTable = () => {
    if (String(title).includes("Liste des employées de même superieur")) {
      handleRefreshColleagueList();
    } else {
      handleRefreshSubordinateList();
    }
  }

  const handleStartEvaluation = (employee) => {
    setSelectedPerson(employee)
    handleOpenDialog();
    setFormEvaluationOpen(true);
  }
  const handleShowEvaluationResume = (employee) => {
    setSelectedPerson(employee)
    handleOpenDialog();
    setFormResumeOpen(true);
  }

  useEffect(() => {
    if (filtre) {
      refreshDataTable()
    }
    if (openForm) {
      setFormEvaluationOpen(openForm)
    }
    if (title) {
      refreshDataTable();
    }

    if (excelExport > 0) {
      exportToExcel(user_list, columns, "Liste des employees " + new Date());
    }
  }, [filtre, openForm, title, excelExport]);


  const handleCloseFormEvaluationDIalog = () => {
    onCloseForm();
    refreshDataTable();
    setFormEvaluationOpen(false);
  }

  const handleCloseFormResumeDIalog = () => {
    onCloseForm();
    refreshDataTable();
    setFormResumeOpen(false);
  }

  const handleOpenFormDIalog = () => {
    handleOpenDialog();
  }
  const columns = [
    {
      field: 'last_name',
      headerName: "Nom de l'employée",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'first_name',
      headerName: "Prénoms de l'employée",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'function_name',
      headerName: "Fonction",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'service_code',
      headerName: "Code du service",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'libelle_actions',
      headerName: 'Actions',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour commencer l'evaluation "}>
              <LoadingButton
                onClick={(e) => handleStartEvaluation(params.row)}
                color="primary"
                variant="contained"
                startIcon={<PsychologyAltOutlinedIcon />}
              >
                Evaluer
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour afficher l'evaluation "}>
              <LoadingButton
                onClick={(e) => handleShowEvaluationResume(params.row)}
                sx={{ ml: 1 }}
                color="secondary"
                variant="outlined"
                startIcon={<RemoveRedEyeOutlined />}
              >
                Afficher
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 300,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <EvaluationExaminationForm selected_employee={selected_person} isOpen={formEvaluationOpen} handleClose={handleCloseFormEvaluationDIalog} />
      <EvaluationExaminationFormResume selected_employee={selected_person} isOpen={formResumeOpen} handleClose={handleCloseFormResumeDIalog} />
      <DataGrid
        rows={user_list}
        getRowId={(row) => row.user_id}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 5]}
        disableSelectionOnClick
      />
    </>
  )
}

export default EmployeeListTable