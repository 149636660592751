import { Button, Typography } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { evaluation_saison_service } from "../../../services/PersonalEvaluationService";
import { current_date } from "../shared/Utilitises";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import useConfirm from "../../../hooks/useConfirm";

const EvaluationSaisonForm = ({ isOpen, current_saison, handleClose }) => {
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [selected_saison, setSelectedSaison] = useState({
    ID_SAISON: 0,
    NOM_SESSION: "",
    DATE_DEBUT: format(new Date(), "yyyy-MM-dd"),
    DATE_FIN: format(new Date(), "yyyy-MM-dd"),
    CREATEUR: 0,
    OUVERT: false,
  });

  const [submitLoad, setLoadSUbmit] = useState(false);

  const handleChangeOuvertStatus = (event) => {
    let misokatra = event.target.checked;
    if (misokatra) {
      openConfirmDialog(
        "Confirmation",
        "Voullez vous ouvrir cette saison? \n Cette action mene à fermer les autres saisons",
        () =>
          setSelectedSaison({
            ...selected_saison,
            OUVERT: misokatra ? "1" : "0",
          })
      );
    } else {
      setSelectedSaison({
        ...selected_saison,
        OUVERT: event.target.checked ? "1" : "0",
      });
    }
  };

  useEffect(() => {
    if (current_saison) {
      setSelectedSaison({
        ID_SAISON: current_saison.ID_SAISON,
        NOM_SESSION: current_saison.NOM_SESSION,
        DATE_DEBUT: format(new Date(current_saison.DATE_DEBUT), "yyyy-MM-dd"),
        DATE_FIN: format(new Date(current_saison.DATE_FIN), "yyyy-MM-dd"),
        CREATEUR: current_saison.CREATEUR,
        OUVERT: current_saison.OUVERT,
      });
    }
  }, [current_saison]);

  const isAllFieldValide = () => {
    let ok = true;
    if (
      selected_saison.NOM_SESSION === null ||
      selected_saison.NOM_SESSION.trim() === ""
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous devez mentionner le nom de la saison"
      );
      ok = false;
    } else if (
      selected_saison.DATE_DEBUT === null ||
      isNaN(Date.parse(selected_saison.DATE_DEBUT))
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous devez mentionner une date de début valide"
      );
      ok = false;
    } else if (
      selected_saison.DATE_FIN === null ||
      isNaN(Date.parse(selected_saison.DATE_FIN))
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous devez mentionner une date de fin valide"
      );
      ok = false;
    } else if (
      Date.parse(selected_saison.DATE_FIN) <=
      Date.parse(selected_saison.DATE_DEBUT)
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "La date de fin doit être postérieure à la date de début"
      );
      ok = false;
    }

    return ok;
  };

  const handleSaveSaison = () => {
    if (isAllFieldValide()) {
      if (selected_saison.ID_SAISON > 0) {
        handleUpdateSaison();
      } else {
        handleInsertSaison();
      }
    }
  };

  const handleInsertSaison = () => {
    evaluation_saison_service
      .insertEvaluationSaison(selected_saison)
      .then((res) => {
        if (res && res.success) {
          handleClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          initializeFormFields();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleUpdateSaison = () => {
    evaluation_saison_service
      .updateEvaluationSaison(selected_saison)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleClose();
          initializeFormFields();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleSaveCritereAndClose = () => {
    handleSaveSaison();
    handleClose();
  };

  const handleChangeSelectedSaison = (evt) => {
    const { name, value } = evt.target;
    setSelectedSaison({ ...selected_saison, [name]: value });
  };

  const initializeFormFields = () => {
    setSelectedSaison({
      CREATEUR: null,
      DATE_DEBUT: current_date,
      DATE_FIN: current_date,
      ID_SAISON: 0,
      NOM_SESSION: "",
      OUVERT: false,
    });
  };

  const handleDialogClose = () => {
    handleClose();
    initializeFormFields();
  };

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogComponent />
            <DialogTitle id="dialog-title" className="entete-dialog">
              <Grid display={"flex"} flexDirection={"row"}>
                <Grid alignContent={"center"}>
                  {selected_saison.ID_SAISON === 0
                    ? "Nouvelle saison"
                    : "Modifier saison"}
                </Grid>
                <Grid flexGrow={1}>
                  <DialogActions>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selected_saison.OUVERT === "1"}
                          onChange={handleChangeOuvertStatus}
                          name="gilad"
                        />
                      }
                      label={
                        selected_saison.OUVERT === "1" ? "Ouvert" : "Fermé"
                      }
                    />
                  </DialogActions>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid container mt={0.5}>
                <Grid item xs={12} className="form_control">
                  <Typography className="form-control">
                    Nom de la saison *
                  </Typography>
                  <TextField
                    fullWidth
                    name="NOM_SESSION"
                    size="small"
                    label="Nom de la session *"
                    value={selected_saison.NOM_SESSION || ""}
                    onChange={(e) => handleChangeSelectedSaison(e)}
                  />
                </Grid>
                <Grid item xs={12} lg={5.75} m={0.5} className="form_control">
                  <Typography className="form-control">
                    Date de debut *
                  </Typography>
                  <TextField
                    fullWidth
                    type="date"
                    name="DATE_DEBUT"
                    size="small"
                    value={selected_saison.DATE_DEBUT}
                    onChange={(e) => handleChangeSelectedSaison(e)}
                  />
                </Grid>
                <Grid item xs={12} lg={5.75} m={0.5} className="form_control">
                  <Typography className="form-control">Daten fin *</Typography>
                  <TextField
                    fullWidth
                    type="date"
                    name="DATE_FIN"
                    size="small"
                    value={selected_saison.DATE_FIN}
                    onChange={(e) => handleChangeSelectedSaison(e)}
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selected_saison.OUVERT === "1"}
                        onChange={handleChangeOuvertStatus}
                        name="gilad"
                      />
                    }
                    label={selected_saison.OUVERT === "1" ? "La session precedente sera dupliquée" : "La session precedente ne sera pas dupliquée"}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSaveSaison}
                variant="contained"
              >
                Enregistrer{" "}
              </LoadingButton>
              {/* <LoadingButton
              loading={submitLoad}
              size='small'
              className='form_control' type='submit'
              onClick={handleSaveCritereAndClose}
              variant='contained'>Enregistrer & suivant </LoadingButton> */}
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Annuler
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
export default EvaluationSaisonForm;
