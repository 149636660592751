import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import RequestObservationService from '../../services/RequestObservationService';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router-dom";
import AcceptedHelpService from '../../services/AcceptedHelpService';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  id: {
    color: "#3f51b5",
    fontWeight: "bold",
  },
  actualBalance: {
    textAlign: "right",
    margin: "auto"
  },
  detail: {
    fontSize: "13px"
  },
  divDetail: {
    justifyContent: "center"
  }
}));

export default function ConfirmationDialogueRequest(props) {
    const navigate = useNavigate();
  const classes = useStyles();
  const { openDialog, closeDialog, cancelDialog, detail } = props;
  const [observation, setObservation] = useState([]);
  const [isautre, setIsautre] = useState(false);
  const [form, setForm] = useState({
    raison: "",
    autre: ''
  });

  function confirmDialog() {

    const raison=(form.autre==="")?form.raison.name:form.autre;
    AcceptedHelpService.updateAccepted(detail.mission_request_id,detail.lastvalidation,detail.type_validation,detail.number_validation,detail.file_id,detail.user_id,raison,'')
     .then((res) => {
       if (res.status) {
         window.location.reload();
       } else {
         window.location.reload();
       }
     })
     .catch((err) => {

     });
  }
  function handleChangemotif(event) {
    const { name, value} = event.target ;
    setForm((prevForm) => ({
        ...prevForm,
        [name]:  value, 
      }));
  }


  function handleChange(event, value) {
    const { name, value: inputValue } = event.target || {};
    console.log("name",name);
    const name1 = name === undefined ? "raison" : name;

    setForm((prevForm) => ({
      ...prevForm,
      [name1]:  inputValue || value, 
    }));
    

    if (value && value.name === "Autres") {
      setIsautre(true);
    } else {
      setIsautre(false);
    }
  }

  useEffect(() => {
    RequestObservationService.getAll()
      .then((result) => {
        if (result && result.data) {
          const observationsWithCustom = [
            ...result.data,
            { request_observation_id: 'Autres', name: 'Autres' }
          ];
          setObservation(observationsWithCustom);
        }
      })
      .catch((error) => {
        console.error("Error fetching observations:", error);
      });
  }, []);

  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="form-dialog-title" color="primary">Confirmation demande d'appui</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                La demande a été acceptée pour :
              </Grid>
              <Grid item xs={12}>
                    <Autocomplete
                    options={observation}
                    onChange={ handleChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        fullWidth
                        label="Raison"
                        size="small"
                        variant="outlined"
                        name='raison'
                        InputProps={{ ...params.InputProps, type: "search" }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12}>
                    {isautre&&
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Autre motif"
                            name='autre'
                            variant="outlined"
                            onChange={handleChangemotif}
                        />
                    }
                    
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmDialog}>CONFIRMER</Button>
          <Button variant="contained" color="secondary" onClick={cancelDialog}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
