import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import UserService from "../../services/UserService";
import LoadingButton from '@mui/lab/LoadingButton';

function PersonnalValidateurAppui(props) {
    const handleChangeNumberOfValidator = props.handleChangeNumberOfValidator;
    const validatorNumber = props.validatorNumber;
    const userName = props.userName;
    const [validatorNumberappui, setValidatorNumberappui] = useState("");
    const [validatorNumberappuiext, setValidatorNumberappuiext] = useState("");
    const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [idValidatorNumber, setIdValidatorNumber] = useState(0);
    const setId = props.id;
    const handleClose = props.handleCloseDialog;
    const [message, setMessage] = React.useState({
        openMessage: false,
        color: 'green',
        messageInfo: '',
    });
    const [rows, setRows] = useState([]);
    const [idModule, setIdModule] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = useState({
        module_type_id: "",
        libelle_module_type: "",
    });
    const [select, setSelect] = useState({
        value: "first",
        label: "first"
    });

    useEffect(() => {
        UserService.checkValidatornumberByUserIdAppui(setId).then((result) => {
            if (result && result.status) {
                setIdValidatorNumber(result.data.user_validator_number_id);
                setValidatorNumberappui(result.data.user_validator_number_validatorNumber);
                setValidatorNumberappuiext(result.data.user_validator_number_validatorNumberext);
            } else {
                setValidatorNumberappui(0);
                setIdValidatorNumber(0);
            }
        })
    }, [props]);

    function loadAllUser() {
        setOpenLoadingSimpleBackdrop(true);
        UserService.getAllModule().then((result) => {
            if (result && result.data) {
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }


    function handleValidate() {
        setLoading(true);
        UserService.saveValidatorNumberMissionAppui(idValidatorNumber, setId, validatorNumberappui,validatorNumberappuiext, 5).then((result) => {
            if (result && result.status) {
                setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
                setOpenSpecialSettingDialog(false);
                loadAllUser();
                handleClose(false);
                setLoading(false);
            }
        });
    }


    function handleChangeNumberOfValidatorMission(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9]/g, '');
        setValidatorNumberappui(newValue);
    }


    function handleChangeNumberOfValidatorMissionext(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9]/g, '');
        setValidatorNumberappuiext(newValue);
    }

    return (
        <Grid container spacing={2} xs={12}>

            <Grid item xs={4}>
                <label>Module :</label>
            </Grid>
            <Grid item xs={8}>
            <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="module"
                    value="Demande d'appui"
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <label>Utilisateur:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="user"
                    value={userName}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <label>Nombre de validateur interne:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="validatorNumber"
                    inputProps={{ maxLength: 1 }}
                    value={validatorNumberappui}
                    onChange={handleChangeNumberOfValidatorMission}
                />
            </Grid>

            <Grid item xs={4}>
                <label>Nombre de validateur externe:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="validatorNumberappuiext"
                    inputProps={{ maxLength: 1 }}
                    value={validatorNumberappuiext}
                    onChange={handleChangeNumberOfValidatorMissionext}
                />
            </Grid>

            <Grid container item xs={12} direction="row-reverse">

                {/* <Button
                    type="submit"
                    style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
                    onClick={handleValidate}
                    color="primary"
                    variant="contained"
                >
                    Valider
                </Button> */}

                <LoadingButton
                      onClick={handleValidate}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                >
                    Valider
                </LoadingButton>
                <Spacer x={2} />
                <Button
                    style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
                    onClick={handleClose}
                    variant="contained"
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
export default PersonnalValidateurAppui;