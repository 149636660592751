import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import Title from "../../../components/Title";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import MissionService from "../../../services/MissionService";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from "@material-ui/core/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));



export default function TDRHistory() {
  const classes = useStyles();
  const [rows,setRows]=useState([]);
  const [observation,setObservation]=useState([]);
  const [ openobservation, setOpenobservation] = useState(false);
 
  const handleobserv = (user_id, file_id) => {

    MissionService.getobservationpervalidate(user_id, file_id).then((result) => {
      if (result && result.data) {
        setObservation(result.data);
        setOpenobservation(true);
      } else {
        setObservation([]);
      }
    });
  };

  const handleCloseobservations = () => {
    setOpenobservation(false); 
  };

  const handleCloseobservation = () => {
    setOpenobservation(false);
   
  };

  useEffect(() => {

    MissionService.gethistory().then((result) => {
      if (result && result.data) {
        setRows(result.data?.map((row) => ({ ...row, id: row.mission_planning_id })));
      } else {
       setRows([]);
      }
    });
  }, []);
  return (
    <MainContainer {...headerDataMission}>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
            <Title style={{ marginTop: '50px' }}>Historique des demandes de mission</Title>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                           <TableHead>
                                    <TableRow>
                                      <TableCell>Réference mission</TableCell>
                                      <TableCell>Intitulé</TableCell>
                                      <TableCell>Parcours</TableCell>
                                    </TableRow>
                            </TableHead>
  
                           
                            <TableBody>
                                {rows.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.reference}</TableCell>
                                        <TableCell>{data.mission_title}</TableCell>
                                        <TableCell>
                                        {data.parcours.map((ts, index) => {
                                            let suffix = ts.state === "CONFIRMED" ? "Validé par " : "Réfusé par ";
                                            suffix += ts.validateur;

                                            // Vérifiez si c'est le dernier élément pour gérer la séparation
                                            const separator = index === data.parcours.length - 1 ? '' : '  ---->';
                                            const user_id=ts.user_id;
                                            const file_id=ts.file_request_id;                                          
                                             // Vérifiez si le suffix est "Réfusé par" pour afficher le bouton
                                            return (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span>{suffix}</span>
                                                    {ts.state === "DECLINED" && (
                                                        <Button title="Voir les observations" onClick={() => handleobserv(user_id, file_id)}>
                                                            <RemoveRedEyeIcon />
                                                        </Button>
                                                    )}
                                                    <span>{separator}</span>
                                                </div>
                                            );
                                        })}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                      </TableContainer>
                  </Grid>
               </Grid>
               <Dialog open={openobservation} onClose={handleCloseobservation}>
              <DialogTitle id="form-dialog-title" color="primary"  >Observation refus du mission</DialogTitle>
                <DialogContent>
                  <DialogContentText>  
                  <Grid container justifyContent="center" spacing={2}> 
                    
                      <Grid container item spacing={2}  xs={8} >
                        <Grid item xs={5}>
                          <label >Context :</label>
                        </Grid>
                        <Grid item xs={7}>
                        <label>{observation[0] ? observation[0].observation : ''}</label>
                        </Grid>
                        <Grid item xs={5}>
                          <label>Objectifs :</label>
                        </Grid>
                        <Grid item xs={7}>
                          <label>{observation[1] ? observation[0].observation : ''}</label>
                        </Grid>
                        <Grid item xs={5}>
                          <label>Résultat attendus :</label>
                        </Grid>
                        <Grid item xs={7}>
                          <label>{observation[2] ? observation[1].observation : ''}</label>
                        </Grid>
                        <Grid item xs={5}>
                          <label>Tâche :</label>
                        </Grid>
                        <Grid item xs={7}>
                          <label>{observation[3] ? observation[2].observation : ''}</label>
                        </Grid>
                        <Grid item xs={5}>
                          <label>Agenda :</label>
                        </Grid>
                        <Grid item xs={7}>
                          <label>{observation[4] ? observation[3].observation : ''}</label>
                        </Grid>
                      </Grid>
          
                </Grid>

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
              <Button variant="contained" color="secondary" onClick={handleCloseobservations} >FERMER</Button>
          </DialogActions>
      </Dialog>
        </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
