import { RefreshOutlined } from '@material-ui/icons';
import { DownloadOutlined } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react'
import CritereGroupeeCritereTable from './CritereGroupeeCritereTable';
import { useLocation, useNavigate } from 'react-router';
import { evaluation_critere_groupee_service } from '../../../services/PersonalEvaluationService';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const CritereGroupeeCritereFiltre = ({ handleOpenFormDialog, handleCloseFormDialog, openForm }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [selectedGroupCritere, setSelectedGroupCritere] = useState(null);
  const [groupCritereList, setGroupCritereList] = useState([]);
  const [filtre, setFiltre] = useState({
    groupe_critere: null,
    mot_cle: "",
    refresh: 0,
  });
  const [excelExport, declenceExportToExcel] = useState(0);

  const downloadExcel = () => {
    declenceExportToExcel(excelExport + 1);
  }

  const handleOpenDialogForm = () => {
    handleOpenFormDialog();
  }

  const handleCloseDialogForm = () => {
    handleCloseFormDialog();
  }

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  }

  const handleChangeGroupeCritere = (new_value) => {
    setFiltre({ ...filtre, groupe_critere: new_value });
    navigate(`/critere_groupee_detail?id_groupe=${new_value.ID_CRITERE_GROUPEE}`)
  }

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  }

  const handleRefreshGroupeByUrlParameter = (url_param) => {
    if (url_param) {
      evaluation_critere_groupee_service.getCritereGroupeeById(url_param)
        .then(res => {
          if (res && res.data) {
            setSelectedGroupCritere(res.data || null);
          }
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  const loadDataForAllComponents = () => {
    evaluation_critere_groupee_service.getCritereGroupeeList(null)
      .then(res => {
        if (res && res.data) {
          setGroupCritereList(res.data || []);
        }
      })
      .catch(e => {
        console.log(e);
      })
  }

  useEffect(() => {
    handleRefreshGroupeByUrlParameter();
    loadDataForAllComponents();
    if (query.get('id_groupe') > 0) {
      setFiltre({ ...filtre, ID_CRITERE_GROUPEE: query.get("id_groupe") })
    }
  }, [query.get('id_groupe')])

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        <TextField
          sx={{ width: 400 }}
          size='small'
          value={filtre.mot_cle}
          label="Mot clé"
          name='mot_cle'
          onChange={handlechangeFiltre}
          className="filtre_list"
        />
        <Button
          variant='outlined'
          className='filtre_list'
          onClick={handleRefreshData}
        >
          <RefreshOutlined />
        </Button>
        <Button
          variant='outlined'
          className='filtre_list'
          onClick={downloadExcel}
        >
          <DownloadOutlined /> Excel export
        </Button>
      </Grid>
      <Grid flexGrow={1} mt={3}>
        <CritereGroupeeCritereTable excelExport={excelExport} handleOpenDialog={handleOpenDialogForm} onCloseForm={handleCloseDialogForm} openForm={openForm} filtre={filtre} />
      </Grid>
    </Grid>
  )
}

export default CritereGroupeeCritereFiltre