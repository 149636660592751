import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import CritereForm from "./CritereForm";
import { evaluation_critere_service } from "../../../services/PersonalEvaluationService";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import useToast from "../../../hooks/useToast";
import useConfirm from "../../../hooks/useConfirm";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const CritereTable = ({
  openForm,
  onCloseForm,
  handleOpenDialog,
  excelExport,
  filtre,
}) => {
  const [criteres, setCriteres] = useState([]);
  const [current_critere, setCurrentCritere] = useState(null);
  const [form_open, setFormOpen] = useState(openForm);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const { Toast, showToast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleRefreshDataTable(filtre);
    setFormOpen(false);
  };

  const handleEditSelectedCritere = (critere) => {
    setCurrentCritere(critere);
    setFormOpen(true);
  };

  const handleRefreshDataTable = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_critere_service
      .getCriteresList(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCriteres(res.data || []);
      })
      .catch((e) => console.log(e.toString()));
  };

  const confirmDelete = (critere) => {
    evaluation_critere_service
      .deleteCritere(critere)
      .then((res) => {
        if (res && res.success) {
          handleRefreshDataTable(filtre);
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => {
        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  const handleDeleteCritere = (critere) => {
    openConfirmDialog(
      "Confirmation",
      "Vous etes sur de vouloir supprimer cette ligne? Cette action est irreversible.",
      () => confirmDelete(critere)
    );
  };

  useEffect(() => {
    if (filtre) {
      handleRefreshDataTable(filtre);
    }
    if (openForm) {
      setFormOpen(openForm);
    }
    if (excelExport > 0) {
      exportToExcel(criteres, columns, "Liste des criteres " + new Date());
    }
  }, [filtre, openForm, excelExport]);

  const columns = [
    {
      field: "QUESTION",
      headerName: "Question",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "NOM_TYPE",
      headerName: "Type de la question",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "NOM_CATEGORY",
      headerName: "Nom de la catégorie",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditSelectedCritere(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
                onClick={(e) => handleDeleteCritere(params.row)}
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <Toast />
      <DialogComponent />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <CritereForm
        current_critere={current_critere}
        handleClose={handleCloseFormDIalog}
        isOpen={form_open}
      />
      <DataGrid
        rows={criteres}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_CRITERE}
      />
    </>
  );
};
export default CritereTable;
