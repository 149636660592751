import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RefusedRequestService from "../../services/RefusedRequestService";
import Message from "./Message";
import { FormGroup, FormControlLabel, Checkbox , Typography, DialogContent} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Alert from "@material-ui/lab/Alert";
import Loading from "../../components/loading";
import AcceptRequestService from "../../services/AcceptRequestService";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
    marginBottom: theme.spacing(8),
  },
}));

export default function RefusedRequest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const type_observation = props.type_observation;
  const file_validation_request_id = props.file_validation_request_id;
  const validation_id = props.validation_id;
  const open = props.openRefusedRequest;
  const handleCloseRefusedRequest = props.handleCloseRefusedRequest;
  const formular = props.formular;
  const module_type_id = props.module_type_id;
  const parent=props.closeparent;
  const typerefus=props.typerefus;
  const filename=props.filename;
  const typevalidation=props.typevalidation;

  const [error, setError] = useState('');
  const [openobservation,setOpenobservation]=useState(false);
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [errorMdpMessage, setErrorMdpMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [opentdr,setOpentdr]=useState(false);
  const [message, setMessage] = useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const { openMessage, color, messageInfo } = message;

  const [form, setForm] = useState({
    validation_id : validation_id,
    typevalidation:typevalidation,
    planning: 'valide',
    tdr: 'valide',
    observation : '',
    context:'',
    result:'',
    objectif:'',
    tache:'',
    agenda:''
  });
  const [filter,setFilter]=useState(
    {
    mdp:''
    }
  );

  const handleChangePlanning = (event) => {
    setForm({ ...form, planning: event.target.value });
    if (event.target.value==="refuse") {
      setOpenobservation(true);
    }
    else{
      setOpenobservation(false);
    }
  };

  const handleChangeTDR = (event) => {
    setForm({ ...form, tdr: event.target.value });
    if (event.target.value==="refuse") {
      setOpentdr(true);
    }
    else{
      setOpentdr(false);
    }
  };

  function handleChangeGlobalObservation(event){
    const {name, value} = event.target;
    setForm({ ...form, validation_id: validation_id, [name]: value });
  }

  function handleChange(event, index){
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });   
   }
 
   const handleCloseValidateRequest = (event, reason) => {
    setOpenValidateRequest(false);
   }

   const handleFocusPassword = (event, reason) => {
    setOpenValidateRequest(true);
   }
   
   function handleClickPassword() {
    if (filter.mdp.trim() == "") {
      setError(true);
      setErrorMdpMessage("Mot de passe requis!!");

    } else {
      setLoading(true);
       
        AcceptRequestService.verifyMdp(filter.mdp,2).then((result) => {
          if (result.status) {                    
              RefusedRequestService.validatemission(form).then((result) => {
                if (result.status) {
                  setMessage({
                    ...message,
                    openMessage: true,
                    color: "green",
                    messageInfo: result.message,
                  });
                  setTimeout(function () { navigate("/me/validations"); }, 2000);
                  window.location.reload();
                } else {
                    setMessage({
                      ...message,
                      openMessage: true,
                      color: "red",
                      messageInfo: "Erreur , veuillez essayer à nouveau.",
                    });
                    setLoading(false);
                }
              }).catch((err) => {
                  setMessage({
                    ...message,
                    openMessage: true,
                    color: "red",
                    messageInfo: "Erreur , veuillez essayer à nouveau.",
                  });
              });
          } else {
            setError(true);
            setErrorMdpMessage(result.message);
            setLoading(false);
          }
        }).catch((err) => {
          setError(true);
          setErrorMdpMessage('Une erreur s\'est produite');
          setLoading(false);
          navigate("/acceptedHelp");

        });
      }
   }  

   function handleChangemdp(event) {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });

  }

  function handleRefusedRequest(){
       console.log("form",form);  

        setForm(prevForm => ({
          ...prevForm,
          // typerefus: typerefus,
          typerefus:"MISSION",
          validation_id: validation_id,
          file_validation_request_id: file_validation_request_id,
          filename:filename
        }));

        setOpenValidateRequest(true);
          
          //   handleCloseRefusedRequest();
      
  }
  
  useEffect(() => {

  }, []);

  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  return (
    <>
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Dialog open={open} onClose={handleCloseRefusedRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm" maxHeight="1000px">
        <DialogTitle id="form-dialog-title1">
           Validation demande de mission
        </DialogTitle>
        <br />
        <div className={classes.root}>

            <Grid className={classes.step1Grid} container>
                <Grid item xs={4}>
                   <Typography variant="body1">Planning Mission</Typography>
                </Grid>
                <Grid item xs={8}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={form.planning === 'valide'} onChange={() => handleChangePlanning({ target: { value: 'valide' } })} />}
                    label="Valider"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={form.planning === 'refuse'} onChange={() => handleChangePlanning({ target: { value: 'refuse' } })} />}
                    label="Réfuser"
                  />
                </FormGroup>
               </Grid>
               {openobservation &&
                  <>
                    <Grid className={classes.gridItem} item xs={4}>
                      <label className={classes.label}>{"Observation : "}</label>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                      <TextField
                          fullWidth
                          rows={3}
                          multiline
                          id="outlined-basic"
                          size="small"
                          label="Observation"
                          variant="outlined"
                          value={form.observation}
                          name="observation"
                          onChange={handleChange}
                      />
                    </Grid>
                  </>
               }

               {typevalidation!=="PLANNING"&&
                 <>

                    <Grid item xs={4}>
                      <Typography variant="body1">TDR</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={form.tdr === 'valide'} onChange={() => handleChangeTDR({ target: { value: 'valide' } })} />}
                          label="Valider"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={form.tdr === 'refuse'} onChange={() => handleChangeTDR({ target: { value: 'refuse' } })} />}
                          label="Réfuser"
                        />
                      </FormGroup>
                    </Grid>
                    {opentdr &&
                    <>
                        <Grid className={classes.gridItem} item xs={4}>
                          <label className={classes.label}>{"Context et justification : "}</label>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                          <TextField
                              fullWidth
                              rows={3}
                              multiline
                              id="outlined-basic"
                              size="small"
                              label="Context et justification"
                              variant="outlined"
                              value={form.context}
                              name="context"
                              onChange={handleChange}
                          />
                        </Grid>
                        <Grid className={classes.gridItem} item xs={4}>
                          <label className={classes.label}>{"Objectifs : "}</label>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                          <TextField
                              fullWidth
                              rows={3}
                              multiline
                              id="outlined-basic"
                              size="small"
                              label="Objectifs"
                              variant="outlined"
                              value={form.objectif}
                              name="objectif"
                              onChange={handleChange}
                          />
                        </Grid>
                        <Grid className={classes.gridItem} item xs={4}>
                          <label className={classes.label}>{"Résultat attendue : "}</label>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                          <TextField
                              fullWidth
                              rows={3}
                              multiline
                              id="outlined-basic"
                              size="small"
                              label="Résultat attendue "
                              variant="outlined"
                              value={form.result}
                              name="result"
                              onChange={handleChange}
                          />
                        </Grid>
                        <Grid className={classes.gridItem} item xs={4}>
                          <label className={classes.label}>{"Tâche: "}</label>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                          <TextField
                              fullWidth
                              rows={3}
                              multiline
                              id="outlined-basic"
                              size="small"
                              label="Tâche"
                              variant="outlined"
                              value={form.tache}
                              name="tache"
                              onChange={handleChange}
                          />
                        </Grid>
                        <Grid className={classes.gridItem} item xs={4}>
                          <label className={classes.label}>{"Agenda: "}</label>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={8} style={{marginBottom:"20px"}}>
                          <TextField
                              fullWidth
                              rows={3}
                              multiline
                              id="outlined-basic"
                              size="small"
                              label="Agenda"
                              variant="outlined"
                              value={form.agenda}
                              name="agenda"
                              onChange={handleChange}
                          />
                        </Grid>
                    </>
                    }
                 </>
               }    
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseRefusedRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF",}} onClick={handleRefusedRequest} color="secondary" variant="contained" >
                    Confirmer
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
        </div>
      </Dialog>
      <div>
      <Dialog open={openValidateRequest} onClose={handleCloseValidateRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
              Confirmation de la validation
          </DialogTitle>
          <br />
          <div className={classes.root}>
            <div>
              <h3 className={classes.step1Title}>Votre mot de passe </h3>
            </div>
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Votre mot de passe"
                  variant="outlined"
                  value={filter.mdp}
                  name="mdp"
                  type="password"
                  onChange={handleChangemdp}
                  onFocus={handleFocusPassword}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12}>

                <Spacer y={3} />
             
                {isLoading && <Loading />}
                {error && (
                  <Alert severity="error">{errorMdpMessage}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseValidateRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleClickPassword} color="secondary" variant="contained" >
                    Valider
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    </>
  );
}
