import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import TDRElaborationTable from "./TDRElaborationTable";
import { DataGrid } from '@material-ui/data-grid';
import TDRService from "../../../services/TDRService";
import { useNavigate } from 'react-router-dom';
import { DeleteOutlineRounded} from "@material-ui/icons";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Title from "../../../components/Title";
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CreateTwoTone from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));


export default function AgendaTable() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [id_to_delete, setId_to_delete] = useState(0);
  function handleClick() {
    navigate("/mission/agenda/add");
  }
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer ?',
  });

  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const generateYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const [selectedYear, setSelectedYear] = useState(null);

  const handleChange2 = (event, newValue) => {
    setSelectedYear(newValue);
    setForm({ ...form, annee: newValue });
  };
  const [form,setForm]=useState(
    {
      statut:'Nouveau',
      mois:'',
      annee:''
    }
  );

  const years = generateYears(2024)
  const months = [
    { label: 'Janvier', value: 1 },
    { label: 'Février', value: 2 },
    { label: 'Mars', value: 3 },
    { label: 'Avril', value: 4 },
    { label: 'Mai', value: 5 },
    { label: 'Juin', value: 6 },
    { label: 'Juillet', value: 7 },
    { label: 'Août', value: 8 },
    { label: 'Septembre', value: 9 },
    { label: 'Octobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Décembre', value: 12 },
  ];
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [status,setStatus]=useState({ label: 'Nouveau', id: 'Nouveau' });
  const statut = [
    { label: 'Aucun', id: 'Aucun' },
    { label: 'Nouveau', id: 'Nouveau' },
    { label: 'En attente de validation', id: 'En attente de validation' },
    { label: 'Réfusé', id: 'Réfusé' },
    { label: 'Validé', id: 'Validé' },
  ];

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }


  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  const handleChange = (event, newValue) => {
    setSelectedMonth(newValue);
    setForm({ ...form, mois: newValue.value });
  };

  const handleChangeStatut = (event, newValue) => {
    setStatus(newValue);
    setForm({ ...form, statut: newValue.label });
  };

  const handlefilter = () => {
    TDRService.getagenda(form).then((result) => {
      if (result && result.data) {
          setRows(result.data);
      } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Aucune donnée disponible"});
          
      }
    });
  };
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    TDRService.getagendadelete(id_to_delete).then((result) => {
      window.location.reload();
      TDRService.getagenda().then((result) => {
        if (result && result.data) {
          setRows(result.data?.map((row) => ({ ...row, id: row.id })));
        } else {
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
        navigate("/mission/agendalist");
      } else {
        setMessage({ ...message, open: true, color: 'green', messageInfo: "Erreur"});
      }
    });
  }

  const { openDialog, messageDialog } = confirmationDialog;

  const handleDelete = (id) => (event) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };
  const [rows,setRows]=useState([]);
  const columns = [
    { field: 'reference', headerName: 'Référence', width: 250, },
    { field: 'title', headerName: 'Intitulé', width: 250, },
    { field: 'user_name', headerName: 'Crée par ', width: 250, },
    {
      field: 'actions', headerName: 'Actions', disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
          
              {/* <Button title="Partager Agenda" onClick={sendRequestValidation(params.row.tdr_mission_id)}>
                <CheckIcon style={{ color: "green" }} />
              </Button> */}

              {/* <Button >
                  <CreateTwoTone style={{ color: "black" }} />
               </Button> */}
                  {params.row.mission_planning_id===null&&
                         <Button onClick={handleDelete(params.row.id)}>
                              <DeleteOutlineRounded style={{ color: "red" }} />
                       </Button>
                  }
                           

          </strong>
        );
      },
      width: 200,
    }
  ];

  useEffect(() => {
    TDRService.getagenda(form).then((result) => {
      if (result && result.data) {
          setRows(result.data);
      } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Aucune donnée disponible"});
      }
    });
  }, []);

  return (
    <MainContainer {...headerDataMission}>
      <Spacer y={2} />
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog}
          closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
           <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600,marginTop:"30px",width:"200px" }} >Elaboration Agenda</Button>
            <Title>Liste Agenda</Title>
           <div style={{ height: "70vh", width: '100%' }}>
            <DataGrid rows={rows} columns={columns} />
           </div>
        </Paper>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
        <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog}
          closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
          <Grid container spacing={2}>
            <Grid item xs={2.5}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                style={{ fontWeight: 600, marginTop: "30px", width: "100%" }}
              >
                 <EditNoteIcon style={{ marginRight: '8px' }} />
                 Elaboration Agenda
              </Button>
            </Grid>
            <Grid item xs={1.5} >
               <Button
                  variant="contained"
                  color="primary"
                  onClick={handlefilter}
                  style={{ fontWeight: 600, marginTop: "30px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <SearchIcon style={{ marginRight: '8px' }} />
                  Filtrer
                </Button>
            </Grid>
            <Grid item xs={7} >
            </Grid>
            <Grid item xs={12} >
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                 value={status}
                onChange={handleChangeStatut}
                options={statut}
                getOptionLabel={(option) => option.label}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Statut"
                    variant="outlined"
                    size="small"
                    placeholder="Statut"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                  value={selectedYear}
                  onChange={handleChange2}
                  options={years}
                  getOptionLabel={(option) => option.toString()}  
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisissez une année"
                      variant="outlined"
                      size="small" 
                    />
                  )}
                  style={{ width: '100%' }}
                />
              </Grid>
            <Grid item xs={4} >
              <Autocomplete
                value={selectedMonth}
                onChange={handleChange}
                options={months}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Choisissez un mois"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
           
          </Grid>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Title>
                   Liste de l'agenda
                </Title>
              </Grid>
            <Grid item xs={12}>
              <div style={{ height: "70vh", width: '100%' }}>
                <DataGrid rows={rows} columns={columns} />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    </MainContainer>
  );
}
