import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AntTab, AntTabs } from '../../components/ant-tab';
import AppBar from "@material-ui/core/AppBar";
import Spacer from "../../components/Spacer";
import PersonnalValidator from "./PersonnalValidator";
import PersonnalValidatorMission from "./PersonnalValidatorMission";
import Userhierarchie from "./Userhierarchie";
import PersonnalSpecialValidator from "./SpecialValidator/PersonnalSpecialValidator";
import { SettingsBackupRestoreOutlined } from "@material-ui/icons";
import DayNotComptedPerPerson from "./DayNotComptedPerPerson/DayNotComptedPerPerson";
import PersonnalValidateurAppui from "./PersonnalValidateurAppui";

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      elevation: 0
    },
    paperLeaveInDay:{
      
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      elevation: 0
    },
    tab1:{
      width: "40%"
    }
  }));
export default function SpecialSettingDialog(props) {
    const open = props.openSpecialDialog;
    const userInfo = props.userInfo;
    const handleClose = props.handleCloseDialog;
    const handleValidate= props.handleValidate;
    const setId= props.id;
    const handleChangeNumberOfValidator = props.handleChangeNumberOfValidator;
    const handleChangeNumberOfValidatorMission = props.handleChangeNumberOfValidatorMission;
    const validatorNumber= props.validatorNumber;
    const validatorNumberMission= props.validatorNumberMission;

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [userName, setUserName] = useState("");
    const [userDirection, setUserDirection] = useState(0);
    const [userId, setUserId] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        setValue(0);
        setUserName(userInfo.first_name);
        setUserDirection(userInfo.direction_id);
        setUserId(userInfo.user_id);
    }, [props]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title2"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="form-dialog-title2">
                Paramétrage spécial : { userInfo.first_name }
        </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                    <AppBar position="static" color="default">
                        <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                            <AntTab label="Supérieur hiérarchique" className={classes.tab1} />
                            <AntTab disableRipple label="Nombre de validateur par personne congé" className={classes.tab1} />
                            <AntTab disableRipple label="Nombre de validateur par personne mission" className={classes.tab1} />
                            <AntTab disableRipple label="Nombre de validateur par personne demande d'appui" className={classes.tab1} />
                            <AntTab disableRipple label="Validateur(s) spécial" className={classes.tab1} />
                            <AntTab disableRipple label="Jour non comptabilisé par personne" className={classes.tab1} />
                            {/* <AntTab label="Export état" className={classes.tab1} /> */}
                        </AntTabs>
                    </AppBar>
                </Grid>
                <Spacer y={2} />
                {value == 0 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">       
                        <Userhierarchie validatorNumber={validatorNumber} 
                            handleChange={handleChangeNumberOfValidator}
                            userName={userName} handleCloseDialog={handleClose}  id={setId}
                            handleValidate={handleValidate} 
                            handleClose = {handleClose}
                            handleChangeNumberOfValidator={handleChangeNumberOfValidator}/>
                    </Paper>
                </Grid>}

                {value == 1 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">
                        <PersonnalValidator validatorNumber={validatorNumber} 
                        handleChange={handleChangeNumberOfValidator}
                        userName={userName} handleCloseDialog={handleClose}  id={setId}
                        handleValidate={handleValidate} 
                        handleClose = {handleClose}
                        handleChangeNumberOfValidator={handleChangeNumberOfValidator}/>
                    </Paper>
                </Grid>}


                {value == 2 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">
                        <PersonnalValidatorMission validatorNumberMission={validatorNumberMission} 
                        handleChange={handleChangeNumberOfValidatorMission}
                        userName={userName} handleCloseDialog={handleClose} id={setId}
                        handleClose = {handleClose}
                        handleValidate={handleValidate} handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}/>
                    </Paper>
                </Grid>}

                {value == 3 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">
                      <PersonnalValidateurAppui validatorNumberMission={validatorNumberMission} 
                        handleChange={handleChangeNumberOfValidatorMission}
                        userName={userName} handleCloseDialog={handleClose} id={setId}
                        handleClose = {handleClose}
                        handleValidate={handleValidate} handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}/>
                    </Paper>
                </Grid>}
                
                {value == 4 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">
                        <PersonnalSpecialValidator validatorNumberMission={validatorNumberMission} 
                        handleChange={handleChangeNumberOfValidatorMission} userId={userId}
                        userName={userName} handleCloseDialog={handleClose} id={setId}
                        handleClose = {handleClose} userDirection={userDirection}
                        handleValidate={handleValidate} handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}/>
                    </Paper>
                </Grid>}

                {value == 5 && <Grid item>
                    <Paper className={classes.paperLeaveInDay} variant="outlined">
                        <DayNotComptedPerPerson validatorNumberMission={validatorNumberMission} 
                        handleChange={handleChangeNumberOfValidatorMission} userId={userId}
                        userName={userName} handleCloseDialog={handleClose} id={setId}
                        handleClose = {handleClose} userDirection={userDirection}
                        handleValidate={handleValidate} handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}/>
                    </Paper>
                </Grid>}

            </DialogContent>
            <DialogActions>
            </DialogActions>

        </Dialog>
    );
}
