import React, { useEffect, useState } from 'react'
import NotificationBull from '../components/reusable/NotificationBull';

const useToast = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("ceci est une notification");
    const [severity, setSeverity] = useState("success");

    /*   useEffect(() => {
          if (contenu)
              setMessage(contenu)
      }, [contenu]) */

    const showToast = (newSeverity = "success", newMessage) => {
        if (newMessage) {
            setMessage(newMessage);
        }
        if (newSeverity) {
            setSeverity(newSeverity);
        }
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    }

    const Toast = () => (
        <NotificationBull
            open={isOpen}
            severity={severity}
            message={message}
            autoHideDuration={3500}
            handleCloseNotification={handleClose}
        />
    );

    return { showToast, Toast };
}

export default useToast