import { Tooltip, Typography } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react'
import CategoryCritereForm from './CategoryCritereForm';
import { evaluation_categorie_critere_service } from '../../../services/PersonalEvaluationService';
import { EditOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import LoadingSimpleBackdrop from '../../tools/LoadingSimpleBackdrop';
import { exportToExcel } from '../../../helpers/ExportHelpers';

const CategoryCritereTable = ({ openForm, onCloseForm, filtre, handleOpenDialog, excelExport }) => {
  const [selected_category, setSelectedCategory] = useState({} || null);
  const [categorie_criteres, setCategoryCriteres] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const navigate = useNavigate()

  const refreshDataTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_categorie_critere_service.get_evaluation_category_critere_list(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCategoryCriteres(res.data || []);
      }).catch(e => console.log(e.toString())
      )
  }

  const handleEditCategoryCritere = (category) => {
    setSelectedCategory(category);
    handleOpenDialog();
    setFormOpen(true);
  }

  useEffect(() => {
    if (filtre) {
      refreshDataTable()
    }
    if (openForm) {
      setFormOpen(openForm)
    }

    if (excelExport > 0) {
      exportToExcel(categorie_criteres, columns, "Liste des catégories de criteres " + new Date());
    }
  }, [filtre, openForm, excelExport]);


  const handleCloseFormDIalog = () => {
    onCloseForm();
    refreshDataTable();
    setFormOpen(false);
  }

  const handleShowDetailCategoryCritere = (categ) => {
    navigate(`/critere?category=${categ.ID_CATEGORY}`)
  }

  const handleOpenFormDIalog = () => {
    handleOpenDialog();
  }
  const columns = [
    {
      field: 'NOM_CATEGORY',
      headerName: 'Nom de la catégorie',
      width: 800,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'libelle_actions',
      headerName: 'Actions',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour afficher "}>
              <LoadingButton
                onClick={e => handleShowDetailCategoryCritere(params.row)}
                color="primary"
              >
                <RemoveRedEyeOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditCategoryCritere(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
            {/* <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
                //onClick={}
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip> */}
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <CategoryCritereForm isOpen={formOpen} handleClose={handleCloseFormDIalog} current_category_critere={selected_category} />
      <DataGrid
        rows={categorie_criteres}
        getRowId={(row) => row.ID_CATEGORY}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
      />
    </>
  )
}

export default CategoryCritereTable