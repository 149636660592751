import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MissionService = {
    async saveTDRMission(formData, tdrId){
        try{
            const result = tdrId?await axios.put(`${apiUrl}/tDRMission/${tdrId}`, formData)
            :await axios.post(`${apiUrl}/tDRMission`, formData);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async checkAttachment(mission_planning_id){
        try{
            const result = await axios.get(`${apiUrl}/mission/checkAttachment/${mission_planning_id}`);
            if (result) {
                return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getTDRMission(mission_planning_id, user_id, tdrId){
        try{
            const result = tdrId?await axios.get(`${apiUrl}/tDRMission/tdr/${mission_planning_id}/${user_id}/${tdrId}`)
            :await axios.get(`${apiUrl}/tDRMission/tdr/${mission_planning_id}/${user_id}`);
            if (result) {
                return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,
  async deleteElaborationTDR(mission_planning_id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/TDRMission/${mission_planning_id}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getobservation(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/TDR/getobservation/`+id);
      if (result) {
   
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async gethistory() {
    try {
      const result = await axios.get(
        `${apiUrl}/PlanningMission/gethistory`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getobservationpervalidate(user,file) {
    try {
      const result = await axios.post(
        `${apiUrl}/PlanningMission/observationpervalidate`,{
          user_id:user,
          file_id:file
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insertagenda(titre,lieu,dr,dm) {
    try {
      const result = await axios.post(
        `${apiUrl}/PlanningMission/addagenda`,{
          titre:titre,
          lieu:lieu,
          dr:dr,
          dm:dm
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updateplanification(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/Mission/getupdate/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getcomments(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/Mission/getComments/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async participants(direction_id,service_id,fonction_id) {
    try {
      const result = await axios.post(
        `${apiUrl}/User/filteruser`,{
          direction_id:direction_id,
          service_id:service_id,
          fonction_id:fonction_id
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getsugestion()
  {
      try {
        const result = await axios.get(
          `${apiUrl}/Agenda/getsuggestion`);
        if (result) {
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
  },


}

export default MissionService;


