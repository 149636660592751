import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { DeleteOutlineRounded, Subject, CalendarToday, CreateTwoTone, Clear, Add, PostAdd, Edit, LocalFlorist } from "@material-ui/icons";
import { headerDataMission } from "../../../data/modules/mission-header";
import { AntTab, AntTabs } from "../../../components/ant-tab";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import MissionService from "../../../services/MissionService";
import ObjectifService from "../../../services/ObjectifService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import { useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import { useNavigate } from 'react-router-dom';
import ResultatService from "../../../services/ResultatService";
import ContextService from "../../../services/ContextService";
import TacheService from "../../../services/TacheService";
import TDRService from "../../../services/TDRService";
import SearchIcon from '@mui/icons-material/Search';
import LocalisationMisService from "../../../services/LocalisationMisService";
import TableViewIcon from '@mui/icons-material/TableView';
import { utils, writeFile } from 'xlsx';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    // maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 750,
    height: 100,
  },
  table: {
    width: "1150px",
    textAlign: "center"
  },
  dialog: {
    width: "1150px",
    height: "1150px",
    size: "1000px",
    position: "center"
  }
}));

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

function TDRElaborationAdd(props) {
  const navigate = useNavigate();
  const [descriptions, setDescriptions] = useState([{ description_resultat: '' }]);
  const [isValidExpectedresult, setIsValidExpectedresult] = useState(false);
  const classes = useStyles();
  const [opens, setOpens] = React.useState(false);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const options = [
    { value: 'Aucun', label: 'Aucun' },
    { value: 'insert', label: 'Ajout' },
    { value: 'modif', label: 'Modification' },
    { value: 'suppression', label: 'Suppression' }
  ];
  const [historiuser,setHistoriuser]=useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [ouvert, setOuvert] = React.useState(false);
  const [tachesup, setTachesup] = React.useState(false);
  const [dialogue, setDialogue] = React.useState(false);
  const [opentache, setOpentache]=React.useState(false);
  const [isValidContext, setIsValidContext] = useState(false);
  const [isValidGoals, setIsValidGoals] = useState(false);
  const [isValidExpected_result, setIsValidExpected_result] = useState(false);
  const [isValidActivity, setIsValidActivity] = useState(false);
  const params = useParams();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const tdrId = params.tdrId;
  const [isSoldeEmpty, setisSoldeEmpty] = React.useState(false);
  const mission_planning_id = params.id;
  const user_id = localStorage.getItem('USER');
  const [rows, setRows] = useState([]);
  const[idtdr,setIdtdr]=useState(params.tdrId);
  const [tache,setTache]=useState([]);
  const [id, setId] = useState([]);
  const [idid, setIdid] = useState([]);
  const [attendu, setAttendu] = useState([]);
  const [comments,setComments]=useState([]);
  const [filtre,setFiltre]=useState({
    user_id:'',
    action:  { value: 'Aucun', label: 'Aucun' },
    tdr_id:''
  });
  const [history,setHistory]=useState([]);
  const [form, setForm] = useState({
    mission_planning_id: mission_planning_id,
    context: "",
    title:"",
    financement:[],
    objectif_id: "",
    description_objectif: "",
    commentaire_objectif: "",
    type_objectif: "",
    taux_objectif: "",
    echelonne_objectif: "",
    qualitatif_objectif: "",
    chiffre_objectif: "",
    mode_mesure_id: "",
    unite_objectif: "",

    resultat_attendu_id: "",
    description_resultat: [],
    commentaire_resultat: "",
    type_resultat: "",
    taux_resultat: "",
    echelonne_resultat: "",
    qualitatif_resultat: "",
    chiffre_resultat: "",
    unite: "",

    description_tache: "",
    tache_id:"",
    user_id: user_id
  });
  const [value, setValue] = useState(0);
  const [next, setNext] = useState([]);
  const[financement,setFinancement]=useState([]);


  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(history);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    writeFile(workbook, 'history.xlsx');
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setFiltre({ ...filtre, tdr_id: idtdr});
    if (newValue===4) {
      TDRService.gethistorie(filtre).then((res) => {
        if (res.status) {
            setHistory(res.data);
            setHistoriuser(res.user);
        } else {
        }
      }).catch((err) => {
      });
    }
  };

  const handleChangePrev = (ev) => {
    if (value == 0) {
      setValue(0);
    } else {
      setValue(value - 1);
    }
  };

  const handleChangeNext = (ev) => {

    if (value==1) {
      handleInsertContext();
    }
    if (value == 4) {
      setValue(4);
    } else {
      setValue(value + 1);
    }
  };


  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: ''
  });
  const { open, color, messageInfo } = message;

  const storage = JSON.parse(
    localStorage.getItem("storage")
  );

  const handlefilter = () => {
    console.log("filtre",filtre);
    
    TDRService.gethistorie(filtre).then((res) => {
      if (res.status) {
          setHistory(res.data);
          setHistoriuser(res.user);
      } else {
        setHistory([]);
      }
    }).catch((err) => {
    });
  };

  useEffect(() => {
    TDRService.gethistorie(filtre).then((res) => {
      if (res.status) {
          setHistory(res.data);
          setHistoriuser(res.user);
      } else {
      }
    }).catch((err) => {
    });
  }, [idtdr,filtre]);

  useEffect(() => {
    LocalisationMisService.getFinancement().then((result) => {
      if (result ) {
        setFinancement(result);
      }
    });
    if (idtdr!==undefined) {
      
     // setFiltre({ ...filtre, tdr_id: idtdr});
      ContextService.getId(idtdr).then((result) => {
        if (result && result.data) {        
          setForm(prevForm => ({
            ...prevForm,
            context: result.data.description_context,
            title: result.data.title,
            financement:result.data.financement
        }));
        } else {
        }
      })
      .catch((err) => {
        
      });

      TacheService.getDetails(idtdr).then((result) => {
        if (result && result.data) {
          setTache(result.data);
        } else {
        
        }
       })
        .catch((err) => {
      });

      ObjectifService.getById(idtdr).then((res) => {
        if (res.status) {
          setRows(res.data);
        } else {
        }
      }).catch((err) => {
      });

      ResultatService.getById(idtdr).then((res) => {
        if (res.status) {
          setAttendu(res.data);
        } else {
        }
      }).catch((err) => {
      });

      MissionService.getcomments(idtdr).then((res) => {
        if (res.status) {
            setComments(res.data);
        } else {
        }
      }).catch((err) => {
      });

      TDRService.gethistorie(filtre).then((res) => {
        if (res.status) {
            setHistory(res.data);
            setHistoriuser(res.user);
        } else {
        }
      }).catch((err) => {
      });
    }
        
  }, []);

  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setOuvert(true);
  };


  const handleDeleteTache = (id) => (ev) => {
    setId_to_delete(id);
    setTachesup(true);
  };

  const handleDeleteResultat = (id) => (ev) => {
    setId_to_delete(id);
    setDialogue(true);
  };



  const handleClickClose = () => {
    setOuvert(false);
    setDialogue(false);
    setTachesup(false)
;  };


  function handleClick() {
    if (form.context === '') {
      setIsValidContext(true);
    }
    if (form.goals === '') {
      setIsValidGoals(true);
    }
    if (form.expected_result === '') {
      setIsValidExpected_result(true);
    }
    if (form.activity === '') {
      setIsValidActivity(true);
    }
    if (form.context !== '' && form.goals !== '' && form.expected_result !== '' && form.activity !== '') {
      MissionService.saveTDRMission(form, form.tdr_mission_id).then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          navigate("/mission/elaboration_tdr");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      })
        .catch((err) => {
          setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        });
    }
  }



  function handleInsertContext() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });

    ContextService.add(form,idtdr).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(true);
        ContextService.getId(idtdr).then((result) => {
          if (result && result.data) {
            setForm(prevForm => ({
              ...prevForm,
              context: result.data.description_context,
              title: result.data.title
          }));
          } else {
          }
        })
        .catch((err) => {
        });

      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(false);

      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);

    });
    setForm({
      mission_planning_id: mission_planning_id,
      context: "",
      description_objectif: "",
      commentaire_objectif: "",
      type_objectif: "choix",
      taux_objectif: "",
      echelonne_objectif: "",
      qualitatif_objectif: "",
      chiffre_objectif: "",
      unite_objectif: "",

      description_resultat: [],
      commentaire_resultat: "",
      type_resultat: "",
      taux_resultat: "",
      echelonne_resultat: "",
      qualitatif_resultat: "",
      chiffre_resultat: "",
      unite: "",

      description_tache: "",
      tache_id:"",
      user_id: user_id
    });
      setValue(2);
  }


  // function handleEditObjectif(id) {
  //   setOpens(true);
  // };


  // const handleEditObjectif = () => {
  //   setOpens(true);
  // };




  function handleStockInsertObjectif() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    setForm(prevForm => ({
      ...prevForm,
      description_resultat: descriptions
    }));
     
    ObjectifService.add(form,idtdr, form.objectif_id,descriptions).then((result) => {
      
      ObjectifService.getById(idtdr).then((res) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOpens(false);
      });
      setForm({
        mission_planning_id: mission_planning_id,
        context: "",
        title:"",
        financement:[],
        description_objectif: "",
        commentaire_objectif: "",
        type_objectif: "choix",
        taux_objectif: "",
        echelonne_objectif: "",
        qualitatif_objectif: "",
        chiffre_objectif: "",
        unite_objectif: "",

        description_resultat: [],
        commentaire_resultat: "",
        type_resultat: "",
        taux_resultat: "",
        echelonne_resultat: "",
        qualitatif_resultat: "",
        chiffre_resultat: "",
        unite: "",

        description_tache: "",
        tache_id:"",
        user_id: user_id
      });
      setDescriptions([{ description_resultat: '' }]);
      setId([]);
    });
    setOpens(false);

  }


  function handleInsertObjectif() {
    setOpenLoadingSimpleBackdrop(true);
 
    ObjectifService.insertion(mission_planning_id).then((result) => {
      ObjectifService.getById(mission_planning_id).then((res) => {
      
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOuvert(false);
      });
    });
    setValue(2);
  }



  function handleStockInsertResultat() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    ResultatService.add(form, idtdr,form.resultat_attendu_id).then((result) => {
      ResultatService.getById(idtdr).then((res) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOpens(false);
      });
      setForm({
        mission_planning_id: mission_planning_id,
        context: "",
        title:"",
        description_objectif: "",
        commentaire_objectif: "",
        type_objectif: "choix",
        taux_objectif: "",
        echelonne_objectif: "",
        qualitatif_objectif: "",
        chiffre_objectif: "",
        unite_objectif: "",

        description_resultat: [],
        commentaire_resultat: "",
        type_resultat: "",
        taux_resultat: "",
        echelonne_resultat: "",
        qualitatif_resultat: "",
        chiffre_resultat: "",
        unite: "",

        description_tache: "",
        tache_id:"",
        user_id: user_id
      });
      setIdid([]);
    });
    setOpens(false);
  }


  function handleInsertResultat() {
    setOpenLoadingSimpleBackdrop(true);
    ResultatService.insertion(mission_planning_id).then((result) => {
      ResultatService.getById(mission_planning_id).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setAttendu([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setDialogue(false);
      });
    });
    setValue(3);
  }

  function handleValider() {
     navigate("/mission/elaboration_tdr");
  }

  function handleInsertTache() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });

    TacheService.add(form,idtdr).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setOpentache(false);
        // setOpenLoadingSimpleBackdrop(false);
        // navigate("/mission/elaboration_tdr");

          TacheService.getDetails(idtdr).then((result) => {
            if (result && result.data) {
              setTache(result.data);
            } else {
            
            }
          })
            .catch((err) => {
            });
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(false);

      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);

    });
    setForm({
      mission_planning_id: mission_planning_id,
      context: "",
      description_objectif: "",
      commentaire_objectif: "",
      type_objectif: "choix",
      taux_objectif: "",
      echelonne_objectif: "",
      qualitatif_objectif: "",
      chiffre_objectif: "",
      unite_objectif: "",

      description_resultat: [],
      commentaire_resultat: "",
      type_resultat: "",
      taux_resultat: "",
      echelonne_resultat: "",
      qualitatif_resultat: "",
      chiffre_resultat: "",
      unite: "",

      description_tache: "",
      tache_id:"",
      user_id: user_id
    });
  }




  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (name==="type_objectif") {
      setForm({ ...form, 'type_resultat': value ,'type_objectif':value});
    }
  }


  function handleChange_resultat(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }



  function handleChangeEchelonne(event) {
    const { name, value } = event.target;
    const newValue = value.replace(" ", ",");
    setForm({ ...form, [name]: newValue });
  }


  function handleChangeBalance(ev) {
    const { name, value } = ev.target;
    const newValue = value.replace(/[^0-9.]/g, '');
    setForm({ ...form, [name]: newValue });
    setisSoldeEmpty(false);
  }


  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClickOpentache = () => {
    setOpentache(true);
  };

  const handleClosetache = () => {
    setOpentache(false);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleEdit = (id) => (ev) => {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  };

  
  const handleFilter = (event, newValue, fieldName) => {
    setFiltre(prevFiltre => ({
      ...prevFiltre,
      [fieldName]: newValue
    }));
  };

  const handleInsertTitle = ()  => {
    if (idtdr!==undefined) {
      TDRService.updateTitle(form,idtdr).then((result) => {
        if (result && result.data) {
           setIdtdr(result.data);
           setFiltre({ ...filtre, tdr_id: result.data});
        } else {
        }
       })
        .catch((err) => {
        });
    } else {
      TDRService.AddTitle(form).then((result) => {
        if (result && result.data) {
           setIdtdr(result.data);
        } else {
        }
       })
        .catch((err) => {
        });
    }
     
      setValue(1);
  };
  

  const handleEditObjectif = (id) => (ev) => {
    setOpens(true);
   
    ObjectifService.getId(id).then((res) => { 
      if (res.status) {
        setForm({
          mission_planning_id: mission_planning_id,
          context: "",

          objectif_id: res.data.tdr_objectif_id,
          description_objectif: res.data.description,
          commentaire_objectif: res.data.commentaire,
          type_objectif: res.data.libelle_mode_mesure,
          taux_objectif: res.data.valeur,
          echelonne_objectif: res.data.valeur,
          qualitatif_objectif: res.data.valeur,
          chiffre_objectif: res.data.valeur,
          unite_objectif: res.data.unite,
          mode_mesure_id: res.data.mode_mesure_id,

          resultat_attendu_id: res.data.tdr_ra_id,
          description_resultat: res.data.resultat_description,
          commentaire_resultat: "",
          type_resultat: res.data.libelle_mode_mesure,
          taux_resultat: res.data.valeur,
          echelonne_resultat: res.data.valeur,
          qualitatif_resultat: res.data.valeur,
          chiffre_resultat: res.data.valeur,
          unite: res.data.unite,
          mode_mesure_id_resultat:res.data.mode_mesure_id,

          description_tache: "",
          tache_id:"",
          user_id: user_id
        });
        setDescriptions(res.data.resultat);
        setOpenLoadingSimpleBackdrop(true);
        
      } else {
        setForm([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);
    });
  };

  const handleChangedes = (e, index) => {
    const { name, value } = e.target;
    const newDescriptions = [...descriptions];
    newDescriptions[index][name] = value;
    setDescriptions(newDescriptions);
  }; 

  const handleAddDescription = () => {
    setDescriptions([...descriptions, { description_resultat: '' }]);
  };

  const handleEdiTache = (id) => (ev) => {
    setOpentache(true);
    TacheService.getid(id).then((res) => {
      if (res.status) {

        setForm({
          mission_planning_id: mission_planning_id,
          context: "",

          objectif_id: "",
          description_objectif: "",
          commentaire_objectif: "",
          type_objectif: "",
          taux_objectif: "",
          echelonne_objectif: "",
          qualitatif_objectif: "",
          chiffre_objectif: "",
          unite_objectif: "",
          mode_mesure_id: "",

          resultat_attendu_id: "",
          description_resultat: [],
          commentaire_resultat:"",
          type_resultat:"",
          taux_resultat:"",
          echelonne_resultat: "",
          qualitatif_resultat: "",
          chiffre_resultat:"",
          unite: res.data.unite,
          mode_mesure_id_resultat:"",

          description_tache: res.data.description_tache,
          tache_id:res.data.tache_id,
          user_id: user_id
        });
        setOpenLoadingSimpleBackdrop(true);
      } else {
        setForm([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      ResultatService.getId(id).then((res) => {
        if (res.status) {

          setForm({
            mission_planning_id: mission_planning_id,
            context: "",
  
            objectif_id: "",
            description_objectif: "",
            commentaire_objectif: "",
            type_objectif: "",
            taux_objectif: "",
            echelonne_objectif: "",
            qualitatif_objectif: "",
            chiffre_objectif: "",
            unite_objectif: "",
            mode_mesure_id: res.data.mode_mesure_id,
  
            resultat_attendu_id: res.data.tdr_ra_id,
            description_resultat: res.data.description,
            commentaire_resultat: res.data.commentaire,
            type_resultat: res.data.libelle_mode_mesure,
            taux_resultat: res.data.libelle_mode_mesure,
            echelonne_resultat: res.data.valeur,
            qualitatif_resultat: res.data.valeur,
            chiffre_resultat: res.data.valeur,
            unite: res.data.unite,
            mode_mesure_id_resultat:"",
  
            description_tache: "",
            tache_id:"",
            user_id: user_id
          });
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setForm([]);
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
      });
    });
    
    }

    const handleChangefinancement= (selectedValues) => {  
      setForm({ ...form, financement:selectedValues});
    }

  const handleEditResultat = (id) => (ev) => {
    setOpens(true);
    ResultatService.getId(id).then((res) => {
      if (res.status) {
       
        setForm({
          mission_planning_id: mission_planning_id,
          context: "",

          objectif_id: "",
          description_objectif: "",
          commentaire_objectif: "",
          type_objectif: "",
          taux_objectif: "",
          echelonne_objectif: "",
          qualitatif_objectif: "",
          chiffre_objectif: "",
          unite_objectif: "",
          mode_mesure_id: res.data.mode_mesure_id,

          resultat_attendu_id: res.data.tdr_ra_id,
          description_resultat: res.data.description,
          commentaire_resultat: res.data.commentaire,
          type_resultat: res.data.libelle_mode_mesure,
          taux_resultat: res.data.libelle_mode_mesure,
          echelonne_resultat: res.data.valeur,
          qualitatif_resultat: res.data.valeur,
          chiffre_resultat: res.data.valeur,
          unite: res.data.unite,
          mode_mesure_id_resultat:"",

          description_tache: "",
          tache_id:"",
          user_id: user_id
        });
        setOpenLoadingSimpleBackdrop(true);
      } else {
        setForm([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);
    });
  };


  const handleToClose = () => {
    setOuvert(false);
    setOpenLoadingSimpleBackdrop(true);
    ObjectifService.delete(id_to_delete).then((result) => {
      ObjectifService.getById(idtdr).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOuvert(false);
      });
    });
  };

  const handleToCloseTache = () => {
    TacheService.deletetache(id_to_delete).then((result) => {
          TacheService.getDetails(idtdr).then((result) => {
          if (result && result.data) {
            setTache(result.data);
            setTachesup(false);
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          } else {
            setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
          }
        })
    });
  }

  const handleToCloseResultat = () => {
    setDialogue(false);
    setOpenLoadingSimpleBackdrop(true);
    ResultatService.delete(id_to_delete).then((result) => {
      ResultatService.getById(idtdr).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setAttendu([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setDialogue(false);
      });
    });
  };




  const contextData = useContext(userDetailContext);
  return (
    <MainContainer {...headerDataMission}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      {/* <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Service') &&
          <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
        }
      </Grid> */}

      <Spacer y={2} />
      <Paper className={classes.root}>
        <AntTabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary" /*centered*/
        >
          <AntTab label="Titre" />
          <AntTab label="Contexte et justification" disabled={idtdr=== undefined} />
          <AntTab label="Objectifs et résultats attendus" disabled={idtdr === undefined}  />
          {/* <AntTab label="Résultats atendus" disabled={idtdr=== undefined}/> */}
          <AntTab label="Tâches" disabled={idtdr=== undefined} />
          <AntTab label="Historique" disabled={idtdr=== undefined} />
        </AntTabs>

        <TabPanel value={value} index={0}>
          <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
              <Grid item xs={3}>
                  <label className={classes.labelPosition}>Intitulé {bullRed} :</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField multiline className={classes.text} width="auto" 
                    id="outlined-basic" size="small" value={form.title}
                    label=""
                    variant="outlined" name="title" onChange={handleChange} /> 
                </Grid>
              
                {/* <Grid item xs={3}>
                    <label className={classes.labelPosition}>Financement {bullRed} :</label>
                </Grid>
                <Grid item xs={9}>
                <Autocomplete
                      multiple
                      className={classes.text} 
                      onChange={(event, value) => handleChangefinancement(value)} 
                      options={financement} 
                      getOptionLabel={(option) => option.code_financement}
                      value={form.financement}
                      noOptionsText="Aucun résultat trouvé"
                      filterOptions={(options, { inputValue }) => { 
                      return options.filter((option) =>
                           option.code_financement.toLowerCase().includes(inputValue.toLowerCase())
                            );
                      }}
                       renderInput={(params) => (
                       <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          label="Financement"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                           placeholder="Recherche ici" 
                           />
                        )}
                    />
                </Grid> */}
                <Grid container item xs={12}>
                  <hr></hr>
                  <Button
                    variant="outlined" color="primary"
                    onClick={handleInsertTitle}
                    style={{ fontWeight: 600 ,marginRight:'30px'}} >
                    ENREGISTRER
                  </Button>
                </Grid>

              </Grid>
              <br />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
              {comments.length>0 &&
                   <Grid item xs={12} >
                      <Box sx={{ p: 1, border: '1px dashed grey', color: 'red', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ color: 'black', textDecoration: 'underline', margin: 0 }}>Commentaires sur le contexte et justification : </h3>
                          <h3 style={{ color: 'red', marginLeft: '10px', margin: 0 }}>
                            {
                              (() => {
                                const target = comments.find(comment => comment.field_label === 'Context');
                                return target ? target.observation : 'Non trouvé';
                              })()
                            }
                          </h3>
                        </Box>
                      </Box>
                    </Grid>
                    }
                <Grid item xs={3}>
                  <label className={classes.labelPosition}>Contexte et justification {bullRed}</label>
                </Grid>
                <Grid item xs={9}> 
                  <TextField multiline className={classes.text} width="auto" rows={8}
                    id="outlined-basic" size="large" value={form.context}
                    label="Contexte et Justification"
                    variant="outlined" name="context" onChange={handleChange} />
                  {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                </Grid>
                <Grid container item xs={12}>
                </Grid>
                <Grid container item xs={12}>
                </Grid>
                <Grid container item xs={12}>
                </Grid>
                <Grid container item xs={12}>
                  <hr></hr>
                  <Button
                      name="prev"
                      value={value}
                      color="primary"
                      variant="outlined"
                      style={{marginRight:"30px"}}
                      onClick={handleChangePrev}>
                     Retour
                    </Button>
                    <Button
                      variant="outlined" 
                      color="primary"
                      onClick={handleChangeNext}
                      style={{marginRight:"30px"}} >
                      Suivant
                    </Button>
                </Grid>

              </Grid>
              <br />
            </Grid>
          </Grid>
        </TabPanel>



        <TabPanel value={value} index={2}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <fieldset>
                <legend>Objectifs et résultats attendus</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1>
                      <Button title="Ajout d'observation" onClick={handleClickOpen}>
                        <Add style={{ color: "green" }} />
                      </Button>
                    </h1>
                   {comments.length>0 &&
                   <>
                   <Grid item xs={12} >
                      <Box sx={{ p: 1, border: '1px dashed grey', color: 'red', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ color: 'black', textDecoration: 'underline', margin: 0 }}>Commentaires sur les objectifs : </h3>
                          <h3 style={{ color: 'red', marginLeft: '10px', margin: 0 }}>
                            {
                              (() => {
                                const target = comments.find(comment => comment.field_label === 'Objectifs');
                                return target ? target.observation : 'Non trouvé';
                              })()
                            }
                          </h3>
                        </Box>
                      </Box>
                    </Grid>
                  
                    <Grid item xs={12} >
                      <Box sx={{ p: 1, border: '1px dashed grey', color: 'red', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ color: 'black', textDecoration: 'underline', margin: 0 }}>Commentaires sur les résultats attendus : </h3>
                          <h3 style={{ color: 'red', marginLeft: '10px', margin: 0 }}>
                            {
                              (() => {
                                const target = comments.find(comment => comment.field_label === 'Resulat attendus');
                                return target ? target.observation : 'Non trouvé';
                              })()
                            }
                          </h3>
                        </Box>
                      </Box>
                    </Grid>
                    </>
                    }
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Objectifs</th>
                            <th>Unité de mesure</th>
                            <th>Valeur</th>
                            <th>Unité</th>
                            <th>Résultats attendus</th>
                            <th>Unité de mesure</th>
                            <th>Valeur</th>
                            <th>Unité</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row) => (
                            <tr>
                              <td >{row.description}</td>
                              <td>{row.libelle_mode_mesure}</td>
                              <td>{row.valeur}</td>
                              <td>{row.unite}</td>
                              <td>
                                {row.resultat.map((ts, index) => (
                                  <div key={index}>
                                    <ul>
                                      <li> {ts.resultat_description}</li>
                                    </ul>  
                                  </div>
                                ))}
                              </td>
                              <td>
                                {row.resultat.map((ts, index) => (
                                  <div key={index}>
                                    <ul>
                                      <li> {ts.libelle_mode_mesure_resultat}</li>
                                    </ul>  
                                  </div>
                                ))}
                              </td>
                              <td>
                                {row.resultat.map((ts, index) => (
                                  <div key={index}>
                                    <ul>
                                      <li> {ts.valeur_resultat}</li>
                                    </ul>  
                                  </div>
                                ))}
                              </td>
                              <td>
                                {row.resultat.map((ts, index) => (
                                  <div key={index}>
                                    <ul>
                                      <li> {ts.unite_resultat}</li>
                                    </ul>  
                                  </div>
                                ))}
                              </td>
                              <td>
                                <Button title="modifier l'observation"
                                  onClick={handleEditObjectif(row.tdr_objectif_id)}
                                >
                                  <Edit style={{ color: "black" }} />
                                </Button>
                                <Button title="supprimer l'observation"
                                  onClick={handleDelete(row.tdr_objectif_id)}
                                >
                                  <DeleteOutlineRounded style={{ color: "red" }} />
                                </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>

                  <Dialog open={ouvert}
                    // onClose={handleToClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText>
                        voulez-vous vraiment supprimer ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClickClose}
                        variant="contained" color="secondary">
                        Annuler
                      </Button>
                      <Button
                        onClick={handleToClose}
                        variant="contained" color="primary">
                        valider
                      </Button>
                    </DialogActions>
                  </Dialog>


                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    style={{maxHeight: '100vh'}}
                    open={opens}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Objectifs et résultats attendus
                    </DialogTitle>
                    <DialogContent
                      sx={{ height: "40vh" }}>
                     

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Description de l'objectif {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                          id="outlined-basic" size="small" label="" variant="outlined"
                          value={form.description_objectif} name="description_objectif" onChange={handleChange} />
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      {/* <Grid item xs={3}>
                        <label className={classes.labelPosition}>Commentaire </label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic"
                          size="small" label="Commentaire" variant="outlined" value={form.commentaire_objectif} name="commentaire_objectif" onChange={handleChange} />
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid> */}

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Mode mesure de l'objectif {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="" name="type_objectif"
                            value={form.type_objectif} onChange={handleChange}>
                            <MenuItem value="Narratif">Narratif</MenuItem>
                            <MenuItem value="taux">Taux</MenuItem>
                            <MenuItem value="echélonné">Echélonné</MenuItem>
                            <MenuItem value="chiffre">Chiffre</MenuItem>
                          </Select>
                        </FormControl>
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      {form.type_objectif === "taux" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}>. Taux {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={1} rowsMax={5} id="outlined-basic"
                              size="small" value="100" variant="outlined" name="taux_objectif" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      {form.type_objectif === "echélonné" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Echélonné {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                              id="outlined-basic" size="small" label="mot séparé par une virgule" value={form.echelonne_objectif} variant="outlined"
                              name="echelonne_objectif" onChange={handleChangeEchelonne} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}


                      {form.type_objectif === "chiffre" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Chiffre {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              className={classes.text}
                              error={isSoldeEmpty}
                              id="outlined-basic"
                              size="small"
                              label="Seulement des chiffres"
                              variant="outlined"
                              value={form.chiffre_objectif}
                              name="chiffre_objectif"
                              onChange={handleChangeBalance}
                            />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}
                      
                      {form.type_objectif=== "chiffre" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> Unité</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                              id="outlined-basic" size="small" value={form.unite_objectif} label="Unité" variant="outlined"
                              name="unite_objectif" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      {form.type_objectif=== "taux" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> Unité</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                              id="outlined-basic" size="small" value={"%"} label="Unité" variant="outlined"
                              name="unite_objectif" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <label className={classes.labelPosition}>Description du résultat attendu{bullRed}</label>
                      </Grid>
                      {descriptions.map((desc, index) => (
                        <Grid item xs={9}>
                              <TextField
                                multiline
                                className={classes.text}
                                rows={3}
                                rowsMax={5}
                                id={`description-${index}`}
                                size="small"
                                label={`Résultat attendu ${index + 1}`}
                                variant="outlined"
                                value={desc.description_resultat}
                                name="description_resultat"
                                onChange={(e) => handleChangedes(e, index)}
                              />
                          {isValidExpected_result && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                        </Grid>
                       ))}
                       <Grid item xs={12} className={classes.addButton}>
                          <Button variant="outlined" color="primary" onClick={handleAddDescription}>
                            Autre résultat
                          </Button>
                       </Grid>
                      <Grid item xs={4}>
                        <label className={classes.labelPosition}>Mode mesure du résultat attendu {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                            label="" name="type_resultat" value={form.type_resultat} onChange={handleChange_resultat}>
                            <MenuItem value="Narratif">Narratif</MenuItem>
                            <MenuItem value="taux">Taux</MenuItem>
                            <MenuItem value="echélonné">Echélonné</MenuItem>
                            <MenuItem value="chiffre">Chiffre</MenuItem>
                          </Select>
                        </FormControl>
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>
                      {form.type_resultat === "taux" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}>. Taux {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={1} rowsMax={5} id="outlined-basic" size="small" value={100}
                              variant="outlined" name="taux_resultat" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      {form.type_resultat === "echélonné" && (
                        <Grid>

                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Echélonné {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small"
                              label="mot séparé par une virgule" variant="outlined"
                              value={form.echelonne_resultat} name="echelonne_resultat" onChange={handleChangeEchelonne} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}
                      {form.type_resultat === "chiffre" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Chiffre {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              error={isSoldeEmpty}
                              id="outlined-basic"
                              size="small"
                              label="Seulement des chiffres"
                              variant="outlined"
                              value={form.chiffre_resultat}
                              name="chiffre_resultat"
                              onChange={handleChangeBalance}
                            />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                   {form.type_resultat === "chiffre" && (
                      <>
                        <Grid item xs={3}>
                          <label className={classes.labelPosition}>Unité {bullRed}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Résultat attendus"
                            variant="outlined" name="unite" value={form.unite} onChange={handleChange} />
                          {isValidExpected_result && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                        </Grid>
                        </>
                    )}
                      {form.type_resultat === "taux" && (
                      <>
                        <Grid item xs={3}>
                          <label className={classes.labelPosition}>Unité {bullRed}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Résultat attendu"
                            variant="outlined" name="unite" value={"%"} onChange={handleChange} />
                          {isValidExpected_result && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                        </Grid>
                        </>
                    )}

                    </DialogContent>
                    <DialogActions>
                      <Button color="primary" variant="contained" onClick={handleStockInsertObjectif}>Enregistrer</Button>
                      <Button onClick={handleClose} variant="contained" color="secondary" autoFocus>
                        Annuler
                      </Button>
                    </DialogActions>
                  </Dialog>


                  <Grid container item xs={12}>
                    <hr></hr>
                    <Button
                      name="prev"
                      value={value}
                      color="primary"
                      variant="outlined"
                      style={{marginRight:"30px"}}
                      onClick={handleChangePrev}>
                     Retour
                    </Button>
                    <Button
                      variant="outlined" 
                      color="primary"
                      onClick={handleChangeNext}
                      style={{marginRight:"30px"}} >
                      Suivant
                    </Button>
                    {/* <Button
                      name="next"
                      value={value}
                      variant="outlined"
                      style={{marginRight:"30px"}}
                      onClick={handleChangeNext}>
                      Next
                    </Button> */}
                  </Grid>

                </Grid>
                <br />
              </fieldset>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <fieldset>
                <legend>Tâche à faire</legend>
                <br />
                <Grid item xs={12}>
                    <h1>
                      <Button title="Ajout tâche" onClick={handleClickOpentache}>
                        <Add style={{ color: "green" }} />
                      </Button>
                    </h1>
                    {comments.length>0 &&
                    <Grid item xs={12} >
                      <Box sx={{ p: 1, border: '1px dashed grey', color: 'red', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ color: 'black', textDecoration: 'underline', margin: 0 }}>Commentaires sur les tâches : </h3>
                          <h3 style={{ color: 'red', marginLeft: '10px', margin: 0 }}>
                            {
                              (() => {
                                const target = comments.find(comment => comment.field_label === 'Tâche');
                                return target ? target.observation : 'Non trouvé';
                              })()
                            }
                          </h3>
                        </Box>
                      </Box>
                    </Grid>}
                    <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={opentache}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Tâche
                    </DialogTitle>
                    <DialogContent
                      sx={{ height: "20vh" }}>
                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Description {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                          id="outlined-basic" size="small" label="" variant="outlined"
                          value={form.description_tache} name="description_tache" onChange={handleChange} />
                      </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleInsertTache}>Enregistrer</Button>
                        <Button onClick={handleClosetache} autoFocus>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </Grid>
                  <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Tâche</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tache.map((row) => (
                            <tr>
                              <td>{row.description_tache}</td>
                              <td>
                                <Button title="modifier la tâche"
                                  onClick={handleEdiTache(row.tache_id)}
                                >
                                  <Edit style={{ color: "black" }} />
                                </Button>
                                <Button title="supprimer l'observation"
                                  onClick={handleDeleteTache(row.tache_id)}
                                >
                                  <DeleteOutlineRounded style={{ color: "red" }} />
                                </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                <Grid container item xs={12}>
                  <hr></hr>
                  <Button
                    name="prev"
                    variant="outlined"
                    value={value}
                    style={{marginRight:"30px"}}
                    onClick={handleChangePrev}>
                   Retour
                  </Button>
                  <Button
                      variant="outlined" color="primary"
                      onClick={handleValider}
                      style={{ fontWeight: 600 ,marginRight:"30px"}} >
                      VALIDER
                    </Button>
                
                </Grid>

                <br />
              </fieldset>
              <Dialog open={tachesup}
                    // onClose={handleToClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText>
                        voulez-vous vraiment supprimer ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClickClose}
                        variant="contained" color="secondary">
                        Annuler
                      </Button>
                      <Button
                        onClick={handleToCloseTache}
                        variant="contained" color="primary">
                        valider
                      </Button>
                    </DialogActions>
                  </Dialog>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid container spacing={3} className={classes.paper}>
             <Grid item xs={4}>
             <Autocomplete
                id="demo-select-small"
                options={options}
                value={filtre.action}
                onChange={(event, newValue) => handleFilter(event, newValue, 'action')}
                 getOptionLabel={(option) => option.label}
                size="small"
                style={{ margin: "10px" }}
                renderInput={(params) => <TextField {...params} label="Sélectionnez une action" variant="outlined" size="small" />}
              />
              </Grid>
              <Grid item xs={4}>
             <Autocomplete
                id="demo-select-small"
                options={historiuser}
                value={filtre.user_id}
                onChange={(event, newValue) => handleFilter(event, newValue, 'user_id')}
                 getOptionLabel={(option) => option.utilisateur}
                size="small"
                name="user_id"
                style={{ margin: "10px" }}
                renderInput={(params) => <TextField {...params} label="Utilisateur" variant="outlined" size="small" />}
              />
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlefilter}
                  style={{ fontWeight: 600, marginTop: "10px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <SearchIcon style={{ marginRight: '8px' }} />
                  Filtrer
                </Button>

              </Grid>
              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={exportToExcel}
                  style={{ fontWeight: 600, marginTop: "10px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TableViewIcon style={{ marginRight: '8px' }} />
                  Export Excel
                </Button>

              </Grid>
              <Grid item xs={0.5}>  </Grid>
            <Grid item xs={12}>
              <p style={{textAlign:'center'}}>Liste des historiques</p>
            </Grid>
            <Grid item xs={12}>
                <table className={classes.table} style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                    <thead>
                       <tr>
                          <th>Type</th>
                          <th> Nouvelle version </th>
                          <th> Ancienne version </th>
                          <th> Action </th>
                          <th> Utilisateur </th>
                          <th> Date et heure</th>
                          {/* <th> Heure de la modification</th> */}
                       </tr>
                    </thead>
                    <tbody>
                    {history.map((ts, index) => (
                         <tr key={index} style={{ borderBottom: '1px solid black',fontSize: "10px"}}>
                         <td style={{ border: '1px solid black', padding: '8px',fontSize: "10px" }}>
                         {    
                            ts.type === "context" ? "Contexte et justification" :
                            ts.type === "tache" ? "Tâche" :
                            ts.type === "resultat" ? "Résultat attendu" :
                            "Objectifs"
                          }
                          </td>
                         <td style={{ border: '1px solid black', padding: '8px',fontSize: "10px" }}>{ts.newvaleur}</td>
                         <td style={{ border: '1px solid black', padding: '8px',fontSize: "10px" }}>{ts.oldvaleur}</td>
                         <td style={{ border: '1px solid black', padding: '8px',fontSize: "10px" }}>
                         {
                            ts.action === "insert" ? "Ajout" :
                            ts.action === "modif" ? "Modification" :
                            "Suppression"
                          }
                         </td>
                         <td style={{ border: '1px solid black', padding: '8px',fontSize: "10px" }}>{ts.utilisateur}</td>
                         <td style={{ border: '1px solid black', padding: '8px' ,fontSize: "10px"}}>{ts.date} {ts.formatted_time}</td>
                         {/* <td style={{ border: '1px solid black', padding: '8px' ,fontSize: "5px"}}>{ts.formatted_time}</td> */}       
                       </tr>
                      ))}
                    </tbody>
                </table>
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
    </MainContainer >
  );
}

export default TDRElaborationAdd;
