import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Alert from "@material-ui/lab/Alert";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import TDRService from "../../../services/TDRService";
import { useLocation } from 'react-router-dom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { headerDataMission } from "../../../data/modules/mission-header";
import Message from "../../tools/Message";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import UserService from "../../../services/UserService";
import PlanningService from "../../../services/PlanningService";
import PlanningMissionService from "../../../services/PlanningMissionService";
import DirectionService from "../../../services/DirectionService";
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: '80%',
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
}));

function AppuiAdd(props) {


  const noteStyle = {
    backgroundColor: '#d4edda', 
    color: '#155724',            
    borderRadius: '5px',
    border: '1px solid #c3e6cb',
    maxWidth: '100%',
    textAlign: 'center',
    margin: '0' 
  };

  const classes = useStyles();

  const [form, setForm] = useState({
    id: "",
    nbrjr: "",
    desc: "",
    raison: "",
    type: "",
    composant: "",
    date: "",
    date: "",
    user_id: [],
    type_activity_id: [],

  });

  const [isValidUser_id, setIsValidUser_id] = useState(false);
  const [intervenant, setIntervenant] = useState([]);

  const [isDateEmpty, setIsDateEmpty] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [composant, setComposant] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [tests, setTests] = React.useState([]);
  const [valComposant, setValComposant] = useState();
  const navigate = useNavigate();
  const [loading,setLoading]=useState(false);

  const [date_start, setDate_start] = useState(null);
  const [isValidType_activity_id, setIsValidType_activity_id] = useState(false);
  const [activity, setActivity] = useState([]);

  const [direction, setDirection] = useState([]);
  const [service, setService] = useState([]);
  // const [intervenant, setIntervenant] = useState([]);

  const [direction_id, setDirection_id] = useState({});
  const [service_id, setService_id] = useState({});

  // const [isValidUser_id, setIsValidUser_id] = useState(false);

  const [iddir, setIddir] = React.useState(0);
  const [dir, setDir] = useState([]);
  // const [service, setService] = useState([]);
  const [users, setUsers] = useState([]);
  const [idservice, setIdservice] = useState(0);
  const [isUserUndefined, setisUserUndefined] = React.useState(false);
  const [idUserSup, setIdUserSup] = React.useState(0);
  const [errorMessageUserEmpty, setErrorMessageUserEmpty] = React.useState("");
  const params = useParams();
  const idUser = params.id;



  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  useEffect(() => {
    const idGroup = splitLocation[4];
    if (idGroup) {
      TDRService.Add(idGroup).then((tdr) => {
        setForm({
          id: tdr.data[0].mission_request_id,
          nbrjr: tdr.data[0].number_day,
          desc: tdr.data[0].description,
          raison: tdr.data[0].reason,
          type: tdr.data[0].type_mission_request,
          composant: tdr.data[0].name,
          valComposant: tdr.data[0].type_activity_id,
          type_activity_id:[{type_activity_id:tdr.data[0].type_activity_id,name:tdr.data[0].name}],
          user_id:tdr.user,
          date:tdr.data[0].date
        });
        //Affectation de direction par defaut et le service par defaut
         setDirection_id(tdr.direction);
         if (tdr.service===null) {
          setService_id({});
         }
         else{
          setService_id(tdr.service);
         }
         
      }, []);
    }
  }, []);

  useEffect(() => {
    PlanningMissionService.getActivity().then((result) => {
      if (result && result.data) {
        setActivity(result.data);
      }
    });

    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });
    
    PlanningMissionService.getServiceappui(direction_id.direction_id).then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
    PlanningMissionService.getIntervenant(direction_id.direction_id, service_id.service_id).then((result) => {
      if (result && result.data) { 
         setIntervenant(result.data);
      }
    });
    if(direction_id===null || service_id===null){
      PlanningMissionService.getIntervenant(0, 0).then((result) => {
        if (result && result.data) {
          setIntervenant(result.data);
        }
      });
    }

    TDRService.addComposant().then((result) => {
      if (result && result.data) {
        setComposant(result.data);
      }
    });
 
  }, [direction_id, service_id,setComposant]);


  function handleChangeDirection(event, value){
    setDirection_id(value);
  }

  function handleChangeService(event, value){
    setService_id(value);
  }
  
  function handleChangeIntervenants(event, value){
      setForm({ ...form, user_id: value });
  }

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  const getItemLabel1 = (id) => {
    for (const row of tests) {
      if (row.type_activity_id === id) {
        return row.name;
      }

    }
    return "";
  };
  function handleClick() {
    form['direction'] = direction_id.direction_id;
    form['service'] = service_id.service_id;
     
  
    TDRService.saveMissionRequest(form)
      .then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          navigate("/mission/demande");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      })
      .catch((err) => {
        // alert("error");
      });
 
  }

  function handleEditionChange(newValue)
  {
    //console.log("aaa",newValue.target.value);
      setDate_start(newValue.target.value);
    setForm({ ...form, date: newValue.target.value });
  }


  function handleChangeTypeActivity(event, value) {
    setForm({ ...form, type_activity_id: value });
  }

  function handleChangeUsers(ev) {
    const { name, value } = ev.target;
    setIdUserSup(value);
    setForm({ ...form, [name]: value });
    setisUserUndefined(false);
  }

  function handleComposant() {
    let result = "";
    for (let index = 0; index < tests.length; index++) {
      if (result == "") {
        result = tests[index].type_activity_id;
      }
      else {
        result = result + "," + tests[index].type_activity_id;
      }
    }
    setValComposant(result);
    setForm({ ...form, composant: valComposant });
  }


  function handleChange(ev) {
    const { name, value } = ev.target;
    handleComposant();
    setForm({ ...form, [name]: value })
  }
  

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }


  function handleCancel() {
    navigate("/mission/demande");
  }


  return (
    <MainContainer {...headerDataMission}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <LoadingButton
          onClick={handleClick}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          ENREGISTRER
        </LoadingButton>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>



          <Grid item xs={12}>
            <fieldset>
              <legend>Détail de la demande</legend>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Composante</label>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={activity}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    onChange={handleChangeTypeActivity}
                    value={form.type_activity_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Composante"
                        variant="outlined"
                        placeholder="Composante"
                      />
                    )}
                  />
                  {isValidType_activity_id && (
                    <Alert severity="error">Veuillez remplir ce champ</Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Type*</label>
                </Grid>
                <Grid item xs={10}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type" name="type" value={form.type} onChange={handleChange}>
                      <MenuItem value="Demande d'appui">Demande d'appui</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Raison *</label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label="Raison"
                    rows={2}
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    value={form.raison}
                    name="raison"
                  />
                </Grid>

                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Description</label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    rows={5}
                    multiline
                    label="Description"
                    variant="outlined"
                    onChange={handleChange}
                    value={form.desc}
                    name="desc"
                  />
                </Grid>

                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Nombre du jour*</label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    type="number"
                    id="outlined-basic"
                    size="small"
                    label="Nombre du jour"
                    variant="outlined"
                    onChange={handleChange}
                    value={form.nbrjr}
                    name="nbrjr"
                  />
                </Grid>

                <Grid item xs={2}>
                  <label className={classes.label}>Date désiré:</label>
                </Grid>
                <Grid item xs={10}>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={form.date}
                      onChange={handleEditionChange}
                      renderInput={(params) =>
                        <TextField {...params} name="date" fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} />
                      }
                    />
                  </LocalizationProvider> */}
                   <TextField
                            fullWidth
                            id="outlined-basic"
                            size="small"
                            label=""
                            variant="outlined"
                            type="date"
                            value={form.date}
                            name="date"
                            onChange={handleEditionChange}
                       />
                </Grid>
              </Grid>


              <br />

              <Grid container spacing={3}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  {isValidDate && (
                    <Alert severity="error">
                      veuillez verifier les dates de votre mission
                    </Alert>
                  )}
                  {isDateEmpty && (
                    <Alert severity="error">Veuillez remplir ce champ</Alert>
                  )}
                </Grid>
              </Grid>
            </fieldset>
          </Grid>


          <Grid item xs={12}>
            <fieldset>
              <legend>Personnel désiré</legend><br />
              <Grid container spacing={3}>
              <Grid item xs={12}>
                        <div style={noteStyle}>
                            <p>Merci de sélectionner exclusivement l'une des options suivantes : soit une direction, soit un service</p>
                        </div>
                        </Grid>
                <Grid item xs={2}>
                  <label className={classes.labelPosition}>Direction</label>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    onChange={handleChangeDirection}
                    options={direction} 
                    //getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name + ' (' + option.code + ')';
                      }
                      else{
                        return '';
                      }
                    }}
                    value={direction_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Direction"
                        size="small"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                 <label>Service</label>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    onChange={handleChangeService}
                    options={service} 
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name + ' (' + option.code + ')';
                      }
                      else{
                        return '';
                      }
                    }}
                    value={service_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Service"
                        size="small"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <label className={classes.labelPosition}>Intervenants</label>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete multiple id="tags-outlined" options={intervenant} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                    filterSelectedOptions
                    onChange={handleChangeIntervenants}
                    value={form.user_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Intervenants"
                        variant="outlined"
                        placeholder="Intervenants"
                        // name="intervenant"
                      />
                    )}
                  />
                  {isValidUser_id && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                </Grid> */}
              </Grid><br />
            </fieldset>
          </Grid>

        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default AppuiAdd;
