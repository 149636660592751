import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AcceptRequestService = {
    async verifyMdp(mdp, module_type_id){
        try{
            const result = await axios.post(`${apiUrl}/acceptRequest/mdp`, {
                mdp : mdp,
                module_type_id : module_type_id
            });
           if (result) {
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async doValidationRequest(pin_code, validation_id, module_type_id, file_validation_request_id, user_requested_id){
        try{
            const result = await axios.post(`${apiUrl}/acceptRequest/codePIN`, {
                pin_code : pin_code,
                validation_id : validation_id,
                module_type_id : module_type_id,
                file_validation_request_id : file_validation_request_id,
                user_requested_id : user_requested_id
            });
           if (result) {
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    
    async doValidationRequestSimple(validation_id, module_type_id, file_validation_request_id, user_requested_id, validation_with_observation){
        try{
            const result = await axios.post(`${apiUrl}/acceptRequest/simpleValidation`, {
                validation_id               : validation_id,
                module_type_id              : module_type_id,
                file_validation_request_id  : file_validation_request_id,
                user_requested_id           : user_requested_id,
                validation_with_observation : validation_with_observation
            });
           if (result) {
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
}

export default AcceptRequestService;