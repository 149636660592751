import React , { useEffect, useState } from "react";
import { headerData } from '../../../data/modules/my-validations-header';
import MainContainer from "../../../components/MainContainer";
import { Grid, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@mui/icons-material/Search';
import CalendarView from "./CalendarView";
import PlanningService from "../../../services/PlanningService";
import Box from '@mui/material/Box';
import Message from "../../tools/Message";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { Alert, AlertTitle } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function PlanningListv(props) {
  const noteStyle = {
    backgroundColor: '#d4edda', 
    color: '#155724',            
    borderRadius: '5px',
    border: '1px solid #c3e6cb',
    maxWidth: '100%',
    textAlign: 'center',
    margin: '0' 
  };
  const [filter, setFilter] = useState({
    date_debut: '',
    date_fin: '',
    idperiode:''
  });
  const [showTable, setShowTable] = useState(false);
  const [details,setDetails]=useState([]);
  const [periode,setPeriode]=useState([]);
  const [active,setActive]=useState(true);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value }); 
  }

  function handleChangePeriode(event, value) {
      const datedebut=moment(value.date_debut).format('YYYY-MM-DD');
      const datefin=moment(value.date_fin).format('YYYY-MM-DD');
      setFilter({ ...filter, date_debut: datedebut,date_fin:datefin,idperiode:value.periode_id}); 
  }

  function handleFilter(event) {
     
    if (filter.date_debut===''&& filter.idperiode==='') {
        setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez choisir une période de validation.'});
    } else {
        PlanningService.getplanning(filter.idperiode).then((result) => {
            if (result && result.data) {
                setShowTable(true); 
                setActive(false);
                setDetails(result.data);
            }
            else{
                setMessage({...message, open: true, color: 'red', messageInfo: 'Aucun planning n\'est disponible entre ces dates'});
                setActive(true);
            }
        });
    }
    
  }

  function handlevalidate(event) {
     
    if (filter.date_debut===''&& filter.idperiode==='') {
        setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez choisir une période de validation.'});
    } else {
      PlanningService.tousvalidatemission(details,periode[0].periode_id).then((result) => {
        if (result && result.data) {
          setDetails(result.data);
          setActive(true);
        }
      });
    }  
  }

  useEffect(() => {
    PlanningService.periodelist().then((result) => {
        if (result && result.data) {
          const periodeactive = result.data.filter(item => item.active === "true");
          setPeriode(periodeactive);
        }
        else{
           setPeriode([]);
        }
    });
 }, []);

  return (
    <MainContainer {...headerData}>
      <React.Fragment>
        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
          <Grid container spacing={4}>
                   <Grid item xs={12}>
                          <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                               Veuillez choisir la période de validation pour la planification de la mission.
                            </Alert>
                    </Grid>
                    <Grid item xs={3}>
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                     >
                        <SearchIcon style={{ marginRight: '8px' }} />
                        Consulter les plannings
                     </Button>
                 </Grid>  
                 <Grid item xs={4}>
                    <Autocomplete
                        onChange={handleChangePeriode}
                        options={periode} 
                        getOptionLabel={(option) => option.description + ' (' + moment(option.date_debut).format('DD/MM/YYYY') + ' à '+ moment(option.date_fin).format('DD/MM/YYYY')+ ')'}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            label="Période de validation"
                            size="small"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: "search" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        onClick={handlevalidate} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor:"#8FBC8F" }}
                        disabled={active}
                    >
                        <DoneAllIcon style={{ marginRight: '8px' }} />
                        Valider tout les plannings
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#87CEEB' }}
                    >
                    </Box>
                    Planning en attente de validation 
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#78b785' }}
                    >
                    </Box>
                    Planning validé 
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'red' }}
                    >
                    </Box>
                    Planning refusé
                </Grid>
                <Grid item xs={3}>
                   
                </Grid>
                <Grid item xs={12}>
                   {showTable && filter.date_debut && filter.date_fin && (
                      < CalendarView  
                      startDate={new Date(filter.date_debut)}
                      endDate={new Date(filter.date_fin)}
                      datatables={details}
                      periode={filter.idperiode}
                      />
                    )}
                </Grid>
            </Grid>
           
      </React.Fragment>
    </MainContainer>
  );
}

export default PlanningListv;
