import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_critere_groupee_critere_service } from "../../../services/PersonalEvaluationService";
import { DeleteOutline } from "@material-ui/icons";
import CritereGroupeeCritereForm from "./CritereGroupeeCritereForm";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import useConfirm from "../../../hooks/useConfirm";

const CritereGroupeeCritereTable = ({
  openForm,
  onCloseForm,
  handleOpenDialog,
  filtre,
  excelExport,
}) => {
  const [criteres, setCriteres] = useState([]);
  const [current_critere, setCurrentCritere] = useState(null);
  const [form_open, setFormOpen] = useState(openForm);
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const { Toast, showToast } = useToast();
  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleRefreshDataTable(filtre);
    setFormOpen(false);
  };

  const handleRefreshDataTable = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_critere_groupee_critere_service
      .getCritereGroupeeCritereList(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCriteres(res.data || []);
      })
      .catch((e) => console.log(e.toString()));
  };

  const confirmDeleteCritere = (critere) => {
    evaluation_critere_groupee_critere_service
      .deleteCritereGroupeeCritere(critere)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleRefreshDataTable(filtre);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleDeleteSelectedCritere = (critere) => {
    openConfirmDialog(
      "Confirmation",
      "Voullez vous supprimer cette ligne? L'action est irreversible.",
      () => confirmDeleteCritere(critere)
    );
  };

  useEffect(() => {
    if (filtre) {
      handleRefreshDataTable(filtre);
    }
    if (openForm) {
      setFormOpen(openForm);
    }
    if (excelExport > 0) {
      exportToExcel(criteres, columns, "Groupe de critere liste " + new Date());
    }
  }, [filtre, openForm, excelExport]);

  const columns = [
    {
      field: "QUESTION",
      headerName: "Critere",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "NOM_CRITERE_GROUPEE",
      headerName: "Nom du groupe",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "DATE_CREATION_CRITERE_GROUPEE",
      headerName: "Date de la création ",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
                onClick={(e) => handleDeleteSelectedCritere(params.row)}
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <Toast />
      <DialogComponent />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <CritereGroupeeCritereForm
        current_critere={current_critere}
        handleClose={handleCloseFormDIalog}
        isOpen={form_open}
      />
      <DataGrid
        rows={criteres}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_CRITERE}
      />
    </>
  );
};
export default CritereGroupeeCritereTable;
