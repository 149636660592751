import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import KeybordVirtuel from "./KeybordVirtuel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from '@mui/material/TextField';

export default function RefusedDialog(props) {
  const open = props.openrefused;
  const handleCloseCodePin = props.handleCloserefused;
  const handleValidateCodePin = props.handleValidaterefused;
  const onChangeInput = props.onChangeInputValue;
  return (
    <Dialog
        open={open}
        onClose={handleCloseCodePin}
        aria-labelledby="form-dialog-title2"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title2">
            Refuser la demande 
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer la raison du refus
          </DialogContentText>
          <TextField id="outlined-basic" label="Raison" variant="outlined" style={{ width: "570px" }} onChange={onChangeInput} />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
            onClick={handleCloseCodePin}
            color="secondary"
            variant="contained"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
            onClick={handleValidateCodePin}
            color="primary"
            variant="contained"
          >
            Valider
          </Button>
        </DialogActions>
        
      </Dialog>
  );
}
