import { RefreshOutlined } from "@material-ui/icons";
import { DownloadOutlined } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import QuestionnaireTable from "./QuestionnaireTable";
import {
  evaluation_categorie_critere_service,
  evaluation_saison_service,
  evaluation_utilities_service,
} from "../../../services/PersonalEvaluationService";
import { useNavigate } from "react-router";
import { useQuery } from "../critere_groupee/CritereGroupeeCritereFiltre";

const QuestionnaireFiltre = ({
  handleOpenFormDialog,
  handleCloseFormDialog,
  openForm,
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [categoryCriteres, setCategoryCriteres] = useState([]);
  const [evaluationsSaisons, setEvaluationSaisonsList] = useState([]);
  const [fonctions, setFonctionsList] = useState([]);

  const [filtre, setFiltre] = useState({
    saison: null,
    fonction: null,
    category: null,
    mot_cle: "",
    refresh: 0,
  });

  const handleOpenDialogForm = () => {
    handleOpenFormDialog();
  };

  const handleCloseDialogForm = () => {
    handleCloseFormDialog();
  };

  const handleChangeSaison = (new_val) => {
    if (new_val) {
      setFiltre({ ...filtre, saison: new_val });
      navigate(`/questionnaire?id_saison=${new_val.ID_SAISON}`);
    } else {
      setFiltre({ ...filtre, saison: null });
      navigate(`/questionnaire`);
    }
  };
  const handleChangeFonciton = (new_val) => {
    setFiltre({ ...filtre, fonction: new_val });
  };
  const handleChangeCategory = (new_val) => {
    setFiltre({ ...filtre, category: new_val });
  };

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };

  useEffect(() => {
    handleInitFilterFields();
  }, []);

  useEffect(() => {
    if (query.get("id_saison")) {
      setFiltre({
        ...filtre,
        saison: { ID_SAISON: query.get("id_saison") },
      });
    } else {
      handleRefreshData();
    }
  }, [query.get("id_saison")]);

  const handleInitFilterFields = () => {
    evaluation_categorie_critere_service
      .get_evaluation_category_critere_list(null)
      .then((res) => {
        setCategoryCriteres(res.data || []);
      })
      .catch((e) => {
        console.log(e.toString());
      });

    evaluation_saison_service.getSaisonList(filtre).then((res) => {
      if (res && res.success) {
        setEvaluationSaisonsList(res.data);
        setFiltre({
          ...filtre,
          saison: adaptDataValueToAutocompleteData(
            evaluationsSaisons,
            "NOM_SESSION",
            "ID_SAISON"
          ).find((item) => item.ID_SAISON === query.get("id_saison")),
        });
      }
    });
    evaluation_utilities_service.getFonctionList(filtre).then((res) => {
      if (res && res.success) {
        setFonctionsList(res.data);
      }
    });
  };

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {/* <Button variant="contained" color='error' className='filtre_list' >
                creer
              </Button> */}
        <AutocompleteCombobox
          limitTags={2}
          label="Saisons"
          stylex={{ width: 200 }}
          class_name="filtre_list"
          valeur={adaptDataValueToAutocompleteData(
            evaluationsSaisons,
            "NOM_SESSION",
            "ID_SAISON"
          ).find((item) => item.ID_SAISON === query.get("id_saison"))}
          data={adaptDataValueToAutocompleteData(
            evaluationsSaisons,
            "NOM_SESSION",
            "ID_SAISON"
          )}
          onChange={handleChangeSaison}
        />
        <AutocompleteCombobox
          limitTags={2}
          label="Fonction"
          stylex={{ width: 200 }}
          class_name="filtre_list"
          data={adaptDataValueToAutocompleteData(
            fonctions,
            "name",
            "function_id"
          )}
          onChange={handleChangeFonciton}
        />
        {/*  <AutocompleteCombobox
                    limitTags={2}
                    label="Categorie"
                    stylex={{ width: 200 }}
                    class_name="filtre_list"
                    data={adaptDataValueToAutocompleteData(categoryCriteres, 'NOM_CATEGORY', 'ID_CATEGORY')}
                    onChange={handleChangeCategory}
                /> */}

        <TextField
          sx={{ width: 250 }}
          size="small"
          value={filtre.mot_cle}
          label="Mot clé"
          name="mot_cle"
          onChange={handlechangeFiltre}
          className="filtre_list"
        />
        <Button
          variant="outlined"
          className="filtre_list"
          onClick={handleRefreshData}
        >
          <RefreshOutlined />
        </Button>
        <Button variant="outlined" className="filtre_list">
          <DownloadOutlined /> Excel export
        </Button>
      </Grid>
      <Grid flexGrow={1} mt={3}>
        <QuestionnaireTable
          handleOpenDialog={handleOpenDialogForm}
          onCloseForm={handleCloseDialogForm}
          openForm={openForm}
          filtre={filtre}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionnaireFiltre;
