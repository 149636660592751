import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField'; // Correct import from '@mui/material'

const ValidateMission = ({ open, onClose, onConfirm }) => {
  const [form, setForm] = useState({
    planning: 'valide',
    tdr: 'valide',
    observation: ''
  });

  const handleChangePlanning = (event) => {
    setForm({ ...form, planning: event.target.value });
  };

  const handleChangeTDR = (event) => {
    setForm({ ...form, tdr: event.target.value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title1">Confirmer la mission</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">Intitulé: Test</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Planning Mission</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={form.planning === 'valide'} onChange={() => handleChangePlanning({ target: { value: 'valide' } })} />}
                label="Valider"
              />
              <FormControlLabel
                control={<Checkbox checked={form.planning === 'refuse'} onChange={() => handleChangePlanning({ target: { value: 'refuse' } })} />}
                label="Réfuser"
              />
            </FormGroup>
          </Grid>
          {/* {form.planning === 'refuse' && (
            <> */}
              <Grid item xs={4}>
                <Typography variant="body1">Observation</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  multiline
                  rows={5}
                  fullWidth
                  onChange={handleChange}
                  id="outlined-basic"
                  size="small"
                  value={form.observation}
                  label="Observation"
                  variant="outlined"
                  name="observation"
                />
              </Grid>
            {/* </>
          )} */}
          <Grid item xs={4}>
            <Typography variant="body1">TDR</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={form.tdr === 'valide'} onChange={() => handleChangeTDR({ target: { value: 'valide' } })} />}
                label="Valider"
              />
              <FormControlLabel
                control={<Checkbox checked={form.tdr === 'refuse'} onChange={() => handleChangeTDR({ target: { value: 'refuse' } })} />}
                label="Réfuser"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidateMission;
