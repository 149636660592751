import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const personnal_evaluation_category_critere_endpoint = "EvaluationCategoryCritere";
const personnal_evaluation_saison_endpoint = "EvaluationSaison";
const personnal_evaluation_critere_endpoint = "EvaluationCritere";
const personnal_evaluation_question_type_endpoint = "EvaluationQuestionType";
const personnal_evaluation_questionnnaire_endpoint = "EvaluationQuestionnaire";
const personnal_evaluation_reponse_endpoint = "EvaluationReponse";
const personnal_evaluation_jalon_endpoint = "EvaluationJalon";
const personnal_evaluation_graph_endpoint = "EvaluationGraph";
const personnal_evaluation_utilities_endpoint = "EvaluationUtilities";
const personnal_evaluation_critere_groupee_endpoint = "EvaluationCritereGroupee";
const personnal_evaluation_critere_groupee_critere_endpoint = "EvaluationCritereGroupeeCritere";

export const evaluation_categorie_critere_service = {
    async get_evaluation_category_critere_list(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_category_critere_endpoint + `/evaluation_category_critere_list`, { params: filtre });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async get_evaluation_category_critere_by_id(id_category_critere) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_category_critere_endpoint + `/evaluation_category_critere_by_id/` + {
                params: {
                    id_category: id_category_critere
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insert_evaluation_category(category_critere) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_category_critere_endpoint + `/insert_category_critere/`, category_critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateEvaluationCategory(category_critere) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_category_critere_endpoint + `/update_category_critere/`, category_critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

}

export const evaluation_saison_service = {
    async getSaisonList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_saison_endpoint + `/evaluation_saison_list/`, {
                params: filtre
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertEvaluationSaison(evaluation_saison) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_saison_endpoint + `/insert_evaluation_saison/`, evaluation_saison);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateEvaluationSaison(evaluation_saison) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_saison_endpoint + `/update_questionnaire/`, evaluation_saison);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export const evaluation_critere_service = {
    async getCriteresList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_endpoint + `/evaluation_critere_list/`, {
                params: filtre
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertCritere(critere) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_endpoint + `/insert_evaluation_critere/`, critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateCritere(critere) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_endpoint + `/update_questionnaire/`, critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteCritere(critere) {
        try {
               const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_endpoint + `/delete_questionnaire/`,critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}
export const evaluation_reponse_jalon_service = {
    async getJalonList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_jalon_endpoint + `/evaluation_jalon_list/`, {
                params: filtre
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getJalonById(id_jalon) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_jalon_endpoint + `/evaluation_jalon_by_id/`,
                id_jalon);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertJalon(jalon) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_jalon_endpoint + `/insert_evaluation_jalon/`, jalon);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateJalon(jalon) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_jalon_endpoint + `/update_evaluation_jalon/`, jalon);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteJalon(jalon) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_jalon_endpoint + `/delete_evaluation_jalon/`, jalon);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}
export const evaluation_utilities_service = {
    async getFonctionList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_utilities_endpoint + `/evaluation_fonctions_list/`, {
                params: filtre
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFonctionById(critere) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_utilities_endpoint + `/evaluation_fonction_by_id/`, critere);
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}
export const evaluation_personnel_service = {
    async getPersonalList(filtre) {
        try {
            const result = await axios.get(`/personal_list`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export const evaluation_commentaire_service = {
    async insertCommentaire(comment) {
        try {
            const result = axios.post(apiUrl + '/' + personnal_evaluation_reponse_endpoint + `/insert_comment/`, comment);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateCommentaire(comment) {
        try {
            const result = axios.post(apiUrl + '/' + personnal_evaluation_reponse_endpoint + `/update_comment/`, comment);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export const evaluation_questionnaire_service = {
    async getQuestionnaireListByuserId(id_user) {
        try {
            const result = await axios.get(apiUrl + "/" + personnal_evaluation_questionnnaire_endpoint + "/evaluation_questionnaire_list_by_employee_id", {
                params: { id_user: id_user }
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getQuestionnaireListByuserIdForResume(id_user) {
        try {
            const result = await axios.get(apiUrl + "/" + personnal_evaluation_questionnnaire_endpoint + "/evaluation_questionnaire_list_by_employee_id_for_resum", {
                params: { id_user: id_user }
            });
            return result.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getQuestionnaireList(filtre) {
        console.log("filtre", filtre);
        
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/evaluation_questionnaire_list`, { params: filtre });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getExcludedByIdsQuestionnaireList(ids_list) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/excluded_by_id_list_evaluation_questionnaire_list`, { params: ids_list });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertAssignedQuestionnaire(questionnaire) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/insert_questionnaire/`, questionnaire);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateAssignedQuestionnaire(questionnaire) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/update_questionnaire/`, questionnaire);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteAssignedQuestionnaire(questionnaire) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/delete_questionnaire/`, questionnaire);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteAssignedQuestionnaireByCritereGroupee(data) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/delete_questionnaire_by_critere_groupee/`, data);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertAssignedQuestionnaireByCritereGroupee(data) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_questionnnaire_endpoint + `/insert_questionnaire_by_critere_groupee/`, data);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export const evaluation_reponse_service = {
    async insertReponse(reponse) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_reponse_endpoint + `/insert_reponse/`, reponse);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateReponse(reponse) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_reponse_endpoint + `/update_reponse/`, reponse);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateReponseToBeFinishAllByCurrentActiveSaison(id_candidat) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_reponse_endpoint + `/update_to_finish_all_reponse/`, { id_candidat: id_candidat });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export const evaluation_reponse_type_service = {
    async getPersonalList() {
        try {
            const result = await axios.get(`/personal_list`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export const evaluation_critere_groupee_service = {
    async getCritereGroupeeList(filtre) {
        console.log("filtre", filtre);

        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/evaluation_critere_groupee_list/`, { params: filtre });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertCritereGroupee(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/insert_critere_groupee/`, critere_groupee);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateCritereGroupee(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/update_critere_groupee/`, critere_groupee);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteCritereGroupee(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/delete_critere_groupee`, critere_groupee);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCritereGroupeeById(critere_id) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/evaluation_critere_groupee_by_id`, critere_id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFicheDeEvaluation(critere_groupee) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_endpoint + `/fiche_evaluation`, {params:critere_groupee});
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export const evaluation_critere_groupee_critere_service = {
    async getCritereGroupeeCritereList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/evaluation_critere_groupee_critere_list`, { params: filtre });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCritereNotInGroupeeCritereList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/critere_not_in_groupee_critere_list/`, { params: { filtre: filtre } });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async insertCritereGroupeeCritere(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/insert_critere_groupee_critere`, critere_groupee);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateCritereGroupeeCritere(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/update_critere_groupee_critere`, critere_groupee);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteCritereGroupeeCritere(critere_groupee) {
        try {
            const result = await axios.post(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/delete_critere_groupee_critere`,  critere_groupee );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCritereGroupeeCritereById(critere_id) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_critere_groupee_critere_endpoint + `/evaluation_critere_groupee_critere_by_id`, critere_id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export const evaluation_question_type_service = {
    async getQuestionTypeList() {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_question_type_endpoint + `/evaluation_question_type_list`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export const evaluation_graph_service = {
    async getDataGraphParCategoryEtSaisonList(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_graph_endpoint + `/graph_per_category_and_saison`, { params: { filtre: filtre } });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getGlobalDataGraphEntreDeuxDate(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_graph_endpoint + `/global_graph_between_date`, { params: { filtre: filtre } });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDataGraphEntreDeuxDate(filtre) {
        try {
            const result = await axios.get(apiUrl + '/' + personnal_evaluation_graph_endpoint + `/evolution_graph_between_date`, { params: { filtre: filtre } });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}