import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import RefusedRequest from "./RefusedRequest";
import ValidateRequest from "./ValidateRequest";
import RefusedRequestService from "../../services/RefusedRequestService";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LeaveService from "../../services/LeaveService";
import AcceptedHelpService from "../../services/AcceptedHelpService";
import { useNavigate } from 'react-router-dom';
import ValidateMission from './ValidateMission'; 


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
  button: {
    color: 'blue',
    textDecoration: 'underline',
    textTransform: 'none',
  },
}));

export default function RequestSummary(props) {
  const navigate = useNavigate();
  const requestSummaryId = props.requestSummaryId;
  const classes = useStyles();
  const open = props.openDialogRequestSummary;
  const handleCloseRequestSummary = props.handleCloseRequestSummary;
  const [step, setStep] = useState(1);
  const [openRefusedRequest, setOpenRefusedRequest] = useState(false);
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [formular, setFormular] = useState([]);
  const [leave, setLeave] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [opens, setOpens] = useState(false);
  const [opentdr, setOpentdr] = useState(false);
  const [typerefus,setTyperefus]=useState('');

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleClickOpentdr = () => {
    setOpentdr(true);
  };

  const handleClosetdr = () => {
    setOpentdr(false);
  };

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    console.log(requestSummaryId.id);
    AcceptedHelpService.getDeatilsId(requestSummaryId.id).then((result) => {
      if (result && result.data) {
        console.log(result.data[0]);
        setLeave(result.data[0]);
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  }, []);
  const handleRefused = (type) => {
    setTyperefus(type);
    RefusedRequestService.getFormular(requestSummaryId.module_type_id, requestSummaryId.type_observation).then((result) => {
      if (result && result.data) {
        setFormular(result.data);
      }
    });
    setStep(2);
    setOpenRefusedRequest(true);
  }

  function handleValidateRequest() {
    // setOpenValidateRequest(true);
    setOpenValidateRequest(true);
  }


  const handleCloseRefusedRequest = (event, reason) => {
   
    
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenRefusedRequest(false);
  }

  const handleCloseValidateRequest = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenValidateRequest(false);
    handleCloseRequestSummary();
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    // setDialogOpen(true);
    setOpenRefusedRequest(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Logique de validation ou autre action après confirmation
    console.log('Mission validée');
  };
  return (
    <>
       <ValidateMission
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
      <RefusedRequest
        type_observation={requestSummaryId.type_observation}
        formular={formular}
        module_type_id={requestSummaryId.module_type_id}
        file_validation_request_id={requestSummaryId.file_validation_request_id}
        validation_id={requestSummaryId.validation_id}
        openRefusedRequest={openRefusedRequest}
        closeparent={handleCloseRequestSummary}
        typerefus={typerefus}
        typevalidation={leave['type_validation']}
        filename={requestSummaryId.filename }
        handleCloseRefusedRequest={handleCloseRefusedRequest} />
      <ValidateRequest
        openValidateRequest={openValidateRequest}
        id={requestSummaryId.id}
        mission_request_id={leave.id}
        filename={requestSummaryId.filename}
        file_request_id={requestSummaryId.file_request_id}
        handleCloseValidateRequest={handleCloseValidateRequest} />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseRequestSummary}
          aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation éléctronique de l'acceptation de demande 
          </DialogTitle>
          <DialogContent>

            <DialogContentText>

              <Grid container spacing={2} >

                <Grid item xs={4}>
                  <label className={classes.label}>Nom et Prénom du demandeur:</label>
                </Grid>
                <Grid item xs={8}>
                  <label className={classes.label1}>{leave['first_name']+' '+leave['last_name']}</label>
                </Grid>

                <Grid item xs={4}>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Intitulé:</label>
                </Grid>
                <Grid item xs={8}>
                  <label className={classes.label1}>{leave['mission_title']}</label>
                </Grid>
                  <Grid item xs={4}>
                    <label className={classes.label}>Date :</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{leave['date']}</label>

                  </Grid>

                  <Grid item xs={4}>
                    <label className={classes.label}>Nombre de jours:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{parseInt(leave['day_number']) + 1}</label>
                  </Grid>
                
                {leave['type_validation']!=="PLANNING"&&
                  <>
                    <Grid item xs={4}>
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          className={classes.button}
                          onClick={handleClickOpentdr}
                        >
                        Consulter le résumé du TDR
                        </Button>
                        <Dialog
                          open={opentdr}
                          onClose={handleClosetdr}
                          fullWidth
                          maxWidth='sm'
                        >
                          <DialogTitle  id="form-dialog-title1">Résumé du TDR</DialogTitle>
                          <DialogContent>
                             <h3>Context et Justification</h3>
                             <p>{leave['context']}</p>
                            {Array.isArray(leave['objectif']) && leave['objectif'].length > 0 && (
                              <>
                                <h3>Objectifs</h3>
                                <ul>
                                  {leave['objectif'].map((ts, index) => (
                                    <li key={index}>{ts.description}</li>
                                  ))}
                                </ul>
                              </>
                            )}

                            {Array.isArray(leave['resultat']) && leave['resultat'].length > 0 && (
                              <>
                                <h3>Résultat attendu</h3>
                                <ul>
                                  {leave['resultat'].map((ts, index) => (
                                    <li key={index}>{ts.description}</li>
                                  ))}
                                </ul>
                              </>
                            )}

                            {Array.isArray(leave['tache']) && leave['tache'].length > 0 && (
                              <>
                                <h3>Tâche</h3>
                                <ul>
                                  {leave['tache'].map((ts, index) => (
                                    <li key={index}>{ts.description_tache}</li>
                                  ))}
                                </ul>
                              </>
                            )}

                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClosetdr} color="primary">
                              Fermer
                            </Button>
                          </DialogActions>
                        </Dialog>
                    </Grid>
                  </>
                }
                
            
     
              {leave['type_validation']!=="PLANNING"&&
                <>
                  <Grid item xs={4}>
                  </Grid>
                  <Grid item xs={8}>
                          La TDR sous forme{" "}
                          <Link to={"/file/" + requestSummaryId.path_folder + "/" + requestSummaryId.filename} target="_blank" > pdf. </Link>
                          <br></br>
           
                  </Grid>
                </>
                
              }
                </Grid>
              
            </DialogContentText>

          </DialogContent>
          <br />
          <DialogActions>
          
            <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleOpenDialog} color="primary" variant="contained" >
              Valider
            </Button>
            <Button style={{ backgroundColor: "#F0F0F2", color: "black" }} onClick={handleCloseRequestSummary} color="primary" variant="contained" >
              Annuler
            </Button>
          </DialogActions>

        </Dialog>}
    </>
  );
}
