import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_critere_groupee_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const CritereGroupeeForm = ({ isOpen, current_critere, handleClose }) => {
  const { showToast, Toast } = useToast();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [critere_groupee_actuel, setCritereGroupeeActuel] = useState({
    ID_CRITERE_GROUPEE: 0,
    NOM_CRITERE_GROUPEE: "",
  });

  const getInsertableCurrentCritereGroupe = () => {
    return {
      ID_CRITERE_GROUPEE: critere_groupee_actuel.ID_CRITERE_GROUPEE,
      NOM_CRITERE_GROUPEE: critere_groupee_actuel.NOM_CRITERE_GROUPEE,
    };
  };

  const handleDialogClose = () => {
    handleClose();
    handleClearAllField();
  };

  const isAllFieldOk = () => {
    let ok = false;
    if (!getInsertableCurrentCritereGroupe().NOM_CRITERE_GROUPEE) {
      ok = false;
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez mentionner le nom du groupe de critère"
      );
    } else {
      if (
        getInsertableCurrentCritereGroupe().NOM_CRITERE_GROUPEE.trim() === ""
      ) {
        ok = false;
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez mentionner le nom du groupe de critère"
        );
      } else {
        ok = true;
      }
    }
    return ok;
  };

  const handleSaveCritere = () => {
    if (isAllFieldOk()) {
      if (getInsertableCurrentCritereGroupe().ID_CRITERE_GROUPEE > 0) {
        handleUpdateCritereGroupee(getInsertableCurrentCritereGroupe());
      } else {
        handleInsertCritereGroupee(getInsertableCurrentCritereGroupe());
      }
    }
  };

  const handleInsertCritereGroupee = () => {
    evaluation_critere_groupee_service
      .insertCritereGroupee(getInsertableCurrentCritereGroupe())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleUpdateCritereGroupee = () => {
    evaluation_critere_groupee_service
      .updateCritereGroupee(getInsertableCurrentCritereGroupe())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
          handleClearAllField();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleClearAllField = () => {
    setCritereGroupeeActuel({ ID_CRITERE_GROUPEE: 0, NOM_CRITERE_GROUPEE: "" });
  };

  const handleChangeCritereGroupeActuel = (evt) => {
    const { name, value } = evt.target;
    if (value) {
      setCritereGroupeeActuel({ ...critere_groupee_actuel, [name]: value });
    }
  };

  useEffect(() => {
    if (current_critere) {
      setCritereGroupeeActuel(current_critere);
    }
  }, [current_critere]);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {critere_groupee_actuel.ID_CRITERE_GROUPEE === 0
                ? "Nouveau groupe de critere"
                : "Modifier groupe de critere"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid flexDirection={"column"} mt={0.5}>
                <Typography className="form-control">Nom du groupe</Typography>
                <TextField
                  size="small"
                  fullWidth
                  label={"Nom du groupe"}
                  name="NOM_CRITERE_GROUPEE"
                  value={critere_groupee_actuel.NOM_CRITERE_GROUPEE}
                  onChange={(e) => handleChangeCritereGroupeActuel(e)}
                ></TextField>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSaveCritere}
                variant="contained"
              >
                Enregistrer{" "}
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Annuler
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default CritereGroupeeForm;
