import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { useParams } from "react-router-dom";
import PersonalSettingService from "../../../services/PersonalSettingService";
import Message from "../../tools/Message";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 800,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function CategorieAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({name: "", type:"uni",annonce:"oui"});
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const personal_config_id = params.id;
  const navigate = useNavigate();

  useEffect(() => {
    if(personal_config_id) {
      CategoryMissionService.getByID(personal_config_id).then(({ data:personalSetting }) => {
        setForm({
          name: personalSetting.name,
          type: personalSetting.type,
          annonce:personalSetting.annonce
        });
      });
    }
  }, []);

  function handleClick(){
     if (personal_config_id) {   
        CategoryMissionService.updatecategory(personal_config_id,form).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_category");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     } else {
        CategoryMissionService.insert(form).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_category");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     }
    
    
   }
    

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  const handleCheckboxChange = (event, value) => {
    setForm({ ...form, annonce: value });
  };

  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom de la catégorie *</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom de la catégorie" variant="outlined" value={form.name} name="name" onChange={handleChange} />
           
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Type de la catégorie *</label>
          </Grid>
          <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">
                           Type de la catégorie
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Type de la catégorie"
                          value={form.type}
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value="uni">Unique</MenuItem>
                          <MenuItem value="groupe">Groupé</MenuItem>
                        </Select>
                      </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Annonce mission *</label>
          </Grid>
          <Grid item xs={8}>
          <Grid item xs={8}>
            <FormControlLabel 
              control={
                <Checkbox 
                  {...label} 
                  checked={form.annonce === 'oui'} 
                  onChange={(e) => handleCheckboxChange(e, 'oui')}
                />
              }
              label="oui"
            />
            <FormControlLabel 
              control={
                <Checkbox 
                  {...label} 
                  checked={form.annonce === 'non'}  
                  onChange={(e) => handleCheckboxChange(e, 'non')}
                />
              }
              label="non"
            />
          </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default CategorieAdd;