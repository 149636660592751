import { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import CritereGroupeeCritereFiltre from './CritereGroupeeCritereFiltre'

const CritereGroupeeCritereList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Liste des catégories de criteres"}
        show_buton_back={true}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<CritereGroupeeCritereFiltre openForm={openForm} handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} />}
      />
    </MainContainer>
  )
}

export default CritereGroupeeCritereList