import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { DeleteOutlineRounded, Subject, CalendarToday, CreateTwoTone, Clear, Add, PostAdd, CalendarViewDayTwoTonew, CalendarTodayRounded, CalendarViewDaySharp, CalendarTodaySharp, CalendarTodayTwoTone } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Send";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DataGrid } from '@material-ui/data-grid';
import PlanningMissionService from "../../../services/PlanningMissionService";
import MissionRequestValidationService from "../../../services/MissionRequestValidationService";
import MissionService from "../../../services/MissionService";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import PlanningMissionSummary from "../planning-mission/PlanningMissionSummary";
import TDRService from "../../../services/TDRService";
import Grid from "@material-ui/core/Grid";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import Spacer from "../../../components/Spacer";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ResultatService from "../../../services/ResultatService";
import ObjectifService from "../../../services/ObjectifService";
import TacheService from "../../../services/TacheService";
import ContextService from "../../../services/ContextService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import DownloadIcon from '@mui/icons-material/Download';


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));



export default function TDRElaborationTable({ notes, row,valid }) {
  const navigate = useNavigate();
  const contextData = useContext(userDetailContext);
  const [rows, setRows] = useState(row);
  const [note, setNote] = useState(notes);

  const [id_to_delete, setId_to_delete] = useState(0);
  const [id_to_update, setId_to_update] = useState(0);
  const [idrefuse,setIdrefuse]=useState(0);  
  const [direction_id, setDirection_id] = useState(0);
  const [service_id, setService_id] = useState(0);
  const [lien, setLien] = useState({
    reference: ""
  });
  const [tache,setTache]=useState([]);
  const [objectif,setObjectif]=useState([]);
  const [context,setContext]=useState('');
  const [resultat,setResultat]=useState([]);
  const [detail,setDetails]=useState([]);
  const [partage,setPartage]=useState([]);
  const [mission_id, setMission_id] = useState(0);
  const [copieaf,setCopieaf]=useState(false);
  const [name,setName]=useState('');
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer ?',
  });

  const { openDialog, messageDialog } = confirmationDialog;
  const [ openobservation, setOpenobservation] = useState(false);
  const [opendetail,setOpendetail]=useState(false);
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const [summary, setSummary] = React.useState({
    openDialogPlanningMissionSummary: false,
    dataPlanningMissionSummary: {
      reference: '',
      mission_title: '',
      location: '',
      user_name: '',
      mission_planning_id: '',
    },
  });
  const [form, setForm] = useState({
    user_id:[],
    tdr_mission_id:""
  });

  const { openDialogPlanningMissionSummary, dataPlanningMissionSummary } = summary;
  const [direction,setDirection]=useState([]);
  const [service,setService]=useState([]);
  const [utilisateur,setUtilisateur]=useState([]);
  const [type,setType]=useState('');
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [suite,setSuite]=useState([]);
  const [affobserv,setAffobserv]=useState(false);

  useEffect(() => {
    // setOpenLoadingSimpleBackdrop(true);
    
    // TDRService.getlistTDR().then((result) => {
    //   if (result && result.data) {
    //     setRows(result.data?.map((row) => ({ ...row, id: row.tdr_mission_id })));
    //     setNote(result.data);
    //     setOpenLoadingSimpleBackdrop(false);
    //   } else {
    //     setOpenLoadingSimpleBackdrop(false);
    //   }
    // });
   
    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });
  }, []);

    useEffect(() => {
      setRows(row);
    }, [row]);

    useEffect(() => {
      setNote(notes);
    }, [notes]);

  function handleChangeDirection(event, value){
    setDirection_id(value.direction_id);
    PlanningMissionService.getService(value.direction_id).then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setName(value);
};

  function handleChangeService(event, value){
    setService_id(value);
    
    PlanningMissionService.getIntervenant(direction_id, value.service_id).then((result) => {
      if (result && result.data) {
        setUtilisateur(result.data);
      }
    });
  }

  const handleCloseobservation = () => {
    setOpenobservation(false);
 };


  const handleedittdr = () => {
    // setOpenobservation(false); 
    // navigate("/mission/tdr/" + idrefuse);
    TDRService.partage(form,type,name).then((result) => {
      if (result ) {
           setOpenobservation(false); 
           setCopieaf(false);
           setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
           window.location.reload();
      } else {
          setOpenobservation(false); 
          setCopieaf(false);
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
      }
    });

  };

  const handleClosecopie=()=>{
    setCopieaf(false);
  }

  const handleCloseobservations = () => {
      setOpenobservation(false); 
  };

  const handleClosedetail = () => {
    setOpendetail(false); 
  };


  function handleChangeIntervenants(event, value){
    setForm({ ...form, user_id: value });
  }

  // // const handledownload = () => (event) => {
  //   const pdfUrl = 'https://apprendre.auf.org/wp-content/uploads/2021/08/livret3_hist_geo_seq1_prepa_leconpdf.pdf'; // Remplacez par votre URL

  //   const handleDownload = async (event) => {
  //     event.stopPropagation();
      
  //     const response = await fetch(pdfUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
      
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'file.pdf'); 
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  // };
  

  const sendRequestValidation = (id,types) => (event) => {
    setMission_id(id);
    event.stopPropagation();
    if (types==='envoie') {
      setCopieaf(true);
    } else {
      setOpenobservation(true);
    }
    setType(types);
    setForm({ ...form, tdr_mission_id: id });
  }

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function closeDialogPlanningMissionSummary() {
    setSummary({ ...summary, openDialogPlanningMissionSummary: false });
  }

  function validateDialogPlanningMissionSummary() {
    MissionRequestValidationService.sendRequestValidatationMission(mission_id, localStorage.getItem('USER')).then((result) => {
      PlanningMissionService.getUserMission().then((result) => {
        if (result && result.data) {
          setRows(result.data?.map((row) => ({ ...row, id: row.mission_planning_id })));
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setSummary({ ...summary, openDialogPlanningMissionSummary: false });
        navigate("/mission/elaboration_tdr");
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    });
  }
  function cancelDialogPlanningMissionSummary() {
    setSummary({ ...summary, openDialogPlanningMissionSummary: false });
  }

  const handleDelete = (id) => (event) => {
    event.stopPropagation();
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  const handleviewresult = (id) => (event) => {
    event.stopPropagation();
    setId_to_delete(id);
  
    PlanningMissionService.getObservationtdr(id).then((result) => {
      if(result && result.data){
         setAffobserv(true);
          setSuite(result.data);
      }else{
        setSuite([]);
      }
    });
  };
  
  const handleviewfeme = () => {
    setAffobserv(false); 
  };

  const handlemofid = () => {  
    PlanningMissionService.newtrd(id_to_delete).then((result) => {
      if(result){
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setAffobserv(false);
        window.location.reload();
      }else{
        setAffobserv(false);
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Veuillez réessayer, une erreur est survenue." });
      }
    });
  };


  const handleUpdateValidate = (id) => (event) => {
    setId_to_update(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  const handleEdit = (id) => (event) => {
    navigate("/mission/tdr/add/" + id);
  };


  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    MissionService.deleteElaborationTDR(id_to_delete).then((result) => {
      // TDRService.getlistTDR().then((result) => {
      //   if (result && result.data) {
      //     setRows(result.data?.map((row) => ({ ...row, id: row.tdr_mission_id })));
      //     setNote(result.data);
      //     setOpenLoadingSimpleBackdrop(false);
      //   } else {
      //     setOpenLoadingSimpleBackdrop(false);
      //   }
      // });

      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setSummary({ ...summary, openDialogPlanningMissionSummary: false });
        window.location.reload();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    });
  }

  const columns = [
    { field: 'reference', headerName: 'Référence', width: 150, },
    { field: 'version', headerName: 'Version', width: 130, },
    { field: 'title', headerName: 'Intitulé', width: 250, },
    { field: 'user_name', headerName: 'Crée par ', width: 250, },
    { field: 'state', headerName: 'Statut', width: 200, },
    { field: 'year', headerName: 'Année de création', width: 200, },
    // { field: 'month', headerName: 'Mois de création', width: 200, }, 
    {
      field: 'actions', headerName: 'Actions', disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            
                 <Button title="Dupliquer un TDR" onClick={sendRequestValidation(params.row.tdr_mission_id,'envoie')}>
                   <ContentCopyIcon style={{ color: "#22427c" }} />
                 </Button>
                 {/* {( params.row.state==="Validé" && 
                  <Button title="Télecharger un TDR" onClick={handleDownload}>
                    <DownloadIcon style={{ color: "black" }} />
                  </Button>
                 )} */}
                {(params.row.state!=="En attente de validation" && params.row.state!=="Refusé" && !valid)  &&
                <Button title="Partager le TDR" onClick={sendRequestValidation(params.row.tdr_mission_id,'partage')}>
                  <ShareIcon style={{ color: "green" }} />
                </Button>
               }
                {(params.row.state!=="En attente de validation" && params.row.state!=="Validé" && params.row.state!=="Refusé" && !valid)  &&
                  <Button onClick={handleEdit(params.row.tdr_mission_id)}>
                    <CreateTwoTone style={{ color: "black" }} />
                  </Button>
                }
                {(params.row.state!=="En attente de validation" && params.row.state!=="Validé" && params.row.state!=="Refusé" && !valid) &&
                  <Button onClick={handleDelete(params.row.tdr_mission_id)}>
                    <DeleteOutlineRounded style={{ color: "red" }} />
                  </Button>
                }
                {params.row.state==="Refusé" &&
                  <Button onClick={handleviewresult(params.row.tdr_mission_id)}>
                     <RemoveRedEyeIcon style={{ color: "#22427c" }} />
                  </Button>
                }   
          </strong>
        );
      },
      width: 300,
    }
  ];

  const handleRowClick = (params) => {  
    const valeur=params.row;
    setDetails(params.row);
    TacheService.getDetails(valeur.id).then((result) => {
      if (result && result.data) {
        setTache(result.data);
      } else {
      }
     })
      .catch((err) => {
    });

    ContextService.getId(valeur.id).then((result) => {
      if (result && result.data) {
        setContext(result.data.description_context);;
      } else {
      }
    })
    .catch((err) => {
    });
    TacheService.getpartage(valeur.id).then((result) => {
      if (result && result.data) {
        setPartage(result.data);
      } else {
      }
     })
      .catch((err) => {
    });
    
    ObjectifService.getById(valeur.id).then((res) => {
      if (res.status) {
        setObjectif(res.data);
      } else {
      }
    }).catch((err) => {
    });
    ResultatService.getById(valeur.id).then((res) => {
      if (res.status) {
        setResultat(res.data);
      } else {
      }
    }).catch((err) => {
    });

    // event.stopPropagation();
    setOpendetail(true);
  };

  return (
    <React.Fragment>
      <PlanningMissionSummary
        openDialogPlanningMissionSummary={openDialogPlanningMissionSummary}
        dataPlanningMissionSummary={dataPlanningMissionSummary}
        lien={lien.reference}
        closeDialogPlanningMissionSummary={closeDialogPlanningMissionSummary}
        validateDialogPlanningMissionSummary={validateDialogPlanningMissionSummary}
        cancelDialogPlanningMissionSummary={cancelDialogPlanningMissionSummary} />
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog}
        closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
  
      
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }}>
        <DataGrid 
          rows={rows} 
          columns={columns} 
          onRowClick={handleRowClick}
        />
      </div>
      <div>
        <Dialog open={openobservation} onClose={handleCloseobservation}>
        <DialogTitle id="form-dialog-title" color="primary">{type==='partage'?'Partager TDR':'Envoyez TDR'}</DialogTitle>
          <DialogContent>
            <DialogContentText>  
            <Grid container justifyContent="center" spacing={2}> 
              
                <Grid container item spacing={2}  xs={20}>
                  <Grid item xs={5}>
                    <label >Direction</label>
                  </Grid>
                  <Grid item xs={7}>
                      <Autocomplete
                      onChange={handleChangeDirection}
                      options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Direction"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <label >Service</label>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      onChange={handleChangeService}
                      options={service} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Service"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <label >Utilisateur</label>
                  </Grid>
                  <Grid item xs={7}>
                        <Autocomplete multiple id="tags-outlined" options={utilisateur} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                          filterSelectedOptions
                          onChange={handleChangeIntervenants}
                           value={form.user_id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label="Intervenants"
                              variant="outlined"
                              placeholder="Intervenants"
                            />
                          )} 
                        />
                  </Grid>

                 
                </Grid>
          
          </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions> 
              <Button style={{ backgroundColor: "#F0F0F0", color: "#000000" }} variant="contained" color="primary" onClick={handleedittdr} >{type==="partage"?"Partager":"Envoyer"}</Button> 
              <Button variant="contained" color="secondary" onClick={handleCloseobservations} >FERMER</Button>
          </DialogActions>
      </Dialog>
    </div>
    <div>
      <Dialog open={opendetail} onClose={handleClosedetail} maxWidth="sm" fullWidth >
        <DialogTitle id="form-dialog-title" color="primary"   >Détails TDR </DialogTitle>
          <DialogContent>
            <DialogContentText>  
               <p>Intitulé : {detail.title}</p>
               <p>Crée par : {detail.user_name}</p>
               <p>Partager avec :
                    {partage.map((ts, index) => (
                      ts.nom !== detail.user_name ? (
                        <React.Fragment key={index}>
                          {ts.nom}
                          <br />
                        </React.Fragment>
                      ) : ''
                    ))}
                  </p>
                <h4>1- Contexte et justification</h4>
                <p>{context}</p>
               <h4>2- Objectifs</h4>
               <ul>
                  {objectif.map((ts, index) => (
                        <li key={index}>{ts.description}</li>
                   ))}
               </ul>
               <h4>3- Résultat attendue</h4>
               <ul>
                  {resultat.map((ts, index) => (
                        <li key={index}>{ts.description}</li>
                   ))}
               </ul>
               <h4>4- Tâche</h4>
               <ul>
                  {tache.map((ts, index) => (
                        <li key={index}>{ts.description_tache}</li>
                   ))}
               </ul>
           </DialogContentText>
          </DialogContent>
          <DialogActions> 
              <Button variant="contained" color="secondary" onClick={handleClosedetail} >FERMER</Button>
          </DialogActions>
      </Dialog>
    </div>

    <div>
        <Dialog open={affobserv} onClose={handleCloseobservation}>
        <DialogTitle id="form-dialog-title" color="primary"  >Observation refus du mission</DialogTitle>
          <DialogContent>
            <DialogContentText>  
            <Grid container justifyContent="center" spacing={2}> 
              
                <Grid container item spacing={2}  xs={8} >
                  <Grid item xs={5}>
                    <label >Context :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{suite.Context}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Objectifs :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{suite.Objectifs}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Resulat attendus</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{suite["Resulat attendus"]}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Tâche :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{suite.Tâche}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label >Agenda :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label >{suite.Agenda}</label>
                  </Grid>
                </Grid>
    
          </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions> 
              <Button style={{ backgroundColor: 'rgb(19, 62, 103)', color: "#FFFFFF" }} variant="contained" color="primary" onClick={handlemofid} >Modifier TDR</Button> 
              <Button variant="contained" color="secondary" onClick={handleviewfeme} >FERMER</Button>
          </DialogActions>
      </Dialog>
      </div>
      <div>
        <Dialog open={copieaf} onClose={handleClosecopie}  maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title" color="primary">Dupliquer TDR</DialogTitle>
            <DialogContent>
              <DialogContentText>  
              <Grid container justifyContent="center" spacing={2}> 
                
                  <Grid container item spacing={2}  xs={20}>
                    <Grid item xs={3}>
                      <label >Intitulé </label>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            size="small"
                            // value={form.title}
                            label=""
                            variant="outlined"
                            name="name"
                            onChange={handleChange}
                        /> 
                    </Grid>
                  </Grid>
            
            </Grid>

              </DialogContentText>
            </DialogContent>
            <DialogActions> 
                <Button style={{ backgroundColor: "#F0F0F0", color: "#000000" }} variant="contained" color="primary" onClick={handleedittdr} >Dupliquer</Button> 
                <Button variant="contained" color="secondary" onClick={handleClosecopie} >FERMER</Button>
            </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
