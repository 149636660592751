import { Grid } from '@mui/material'
import EvaluationForm from './EvaluationForm'

const AutoEvaluationForm = () => {
  return (
    <Grid item display={"flex"} flexDirection={"column"} justifyContent={"center"} >
        <EvaluationForm />
    </Grid>
  )
}

export default AutoEvaluationForm