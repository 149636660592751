import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import PlanningMissionService from "../../../services/PlanningMissionService";
import Message from "../../tools/Message";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { userDetailContext } from "../../../App";
import {useContext} from "react";
import Spacer from "../../../components/Spacer";
import { useNavigate } from 'react-router-dom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from '@mui/material/Typography';
import TextField from "@material-ui/core/TextField";
import TDRService from "../../../services/TDRService";
import CheckIcon from "@material-ui/icons/Send";
import { format } from 'date-fns';
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(2),
  },
}));

export default function PlanningMissionDetail() {
  const navigate = useNavigate();

   const pathname = window.location.pathname;

   const segments = pathname.split('/');
   const missionId = segments[segments.length - 1];
   const [form,setForm]=useState({
    mission_id:missionId,
    tdr_id:"",
    agenda_id:""
   });
  const handleClose = () => {
    navigate('/mission/planning_mission'); 
  };


  function handleCloseMessage(){
    setMessage({ ...message, open: false });
    };

 

  const contextData = useContext(userDetailContext);
  const [rows, setRows] = useState([]);
  const [details,setDetails]=useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const[tdrinit,setTDRinit]=useState({});
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const [tdr,setTDR]=useState([]);
  const [agenda,setAgenda]=useState([]);
  const [affiche,setAffiche]=useState(false);
  const [listedestination,setListedestination]=useState([]);
  const [agendainit,setAgendainit]=useState({});
  const [intervenants,setIntervenant]=useState([]);
  const [intervenantsoption,setIntervenantoption]=useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    TDRService.geTDR().then((result) => {
      if (result && result.data) { 
        setTDR(result.data?.map((row) => ({ ...row, id: row.tdr_mission_id })))
      } else {
      }
    });
  }, []);

  useEffect(() => {
    PlanningMissionService.getdetails(missionId).then((result) => {
      if(result && result.data){
        setDetails(result.data[0]);
        setTDRinit(result.data[0].tdrref)
        setAgendainit(result.data[0].agedref)
        setIntervenant(JSON.parse(result.data[0].intervenant));    
        setIntervenantoption(JSON.parse(result.data[0].intervenant).map((name) => ({ user_id: name, nom: name })));
        if (result.data && result.data.length > 0 && result.data[0].agedref) {
          const missionPlanningAgendaId = result.data[0].agedref.mission_planning_agenda_id;
          if (missionPlanningAgendaId !== undefined) {
              TDRService.getagendadetails(missionPlanningAgendaId).then((result) => {
                  if (result && result.data) {
                      setAffiche(true);
                      setListedestination(result.data);
                  } else {

                  }
              }).catch(error => {
                 
              });
          }
   
          
        }
      }else{
        setDetails({});
      }
    });

   

    TDRService.getagendasimple().then((result) => {
        if (result && result.data) {
          setAgenda(result.data?.map((row) => ({ ...row, id: row.id })))
        } else {
        }
      });
  }, []);

  const handlevalidate = (type) => {
       if (form.tdr_id!=='' || form.agenda_id==='') {
          setMessage({...message, open: true, color: 'red', messageInfo: "Veuillez sélectionner un agenda approprié pour accompagner le TDR."});
          return;
        }
      //  if (form.tdr_id==='' || form.agenda_id!=='') {
      //   setMessage({...message, open: true, color: 'red', messageInfo: "Veuillez sélectionner un TDR approprié pour accompagner l'agenda ."});
      //   return;
      // }
    
    TDRService.postagenda(missionId,form,listedestination,type).then((result) => {
      if (result.status) {
        setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
        setTimeout(function () { navigate('/mission/planning_mission'); }, 4000);
      } else {
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    });
  };


  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  

  
  function handleChangetdr(event, value){
    setForm({ ...form, tdr_id: value.tdr_mission_id });
    setTDRinit(value);
   }
 
  function handleChangeagenda(event, value){
   setForm({ ...form, agenda_id: value.id });
   setAgendainit(value);
   TDRService.getagendadetails(value.id).then((result) => {
    if (result && result.data) {
     setAffiche(true);
     setListedestination(result.data);
    } else {
    }
  });
  }

  function handleChange(event, index) {
    event.preventDefault();
    const { name, value } = event.target;

    let updatedList = [...listedestination];
    if (name === "participautre") {
      updatedList[index] = {
        ...updatedList[index],
        participautre: value
      };
    }
    else  if (name === "participants") {
      updatedList[index] = {
        ...updatedList[index],
        participants: value
      };
    } else if (name === "observation") {
      updatedList[index] = {
        ...updatedList[index],
        observation: value
      };
    }

    setListedestination(updatedList);
  }
  

function handleChangeMissionary(event, value, index) {
  event.preventDefault();
  
  let updatedList = [...listedestination];
  
  updatedList[index] = {
    ...updatedList[index], 
    user_id: value 
  };

  setListedestination(updatedList);
}

function handleChangeParticipant(event, value, index) {
  event.preventDefault();
  
  let updatedList = [...listedestination];
  
  updatedList[index] = {
    ...updatedList[index], 
    particip: value 
  };

  setListedestination(updatedList);
}

 

  return (
    <React.Fragment>
         <MainContainer {...headerDataMission}>
         <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Paper  variant="outlined" style={{ marginTop: '20px', padding: '20px' }}>
                <Grid container spacing={2} justify="center">
                    <Title style={{ textAlign: "center",marginTop:'20px' ,backgroundColor: '#2196F3 !important',borderRadius: '4px'  }}>Informations détaillées sur la planification</Title>
                </Grid>
                <Spacer y={2} />
            
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >Objet de la mission :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{details.mission_title}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >Categorie :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                        {details.categorie && details.categorie.length > 0 ? 
                            details.categorie.map((ts, index) => (
                                <Typography key={index} variant="body1">{ts.name}{index !== details.categorie.length - 1 && ', '}</Typography>
                            )) 
                            : ''
                        }
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >Transport :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{details.transport}</Typography>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >{details.categtype === "groupe" ? "Intervenants :" : "Missionaires :"}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {details.missionnary && details.missionnary.length > 0 ? 
                                details.missionnary.map((ts, index) => (
                                    <Typography key={index} variant="body1">{ts.nom}{index !== details.missionnary.length - 1 && ', '}</Typography>
                                )) 
                                : ''
                            }

                            {intervenants.length > 0 ? (
                                intervenants.map((ts, index) => (
                                  <Typography key={index} variant="body1">
                                    {ts}{index !== intervenants.length - 1 && ', '}
                                  </Typography>
                                ))
                              ) : (
                                ''
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >Autre participant(s) :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">
                            {
                              (() => {
                                try {
                                  const partnershipArray = JSON.parse(details.partnership);
                                  return partnershipArray.join(',');
                                } catch (e) {
                                  return '';
                                }
                              })()
                            }
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >TDR :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                               onChange={handleChangetdr}
                                options={tdr} getOptionLabel={(option) => option.title + ' (' + option.reference + '- '+option.state+ ')'}
                                value={tdrinit}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="TDR"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" align="left" >Agenda :</Typography>
                        </Grid>
                        <Grid item xs={10}>
                        <Autocomplete
                              onChange={handleChangeagenda}
                              options={agenda.filter(option => option.mission_planning_id === null)}
                              getOptionLabel={(option) => option.title + ' (' + option.reference + ')'}
                              value={agendainit}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      label="Agenda"
                                      size="small"
                                      variant="outlined"
                                      InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                              )}
                          />

                        </Grid>
                        {affiche&&
                          <>
                           <Grid item xs={2}>
                              <Typography variant="body1" align="left" >Détail agenda :</Typography>
                           </Grid>
                           <Grid item xs={12}> </Grid>
                          <Grid item xs={12}>
                             <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell  >Date début </TableCell>
                                                <TableCell  >Date Fin </TableCell>
                                                <TableCell  >Heure</TableCell>
                                                <TableCell >Activités</TableCell>
                                                <TableCell>
                                                    {details.categtype === "groupe" ? "Intervenants" : "Participants"}
                                                </TableCell>
                                                {/* <TableCell  > {details.categtype === "groupe" ? "Autres intervenants" : "Autres participants"}</TableCell> */}
                                                {details.categtype==="groupe"&&
                                                  <>
                                                    <TableCell >Participants</TableCell>
                                                    {/* <TableCell >Autres participants</TableCell> */}
                                                  </>
                                                }
                                                <TableCell >Observation</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {listedestination.map((items, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row"  ><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={formatDate(items.date_begin)} /></TableCell>
                                                    <TableCell component="th" scope="row"  ><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={formatDate(items.date_begin)} /></TableCell>
                                                    <TableCell component="th" scope="row"  ><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.heure_begin +" - "+items.heure_end } /></TableCell>
                                                    <TableCell>
                                                      <TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.libelle===null?items.depart+"-"+items.arriver:items.libelle} name="activity" /*onChange={(event) => handleChange(event, index)}*/ />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Autocomplete multiple id="tags-outlined" 
                                                        options={ [...details.missionnary, ...intervenantsoption]} 
                                                        getOptionLabel={(option) => option.nom}
                                                            filterSelectedOptions
                                                            onChange={(event, value) => handleChangeMissionary(event, value, index)}
                                                            value={items.user_id}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                id="outlined-basic"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                            )} 
                                                        />
                                                    </TableCell>
                                                    {/* <TableCell><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.participants} name="participants" onChange={(event) => handleChange(event, index)}/></TableCell> */}
                                                  
                                                    {details.categtype==="groupe"&&
                                                      <>
                                                        <TableCell>
                                                          <Autocomplete multiple id="tags-outlined" 
                                                              options={details.participants} 
                                                              getOptionLabel={(option) => option.nom}
                                                              filterSelectedOptions
                                                              onChange={(event, value) => handleChangeParticipant(event, value, index)}
                                                              value={items.particip}
                                                              renderInput={(params) => (
                                                              <TextField
                                                                  {...params}
                                                                  fullWidth
                                                                  id="outlined-basic"
                                                                  size="small"
                                                                  variant="outlined"
                                                              />
                                                              )} 
                                                          />
                                                       </TableCell>
                                                       
                                                        {/* <TableCell >< TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.autreparticipants} name="participautre" onChange={(event) => handleChange(event, index)}/></TableCell> */}
                                                      </>
                                                    }
                                                    <TableCell><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.observation} name="observation" onChange={(event) => handleChange(event, index)} /></TableCell> 
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                            </Grid>
                          </>
                        }
                   
                          <Grid item xs={6}>
                             
                           </Grid>
                              
                          <Grid item xs={12} justifyContent="left">
                              <Button variant="contained" color="primary" style={{marginRight:'30px',marginBottom:"40px"}} onClick={() => handlevalidate("enregitrer")} autoFocus>
                                Enregistrer
                              </Button>
                              {/* <Button variant="contained" color="primary" style={{marginRight:'30px',marginBottom:"40px"}} onClick={() => handlevalidate("planning")} autoFocus>
                                Envoyez le planning
                              </Button> */}
                              <Button variant="contained" color="primary" style={{marginRight:'30px',marginBottom:"40px"}} onClick={() => handlevalidate("validation")} autoFocus>
                                Envoyer pour validation
                              </Button> 
                              <Button variant="contained" color="secondary" style={{marginBottom:"40px"}} onClick={handleClose} autoFocus>
                                Annuler
                              </Button> 
                          </Grid>
                          </Grid>
                    </Paper>
         </MainContainer>
     
    </React.Fragment>
  );
}
