import React, { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import CritereFiltre from './CritereFiltre'
import CritereTable from './CritereTable'

const CritereList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        show_buton_back={true}
        url_back={"/critere_categorie"}
        title={"Liste des criteres"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<CritereFiltre openForm={openForm} handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} />}
      /* table_component={<CritereTable handleOpenDialog={handleOpenDialog} onCloseForm={handleCloseDialog} openForm={openForm} />} */
      />
    </MainContainer>
  )
}

export default CritereList