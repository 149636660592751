import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainContainer from '../../../components/MainContainer';
import { headerData } from '../../../data/modules/dashboard-header';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AntTab, AntTabs } from '../../../components/ant-tab';
import AppBar from "@material-ui/core/AppBar";
import Spacer from "../../../components/Spacer";
import PlanningList from './PlanningList';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
  paperLeaveInDay:{
    
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    elevation: 0
  },
  tab1:{
    width: "40%"
  }
}));

export default function PlanningEtat() {

  const classes = useStyles()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainContainer {...headerData}>
      <Grid container xs={12}>
        <AppBar position="static" color="default">
          <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            <AntTab disableRipple label="Liste planning mission entre deux dates" className={classes.tab1} />
            {/* <AntTab label="Liste planning mission par destination" className={classes.tab1} /> */}
          </AntTabs>
        </AppBar>
      </Grid>
      <Spacer y={2} />
      {value == 0 && <Grid item>
        <Paper className={classes.paperLeaveInDay} variant="outlined">
           <PlanningList/>
        </Paper>
      </Grid>}
      {value == 1 && <Grid item>
        <Paper className={classes.paper} variant="outlined"> 
        </Paper>
      </Grid>}
      
    </MainContainer>
  );
}
