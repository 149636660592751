import React, { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import QuestionnaireFiltre from './QuestionnaireFiltre'

const QuestionnaireList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }

  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        show_buton_back={true}
        url_back={"/evaluation_saison"}
        title={"Liste des criteres assignés"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<QuestionnaireFiltre handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} openForm={openForm} />}
      /*   table_component={<QuestionnaireTable handleOpenDialog={handleOpenDialog} onCloseForm={handleCloseDialog} openForm={openForm} />} */
      />
    </MainContainer>
  )
}

export default QuestionnaireList