import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { DataGrid } from '@material-ui/data-grid';
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import Title from "../../../components/Title";
import Spacer from "../../../components/Spacer";
import { Button } from "@material-ui/core";
import { DeleteOutlineRounded, CreateTwoTone } from "@material-ui/icons";
import Message from "../../tools/Message";


const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function ObservationAppuiTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const handleEdit = (id) => (ev) => {
    navigate("/mission_reponse/add/"+id);
  };

  const handleDelete = (id) => (ev) => {
  
    CategoryMissionService.deleteresponse(id).then((result) => {
      if (result) {
        setOpenLoadingSimpleBackdrop(true);
        CategoryMissionService.getresponse().then((result) => {
          if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.request_observation_id })));
            setOpenLoadingSimpleBackdrop(false);
          }
          else{
            setOpenLoadingSimpleBackdrop(false);
          }
        });
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    CategoryMissionService.getresponse().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.request_observation_id })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

 
  const columns = [
    { 
      field: 'name',
      headerName: 'Intitulé',
      width: 300,
    },
    { field: 'description',
      headerName: 'Description',
      width: 620
    },
    {
      field: 'ct',
      headerName: 'Actions ',
      width: 250,
      renderCell: (params) => {
        return (
          <div>
                 <Button onClick={handleEdit(params.row.request_observation_id)} title="Modifier" ><CreateTwoTone style={{ color: "black" }} /></Button>
                 <Button onClick={handleDelete(params.row.request_observation_id)} title="Supprimer"><DeleteOutlineRounded style={{ color: "red" }} /></Button>
          </div>
        );
      },
    },
    
    
  ];
  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <React.Fragment>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Liste des suites de demandes d'appui</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
    
  );
}
