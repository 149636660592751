import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AcceptedHelpService = {
  async updateAccepted(id) {
    try {
      const result = await axios.get(`${apiUrl}/MissionRequestUserRequired/updateAccepted/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAll(status) {
    try {
      const result = await axios.post(`${apiUrl}/MissionRequestUserRequired/getAll`,{status:status});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getById(id) {
    try {
      const result = await axios.get(`${apiUrl}/MissionRequestUserRequired/getAll/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(pin_code, id, mission_request_id,
    mission_request_reference, mission_request_user_required_mission_request_id,
    mission_request_user_required_user_id, name, number_day, reason, service_id, type_mission_request,
    user_id, date, description, file_request_id) {
    try {
      const result = await axios.post(`${apiUrl}/MissionRequestUserRequired/codePIN`, {
        pin_code: pin_code,
        id: id,
        mission_request_id: mission_request_id,
        mission_request_reference: mission_request_reference,
        mission_request_user_required_mission_request_id: mission_request_user_required_mission_request_id,
        mission_request_user_required_user_id: mission_request_user_required_user_id,
        name: name,
        number_day: number_day,
        reason: reason,
        service_id: service_id,
        type_mission_request: type_mission_request,
        user_id: user_id,
        date: date,
        description: description,
        file_request_id: file_request_id
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveMissionRequestUserRequired(form, id) {
    try {
      //console.log("ato lay izy a",type,raison,desc,nbrjr,valComposant)
      const result = id ?
        await axios.put(`${apiUrl}/TDR/MissionRequestUserRequired/saveMissionRequired`, form)
        :
        await axios.post(`${apiUrl}/TDR/MissionRequestUserRequired/saveMissionRequired`, form);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveMissionRequestUserRequiredSup(mission_request_id) {
    try {
      //console.log("ato lay izy a",type,raison,desc,nbrjr,valComposant)
      const result = await axios.post(`${apiUrl}/MissionRequestUserRequired/saveMissionRequiredSup`, {
        mission_request_id: mission_request_id,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getSupValidatorNumber() {
    try {
      const result = await axios.get(`${apiUrl}/acceptRequest/getSupValidator`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCountMissionRequiredMR(mission_request_id) {
    try {
      const result = await axios.get(`${apiUrl}/acceptRequest/getCountMissionRequiredMR/${mission_request_id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCountRequiredAttente(mission_request_id) {
    try {
      const result = await axios.get(`${apiUrl}/acceptRequest/getCountRequiredAttente/${mission_request_id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getVerifyValidatorNumber() {
    try {
      const result = await axios.get(`${apiUrl}/acceptRequest/getCountRequiredAttente/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getVerifyRequestType() {
    try {
      const result = await axios.get(`${apiUrl}/acceptRequest/getCountRequiredAttente/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }

}

export default AcceptedHelpService;
