import { RefreshOutlined } from "@material-ui/icons";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PerformanceGraph, { TYPE_GRAPH } from "./type_graph/PerformanceGraph";
import { format } from "date-fns";
import { evaluation_graph_service } from "../../../services/PersonalEvaluationService";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { adaptDataToGraph } from "./type_graph/DataGraph";

const EvolutionGraph = () => {
  const [dataGraph, setDataGraph] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [filtre, setFiltre] = useState({
    dateDebut: format(new Date(), "yyyy-MM-dd"),
    dateFin: format(new Date(), "yyyy-MM-dd"),
    refresh: 0,
    nombre_dernier_saison: 4,
  });

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };

  const handleRefreshPerformance = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_graph_service
      .getDataGraphParCategoryEtSaisonList(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setDataGraph(res.data || []);
      })
      .catch((e) => console.log(e));
  };

  const handleChangeNombreSession = (val) => {
    setFiltre({ ...filtre, nombre_dernier_saison: val });
  };

  useEffect(() => {
    handleRefreshPerformance();
  }, [filtre]);

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <Grid>
          <Typography sx={{ mb: -1 }}>Nombre session</Typography>
          <TextField
            type="number"
            name="nombre_dernier_saison"
            size="small"
            value={filtre.nombre_dernier_saison}
            className="filtre_list"
            onChange={(e) => handleChangeNombreSession(e.target.value)}
          />
        </Grid>
        {/* <Grid>
          <Typography sx={{ mb: -1 }}>Date fin</Typography>
          <TextField
            type="date"
            name="DATE_DEBUT"
            size="small"
            className="filtre_list"
          />
        </Grid> */}
        <Grid display={"flex"} height={"100%"} alignItems={"end"}>
          <Button
            variant="outlined"
            className="filtre_list"
            onClick={handleRefreshData}
          >
            <RefreshOutlined />
          </Button>
        </Grid>
      </Grid>
      <Grid
        flexGrow={1}
        mt={3}
        height={"60vh"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        overflow={"auto"}
      >
        <LoadingSimpleBackdrop
          openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
        />
        <PerformanceGraph
          type_graph={TYPE_GRAPH.bar}
          data={dataGraph}
          //data={adaptDataToGraph(dataGraph, TYPE_GRAPH.ligne,"NOM_SESSION","NOTE_MOYENNE")}
        />
      </Grid>
    </Grid>
  );
};

export default EvolutionGraph;
