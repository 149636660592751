import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import {
  evaluation_categorie_critere_service,
  evaluation_critere_service,
  evaluation_question_type_service,
  evaluation_reponse_jalon_service,
} from "../../../services/PersonalEvaluationService";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import CategoryCritereForm from "../category_criteria/CategoryCritereForm";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import {
  CloseOutlined,
} from "@material-ui/icons";
import useConfirm from "../../../hooks/useConfirm";

const CritereForm = ({ isOpen, current_critere, handleClose }) => {
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [categeoryCritereFormOpen, setCategeoryCritereFormOpen] =
    useState(false);
  const [categoryCriteres, setCategoryCriteres] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [selectedCategoryCritere, setSelectedCategoryCritere] = useState({
    ID_CATEGORY: 0,
    NOM_CATEGORY: "",
  });
  const [critere_actuel, setCritereActuel] = useState({
    ID_CRITERE: 0,
    ID_CATEGORY: 0,
    ID_TYPE_QUESTION: 0,
    NOM_CATEGORY: "",
    NOM_TYPE: "",
    QUESTION: "",
  });
  const [jalonReponses, setJalonReponses] = useState([]);
  const [selectedJalon, setSelectedJalon] = useState({
    ID_JALON: null,
    ID_CRITERE: critere_actuel.ID_CRITERE,
    VALEUR: "",
  });

  const handleRefreshJalonList =(id_critere)=>{
    evaluation_reponse_jalon_service.getJalonList({ID_CRITERE : id_critere})
    .then(res => {
      if(res && res.data){
        setJalonReponses(res.data);
      }
    })
  }

  const handleAddJalonResponse = () => {
    let new_jalons = jalonReponses.slice();
    new_jalons.push(selectedJalon);
    handleInsertJalon(selectedJalon);
    setJalonReponses(new_jalons);
    setSelectedJalon("");
  };

  const confirmDeleteJalon = (jalon)=>{
    evaluation_reponse_jalon_service.deleteJalon(jalon)
    .then(res=>{
      if(res && res.success){
        handleRefreshJalonList(critere_actuel.ID_CRITERE);
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      }else{
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    })

  }

  const handleRemoveJalon = (idx,jalon) => {
 /*    let new_jalons = jalonReponses.slice();
    new_jalons.splice(idx, 1);
    setJalonReponses(new_jalons); */
    openConfirmDialog("Confirmation","Voullez vous vraiment supprimer cette reponse?",()=>confirmDeleteJalon(jalon))
  };

  const getInsertableCurrentCritere = () => {
    return {
      ID_CRITERE: critere_actuel.ID_CRITERE,
      ID_CATEGORY: critere_actuel.ID_CATEGORY,
      ID_TYPE_QUESTION: critere_actuel.ID_TYPE_QUESTION,
      QUESTION: critere_actuel.QUESTION,
      NOTE_MIN: critere_actuel.NOTE_MIN,
      NOTE_MAX: critere_actuel.NOTE_MAX,
    };
  };

  const isAllFieldOk = () => {
    let ok = false;
    if (!getInsertableCurrentCritere().ID_CATEGORY) {
      ok = false;
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez selectionner la catégorie du critère"
      );
    } else if (
      !getInsertableCurrentCritere().QUESTION ||
      getInsertableCurrentCritere().QUESTION.trim() === ""
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez inserer le critere correspondant à la catégorie"
      );
      ok = false;
    } else if (!getInsertableCurrentCritere().ID_TYPE_QUESTION) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez selectionner le type de la reponse souhaité"
      );
      ok = false;
    } else if (getInsertableCurrentCritere().ID_TYPE_QUESTION) {
      if (
        critere_actuel.NOM_TYPE.includes("note") &&
        (!getInsertableCurrentCritere().NOTE_MIN ||
          !getInsertableCurrentCritere().NOTE_MAX)
      ) {
        ok = false;
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez verifier que vous avez ajouté le note min et note max"
        );
      } else {
        ok = true;
      }
    } else {
      ok = true;
    }
    return ok;
  };

  const reinitialiseCurrentCritere = () => {
    setCritereActuel({
      ID_CRITERE: 0,
      ID_CATEGORY: 0,
      ID_TYPE_QUESTION: 0,
      NOM_CATEGORY: "",
      NOM_TYPE: "",
      QUESTION: "",
    });
    setJalonReponses([]);
  };

  const handleChangeCategoryCritere = (new_value) => {
    setCritereActuel({
      ...critere_actuel,
      ID_CATEGORY: new_value.ID_CATEGORY,
      NOM_CATEGORY: new_value.NOM_CATEGORY,
    });
    setSelectedCategoryCritere({
      ID_CATEGORY: new_value.ID_CATEGORY,
      NOM_CATEGORY: new_value.NOM_CATEGORY,
    });
  };
  const handleChangeTypeQuestion = (new_value) => {
    setCritereActuel({
      ...critere_actuel,
      ID_TYPE_QUESTION: new_value.ID_TYPE_QUESTION,
      NOM_TYPE: new_value.NOM_TYPE,
    });
  };

  const handleChangeQuestion = (evt) => {
    const { name, value } = evt.target;
    setCritereActuel({ ...critere_actuel, [name]: value });
  };

  const handleDialogClose = () => {
    handleClose();
    reinitialiseCurrentCritere();
  };

  const handleSaveCritere = () => {
    if (isAllFieldOk()) {
      if (getInsertableCurrentCritere().ID_CRITERE > 0) {
        handleUpdateCritere();
      } else {
        handleInsertCritere();
      }
    }
  };

  const handleInsertCritere = (jalon) => {
    evaluation_critere_service
      .insertCritere(getInsertableCurrentCritere())
      .then((res) => {
        if (res && res.success) {
          if(jalon){
            jalon.ID_CRITERE =  res.data.ID_CRITERE 
            handleInsertJalon(jalon);
            handleRefreshJalonList(critere_actuel.ID_CRITERE);
          }else{
            handleDialogClose();
          }
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleUpdateCritere = () => {
    evaluation_critere_service
      .updateCritere(getInsertableCurrentCritere())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleInsertJalon = (jalon) => {
    evaluation_reponse_jalon_service.insertJalon(jalon).then((res) => {
      if (res && res.success) {
        handleRefreshJalonList(critere_actuel.ID_CRITERE);
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handlDeletetJalon = (id_jalon) => {
    evaluation_reponse_jalon_service.deleteJalon(id_jalon).then((res) => {
      if (res && res.success) {
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleChangeJalon = (evt) => {
    const { name, value } = evt.target;
    setSelectedJalon({
      ...selectedJalon,
      ID_CRITERE: critere_actuel.ID_CRITERE,
      VALEUR: value,
    });
  };
  const handleDeleteJalon = (jalon) => {
    evaluation_reponse_jalon_service.deleteJalon(selectedJalon).then((res) => {
      if (res && res.success) {
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleSaveJalon = () => {
    if (!critere_actuel.ID_CRITERE) {
      openConfirmDialog(
        "Confirmation",
        "Vous devez enregistrer cette critere d'abord, Voullez vous proceder à l'enregistrement?",
        () => {
          handleInsertCritere(selectedJalon);
        }
      );
    } else {
      handleAddJalonResponse();
    }
  };

  const handleRefreshCritereCategoryList = () => {
    evaluation_categorie_critere_service
      .get_evaluation_category_critere_list({ mot_cle: "" })
      .then((res) => {
        if (res && res.data) {
          setCategoryCriteres(res.data);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  const handleRealoadCompsOfTheForm = () => {
    handleRefreshCritereCategoryList();

    evaluation_question_type_service
      .getQuestionTypeList(null)
      .then((res) => {
        if (res && res.data) {
          setQuestionTypes(res.data);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  const handleChangeReponseJalon = (evt) => {
    const { value } = evt.target;
    setSelectedJalon({ ...selectedJalon, VALEUR: value, ID_CRITERE : critere_actuel.ID_CRITERE });
  };
  const handleClearAllField = () => {
    setSelectedCategoryCritere({ ID_CATEGORY: 0, NOM_CATEGORY: "" });
  };

  const handleClickNewCategory = () => {
    handleClearAllField();
    setCategeoryCritereFormOpen(true);
  };
  const handleClickEditCategory = () => {
    setSelectedCategoryCritere({
      ID_CATEGORY: critere_actuel.ID_CATEGORY,
      NOM_CATEGORY: critere_actuel.NOM_CATEGORY,
    });
    setCategeoryCritereFormOpen(true);
  };

  const handleCloseFormDIalog = () => {
    handleRefreshCritereCategoryList();
    handleClearAllField();
    setCategeoryCritereFormOpen(false);
  };

  useEffect(() => {
    if (current_critere) {
      setCritereActuel(current_critere);
      handleRefreshJalonList(current_critere.ID_CRITERE);
    }
  }, [current_critere]);

  useEffect(() => {
    handleRealoadCompsOfTheForm();
  }, []);

  return (
    <>
      <Toast />
      <DialogComponent />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <CategoryCritereForm
            isOpen={categeoryCritereFormOpen}
            handleClose={handleCloseFormDIalog}
            current_category_critere={selectedCategoryCritere}
          />
          <Grid
            container
            height={"90vh"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {critere_actuel.ID_CRITERE === 0
                ? "Nouveau critère"
                : "Modifier critère"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid flexDirection={"column"} mt={0.5}>
                <Typography className="form-control">
                  Catégorie critere *
                </Typography>
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                >
                  <Grid flexGrow={1}>
                    <AutocompleteCombobox
                      limitTags={2}
                      fullWidth
                      label="Catégorie critere *"
                      data={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "NOM_CATEGORY",
                        "ID_CATEGORY"
                      )}
                      valeur={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "NOM_CATEGORY",
                        "ID_CATEGORY"
                      ).find(
                        (category_critere) =>
                          category_critere.ID_CATEGORY ===
                          critere_actuel.ID_CATEGORY
                      )}
                      onChange={handleChangeCategoryCritere}
                    />
                  </Grid>
                  <Grid alignContent={"center"}>
                    <Button sx={{ ml: 0.5 }} onClick={handleClickNewCategory}>
                      <AddOutlined />
                    </Button>
                    <Button
                      sx={{ ml: 0.5 }}
                      disabled={!critere_actuel.ID_CATEGORY}
                      onClick={handleClickEditCategory}
                    >
                      <EditOutlined />
                    </Button>
                  </Grid>
                </Grid>
                <Typography className="form-control">Question *</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  name="QUESTION"
                  label="Question *"
                  onChange={(e) => handleChangeQuestion(e)}
                  value={critere_actuel.QUESTION}
                />
                <Typography className="form-control">
                  Type de la reponse *
                </Typography>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Type de la reponse *"
                  valeur={adaptDataValueToAutocompleteData(
                    questionTypes,
                    "NOM_TYPE",
                    "ID_TYPE_QUESTION"
                  ).find(
                    (question_type) =>
                      question_type.ID_TYPE_QUESTION ===
                      critere_actuel.ID_TYPE_QUESTION
                  )}
                  data={adaptDataValueToAutocompleteData(
                    questionTypes,
                    "NOM_TYPE",
                    "ID_TYPE_QUESTION"
                  )}
                  onChange={handleChangeTypeQuestion}
                />
                <Grid
                  display={
                    critere_actuel.NOM_TYPE.includes("note") ? "flex" : "none"
                  }
                  flexDirection={"row"}
                >
                  <Grid item xs={6}>
                    <Typography className="form-control">Note Min *</Typography>
                    <TextField
                      label="Note Min"
                      size="small"
                      value={critere_actuel.NOTE_MIN || -1}
                      name="NOTE_MIN"
                      onChange={handleChangeQuestion}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="form-control">Note Max *</Typography>
                    <TextField
                      label="Note Max"
                      size="small"
                      value={critere_actuel.NOTE_MAX || 1}
                      name="NOTE_MAX"
                      onChange={handleChangeQuestion}
                    />
                  </Grid>
                </Grid>
                <Grid
                  display={
                    critere_actuel.NOM_TYPE.includes("jalon") ? "flex" : "none"
                  }
                  flexDirection={"row"}
                >
                  <Grid item xs={12}>
                    <Typography className="form-control">
                      Entrer une reponse au choix
                    </Typography>
                    <Grid display={"flex"} flexDirection={"row"}>
                      <TextField
                        label="Reponse"
                        size="small"
                        value={selectedJalon.VALEUR || ""}
                        fullWidth
                        name="reponse_jalon"
                        onChange={handleChangeReponseJalon}
                        // onChange={(e) => console.log("Valeur saisie :", e.target.value)}
                      />
                      <Tooltip title="Cliquer pour ajouter la reponse">
                        <Button
                          disabled={
                            !selectedJalon.VALEUR ||
                            selectedJalon.VALEUR.trim() === ""
                          }
                          onClick={handleSaveJalon}
                        >
                          <AddOutlined />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="jalon_list"
                      item
                      mt={1}
                      xs={12}
                      maxHeight={"17vh"}
                      overflow={"auto"}
                    >
                      {jalonReponses.map((item, idx) => (
                        <>
                          <Paper key={idx}>
                            <Grid
                              key={idx}
                              m={1}
                              p={0.5}
                              display={"flex"}
                              flexDirection={"row"}
                            >
                              <Grid
                                flexGrow={1}
                                display={"flex"}
                                alignContent={"center"}
                              >
                                {item.VALEUR}
                              </Grid>
                              <Grid display={"flex"} alignContent={"center"}>
                                <Button
                                  size="small"
                                  onClick={() => handleRemoveJalon(idx,item)}
                                >
                                  <CloseOutlined />
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSaveCritere}
                variant="contained"
              >
                Enregistrer{" "}
              </LoadingButton>
              {/* <LoadingButton
              loading={submitLoad}
              size='small'
              className='form_control' type='submit'
              onClick={handleSaveCritereAndClose}
              variant='contained'>Enregistrer & suivant </LoadingButton> */}
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Annuler
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default CritereForm;
