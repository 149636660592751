import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const StatistiqueService = {
    async getList() {
        try {
            const result = await axios.get(`${apiUrl}/statistique/getlist`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getpourcent() {
        try {
            const result = await axios.get(`${apiUrl}/statistique/listpourcent`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getdetails() {
        try {
            const result = await axios.get(`${apiUrl}/statistique/getdetail`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async getdistrict(region) {
        try {
            const result = await axios.get(`${apiUrl}/statistique/getdistrict/`+region);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    
    async getdistrictlist(region) {
        try {
            const result = await axios.get(`${apiUrl}/statistique/getdistrictlist/`+region);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getcommunlist(district) {
        try {
            const result = await axios.post(`${apiUrl}/statistique/getcommunelist`,{district});
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

}

export default StatistiqueService;