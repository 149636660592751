import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TacheService = {
    async add(form,idtdr) {   
        try {
            if (form.tache_id!=="") {
                const result = await axios.post(`${apiUrl}/tache/updatetache/`+form.tache_id,
                    {
                        "tdr_mission": idtdr,
                        "description_tache": form.description_tache
                    });
                    if (result) {
                        return result.data;
                    }
            }
            else{
                const result = await axios.post(`${apiUrl}/tache/saveTache/`,
                    {
                        "tdr_mission": idtdr,
                        "description_tache": form.description_tache,
                    });
                    if (result) {
                        return result.data;
                    }
            }
            
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDetails(id) {
        try {
            const result = await axios.get(`${apiUrl}/tache/getDetailsById/`+id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    
    async deletetache(id) {
        try {
            const result = await axios.get(`${apiUrl}/tache/deletetache/`+id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getid(id) {
        try {
            const result = await axios.get(`${apiUrl}/tache/getId/`+id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getpartage(id) {
        try {
            const result = await axios.get(`${apiUrl}/tache/getPartage/`+id);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}
export default TacheService;
