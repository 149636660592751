import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import {
  evaluation_critere_groupee_critere_service,
  evaluation_questionnaire_service,
} from "../../../services/PersonalEvaluationService";
import { AddOutlined, DeleteOutline } from "@material-ui/icons";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const AssignerParGroupeDeCritere = ({
  formOpen,
  onFormClose,
  onFormOpen,
  saison,
  fonction,
}) => {
  const [isOpen, setOpen] = useState(formOpen);
  const [critereGroupee, setCritereGroupee] = useState([]);
  const [selectedCritereGroupee, setSelectedCCritereGroupee] = useState(null);
  const { Toast, showToast } = useToast();
  const [current_saison, setCurrentSaison] = useState(
    saison || {
      NOM_SESSION: "",
      ID_SAISON: 0,
    }
  );
  const [current_fonction, setCurrentFonction] = useState(
    fonction || {
      function_id: 0,
      name: "",
    }
  );
  const handleDialogClose = () => {
    onFormClose();
    setOpen(false);
  };

  const handleCaractereGroupee = (new_val) => {
    setSelectedCCritereGroupee(new_val);
  };

  const handleLoadCritereGroupee = () => {
    evaluation_critere_groupee_critere_service
      .getCritereGroupeeCritereList({
        groupe_critere: null,
        mot_cle: "",
        refresh: 0,
      })
      .then((res) => {
        setCritereGroupee(res.data || []);
      });
  };

  const handleSaveQuestionnaireByCritereGroupee = () => {
    evaluation_questionnaire_service
      .insertAssignedQuestionnaireByCritereGroupee({
        ID_SAISON: current_saison.ID_SAISON,
        function_id: current_fonction.function_id,
        ID_CRITERE_GROUPEE: selectedCritereGroupee.ID_CRITERE_GROUPEE,
      })
      .then((res) => {
        if(res && res.success){
          handleDialogClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        }else{
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleRemoveQuestionnaireByCritereGroupee = () => {
    evaluation_questionnaire_service
      .deleteAssignedQuestionnaireByCritereGroupee({
        ID_SAISON: current_saison.ID_SAISON,
        function_id: current_fonction.function_id,
        ID_CRITERE_GROUPEE: selectedCritereGroupee.ID_CRITERE_GROUPEE,
      })
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleDialogClose();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => {
        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  useEffect(() => {
    handleLoadCritereGroupee();
    if (formOpen) setOpen(formOpen);
    if (saison) setCurrentSaison(saison);
    if (fonction) setCurrentFonction(fonction);
  }, [formOpen, fonction, saison]);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Paper>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            height={"65vh"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title"  className="entete-dialog">
              {"Assigner questionnaires groupée"}
            </DialogTitle>
            <DialogContent>
              <Grid spacing={2} display={"flex"} flexDirection={"column"}>
                <Typography>Saison selectionnée</Typography>
                <TextField
                  label="Saison"
                  size="small"
                  value={current_saison.NOM_SESSION || ""}
                  aria-readonly
                ></TextField>
                <Typography>Fonction selectionnée</Typography>
                <TextField
                  label="fonction"
                  size="small"
                  value={current_fonction.name || ""}
                  aria-readonly
                ></TextField>
                <Typography>
                  Selectionnez une catégorie de critere groupé
                </Typography>
                <Grid display={"flex"} flexDirection={"column"}>
                  <AutocompleteCombobox
                    limitTags={2}
                    label="Categorie"
                    fullWidth
                    class_name="filtre_list"
                    data={adaptDataValueToAutocompleteData(
                      critereGroupee,
                      "NOM_CRITERE_GROUPEE",
                      "ID_CRITERE_GROUPEE"
                    )}
                    onChange={handleCaractereGroupee}
                  />
                  <Grid display={"flex"} justifyContent={"center"} spacing={2}>
                    <Tooltip title="Cliquer pour assigner critere groupés">
                      <Button
                        disabled={
                          !(fonction && saison && selectedCritereGroupee)
                        }
                        color="primary"
                        onClick={handleSaveQuestionnaireByCritereGroupee}
                        startIcon={<AddOutlined />}
                        variant="outlined"
                      >
                        Assigner
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title="Cliquer pour de-assigner critere groupés"
                      sx={{ ml: 1 }}
                    >
                      <Button
                        disabled={
                          !(fonction && saison && selectedCritereGroupee)
                        }
                        startIcon={<DeleteOutline />}
                        variant="contained"
                        color="warning"
                        onClick={handleRemoveQuestionnaireByCritereGroupee}
                      >
                        Enlever
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions sx={{ mr: 2 }}>
              <Button
                className="form_control"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default AssignerParGroupeDeCritere;
