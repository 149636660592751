import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Moment from "moment";
import { extendMoment } from "moment-range";
import ValidationPlanningService from "../../services/ValidationPlanningService";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spacer from "../../components/Spacer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Message from "../tools/Message";
import Alert from "@material-ui/lab/Alert";

import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

//import Switch from '@mui/material/Switch';
//const label = { inputProps: { 'aria-label': 'Switch demo' } };
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    maxWidth: 500,
    elevation: 0,
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function ValidationSetting(props) {
  const navigate = useNavigate();
  const moment = extendMoment(Moment);
  const params = useParams();
  const idValidationPlanning = params.id
    ? params.id
    : "";
  const [form, setForm] = useState({
    date_start: " ",
    date_end: " ",
    libelle_planning_interval_parameter: " ",
    date_start_notification: " ",
    is_active: " ",
    next_parameter_id: " ",
    periodicity: " ",
    module_type_id:[],
  });
  const [failedLogin, setFailedLogin] = useState(false);
  const [soldePrevisional, setSoldePrevisional] = useState(0);
  const [askedDayOff, setAskedDayOff] = useState(0);
  const [errorDate, setErrorDate] = useState("");

  const [parameterIntervalList, setParameterIntervalList] = useState([]);
  // date_start:" ",
  //   date_end: " ",
  // const [beginDate, setBeginDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [date_start, setDate_start] = useState(null);
  const [date_end, setDate_end] = useState(null);
  const [description, setDescription] = useState(null);
  const [delta, setDelta] = useState();
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [
    libelle_planning_interval_parameter,
    setLibelle_planning_interval_parameter,
  ] = useState(null);
  const [date_start_notification, setDate_start_notification] = useState(null);
  const [periodicity, setPeriodicity] = useState(null);
  const [is_active, setIs_active] = useState(null);
  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  const [radioBeginDate, setRadioBeginDate] = useState(null);
  const [radioEndDate, setRadioEndDate] = useState(null);
  const [motif, setMotif] = useState("");
  const [balanceAfter, setBalanceAfter] = useState();
  const [isValidDateEnd, setIsValidDateEnd] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const id_planning_interval_parameter = params.id
    ? params.id
    : "";
  const [inputStyle, setInputStyle] = useState({
    border: "1px solid black",
  });
  const [inputStyle1, setInputStyle1] = useState({
    border: "1px solid black",
  });


  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  // function handleBeginDateChange(e) {
  //   // console.log("begin_date****", e)
  //   // const { name, value } = e.target;
  //   // setForm({ ...form, [name]: value});
  //   setForm({ ...form, [date_start]: e });
  //   setDate_start(e);
  // }

  const handleBeginDateChange = (newValue) => {
    console.log("begin_date****", newValue);
    setDate_start(newValue);
    setForm({ ...form, date_start: newValue });
  };

  function handleEndDateChange(e) {
    const datefin = e;
    if (date_start > datefin) {
      setIsValidDateEnd(false);
    } else {
      setIsValidDateEnd(true);
      setForm({ ...form, date_end: datefin });
      setDate_end(datefin);
    }
  }
  function handleLibelle(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });

    // setEndDate
    setLibelle_planning_interval_parameter(e.target.value);
  }
  function handleDateNotification(e) {
    // const { name, value } = e.target;
    setForm({ ...form, date_start_notification: e });
    // setEndDate
    setDate_start_notification(e);
  }
  function handleProchaineConfiguration(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    // setEndDate
    setDate_start_notification(value);
  }

  function handlePeriodicity(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setPeriodicity(e.target.value);

  }
  const handleActif = (id) => (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setIs_active(e.target.value);
    ValidationPlanningService.saveValidationPlanningIs_active(
      is_active,
      id,
    )
  }

  function handleChangeNextParameter(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }

  function handleRadioBtnIsActiveChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleSaveParameterInterval() {
    ValidationPlanningService.saveValidationPlanning(
      form.date_start,
      form.date_end,
      form.libelle_planning_interval_parameter,
      form.date_start_notification,
      form.is_active,
      form.next_parameter_id,
      id_planning_interval_parameter,
      form.periodicity,
      form.module_type_id,
    ).then((res) => {
      if (res.status) {
        setMessage({
          ...message,
          open: true,
          color: "green",
          messageInfo: res.message,
        });
        navigate("/validation_planning");
      } else {
        setFailedLogin(true);
        setErrorMessage("responseErrorMessage");
        setAlertSeverity("error");
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: res.message,
        });
      }
    })
      .catch((err) => {
        setFailedLogin(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "Une erreur est survenue",
        });
      });
  }

  const [validationPlanning, setValidationPlanning] = useState([]);
  useEffect(() => {
    ValidationPlanningService.getValidationPlanning().then((result) => {
      if (result && result.data) {
        setValidationPlanning(
          result.data.map((d) => {
            return {
              id: d.id_planning_interval_parameter,
              date_start: d.date_start,
              date_end: d.date_end,
              libelle_planning_interval_parameter:
                d.libelle_planning_interval_parameter,
              data_start_notification: d.data_start_notification,
              is_active: d.is_active,
              next_parameter_id: d.next_parameter_id,
              periodicity: d.periodicity,
              module_type_id: d.module_type_id,
            };
          })
        );
      }
    });
    if (idValidationPlanning) {
      ValidationPlanningService.ValidationPlanningById(
        idValidationPlanning
      ).then((info) => {
        //date display on modification
        const resultsbeginDate = info.data.date_start;
        const BeginDate = resultsbeginDate.split(' ');
        const resultsEndDate = info.data.date_end;
        const endDate = resultsEndDate.split(' ');
        const resultsNotifDate = info.data.date_start_notification;
        const notifDate = resultsNotifDate.split(' ');
        setForm({
          date_start: BeginDate[0],
          date_end: endDate[0],
          libelle_planning_interval_parameter:
            info.data.libelle_planning_interval_parameter,
          data_start_notification: notifDate[0],
          is_active: info.data.is_active,
          next_parameter_id: info.data.next_parameter_id,

          id: idValidationPlanning,
          periodicity: info.data.periodicity,
        });
      });

      ValidationPlanningService.getAllPlanningIntervalExcepId(idValidationPlanning).then((res) => {
        if (res) {
          setParameterIntervalList(res.data);
        }
      });
    }
  }, []);
  function handleCancel() {
    navigate("/validation_planning");
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveParameterInterval}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      {/* <Title>Validation Planning</Title> */}
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.label}>Date de début{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_start}
                onChange={handleBeginDateChange}
                renderInput={(params) =>
                  <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} />
                }
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Date fin{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_end}
                onChange={handleEndDateChange}
                renderInput={(params) =>
                  <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} />
                }
              />
            </LocalizationProvider>
            {!isValidDateEnd && <><Spacer y={2} /> <Alert severity="error">La date de début doit être inférieur à la date fin</Alert></>}
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Description:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.libelle_planning_interval_parameter}
              onChange={handleLibelle}
              name="libelle_planning_interval_parameter"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Date d'alerte{bullRed}:</label>
          </Grid>

          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_start_notification}
                onChange={handleDateNotification}
                renderInput={(params) =>
                  <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} />
                }
              />
            </LocalizationProvider>
          </Grid>
          {idValidationPlanning !== "" &&
            <>
              {parameterIntervalList.length > 0 &&
                <>
                  <Grid item xs={4}>
                    <label className={classes.label}>Prochaine Configuration{bullRed}:</label>
                  </Grid>

                  <Grid item xs={8}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Choisir
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Module"
                        value={form.next_parameter_id}
                        name="next_parameter_id"
                        onChange={handleChangeNextParameter}
                      >
                        {parameterIntervalList.map((row) => (
                          <MenuItem value={row.id_planning_interval_parameter}>{row.libelle_planning_interval_parameter}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              }

              <Grid item xs={4}>
                <label className={classes.label}>Activer:</label>
              </Grid>

              <Grid item xs={8}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    defaultValue="0"
                    name="is_active"
                    row
                    value={form.is_active}
                    onChange={handleRadioBtnIsActiveChange}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          }

          <Grid item xs={4}>
            <label className={classes.label}>Nombre du jour pour le rappel{bullRed}:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nombre du jour pour le rappel"
              variant="outlined"
              value={form.periodicity}
              onChange={handlePeriodicity}
              name="periodicity"
            />
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer >
  );
}
export default ValidationSetting;
