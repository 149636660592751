import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const CategoryMissionService = {
  async getAll() {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getresponse() {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/getlistresponse`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async getByID(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/getCategoryByID/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updatecategory(id,form) {
    try {
      const result = await axios.put(`${apiUrl}/CategoryMission/`+id,{
        name:form.name,
        type:form.type,
        annonce:form.annonce
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async deletecategory(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/deletecategory/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(form) {
    try {
      const result = await axios.post(`${apiUrl}/CategoryMission`,
        {
          name:form.name,
          type:form.type,
          annonce:form.annonce
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteresponse(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/deleteresponse/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insertresponse(form) {
    try {
      const result = await axios.post(`${apiUrl}/CategoryMission/insertresponse`,
        {
          name:form.name,
          desc:form.desc
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getreponseByID(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/getresponseByID/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async updateresponse(id,form) {
    try {
      const result = await axios.put(`${apiUrl}/CategoryMission/updateresponse/`+id,{
        name:form.name,
        desc:form.desc
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async insertnbrvalidation(form) {
    try {
      const result = await axios.post(`${apiUrl}/CategoryMission/validation`,
        {
          dir:form.dir,
          interne:form.interne,
          externe:form.externe
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updatevalidation(form,id) {
    try {
      const result = await axios.put(`${apiUrl}/CategoryMission/validation/`+id,
        {
          dir:form.dir,
          interne:form.interne,
          externe:form.externe
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getlistvalidateur() {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/getlistvalidateur`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async desactivelist(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/desactivelist/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async getlistByID(id) {
    try {
      const result = await axios.get(`${apiUrl}/CategoryMission/getlistByID/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async insertcopie(type,dircopie,servcopie,dirmis,servmis,user) {
    try {
      const result = await axios.post(`${apiUrl}/ValidationMission/insertcopiemail`,
        {
          type:type,
          dircopie:dircopie,
          servcopie:servcopie,
          dirmis:dirmis,
          servmis:servmis,
          user:user
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async listcopie() {
    try {
      const result = await axios.get(`${apiUrl}/ValidationMission/getlistmail`,);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  

}

export default CategoryMissionService;
