import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_critere_groupee_service } from "../../../services/PersonalEvaluationService";
import {
  DeleteOutline,
  EditOutlined,
  ListAltSharp,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";
import { useNavigate } from "react-router";
import CritereGroupeeForm from "./CritereGroupeeForm";
import useConfirm from "../../../hooks/useConfirm";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import FicheDevaluation from "./FicheDevaluation";

const CritereGroupeeTable = ({
  openForm,
  onCloseForm,
  excelExport,
  handleOpenDialog,
  filtre,
}) => {
  const [criteres, setCriteres] = useState([]);
  const navigate = useNavigate();
  const [current_critere, setCurrentCritere] = useState(null);
  const [form_open, setFormOpen] = useState(openForm);
  const [fiche_evaluation_form_open, setFicheEvaluationFormOpen] =
    useState(false);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const { Toast, showToast } = useToast();

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleRefreshDataTable(filtre);
    setFormOpen(false);
  };

  const handleEditSelectedCritereGroupee = (critereGroupee) => {
    setCurrentCritere(critereGroupee);
    setFormOpen(true);
  };

  const handleConfirmSuppresion = (critere) => {
    evaluation_critere_groupee_service
      .deleteCritereGroupee(critere)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleRefreshDataTable(filtre);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleDeleteSelectedCritereGroupee = (evt, critereGroupee) => {
    evt.preventDefault();
    openConfirmDialog(
      "Confirmation",
      "Voulez vous supprimer cette ligne?",
      () => handleConfirmSuppresion(critereGroupee)
    );
  };

  const handleShowSelectedCritereGroupee = (critereGroupee) => {
    navigate(
      `/critere_groupee_detail?id_groupe=${critereGroupee.ID_CRITERE_GROUPEE}`
    );
  };
  const handleShowSelectedCritereGroupeeByPosition = (critereGroupee) => {
    setCurrentCritere(critereGroupee);
    setFicheEvaluationFormOpen(true);
  };
  
  const handleCLoseFicheDePoste = ()=>{
    setFicheEvaluationFormOpen(false);
  }

  const handleRefreshDataTable = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_critere_groupee_service
      .getCritereGroupeeList(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCriteres(res.data || []);
      })
      .catch((e) => console.log(e.toString()));
  };

  useEffect(() => {
    if (filtre) {
      handleRefreshDataTable(filtre);
    }
    if (openForm) {
      setFormOpen(openForm);
    }
    if (excelExport > 0) {
      exportToExcel(criteres, columns, "Groupe de critere liste " + new Date());
    }
  }, [filtre, openForm, excelExport]);

  const columns = [
    {
      field: "NOM_CRITERE_GROUPEE",
      headerName: "Nom du groupe",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "DATE_CREATION_CRITERE_GROUPEE",
      headerName: "Date de la création ",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip
              title={"Cliquer pour afficher l'appercu fiche d'evaluation"}
            >
              <LoadingButton
                onClick={(e) =>
                  handleShowSelectedCritereGroupeeByPosition(params.row)
                }
                color="primary"
              >
                <ListAltSharp />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour afficher "}>
              <LoadingButton
                onClick={(e) => handleShowSelectedCritereGroupee(params.row)}
                color="primary"
              >
                <RemoveRedEyeOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditSelectedCritereGroupee(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
                onClick={(e) =>
                  handleDeleteSelectedCritereGroupee(e, params.row)
                }
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 280,
    },
  ];
  return (
    <> 
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Toast />
      <DialogComponent />
      <FicheDevaluation
        current_critere_groupe={current_critere}
        isOpen={fiche_evaluation_form_open}
        handleClose={handleCLoseFicheDePoste}
      />
      <CritereGroupeeForm
        current_critere={current_critere}
        handleClose={handleCloseFormDIalog}
        isOpen={form_open}
      />
      <DataGrid
        rows={criteres}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_CRITERE_GROUPEE}
      />
    </>
  );
};
export default CritereGroupeeTable;
