import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { Grid } from '@mui/material';


function calculateValue(value) {
  return value;
}

const SliderNotation = ({ val, step, min_val, max_val, on_leave, disabled = false}) => {
  const [value, setValue] = React.useState(val || 0);
  const [max_value, setMaxValue] = React.useState(max_val || 10);
  const [min_value, setMinValue] = React.useState(min_val || 0);
  const [step_value, setStepValue] = React.useState(step || 1);

  function valueLabelFormat(value) {
    return `${value} / ${max_value}`;
  }

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  const handleMouseOnLeave = (evt, newVal) => {
    if (on_leave) {
      on_leave(newVal);
    }
  }
  return (
    <Grid justifyContent='center' width={"50vw"} >
      <Typography id="non-linear-slider" gutterBottom>
        Note donnée: {valueLabelFormat(value, max_value)}
      </Typography>
      <Slider
        value={value}
        disabled={disabled}
        min={min_value}
        step={step_value}
        max={max_value}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        onMouseLeave={e => handleMouseOnLeave(e, value)}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
    </Grid>
  )
}

export default SliderNotation

