import { DataGrid } from '@material-ui/data-grid';
import { LoadingButton } from '@mui/lab';
import { Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { evaluation_saison_service } from '../../../services/PersonalEvaluationService';
import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';

const EvaluationFunctionTable = ({ openForm, onCloseForm, handleOpenDialog, filtre }) => {
  const [evaluationsSaisons, setEvaluationSaisonsList] = useState([]);
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(openForm);
  const [selected_saison, setSelectedSaison] = useState({
    ID_SAISON: 0,
    NOM_SESSION: "",
    DATE_DEBUT: format(new Date(), "yyyy-MM-dd"),
    DATE_FIN: format(new Date(), "yyyy-MM-dd"),
    CREATEUR: 0,
  });

  const handleOpenSaisonForm = () => {
    handleOpenDialog();
    setFormOpen(true);
  }

  const handleShowFunctionsAttachedCritereDetail = (fonction) => {
    navigate(`/questionnaire?id_function=${fonction.function_id}`);
  }

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleLoadDataTable();
    setFormOpen(false);
  }

  const handleEditSaison = (saison) => {
    setSelectedSaison(saison);
    handleOpenSaisonForm();
  }

  const handleLoadDataTable = () => {
    evaluation_saison_service.getSaisonList(filtre)
      .then(res => {
        if (res && res.data) {
          setEvaluationSaisonsList(res.data);
        }
      })
  }

  useEffect(() => {
    if (filtre) {
      handleLoadDataTable();
    }
    if (openForm) {
      setFormOpen(openForm);
    }
  }, [filtre, openForm]);

  const columns = [
    {
      field: 'NOM_SESSION',
      headerName: 'Nom de la fonction',
      width: 450,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'libelle_actions',
      headerName: 'Actions',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour afficher "}>
              <LoadingButton
                onClick={(e) => handleShowFunctionsAttachedCritereDetail(params.row)}
                color="secondary"
              >
                <RemoveRedEyeOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditSaison(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
                //onClick={}
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <DataGrid
        rows={evaluationsSaisons}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_SAISON}
      />
    </>
  )
}

export default EvaluationFunctionTable