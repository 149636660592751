import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const PlanningService = {

async Listeuser(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/liste`);
      if (result) {

        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  
}
 ,
 async All(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/all`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Direction(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/Direction/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Service(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/Service`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Month(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/month/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Days(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/days/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Final(id,id1,id3,id4){
  try {
      const result = await axios.get(`${apiUrl}/Planning/leavemonth/${id}/${id1}/${id3}/${id4}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async getById(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/id/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async getUser(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/usersuperior`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getplanning(idperiode){
    try {
        const result = await axios.get(`${apiUrl}/ValidationMission/`+idperiode);
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },

    async validatemission(form,periodeId){
      try {
          const result = await axios.post(`${apiUrl}/ValidationMission/validateplanning`,{
            form:form,
            idperiode:periodeId
          });
          if (result) {
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async tousvalidatemission(form,periodeId){
        try {
            const result = await axios.post(`${apiUrl}/ValidationMission/listvalidate`,{
              details:form,
              idperiode:periodeId
            });
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        },

      async sendplanning(datebegin,datefin,periodeid){
        try {
            const result = await axios.post(`${apiUrl}/ValidationMission/sendvalidate`,
              {
                date_begin:datebegin,
                date_fin:datefin,
                periodeid:periodeid
              }
            );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        },

        async checkplanning(){
          try {
              const result = await axios.get(`${apiUrl}/ValidationMission/checkperiode`
              );
              if (result) {
                return result.data;
              }
            } 
            catch (error) {
              if (error.response) {
                return error.response.data;
              }
            }
          },

        async periode(form) {
            try {
                const id = form.id || '';
        
                let result;
        
                if (id === '') {
                    result = await axios.post(`${apiUrl}/ValidationMission/periode`, form);
                } else {
                    result = await axios.put(`${apiUrl}/ValidationMission/periode/${id}`, form);
                }
        
                return result.data; 
        
            } catch (error) {
                if (error.response) {
                    return error.response.data; 
                } else {
                    console.error('Erreur non gérée:', error);
                    throw error; 
                }
            }
          },
          async periodelist(){
            try {
                const result = await axios.get(`${apiUrl}/ValidationMission/periode`);
                if (result) {
                  return result.data;
                }
              } 
              catch (error) {
                if (error.response) {
                  return error.response.data;
                }
              }
          },
          
          async activeperiode(id,active){
            try {
                const result = await axios.post(`${apiUrl}/ValidationMission/activeperiode`,{
                  id:id,
                  active:active
                });
                if (result) {
                  return result.data;
                }
              } 
              catch (error) {
                if (error.response) {
                  return error.response.data;
                }
              }
            },
            async periodeId(id){
              try {
                  const result = await axios.get(`${apiUrl}/ValidationMission/getperiodeId/`+id);
                  if (result) {
                    return result.data;
                  }
                } 
                catch (error) {
                  if (error.response) {
                    return error.response.data;
                  }
                }
            },
            async getperiodeactive()
            {
              try {
                const result = await axios.get(`${apiUrl}/ValidationMission/listactiveperiode`);
                if (result) {
                  return result.data;
                }
              } 
              catch (error) {
                if (error.response) {
                  return error.response.data;
                }
              }
            },
  

}

export default PlanningService;