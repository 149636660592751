import * as React from 'react';
import { useState, useEffect } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { dataset } from './weather';
import { Grid,Button } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StatistiqueService from '../../../services/StatistiqueService';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PieChart } from '@mui/x-charts/PieChart';
import { mobileAndDesktopOS, valueFormatter } from './webUsageStats';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LocalisationMisService from '../../../services/LocalisationMisService';
import { makeStyles } from "@material-ui/core/styles";
import PlanningMissionService from '../../../services/PlanningMissionService';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },

}));
const chartSetting = {
  yAxis: [
    {
      label: '', 
    },
  ],
  series: [
    {
      dataKey: 'seoul',
      label: 'Nombre de missions',
      valueFormatter: (value) => `${value} `,
      color: '#3585CD',
    },
  ],
  height: 400,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: 'translateX(-5px)',
    },
  },
};

const chartSettingdistrict = {
  yAxis: [
    {
      label: '', 
    },
  ],
  series: [
    {
      dataKey: 'value',
      label: 'Nombre de missions',
      valueFormatter: (value) => `${value} `,
      color: '#3585CD',
    },
  ],
  height: 400,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: 'translateX(-5px)',
    },
  },
};

export default function LineChart() {
  const classes = useStyles();
  const [tickPlacement, setTickPlacement] = useState('middle');
  const [tickLabelPlacement, setTickLabelPlacement] = useState('middle');
  const [list, setList] = useState([]);
  const [pourcent,setPourcent]=useState([]);
  const [details,setDetails]=useState([]);
  const [chartType, setChartType] = useState('bar');
  const [district,setDistrict]=useState([]);
  const [actifregion,setActifregion]=useState([]);
  const [detaildistrict,setDetaildistrict]=useState([]);
  const [detailcommune,setDetailcommune]=useState([]);
  const [commune,setCommune]=useState([]);
  const [direction,setDirection]=useState([]);
  const [service,setService]=useState([]);
  const [filter,setFilter]=useState(
    {
       date_debut:'2024-01-01',
       date_fin:'2024-12-31',
       region:'',
       district:''
    }
  );
  const fetchDirection = async () => {

      PlanningMissionService.getDirection().then((result) => {
        if (result && result.data) {
            setDirection(result.data);
        }
    });
  }
  const fetchData = async () => {
    try {
      const result = await StatistiqueService.getList();  
      if (result && result.data) {
        const updatedDataset = dataset.map(element => {
          const matchingElement = result.data.find(elements => elements.region === element.region);
          return {
            ...element,
            seoul: matchingElement ? Number(matchingElement.coun_region) : element.seoul
          };
        });
        setList(updatedDataset);
      } else {
        setList(dataset);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setList(dataset); 
    }
  };

  const fetchPourcent = async () => {
      try {
        const result = await StatistiqueService.getpourcent();  
        if (result && result.data) {
          const updatedDataset = mobileAndDesktopOS.map(element => {
            const matchingElement = result.data.find(elements => elements.region === element.label); 
            return {
              ...element,
              value: matchingElement ? Number(matchingElement.coun_region) : element.value 
            };
          });
          setPourcent(updatedDataset);
        } else {
          setPourcent(mobileAndDesktopOS);
        }
      } catch (error) {
        console.error("Error fetching data:", error); 
        setPourcent(mobileAndDesktopOS); 
      }
    };
    
    const fetchDetails = async () => {
      try {
        const result = await StatistiqueService.getdetails();  
        if (result && result.data) {;  
          setDetails(result.data);
          const val = result.data.map((item) => ({
              label: item.region,
              value: item.region
          }));
      
          setActifregion(val);
        } else {
          setDetails([]);
        }
      } catch (error) {
       setDetails([]);
      }
    };

    const fetchDetailsDistrict = async (region) => {
      try {
        const result = await StatistiqueService.getdistrictlist(region);  
        if (result && result.data) {
          setDetaildistrict(result.data);
        } else {
          setDetaildistrict([]);
        }
      } catch (error) {
       setDetaildistrict([]);
      }
    };

    const fetchDetailsCommune = async (region) => {
      try {
        const result = await StatistiqueService.getcommunlist(region);  
        if (result && result.data) {
          setDetailcommune(result.data);
        } else {
          setDetailcommune([]);
        }
      } catch (error) {
        setDetailcommune([]);
      }
    };
    

    const fetchDistrict = async (region) => {
      try {
          const result = await LocalisationMisService.getDistrictRegion(region);  
          
          if (result && result.data) {
              const dir = result.data.map((item) => ({
                  label: item.nom_district,
                  value: 0
              }));
              setDistrict(dir);
              const details = await StatistiqueService.getdistrict(region);
              if (details && details.data) {
                const updatedDataset = dir.map(element => {
                  const matchingElement = details.data.find(elements => elements.district === element.label); 
                  return {
                      ...element,
                      value: matchingElement ? Number(matchingElement.nombre) : element.value 
                  };
              });
  
              setDistrict(updatedDataset);
              }
              else{
                setDistrict(dir);
              }
              
          } else {
              setDistrict([]);  
          }
      } catch (error) {
          console.error('Error fetching district data:', error);  
          setDistrict([]);  
      }
  };

    const fetchCommune = async (region) => {
      try {
          const result = await LocalisationMisService.getCommuneDistrict(region);  
          
          if (result && result.data) {
              const dir = result.data.map((item) => ({
                  label: item.nom_commune,
                  value: 0
              }));
              setCommune(dir);
              const details = await StatistiqueService.getcommunlist(region);
              if (details && details.data) {
                
                const updatedDataset = dir.map(element => {
                  const matchingElement = details.data.find(elements => elements.commune === element.label); 
                  return {
                      ...element,
                      value: matchingElement ? Number(matchingElement.nombre) : element.value 
                  };
              });
              console.log(updatedDataset);
              
              setCommune(updatedDataset);
               
              }
              else{
               setCommune(dir);
              }
              
          } else {
            setCommune([]);  
          }
      } catch (error) {
          console.error('Error fetching district data:', error);  
          setDistrict([]);  
      }
  };
  
    function handleChangeDirdestination(event, value) {
      setFilter({ ...filter, region: value.label,district:''});
      fetchDistrict(value.label);
    }

    function handleChangeCommune(event, value) {
      setFilter({ ...filter, district: value.label});
      fetchCommune(value.label);
    }

    function handlereinitiale()
    {
      setFilter(  {
        date_debut:'2024-01-01',
        date_fin:'2024-12-31',
        region:''
      });
    }

    const CustomPaper = (props) => (
      <Paper {...props} style={{ maxHeight: '200px', overflowY: 'auto' }} />
    );

    function handleChangeDirection(event, value) {
      setFilter({ ...filter, direction: value.direction_id });

      PlanningMissionService.getServiceappui(value.direction_id).then((result) => {
          if (result && result.data) {
              setService(result.data);
          }
      });
  }

  function handleChangeservice(event, value) {
      setFilter({ ...filter, service: value.service_id });
  }


  useEffect(() => { 
    fetchData();
    fetchPourcent();
    fetchDetails();
    fetchDirection();
  }, []); 

  useEffect(() => { 
    fetchDetailsDistrict(filter.region);
  }, [filter.region]);

  useEffect(()=>{
    fetchDetailsCommune(filter.district);
  },[filter.district]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
              <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleFilter} 
                  style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
              <SearchIcon style={{ marginRight: '8px' }} />
                   Filtrer
              </Button>
       </Grid>
       <Grid item xs={2}>
              <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlereinitiale} 
                  style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
              <RestartAltIcon style={{ marginRight: '8px' }} />
                réinitialiser
              </Button>
       </Grid>
       <Grid item xs={8}>
       </Grid>
      <Grid item xs={3}>
        Date début:
        <TextField
          fullWidth
          id="date_debut"
          size="small"
          variant="outlined"
          type="date"
          value={filter.date_debut}
          name="date_debut"
        />
      </Grid>
      <Grid item xs={3}>
            Détail par region :
           <Autocomplete
               onChange={handleChangeDirdestination}
               name="Region"
               value={filter.region ? { label: filter.region } : null}
               options={actifregion}
               getOptionLabel={(option) => `${option.label}`}
               renderInput={(params) => (
               <TextField
                 {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                )}
              />
      </Grid>
      <Grid item xs={3}>
          Direction des missionnaires:
         <Autocomplete
              onChange={handleChangeDirection}
              name="direction"
              options={direction}
              getOptionLabel={(option) => `${option.name} (${option.code})`}
              renderInput={(params) => (
                  <TextField
                     {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                       )}
          />
      </Grid>
      <Grid item xs={3}>
         Type de diagramme :
        <Autocomplete
          options={['bar', 'pie']}
          getOptionLabel={(option) => option === 'bar' ? 'Diagramme en Barres' : 'Diagramme Circulaire'}
          onChange={(event, newValue) => setChartType(newValue)}
          value={chartType}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
            Date fin :
            <TextField
              fullWidth
              id="date_fin"
              size="small"
              variant="outlined"
              type="date"
              value={filter.date_fin}
              name="date_fin"
              // onChange={handleChange} 
            />
      </Grid>
      <Grid item xs={3}>
            Détail par district :
            <Autocomplete
               onChange={handleChangeCommune}
               name="District"
               value={filter.district ? { label: filter.district } : null}
               options={district.filter((it) => it.value > 0).map((it) => ({ label: it.label, value: it.value }))}
               getOptionLabel={(option) => `${option.label}`}
               renderInput={(params) => (
               <TextField
                 {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                )}
              />
      </Grid>
      <Grid item xs={3}>
                Service des missionnaires: 
                <Autocomplete
                  onChange={handleChangeservice}
                  // value={valdir}
                  name="service"
                  options={service}
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                 />
      </Grid>
      <Grid item xs={3}>
      </Grid>
      
      {/* <Grid item xs={3}>
                <Paper className={classes.paper} variant="outlined" style={{backgroundColor:"rgba(54, 162, 235, 0.6)",height:"120px"}}>
                    <h5 style={{textAlign:"center"}}>Nombre de missions</h5>
                    <h1 style={{textAlign:"center",fontSize:"15px",padding:"0px",margin:"0px",color:"white"}}>12</h1> 
                </Paper>
      </Grid>
      <Grid item xs={3}>
                <Paper className={classes.paper} variant="outlined" style={{backgroundColor:"rgba(54, 162, 235, 0.6)",height:"120px"}}>
                    <h5 style={{textAlign:"center"}}>Mission combiné</h5>
                    <h1 style={{textAlign:"center",fontSize:"15px",padding:"0px",margin:"0px",color:"white"}}>7</h1> 
                </Paper>
      </Grid>
      <Grid item xs={3}>
              <Paper className={classes.paper} variant="outlined" style={{backgroundColor:"rgba(54, 162, 235, 0.6)",height:"120px"}}>
                    <h5 style={{textAlign:"center"}}>Mission sur le terrain</h5>
                    <h1 style={{textAlign:"center",fontSize:"15px",padding:"0px",margin:"0px",color:"white"}}>5</h1> 
                </Paper>
       </Grid>
       <Grid item xs={3}>
              <Paper className={classes.paper} variant="outlined" style={{backgroundColor:"rgba(54, 162, 235, 0.6)",height:"120px"}}>
                    <h5 style={{textAlign:"center"}}>Mission sur le bureau</h5>
                    <h1 style={{textAlign:"center",fontSize:"15px",padding:"0px",margin:"0px",color:"white"}}>5</h1> 
                </Paper>
        </Grid> */}
   {chartType==='bar'&& filter.region===''&&
      <Grid item xs={12}>
      <div style={{ width: '100%', height: '100%' }}>
        <BarChart
          dataset={list}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'region',
              tickPlacement,
              tickLabelPlacement,
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 6, 
              },
            },
          ]}
          {...chartSetting}
          sx={{
            '& .MuiXAxis-tick': {
              overflow: 'visible',
            },
            '& .MuiXAxis-label': {
              padding: '20px', 
            },
          }}
        />
      </div>
    </Grid>}

    <Grid item xs={12}>
    </Grid>
    {chartType==='pie' && filter.region==='' &&
    <Grid item xs={12}>
      <PieChart
      series={[
        {
          data: pourcent.map(item => ({
            ...item,
            color: item.color,
            arcLabel: `${Number(item.value)}%`
            
          })),
          highlightScope: { fade: 'global', highlight: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          valueFormatter, 
        },
      ]}
      height={300}
      margin={{ top: 20, bottom: 50, left: 20, right: 600 }}
    />
    </Grid>}
    {filter.region===''&&
    <Grid item xs={12}>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Region</TableCell>
            <TableCell align="right">Code mission</TableCell>
            <TableCell align="right">Intitulé</TableCell>
            <TableCell align="right">Missionnaires</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((row) => (
            row.detail.map((item, index) => (
              <TableRow
                key={`${row.region}-${index}`} 
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {index === 0 && ( 
                  <TableCell component="th" scope="row" rowSpan={row.detail.length}>
                    {row.region}
                  </TableCell>
                )}
                <TableCell align="right">{item.reference}</TableCell>
                <TableCell align="right">{item.mission_title}</TableCell>
                <TableCell align="right">
                  <ul style={{ listStyleType: 'none', padding: 5, margin: 5}}>
                    {item.user.map((items, idx) => (
                      <li key={idx}>
                        {items.count} {items.name}
                      </li>
                    ))}
                  </ul>
                </TableCell>
              </TableRow>
            ))
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>}
    {filter.region!=='' && district.length>0 && filter.district==='' &&
      <>
        <Grid item xs={12}>
         <div style={{ width: '100%', height: '100%' }}>
            <BarChart
              dataset={district}
              xAxis={[
                {
                  scaleType: 'band',
                  dataKey: 'label',
                  tickPlacement,
                  tickLabelPlacement,
                  tickLabelStyle: {
                    angle: 0,
                    fontSize: 8, 
                  },
                },
              ]}
              {...chartSettingdistrict}
              sx={{
                '& .MuiXAxis-tick': {
                  overflow: 'visible',
                },
                '& .MuiXAxis-label': {
                  padding: '20px', 
                },
              }}
            />
          </div>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>District</TableCell>
              <TableCell align="center">Activité(s)</TableCell>
              <TableCell align="center">Missionnaires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detaildistrict.map((row) => (
                <TableRow
                  key={`${row.district}`} 
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" >
                      {row.district}
                    </TableCell>
                  <TableCell align="center">
                     <ul style={{ listStyleType: 'none', padding: 5, margin: 5}}>
                        {row.activites.map((items, idx) => (
                          <li key={idx}>
                            {items.libelle}
                          </li>
                        ))}
                    </ul>
                  </TableCell>
                    <TableCell align="center">
                    <ul style={{ listStyleType: 'none', padding: 5, margin: 5}}>
                        {row.users.map((items, idx) => (
                          <li key={idx}>
                            {items.count} {items.name}
                          </li>
                        ))}
                      </ul>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
      </>
    }
     {filter.district!=='' && commune.length>0 &&
      <>
        <Grid item xs={12}>
         <div style={{ width: '100%', height: '100%' }}>
            <BarChart
              dataset={commune}
              xAxis={[
                {
                  scaleType: 'band',
                  dataKey: 'label',
                  tickPlacement,
                  tickLabelPlacement,
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 6, 
                  },
                },
              ]}
              {...chartSettingdistrict}
              sx={{
                '& .MuiXAxis-tick': {
                  overflow: 'visible',
                },
                '& .MuiXAxis-label': {
                  padding: '20px', 
                },
              }}
            />
          </div>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Commune</TableCell>
              <TableCell align="center">Activité(s)</TableCell>
              <TableCell align="center">Missionnaires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailcommune.map((row) => (
                <TableRow
                  key={`${row.district}`} 
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" >
                      {row.commune}
                    </TableCell>
                  <TableCell align="center">
                     <ul style={{ listStyleType: 'none', padding: 5, margin: 5}}>
                        {row.activites.map((items, idx) => (
                          <li key={idx}>
                            {items.libelle}
                          </li>
                        ))}
                    </ul>
                  </TableCell>
                    <TableCell align="center">
                    <ul style={{ listStyleType: 'none', padding: 5, margin: 5}}>
                        {row.users.map((items, idx) => (
                          <li key={idx}>
                            {items.count} {items.name}
                          </li>
                        ))}
                      </ul>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
      </>
    }
  </Grid>
   
  );
}
