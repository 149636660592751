import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../../../components/Title";
import MissionService from "../../../services/MissionService";
import { useParams } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { CreateTwoTone } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
    fontWeight: 'bold'
  },
  root: {
    width: 200,
    height: 200,
    marginTop: '5.5%',
  },
  bold: {
    fontWeight: 600
  },
}));

function TDRView(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [row, setRow] = useState({});
  const params = useParams();
  const mission_planning_id = params.id;

  useEffect(() => {
    MissionService.getTDRMission(mission_planning_id, localStorage.getItem('USER')).then((result) => {
      if(result && result.data){
        setRow(result.data[0]);
        console.log(result.data[0].objectif);
      }
    });
  }, []); 
  
  const handleEdit = (tdrId) => (ev) => {
    console.log(tdrId);
  //  navigate("/mission/tdr/"+mission_planning_id+"/" + tdrId);
     navigate("/mission/tdr/"+mission_planning_id);
  };

  return (    
        <MainContainer {...headerDataMission}>
            <Title>Terme De Référence</Title>
            <Spacer y={2} />
            <TableContainer component={Paper}>
            <Spacer y={1} />
              <Button onClick={handleEdit(row.tdr_mission_id)} style={{ float: "right" }}>
                <CreateTwoTone style={{ color: "black"}} />
              </Button>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                      <TableRow key={"1"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row" className={classes.bold} >Contexte et justification : </TableCell>
                          <TableCell>{row.description_context}</TableCell>
                      </TableRow>
                      <TableRow key={"2"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row" className={classes.bold} >Objectifs : </TableCell>
                          <TableCell>
                          {Array.isArray(row.objectif) && row.objectif.map((objectif, index) => (
                            <ul>
                               <li>
                                <div key={index}>
                                    <p><strong style={{paddingRight:'40px'}}>Description : </strong>{objectif.description} </p>
                                    <p><strong style={{paddingRight:'28px'}}>Commentaire : </strong>{objectif.commentaire} </p>
                                    <p> <strong style={{paddingRight:'67px'}}>Mesure : </strong> {objectif.libelle_mode_mesure} </p>
                                    <p><strong style={{paddingRight:'72px'}}>Valeur : </strong> {objectif.valeur} </p>
                                    <p><strong style={{paddingRight:'79px'}}>Unité : </strong> {objectif.unite} </p>
                                </div>
                               </li>
                            </ul>
                              
                          ))}

                          </TableCell>
                      </TableRow>
                      <TableRow key={"3"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row" className={classes.bold} >Résultat attendus : </TableCell>
                          <TableCell>
                            {Array.isArray(row.resultat) && row.resultat.map((resultat, index) => (
                              <ul>
                                <li>
                                  <div key={index}>
                                      <p><strong style={{paddingRight:'40px'}}>Description : </strong>{resultat.description} </p>
                                      <p><strong style={{paddingRight:'28px'}}>Commentaire : </strong>{resultat.commentaire} </p>
                                      <p> <strong style={{paddingRight:'67px'}}>Mesure : </strong> {resultat.libelle_mode_mesure} </p>
                                      <p><strong style={{paddingRight:'72px'}}>Valeur : </strong> {resultat.valeur} </p>
                                      <p><strong style={{paddingRight:'79px'}}>Unité : </strong> {resultat.unite} </p>
                                  </div>
                                </li>
                              </ul>
                                
                            ))}
                          </TableCell>
                      </TableRow>
                      <TableRow key={"4"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row" className={classes.bold} >Tâches à faire : </TableCell>
                          <TableCell>{row.description_tache}</TableCell>
                      </TableRow>
                  </TableBody>
              </Table>

            </TableContainer>
        </MainContainer>
  );
}
export default TDRView;