//import React from 'react';
import React, { useState, useEffect } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerDataRH } from "../../../data/modules/hr-header";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Grid,Button }from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import LeaveService from "../../../services/LeaveService";
import Message from "../../tools/Message";
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      elevation: 0,
    },
  }));
export default function LeaveAnnual(props) {
    const classes = useStyles();
    const [active,setActive]=useState(false);
    const [loading,setLoading]=useState(false);
    const [rows,setRows]=useState([]);
    const [loadinglache,setLoadinglache]=useState(false);
    const [form,setForm]=useState({
         observation:'',
         solde:'',
         date:''
    });
    const [message, setMessage] = React.useState({
        openMessage: false,
        color: 'green',
        messageInfo: '',
      });
      const { openMessage, color, messageInfo } = message;


    function handleChange(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
    }
    function handleClick(ev) {
        setActive(true);
    }

    function handleCloseMessage() {
        setMessage({ ...message, openMessage: false });
    }
    
    const handlelance=(id)=>{
        setLoadinglache(true);
      LeaveService.trowannualleave(id).then((results) => {
        if (results) {
            setMessage({ ...message, openMessage: true, color: 'green', messageInfo:"Succès" });
            LeaveService.getannualleave().then((results) => {
                if (results) {
                  setRows(results.data);
                }
                else {
                    setRows([]);
                }
            });
            setLoadinglache(false);
        }
        else {
            setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Erreur, veuillez réessayer"});
            setLoadinglache(false);
            setActive(false);
        }
      });
    }

    function handleenregistre(ev) {  
        setLoading(true);
        LeaveService.setannualleave(form.observation,form.solde,form.date).then((results) => {
            if (results) {
                setMessage({ ...message, openMessage: true, color: 'green', messageInfo:"Succès" });
                LeaveService.getannualleave().then((results) => {
                    if (results) {
                      setRows(results.data);
                    }
                    else {
                        setRows([]);
                    }
                });
                setActive(false);
                setLoading(false);
            }
            else {
                setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Erreur, veuillez réessayer"});
                setLoading(false);
                setActive(false);
            }
          });
    }

    useEffect(() => {
        LeaveService.getannualleave().then((results) => {
            if (results) {
              setRows(results.data);
            }
            else {
                setRows([]);
            }
          });
    }, []);

  return (
    <MainContainer {...headerDataRH}>
     <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
     <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
            {!active &&
                <>
                  <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        style={{ fontWeight: 600 }}
                    >
                        Congé annuel
                    </Button>
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                </>  
            }
            
            {active && (
            <>
                <Grid item xs={3}>
                Intitulé :
                </Grid>
                <Grid item xs={9}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={form.observation}
                    name="observation"
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={3}>
                   Solde à retirer *:
                </Grid>
                <Grid item xs={9}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={form.solde}
                    name="solde"
                    onChange={handleChange}
                />
                </Grid>
             
                <Grid item xs={3}>
                  Dernière date à considérer * :
                </Grid>
                <Grid item xs={9}>
                 <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    value={form.date}
                    name="date"
                    onChange={handleChange}
                    type="date" 
                    InputLabelProps={{
                        shrink: true, 
                    }}
                />
                </Grid>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={2}>
                    <LoadingButton
                          onClick={handleenregistre}
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                      >
                          ENREGISTRER
                      </LoadingButton>
                </Grid>
            </>
            )}
            <Grid item xs={12}>
                <h2 style={{textAlign:"center"}}>Liste des congés annuels</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Intitulé</TableCell>
                        <TableCell align="right">Solde retiré</TableCell>
                        <TableCell align="right">Dernier date à considerer</TableCell>
                        <TableCell align="right">Créer par </TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.observation}
                        </TableCell>
                        <TableCell align="right">{row.solde}</TableCell>
                        <TableCell align="right">{row.datefin}</TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.dateajout }</TableCell>
                        <TableCell align="right">
                            <LoadingButton
                                onClick={() => handlelance(row.annual_id)}
                                loading={loadinglache}
                                loadingPosition="end"
                                disabled={row.active === "false"}
                                variant="contained"
                            >
                                <DoneAllIcon/>
                                Lancer
                            </LoadingButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </Paper>

    </MainContainer>
  );
}
