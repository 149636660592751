import { RefreshOutlined } from "@material-ui/icons";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import PerformanceGraph, {
  getRandomColor,
  TYPE_GRAPH,
} from "./type_graph/PerformanceGraph";
import { evaluation_graph_service } from "../../../services/PersonalEvaluationService";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { adaptDataToGraph } from "./type_graph/DataGraph";
import { Line } from "react-chartjs-2";

const VueGlobale = () => {
  const [dataGraph, setDataGraph] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [filtre, setFiltre] = useState({
    dateDebut: format(new Date(), "yyyy-MM-dd"),
    dateFin: format(new Date(), "yyyy-MM-dd"),
    refresh: 0,
  });

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };

  const handleRefreshPerformance = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_graph_service
      .getGlobalDataGraphEntreDeuxDate(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setDataGraph(res.data || []);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    handleRefreshPerformance();
  }, [filtre]);

  const data = {
    labels: dataGraph.map((item, idx) => item.NOM_SESSION),
    datasets: [
      {
        label: "Total note par evaluation",
        data: dataGraph.map((item, idx) => item.NOTE_MOYENNE),
        fill: true,
        tension: 0.25,
        pointRadius: 5,
        //fill: chartType === 'radar',
        borderColor: getRandomColor(false),
        backgroundColor: getRandomColor(true),
        //backgroundColor: chartType === 'radar' ? getRandomColor(true) : 'rgba(0,0,0,0)',
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Representation graphique de l'evaluation",
      },
    },
  };

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <Grid>
          <Typography sx={{ mb: -1 }}>Date debut</Typography>
          <TextField
            type="date"
            name="dateDebut"
            size="small"
            className="filtre_list"
            onChange={handlechangeFiltre}
          />
        </Grid>
        <Grid>
          <Typography sx={{ mb: -1 }}>Date fin</Typography>
          <TextField
            type="date"
            name="dateFin"
            size="small"
            className="filtre_list"
            onChange={handlechangeFiltre}
          />
        </Grid>
        <Grid display={"flex"} height={"100%"} alignItems={"end"}>
          <Button
            variant="outlined"
            className="filtre_list"
            onClick={handleRefreshData}
          >
            <RefreshOutlined />
          </Button>
        </Grid>
      </Grid>
      <Grid
        flexGrow={1}
        mt={3}
        height={"60vh"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        overflow={"auto"}
      >
        <LoadingSimpleBackdrop
          openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
        />
        {/* <PerformanceGraph
          type_graph={TYPE_GRAPH.ligne}
          data={adaptDataToGraph(
            dataGraph,
            TYPE_GRAPH.ligne,
            "NOM_SESSION",
            "NOTE_MOYENNE"
          )}
          titre={""}
        /> */}
        <Line data={data} options={options} />
      </Grid>
    </Grid>
  );
};

export default VueGlobale;
