import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function MultipleChoiceAutocomplete({class_name,stylex,data,label,placeholder,valeur,onChange,to_display,id_to_get}) {
  const [values, setSelectedValues] = React.useState([]);

  React.useEffect(() => {
    if (valeur) {
      setSelectedValues(valeur);
    }
  }, [valeur]);

  const handleChange = (event, newValue) => {
    setSelectedValues(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <Autocomplete
      multiple
      sx={stylex}
      size='small'
      limitTags={3}
      className={class_name}
      id="multiple-limit-tags"
      options={data}
      onChange={handleChange}
      value={valeur}
      isOptionEqualToValue={(option, value) => option[id_to_get] === value[id_to_get]}
      //getOptionLabel={(option) => option.label}
      getOptionLabel={(option) => option[to_display]}
      defaultValue={[]}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}