import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Fonction pour personnaliser l'alerte
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TYPE_NOTIFICATION = {
    SUCCESS : 'success',
    ERROR: 'error',
    WARNING : 'warning',
    INFO : 'info'
}

export const MESSAGE_NOTIFICATION = {
    CONNEXION_ERROR : 'Il semble que vous êtes hors connexion',
    INTERNAL_SERVER_ERROR: 'Internal server error',
    AN_ERROR_TRIGGERED_TRY_AGAIN : 'Un erreur s\'est produit, veuillez reéssayer svp',
    SUCCESS : 'Action terminée avec succès',
    TIME_OUT : 'Le serveur a mis du temps pour vous repondre, reéssayer svp',
}



function NotificationBull({ open, message, severity = 'success', autoHideDuration = 6000, handleCloseNotification }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleCloseNotification}
           // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleCloseNotification}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default NotificationBull;
