import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import PlanningMissionService from "../../services/PlanningMissionService";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AcceptedHelpService from '../../services/AcceptedHelpService';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  id: {
    color: "#3f51b5",
    fontWeight: "bold",
  },
  actualBalance: {
    textAlign: "right",
    margin: "auto"
  },
  detail: {
    fontSize: "13px"
  },
  divDetail: {
    justifyContent: "center"
  }
}));
export default function ConfirmationDialogAppui(props) {
  const classes = useStyles();
  const openDialog = props.openDialog;
  const messageDialog = props.messageDialog;
  const closeDialog = props.closeDialog;
  const confirmDialog = props.confirmDialog;
  const cancelDialog = props.cancelDialog;
  const detail=props.detail;
  const [direction,setDirection]=useState([]);
  const [service,setService]=useState([]);
  const [utilisateur,setUtilisateur]=useState([]);
  const [direction_id, setDirection_id] = useState(0);
  const [service_id, setService_id] = useState(0);
  const [user,setUser]=useState(null);

  function handleChangeDirection(event, value){
    setDirection_id(value.direction_id);
    PlanningMissionService.getService(value.direction_id).then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
  }

  function handleChangeIntervenants(event, value){
    setUser(value);
  }

  function confirmdemande()
  {
    console.log("user",user.user_id);
    console.log("detail",detail);

    AcceptedHelpService.updateAccepted(detail.mission_request_id,detail.lastvalidation,detail.type_validation,detail.number_validation,detail.file_id,detail.user_id,'',user.user_id)
    .then((res) => {
      if (res.status) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    })
    .catch((err) => {

    });
  }


  function handleChangeService(event, value){
    setService_id(value);
    
    PlanningMissionService.getIntervenant(direction_id, value.service_id).then((result) => {
      if (result && result.data) {
        setUtilisateur(result.data);
      }
    });
  }

  useEffect(() => {
    PlanningMissionService.getDirection().then((result) => {
        if (result && result.data) {
          setDirection(result.data);
        }
      });
  },[] )
  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="form-dialog-title" color="primary"  >Confirmation demande d'appui</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {detail.demande === "utilisateur" && (
                <p>
                    Après cette validation, la demande est adressée au supérieur hiérarchique de   
                    {detail.user && detail.user.length > 0 ? (
                    detail.user.map((ts, index) => (
                        ` ${ts.user_name}${index !== detail.user.length - 1 ? ', ' : ''}`
                    )).join('')
                    ) : (
                    'Aucun utilisateur spécifié'
                    )}
                </p>
           )}
           {detail.demande !== "utilisateur" && (
             <>
              <Grid container justifyContent="center" spacing={2}>
                 <Grid item xs={12}>
                  Désignez la personne à qui la demande sera adressée
                 </Grid>
                 <Grid item xs={4}>
                    Direction:
                 </Grid>
                 <Grid item xs={8}>
                     <Autocomplete
                      onChange={handleChangeDirection}
                      options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Direction"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                 </Grid>
                 <Grid item xs={4}>
                    Service :
                 </Grid>
                 <Grid item xs={8}>
                       <Autocomplete
                        onChange={handleChangeService}
                        options={service} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            label="Service"
                            size="small"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: "search" }}
                            />
                        )}
                        />
                 </Grid>
                 <Grid item xs={4}>
                    Utilisateur :
                 </Grid>
                 <Grid item xs={8}>
                    <Autocomplete  
                           id="tags-outlined" options={utilisateur} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                          filterSelectedOptions
                          onChange={handleChangeIntervenants}
                          value={user}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label="Intervenants"
                              variant="outlined"
                              placeholder="Intervenants"
                            />
                          )} 
                        />
                 </Grid>
                 <Grid item xs={12}> </Grid>
               </Grid>
             </>
           )}

             { messageDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              {detail.demande === "utilisateur" && 
                <Button variant="contained" color="primary" onClick={confirmDialog} >CONFIRMER</Button>
              }
              {detail.demande !== "utilisateur" && 
                <Button variant="contained" color="primary" onClick={confirmdemande} >CONFIRMER</Button>
              }
               <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
