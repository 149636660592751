import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { DataGrid } from '@material-ui/data-grid';
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import Title from "../../../components/Title";
import Spacer from "../../../components/Spacer";
import { Button } from "@material-ui/core";
import { DeleteOutlineRounded, CreateTwoTone } from "@material-ui/icons";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Message from "../../tools/Message";


const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function CopieMailTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const handleEdit = (id) => (ev) => {
    navigate("/mission_mail/add"+id);
  };

  const handleDelete = (id) => (ev) => {
  
    CategoryMissionService.deletecategory(id).then((result) => {
      if (result) {
        setOpenLoadingSimpleBackdrop(true);
        CategoryMissionService.getAll().then((result) => {
          if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.mail_int })));
            setOpenLoadingSimpleBackdrop(false);
          }
          else{
            setOpenLoadingSimpleBackdrop(false);
          }
        });
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  };

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    CategoryMissionService.listcopie().then((result) => {
       
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.mail_int })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

 
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <React.Fragment>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Liste des personnes en copie de mail</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type de l'envoie</TableCell>
                <TableCell align="right">Direction des missionaires</TableCell>
                <TableCell align="right">Service des missionaires</TableCell>
                <TableCell align="right">Utilisateurs en copie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell align="right">{row.direction_miss}</TableCell>
                  <TableCell align="right">{row.service_miss}</TableCell>
                  <TableCell align="right">
                     <ul>
                       {row.copie.map((ts)=>
                          <li>{ts.name}</li>
                       )}
                     </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
    
  );
}
