import { useEffect, useRef, useState } from "react";
import { evaluation_questionnaire_service } from "../../../services/PersonalEvaluationService";
import { Button, CardHeader, Dialog, DialogActions, DialogContent, Grid, Paper, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { CardContent } from "@material-ui/core";
import useToast from "../../../hooks/useToast";
import { useReactToPrint } from 'react-to-print';
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";

const TYPE_REPONSE = {
  comment: { id: 0, name: 'Commentaire' },
  texte_libre: { id: 1, name: 'Reponse libre' },
  note: { id: 2, name: 'En note' },
  jalon: { id: 3, name: 'En jalon' },
}

const EvaluationExaminationFormResume = ({ isOpen, selected_employee, handleClose, titre }) => {
  const componentRef = useRef();
  const initial_person = {
    user_id: 0,
    last_name: '',
    first_name: '',
    email: '',
    function_name: '',
    function_id: 0,
    direction_id: 0,
    direction_code: '',
    service_code: '',
    service_id: 0,
    registration_number: ''
  }
  const [selected_person, setSelectedPerson] = useState(initial_person);
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const { showToast, Toast } = useToast();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);


  useEffect(() => {
    if (selected_employee || isOpen) {
      setSelectedPerson(selected_employee);
    }
    if (isOpen) {
      handleRefreshQuestionsLIst(selected_employee.user_id);
    }
  }, [selected_employee, isOpen]);

  const handleRefreshQuestionsLIst = (user_id) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_questionnaire_service.getQuestionnaireListByuserIdForResume(user_id).then((res) => {
      if (res) {
        if (res && res.success) {
          setQuestions(res.data);
          setCategories(Array.from(new Set(res.data.map(item => item.NOM_CATEGORY))));
        }
        setOpenLoadingSimpleBackdrop(false);
      }
    }).finally(() => {
      setOpenLoadingSimpleBackdrop(false);
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const initAll = () => {
    setQuestions([]);
    setCategories([]);
    setSelectedPerson(initial_person);
  }

  const handleDialogClose = () => {
    initAll();
    handleClose();
  }

  return (
    <Dialog
      open={isOpen}
      /* onClose={ handleDialogClose} */
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      fullScreen
      maxWidth='xl'
    >
      <Toast />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Paper >
        <Grid display={'flex'} flexDirection={'column'} height={"100vh"} bgcolor={"#f9f9f9"}  >
          <DialogContent id='dialog-description' className='app-scroll-bar-view' sx={{ overflow: 'auto', maxHeight: '100%' }} >
            <Grid item mt={0.5} display={"flex"} flexDirection={"column"} >
              <Paper elevation={1} sx={{ mb: 1, borderTop: "5px solid #00a452" }}>
                <Grid className='info-personnelle'>
                  <Grid ref={componentRef}>
                    <Grid>
                      <Grid display={"flex"} flexDirection={"row"} >
                        <Grid item xs={6} flexGrow={1}>
                          <CardHeader
                            title={"Information du candidat"}
                            titleTypographyProps={{
                              variant: 'h6',
                              color: 'primary',
                              gutterBottom: true,
                            }}
                            sx={{ mb: -2 }}
                          />
                          <Typography variant='body1' component={"div"} sx={{ margin: "20px 30px" }} >
                            Nom et prénoms : {selected_person.last_name + " " + selected_person.first_name} <br />
                            Fonction : {selected_person.function_name}<br />
                            Direction: {selected_person.direction_code}<br />
                            Service: {selected_person.service_code}<br />
                          </Typography>
                        </Grid>
                        <Grid item xs={6} flexGrow={1}>
                        </Grid>
                      </Grid>
                      <Grid>
                        <CardHeader
                          title="Resumé de l'évaluation du candidat"
                          titleTypographyProps={{
                            textAlign: "center",
                            variant: 'h5',
                            color: 'primary',
                            gutterBottom: true,
                          }}
                          sx={{ mb: -2 }}
                        />
                        <CardContent>
                          {
                            (categories.length > 0) ? categories.map((cat, index) =>
                              <Grid>
                                <CardHeader
                                  title={`Section ${index + 1}: ${cat}`}
                                  titleTypographyProps={{
                                    variant: 'h6',
                                    color: 'primary',
                                    gutterBottom: true,
                                  }}
                                  sx={{ mb: -2 }}
                                />

                                <Grid>
                                  <table style={{ width: "100%" }} border="1">
                                    <tr>
                                      <th style={{ minWidth: "30%" }} >Critère</th>
                                      <th>Reponse/note (Auto-evaluation) </th>
                                      <th>Reponse/note (Evaluation du superieur) </th>
                                    </tr>

                                    {
                                      questions
                                        .filter(q => q.NOM_CATEGORY === cat)
                                        .map((qs, idx) =>
                                          <tr>
                                            <td>
                                              {`${idx + 1}. ${qs.QUESTION}`}
                                            </td>
                                            <td>
                                              {(qs.NOM_TYPE === TYPE_REPONSE.note.name) ? `Note: ${Number(qs.NOTE)}/${Number(qs.NOTE_MAX)}` : `Reponse: ${qs.REPONSE}`}
                                            </td>
                                            <td>
                                              {(qs.NOTE_S || qs.REPONSE_S) && ((qs.NOM_TYPE === TYPE_REPONSE.note.name) ? `Note: ${Number(qs.NOTE_S)}/${Number(qs.NOTE_MAX)}` : `Reponse: ${qs.REPONSE_S}`)}
                                            </td>
                                          </tr>
                                        )
                                    }
                                    {
                                      questions
                                        .filter(q => q.NOM_CATEGORY === cat)
                                        .map((qs, idx) =>
                                          (idx === 0) &&
                                          <tr>
                                            <td>
                                              Note total
                                            </td>
                                            <td>
                                              {(qs.NOM_TYPE === TYPE_REPONSE.note.name) ? questions
                                                .filter(q => q.NOM_CATEGORY === cat).reduce((somme, qs) => somme + Number(qs.NOTE), 0) + "/" + questions
                                                  .filter(q => q.NOM_CATEGORY === cat).reduce((somme, qs) => somme + Number(qs.NOTE_MAX || 0), 0) : ``}
                                            </td>
                                            <td>
                                              {(qs.NOM_TYPE === TYPE_REPONSE.note.name) ? questions
                                                .filter(q => q.NOM_CATEGORY === cat).reduce((somme, qs) => somme + Number(qs.NOTE_S), 0) + "/" + questions
                                                  .filter(q => q.NOM_CATEGORY === cat).reduce((somme, qs) => somme + Number(qs.NOTE_MAX || 0), 0) : ``}
                                            </td>
                                          </tr>
                                        )
                                    }
                                  </table>
                                  <table style={{ width: "100%", marginTop: "10px" }} border="1">
                                    <tr>
                                      <th>Commentaire (Auto-evaluation) </th>
                                      <th>Commentaire (Evaluation du superieur) </th>
                                    </tr>
                                    {
                                      questions
                                        .filter(q => q.NOM_CATEGORY === cat)
                                        .map((qs, idx) =>
                                          (idx === 0) &&
                                          <tr>
                                            <td>
                                              {
                                                <Typography sx={{ mt: 1 }}>
                                                  {`${qs.COMMENTAIRE || "--"}`}
                                                </Typography>
                                              }
                                            </td>
                                            <td>
                                              {
                                                <Typography sx={{ mt: 1 }}>
                                                  {`${qs.COMMENTAIRE_S || "--"}`}
                                                </Typography>
                                              }
                                            </td>
                                          </tr>
                                        )
                                    }
                                  </table>
                                </Grid>

                              </Grid>
                            ) : ""
                          }
                          {/*    <Grid display={"flex"} flexDirection={"row"} m={3}>
                            <Grid display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
                              <Typography>
                                Le candidat
                              </Typography>
                            </Grid>
                            <Grid display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
                              <Typography>
                                L'evaluateur
                              </Typography>
                            </Grid>
                          </Grid> */}
                        </CardContent>
                      </Grid>
                      <Grid display={"flex"} flexDirection={"row"}>
                        <Grid item fle></Grid>
                        <Grid></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"center"} m={5} spacing={2}>
                    {/* <Button
                      sx={{ ml: 1 }}
                      color="primary"
                      disabled={questions.some(q => q.TERMINEE === '1')}
                      variant="contained"
                      onClick={handlePrint}
                      endIcon={<ArrowForwardOutlined />}
                    >
                      Imprimer
                    </Button> */}

                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </DialogContent >
          <DialogActions>
            <Grid container display={"flex"} flexDirection={"row"}>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  color="warning"
                  variant="outlined"
                  size="small"
                  endIcon={<CloseOutlined />}
                  onClick={handleDialogClose}
                >
                  Fermer
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid >
      </Paper >
    </Dialog >
  )
}

export default EvaluationExaminationFormResume