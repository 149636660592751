import { useEffect, useState } from "react";
import {
  evaluation_commentaire_service,
  evaluation_questionnaire_service,
  evaluation_reponse_service,
} from "../../../services/PersonalEvaluationService";
import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import SliderNotation from "./comps/SliderNotation";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { CloseOutlined } from "@mui/icons-material";
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ArrowRightAltOutlined,
  EditOutlined,
} from "@material-ui/icons";
import { CardContent } from "@material-ui/core";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import UserService from "../../../services/UserService";
import EvaluationCommentaireGeneral from "./EvaluationCommentaireGeneral";
import useConfirm from "../../../hooks/useConfirm";

const TYPE_REPONSE = {
  comment: { id: 0, name: "Commentaire" },
  texte_libre: { id: 1, name: "Reponse libre" },
  note: { id: 2, name: "En note" },
  jalon: { id: 3, name: "En jalon" },
};

const EvaluationExaminationForm = ({
  isOpen,
  selected_employee,
  handleClose,
  titre,
}) => {
  const initial_person = {
    user_id: 0,
    last_name: "",
    first_name: "",
    email: "",
    function_name: "",
    function_id: 0,
    direction_id: 0,
    direction_code: "",
    service_code: "",
    service_id: 0,
    registration_number: "",
  };
  const [selected_person, setSelectedPerson] = useState(initial_person);
  const [evaluateur, setEvaluateur] = useState(initial_person);
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [active_index, setActiveIndex] = useState(-1);
  const [refresh, setRefresh] = useState(1);
  const [error_list, setErrorList] = useState([]);
  const [nombre_submit, setNombreSubmit] = useState(0);
  const [mode_edit, setModeEdit] = useState(false);
  const { showToast, Toast } = useToast();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const { DialogComponent, openConfirmDialog } = useConfirm();

  useEffect(() => {
    if (selected_employee || isOpen) {
      setSelectedPerson(selected_employee);
    }
    if (isOpen) {
      handleRefreshQuestionsLIst(selected_employee.user_id);
      UserService.myCompleteUserInfoForEvaluation().then((res) => {
        if (res) {
          setEvaluateur(res.data);
        }
      });
    }
  }, [selected_employee, isOpen]);

  const incrementSubmitNumber = () => {
    setNombreSubmit(nombre_submit + 1);
  };
  const initialiseSubmitNumber = () => {
    setNombreSubmit(0);
  };

  const handleResfreshData = () => {
    setRefresh(refresh * -1);
    handleRefreshQuestionsLIst(selected_employee.user_id);
  };

  const handleRefreshQuestionsLIst = (user_id) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_questionnaire_service
      .getQuestionnaireListByuserId(user_id)
      .then((res) => {
        if (res) {
          setOpenLoadingSimpleBackdrop(false);
          if (res && res.success) {
            setQuestions(res.data);
            setCategories(
              Array.from(new Set(res.data.map((item) => item.NOM_CATEGORY)))
            );
          }
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  const handleClickNext = (obj) => {
    if (mode_edit) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "veuillez enregistrer d'abord avant de passer à la catégorie suivante"
      );
    } else {
      if (isAllCategoryContentSaved(obj)) {
        setActiveIndex(active_index + 1);
        initialiseSubmitNumber();
        setErrorList([]);
        setModeEdit(obj.some((o) => o.ID_REPONSE === null));
      } else {
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez repondre à toutes les questions, puis enregistrer"
        );
      }
    }
  };
  const handleClickPrevious = () => {
    if (mode_edit) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "veuillez enregistrer d'abord avant de revenir à la catégorie precedente"
      );
    } else {
      setActiveIndex(active_index - 1);
      initialiseSubmitNumber();
      setErrorList([]);
    }
  };

  const initAll = () => {
    setQuestions([]);
    setCategories([]);
    setActiveIndex(-1);
    setSelectedPerson(initial_person);
  };

  const handleDialogClose = () => {
    initAll();
    handleClose();
  };

  const handleUpdateTableState = (obj, new_note_val, type_value) => {
    if (mode_edit) {
      let idx_pricipal = questions.indexOf(obj);
      let copy_questions = questions.slice();
      if (type_value === TYPE_REPONSE.note) {
        copy_questions[idx_pricipal].NOTE = new_note_val;
      } else if (type_value === TYPE_REPONSE.texte_libre) {
        copy_questions[idx_pricipal].REPONSE = new_note_val;
      } else if (type_value === TYPE_REPONSE.jalon) {
        copy_questions[idx_pricipal].REPONSE = new_note_val;
      } else if (type_value === TYPE_REPONSE.comment) {
        copy_questions[idx_pricipal].COMMENTAIRE = new_note_val;
      } else {
        copy_questions[idx_pricipal].COMMENTAIRE = new_note_val;
      }
      setQuestions(copy_questions);
    }
  };
  const handleUpdateCommentaireSuperieur = (obj, comment) => {
    if (mode_edit) {
      let idx_pricipal = questions.indexOf(obj);
      let copy_questions = questions.slice();

      copy_questions[idx_pricipal].COMMENTAIRE_SUP = comment;

      setQuestions(copy_questions);
    }
  };

  const handleSaveOneReponse = (reponse) => {
    let success_objs = [];
    setOpenLoadingSimpleBackdrop(true);
    if (reponse.ID_REPONSE) {
      evaluation_reponse_service.updateReponse(reponse).then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        handleResfreshData();
        success_objs.push(res.success);
      });
    } else {
      evaluation_reponse_service.insertReponse(reponse).then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        handleResfreshData();
        success_objs.push(res.success);
      });
    }
    return !success_objs.some((so) => so === false);
  };
  const handleSaveCommentaire = (comms) => {
    setOpenLoadingSimpleBackdrop(true);
    if (comms.ID_COMMENTAIRE) {
      evaluation_commentaire_service.updateCommentaire(comms).then((res) => {
        setOpenLoadingSimpleBackdrop(false);
      });
    } else {
      if (comms.COMMENTAIRE) {
        evaluation_commentaire_service.insertCommentaire(comms).then((res) => {
          setOpenLoadingSimpleBackdrop(false);
        });
      }
    }
  };

  const finishEvaluation = (id_candidat) => {
    openConfirmDialog(
      "Confirmation",
      "Voullez vous enregistrer puis fermer? Vous ne serrez plus autoriser à modifier les données plus tard.",
      () => {
        evaluation_reponse_service
          .updateReponseToBeFinishAllByCurrentActiveSaison(id_candidat)
          .then((res) => {
            if (res && res.success) {
              handleDialogClose();
              showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
            } else {
              showToast(TYPE_NOTIFICATION.ERROR, res.message);
            }
          })
          .catch((e) => {
            showToast(TYPE_NOTIFICATION.ERROR, e.toString());
          });
      }
    );
  };

  const getEvaluationReponseOnly = (reponse) => {
    return {
      ID_REPONSE: reponse.ID_REPONSE,
      ID_QUESTIONNAIRE: reponse.ID_QUESTIONNAIRE,
      ID_TYPE_EVALUATION: reponse.ID_TYPE_EVALUATION,
      ID_CANDIDAT: reponse.ID_CANDIDAT || selected_employee.user_id,
      ID_EVALUATEUR: reponse.ID_EVALUATEUR,
      DATE_REPONSE: reponse.DATE_REPONSE || new Date(),
      COMMENTAIRE_SUP: reponse.COMMENTAIRE_SUP || "",
      NOTE: reponse.NOTE || 0,
      REPONSE: reponse.REPONSE,
    };
  };
  const getEvaluationCommentOnly = (reponse) => {
    return {
      ID_COMMENTAIRE: reponse.ID_COMMENTAIRE,
      ID_SAISON: reponse.ID_SAISON,
      ID_CATEGORY: reponse.ID_CATEGORY,
      COMMENTAIRE: reponse.COMMENTAIRE,
      ID_CANDIDAT: reponse.ID_CANDIDAT || selected_employee.user_id,
      ID_EVALUATEUR: reponse.ID_EVALUATEUR,
      DATE_AJOUT: reponse.DATE_AJOUT || new Date(),
    };
  };

  const handleSaveCategory = (e, objs) => {
    incrementSubmitNumber();
    let ok = false;
    if (mode_edit) {
      if (isAllFieldOk(objs)) {
        objs.map((obj, indx) => {
          ok = handleSaveOneReponse(getEvaluationReponseOnly(obj));
          if (indx === objs.length - 1) {
            handleSaveCommentaire(getEvaluationCommentOnly(obj));
          }
        });
        setModeEdit(false);
      } else {
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez verifier que vous n'avez rien oublié"
        );
      }
    } else {
      setModeEdit(true);
    }
  };

  const isAllCategoryContentSaved = (objs) => {
    return !objs.some((obj) => obj.ID_REPONSE === null);
  };

  const isAllFieldOk = (objs) => {
    let err_obj = [];
    let ok = true;
    objs.map((obj, indx) => {
      if (obj.NOM_TYPE === TYPE_REPONSE.note.name) {
        err_obj.push(null);
        if (obj.NOTE === null) {
          showToast(
            TYPE_NOTIFICATION.WARNING,
            `La note donnée à la question N°${
              Number(indx) + 1
            } est la valeur par défaut`
          );
        }
      } else {
        if (!obj.REPONSE || obj.REPONSE.trim() === "") {
          ok = false;
          err_obj.push(
            `Vous n'avez pas encore repondue à la question N°${
              Number(indx) + 1
            }`
          );
          showToast(
            TYPE_NOTIFICATION.ERROR,
            `Vous n'avez pas encore repondue à la question N°${
              Number(indx) + 1
            }`
          );
        } else {
          err_obj.push(null);
        }
      }
    });
    setErrorList(err_obj);
    return ok;
  };

  return (
    <Dialog
      open={isOpen}
      /* onClose={ handleDialogClose} */
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
      maxWidth="xl"
    >
      <DialogComponent />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Toast />
      <Paper>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
          bgcolor={"#f9f9f9"}
        >
          <Grid display={"flex"} flexDirection={"row"} alignContent={"center"}>
            <Grid
              m={1}
              flexGrow={1}
              display={"flex"}
              sx={{ overflowX: "auto" }}
              justifyContent={"center"}
            >
              <DialogTitle id="dialog-title">
                {/*  {titre || "Formulaire d'evaluation"} */}
                <Stepper activeStep={active_index} alternativeLabel>
                  {categories.map((cat, index) => (
                    <Step key={index}>
                      <StepLabel>{`${index + 1}. ${cat}`}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent
            id="dialog-description"
            className="app-scroll-bar-view"
            sx={{ overflow: "auto", maxHeight: "80vh" }}
          >
            <Grid item mt={0.5} display={"flex"} flexDirection={"column"}>
              <Paper
                elevation={1}
                sx={{
                  mb: 1,
                  borderTop: "5px solid #00a452",
                  display: active_index > -1 ? "none" : "",
                }}
              >
                <Grid className="info-personnelle">
                  <CardHeader
                    title={"Information du personnel à evaluer"}
                    titleTypographyProps={{
                      variant: "h6",
                      color: "primary",
                      gutterBottom: true,
                    }}
                    sx={{ mb: -2 }}
                  />
                  <Typography
                    variant="body1"
                    component={"div"}
                    sx={{ margin: "20px 30px" }}
                  >
                    Nom et prénoms :{" "}
                    {selected_person.last_name +
                      " " +
                      selected_person.first_name}{" "}
                    <br />
                    Fonction : {selected_person.function_name}
                    <br />
                    Direction: {selected_person.direction_code}
                    <br />
                    Service: {selected_person.service_code}
                    <br />
                  </Typography>
                  <Grid display={"flex"} justifyContent={"center"} m={5}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={categories.length <= 0}
                      onClick={(e) => setActiveIndex(active_index + 1)}
                      endIcon={<ArrowRightAltOutlined />}
                    >
                      Suivant
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  mb: 1,
                  borderTop: "5px solid #00a452",
                  display: active_index > categories.length - 1 ? "" : "none",
                }}
              >
                <Grid className="info-personnelle" p={3}>
                  <Grid>
                    <EvaluationCommentaireGeneral />
                  </Grid>
                  <Grid
                    display={"flex"}
                    justifyContent={"center"}
                    m={5}
                    spacing={2}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleClickPrevious}
                      startIcon={<ArrowBackOutlined />}
                    >
                      Precedent
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      color="primary"
                      variant="contained"
                      onClick={handleDialogClose}
                      startIcon={<SaveOutlinedIcon />}
                      disabled={questions.some((q) => q.TERMINEE === "1")}
                    >
                      Enregistrer
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      color="primary"
                      disabled={questions.some((q) => q.TERMINEE === "1")}
                      variant="contained"
                      onClick={(e) =>
                        finishEvaluation(questions[0].ID_CANDIDAT)
                      }
                      endIcon={<ArrowForwardOutlined />}
                    >
                      Terminer et fermer
                    </Button>
                  </Grid>
                </Grid>
              </Paper>

              <Grid>
                {categories.length > 0 ? (
                  categories.map((cat, index) => (
                    <>
                      <Paper
                        key={index + cat}
                        sx={{
                          borderLeft: "3px solid #00a452",
                          mb: 3,
                          display: active_index === index ? "" : "none",
                        }}
                      >
                        <Grid m={2}>
                          <CardHeader
                            title={cat}
                            titleTypographyProps={{
                              variant: "h6",
                              color: "primary",
                              gutterBottom: true,
                            }}
                            sx={{ mb: -2 }}
                          />
                          {questions
                            .filter((q) => q.NOM_CATEGORY === cat)
                            .map((qs, idx) => (
                              <>
                                <Divider
                                  variant="middle"
                                  sx={{ display: idx === 0 ? "none" : "" }}
                                />
                                <Grid
                                  ml={1.5}
                                  key={idx}
                                  container
                                  display={"flex"}
                                  flexDirection={"column"}
                                >
                                  <Grid>
                                    <Typography
                                      variant="body1"
                                      m={2}
                                      component={"div"}
                                    >
                                      {`${idx + 1}. ${qs.QUESTION}`}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    display={"flex"}
                                    justifyContent={"center"}
                                    flexDirection={"column"}
                                    m={2}
                                  >
                                    <Grid
                                      display={"flex"}
                                      justifyContent={"center"}
                                    >
                                      {qs.NOM_TYPE ===
                                      TYPE_REPONSE.note.name ? (
                                        <Grid>
                                          <SliderNotation
                                            disabled={!mode_edit}
                                            max_val={Number(qs.NOTE_MAX)}
                                            min_val={Number(qs.NOTE_MIN)}
                                            val={
                                              qs.NOTE === null
                                                ? Number(qs.NOTE_REF || 0)
                                                : Number(qs.NOTE)
                                            }
                                            on_leave={(note) =>
                                              handleUpdateTableState(
                                                qs,
                                                note,
                                                TYPE_REPONSE.note
                                              )
                                            }
                                          />
                                          <Grid
                                            display={
                                              evaluateur.user_id ===
                                              selected_person.user_id
                                                ? "none"
                                                : ""
                                            }
                                          >
                                            <TextField
                                              fullWidth
                                              multiline
                                              disabled={!mode_edit}
                                              sx={{ mr: 3, mt: 1 }}
                                              rows={2}
                                              color={
                                                nombre_submit > 0 &&
                                                error_list[idx]
                                                  ? "error"
                                                  : ""
                                              }
                                              label="Votre commentaire"
                                              onChange={(e) =>
                                                handleUpdateCommentaireSuperieur(
                                                  qs,
                                                  e.target.value
                                                )
                                              }
                                              value={qs.COMMENTAIRE_SUP || ""}
                                            ></TextField>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <TextField
                                          fullWidth
                                          multiline
                                          disabled={!mode_edit}
                                          sx={{ mr: 3 }}
                                          rows={2}
                                          color={
                                            nombre_submit > 0 && error_list[idx]
                                              ? "error"
                                              : ""
                                          }
                                          label="Votre reponse"
                                          onChange={(e) =>
                                            handleUpdateTableState(
                                              qs,
                                              e.target.value,
                                              TYPE_REPONSE.texte_libre
                                            )
                                          }
                                          value={qs.REPONSE || ""}
                                        ></TextField>
                                      )}
                                    </Grid>
                                    <Grid
                                      container
                                      display={"flex"}
                                      justifyContent={"center"}
                                    >
                                      {nombre_submit > 0 && error_list[idx] ? (
                                        <Alert severity="error" sx={{ m: 1 }}>
                                          {error_list[idx]}
                                        </Alert>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {idx ===
                                  questions.filter(
                                    (q) => q.NOM_CATEGORY === cat
                                  ).length -
                                    1 && (
                                  <Grid
                                    className="commentaire_categorie"
                                    m={2}
                                    display={
                                      evaluateur.user_id ===
                                      selected_person.user_id
                                        ? "none"
                                        : ""
                                    }
                                  >
                                    <Typography
                                      variant="body1"
                                      m={2}
                                      component={"div"}
                                    >
                                      Commentaire par rapport à cette catégorie
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={3}
                                      label="Commentaire"
                                      onChange={(e) =>
                                        handleUpdateTableState(
                                          qs,
                                          e.target.value,
                                          TYPE_REPONSE.comment
                                        )
                                      }
                                      value={qs.COMMENTAIRE || ""}
                                    ></TextField>
                                  </Grid>
                                )}
                              </>
                            ))}

                          <CardActions>
                            <Grid
                              container
                              display={"flex"}
                              flexDirection={"row"}
                            >
                              <Grid
                                item
                                xs={6}
                                display={"flex"}
                                justifyContent={"flex-start"}
                              ></Grid>
                              <Grid
                                item
                                xs={6}
                                display={"flex"}
                                justifyContent={"flex-end"}
                              >
                                <Button
                                  color={mode_edit ? "primary" : "secondary"}
                                  size="small"
                                  disabled={questions.some(
                                    (q) => q.TERMINEE === "1"
                                  )}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                  startIcon={
                                    mode_edit ? (
                                      <SaveOutlinedIcon />
                                    ) : (
                                      <EditOutlined />
                                    )
                                  }
                                  onClick={(e) =>
                                    handleSaveCategory(
                                      e,
                                      questions.filter(
                                        (q) => q.NOM_CATEGORY === cat
                                      )
                                    )
                                  }
                                >
                                  {mode_edit ||
                                  categories.some(
                                    (item) => item.ID_REPONSE === null
                                  )
                                    ? "Enregistrer"
                                    : "Modifier"}
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Grid>
                      </Paper>
                      <Paper
                        key={index}
                        sx={{
                          borderBottom: "3px solid #00a452",
                          mb: 3,
                          display: active_index === index ? "" : "none",
                          padding: "8px 0",
                        }}
                      >
                        <Grid
                          container
                          display={"flex"}
                          flexDirection={"row"}
                          m={1}
                        >
                          <Grid
                            item
                            xs={6}
                            display={"flex"}
                            justifyContent={"flex-start"}
                          >
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              disabled={active_index < 0}
                              startIcon={<ArrowBackOutlined />}
                              onClick={handleClickPrevious}
                            >
                              Precedent
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            display={"flex"}
                            justifyContent={"flex-end"}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              disabled={active_index > categories.length - 1}
                              sx={{ mr: 2 }}
                              endIcon={<ArrowForwardOutlined />}
                              onClick={(e) =>
                                handleClickNext(
                                  questions.filter(
                                    (q) => q.NOM_CATEGORY === cat
                                  )
                                )
                              }
                            >
                              Suivant
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </>
                  ))
                ) : (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>Questionnaire non trouvé</AlertTitle>
                      Aucune question disponible pour evaluer cette personne!
                      veuillez contacter le responsable de l'evaluation du
                      personnel
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container display={"flex"} flexDirection={"row"}>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  color="warning"
                  variant="outlined"
                  size="small"
                  endIcon={<CloseOutlined />}
                  onClick={handleDialogClose}
                >
                  Fermer
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default EvaluationExaminationForm;
