import React, { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import EvaluationSaisonFiltre from './EvaluationSaisonFiltre'

const EvaluationSaisonList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Liste des saisons d'evaluation"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<EvaluationSaisonFiltre  handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} openForm={openForm} />}
      /*         table_component={<EvaluationSaisonTable handleOpenDialog={handleOpenDialog} onCloseForm={handleCloseDialog} openForm={openForm}
               />} */
      />
    </MainContainer>
  )
}

export default EvaluationSaisonList