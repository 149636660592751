import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_critere_groupee_critere_service } from "../../../services/PersonalEvaluationService";
import { AddOutlined } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const CritereToBeAssignedTable = ({ filtre }) => {
  const [criteres, setCriteres] = useState([]);
  const { Toast, showToast } = useToast();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const handleLoadCritereList = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_critere_groupee_critere_service
      .getCritereNotInGroupeeCritereList({
        ID_CRITERE_GROUPEE: filtre.ID_CRITERE_GROUPEE,
        ID_CATEGORY: filtre.ID_CATEGORY,
        mot_cle: "",
      })
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCriteres(res.data || []);
      });
  };

  const getInsertableData = (data) => {
    return {
      ID_CRITERE: data.ID_CRITERE,
      ID_CRITERE_GROUPEE: filtre.ID_CRITERE_GROUPEE,
    };
  };

  useEffect(() => {
    if (filtre) {
      console.log(filtre);
      handleLoadCritereList();
    }
  }, [filtre]);

  const handleAddCritere = (data) => {
    evaluation_critere_groupee_critere_service
      .insertCritereGroupeeCritere(getInsertableData(data))
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleLoadCritereList();
        }else{
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const columns = [
    {
      field: "QUESTION",
      headerName: "Critere",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour AJouter "}>
              <LoadingButton
                onClick={(e) => handleAddCritere(params.row)}
                color="primary"
              >
                <AddOutlined />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <Toast />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <DataGrid
        rows={criteres}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_CRITERE}
      />
    </>
  );
};
export default CritereToBeAssignedTable;
