import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { RefreshOutlined } from "@material-ui/icons";
import { DownloadOutlined } from "@mui/icons-material";
import EmployeeListTable from "./EmployeeListTable";
import { Paper } from "@material-ui/core";

const EmployeeListFiltre = ({ title }) => {
  const [filtre, setFiltre] = useState({
    critere: null,
    mot_cle: "",
    refresh: 0,
  });
  const [openForm, setOpenForm] = useState(false);

  const [excelExport, declenceExportToExcel] = useState(0);

  const downloadExcel = () => {
      declenceExportToExcel(excelExport + 1);
  }

  const handleOpenDialogForm = () => {

  }

  const handleCloseDialogForm = () => {

  }

  const handleChangeCritere = (new_critere) => {
    setFiltre({ ...filtre, critere: new_critere });
  }

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  }

  const handleRefreshTable = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  }

  return (
    <Paper>
      <Grid p={2}  display={"flex"} flexDirection={"column"} height={"80vh"}>
        <Typography mb={1} color={"#1a73e8"} textAlign={"center"} variant="h6">
          {title}
        </Typography>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          <TextField
            sx={{ width: 400 }}
            size='small'
            value={filtre.mot_cle}
            name="mot_cle"
            label="Mot clé"
            onChange={handlechangeFiltre}
            className="filtre_list"
          />
          <Button
            variant='outlined'
            className='filtre_list'
            onClick={() => handleRefreshTable()}
          >
            <RefreshOutlined />
          </Button>
          <Button
            variant='outlined'
            className='filtre_list'
            onClick={downloadExcel}
          >
            <DownloadOutlined /> Excel export
          </Button>
        </Grid>
        <Grid flexGrow={1} mt={3}>
          <EmployeeListTable filtre={filtre} excelExport={excelExport} handleOpenDialog={handleOpenDialogForm} onCloseForm={handleCloseDialogForm} openForm={openForm} title={title} />
        </Grid>
      </Grid>
    </Paper>
  )
}
export default EmployeeListFiltre