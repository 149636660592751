import React, { useEffect, useState } from 'react';
import { Chart, LineElement, RadialLinearScale, PointElement, Filler, Tooltip, Legend, CategoryScale, LinearScale, Title } from 'chart.js';
import { Bar, Line, Radar } from 'react-chartjs-2';

Chart.register(LineElement, RadialLinearScale, PointElement, Filler, Tooltip, Legend, CategoryScale, LinearScale, Title);

export const TYPE_GRAPH = {
    ligne: 'line',
    bar: 'bar',
    radar: 'radar',
}

export const getRandomColor = (isOpac) => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    //const a = Math.random().toFixed(2); 
    const a = (isOpac) ? 0.2 : 1;
    return `rgba(${r},${g},${b},${a})`
}

const PerformanceGraph = ({ type_graph, data, titre }) => {
    const [chartType, setChartType] = useState(type_graph || 'bar');
    const [saisons, setsaisons] = useState([]);

    const graphData = (datas) => {
        const labels = Array.from(new Set(datas.map(item => item.NOM_CATEGORY))).sort(item => item.NOM_CATEGORY);

        const datasets = Array.from(new Set(datas.map(item => item.NOM_SESSION))).sort(item => item.NOM_SESSION).map((saison_name, idx) => {
            const data_saison = datas.filter(item => item.NOM_SESSION === saison_name);
            return {
                label: saison_name,
                data: labels.map(label => {
                    return (data_saison.find(data => data.NOM_CATEGORY === label)) ? data_saison.find(data => data.NOM_CATEGORY === label).NOTE_MOYENNE : null;
                }),
                fill: true,
                tension: 0.25,
                spanGaps: true, //graph mitohy
                pointRadius: 5,
                borderColor: getRandomColor(false),
                backgroundColor: getRandomColor(chartType !== 'bar'),
            }
        });

        return {
            labels: labels,
            datasets: datasets
        }
    }

    useEffect(() => {
        console.log("data graph", graphData(data));
    }, [data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: titre || "Representation graphique de l'evaluation",
            },
        },
    };
    const options_radar = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
                display: false
            },
            y: {
                grid: {
                    display: false
                },
                display: false
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <>
          {/*   {chartType === TYPE_GRAPH.ligne ? (
                <Line data={(data)} options={options} />
            ) : chartType === TYPE_GRAPH.bar ? (
                <Bar data={(data)} options={options} />
            ) : (
                <Radar data={(data)} options={options_radar} />
            )} */}

            {chartType === TYPE_GRAPH.ligne ? (
                <Line data={graphData(data)} options={options} />
            ) : chartType === TYPE_GRAPH.bar ? (
                <Bar data={graphData(data)} options={options} />
            ) : (
                <Radar data={graphData(data)} options={options_radar} />
            )}
        </>
    );
};

export default PerformanceGraph;
