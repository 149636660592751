import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import Title from "../../components/Title";
import { useState, useEffect, useContext } from "react";
import PlanningMissionService from "./../../services/PlanningMissionService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CvList from "./CvList";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import BanqueDeCvService from "../../services/BanqueCvService";
import { extraData } from "./ExtraData";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useLocation } from "react-router";
import { createAndDownloadZip } from "../../helpers/zipHerlper";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import useToast from "../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../components/reusable/NotificationBull";
import {
  Filter,
  Filter1Outlined,
  FilterOutlined,
  FilterRounded,
} from "@material-ui/icons";
import { FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function Cvtable({ candidatIntern }) {
  const classes = useStyles();
  const titre = candidatIntern
    ? "Liste des CV internes"
    : "Liste des CV externes";
  const [direction, setDirection] = useState([]);
  const [service, setService] = useState([]);
  const [domaine_competence, setDomaineCompetence] = useState([]);
  const [sous_domaine_competence, setSousDomaineCompetence] = useState([]);
  const [active_offre_emplois, setActiveOffreEmplois] = useState([]);
  const contextData = useContext(userDetailContext);
  const location = useLocation();
  const { Toast, showToast } = useToast();

  const [filtre, setFiltre] = useState({
    direction_id: "",
    service_id: "",
    formation: "",
    diplome: "",
    refresh: 0,
    domaine_comp: "",
    sous_domaine: "",
    mot_cle: "",
    objet_candidature: "",
    isCVIntern: candidatIntern,
  });
  const [excelExport, declenceExportToExcel] = useState(0);

  const handleForbidenPageVisit = () => {
    if (
      location.pathname === "/cv_intern" &&
      !hasPrivilege(contextData.privileges, "CV Interne")
    ) {
      window.history.back();
    }
  };

  useEffect(() => {
    setFiltre({ ...filtre, isCVIntern: candidatIntern });
    handleForbidenPageVisit();
  }, [candidatIntern]);

  const handleExportToexcelEvent = () => {
    declenceExportToExcel(excelExport + 1);
  };

  function handleChangeDirection(event, value) {
    try {
      PlanningMissionService.getService(value.direction_id).then((result) => {
        if (result && result.data) {
          setService(result.data);
          setFiltre({ ...filtre, direction_id: value });
        }
      });
    } catch (err) {
      setService([]);
      setFiltre({ ...filtre, direction_id: 0 });
    }
  }

  function handleChangeDomaineDeCompetence(event, value) {
    try {
      BanqueDeCvService.ShowSousdomaine(value.id_domaine).then((result) => {
        if (result && result.data) {
          setSousDomaineCompetence(result.data);
          setFiltre({ ...filtre, domaine_comp: value });
        }
      });
    } catch (err) {
      setSousDomaineCompetence([]);
      setFiltre({ ...filtre, domaine_comp: "" });
    }
  }

  function handleChangeService(event, value) {
    let srv_id = value !== null ? value : 0;
    setFiltre({ ...filtre, service_id: srv_id });
  }

  function handleChangeDiplome(event, value) {
    let dpl = value !== null ? value : "";
    setFiltre({ ...filtre, diplome: dpl });
  }

  function handleChangeSousDomaine(event, value) {
    let sd = value !== null ? value : "";
    setFiltre({ ...filtre, sous_domaine: sd });
  }

  function handleObjetCandatureChange(event, value) {
    let sd = value !== null ? value : "";
    setFiltre({ ...filtre, objet_candidature: sd });
  }

  function handleChangeFormation(event, value) {
    let srv_id = value !== null ? value : 0;
    setFiltre({ ...filtre, formation: srv_id });
  }

  const handleActionRefresh = () => {
    var newrefreshe = filtre.refresh + 1;
    setFiltre({ ...filtre, refresh: newrefreshe });
  };

  const handleFilterActionUpdate = (event) => {
    const { name, value } = event.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleDownloadZip = async () => {
    //setIsLoading(true);
    let dire = "/public/donwloads/";
    const filesFromDB = [
      { path: dire + "logo512.png", name: "logo512.png" },
      { path: dire + "logo192.png", name: "logo192.png" },
    ];
    try {
      createAndDownloadZip(filesFromDB, "teste .zip");
    } catch (error) {
      showToast(TYPE_NOTIFICATION.ERROR, error.toString());
    } finally {
      // setIsLoading(false);
    }
  };

  const handleTelechargerZip = () => {
    BanqueDeCvService.downloadUploadedFiles("792");
  };

  const changeAttributeName = (jsonTable, oldAttribute, newAttribute) => {
    return jsonTable.map((item) => {
      let newitem = { ...item };
      newitem[newAttribute] = newitem[oldAttribute];
      delete newitem[oldAttribute];
      return newitem;
    });
  };

  useEffect(() => {
    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });

    BanqueDeCvService.showDomaineCompetence().then((res) => {
      if (res) {
        //setDomaineCompetence(changeAttributeName(res.data,'name','label'));
        setDomaineCompetence(res.data);
      }
    });
    BanqueDeCvService.showAllOpenPostedPosition().then((res) => {
      if (res) {
        setActiveOffreEmplois(res.data);
      }
    });
  }, []);

  return (
    <MainContainer {...headerDataRH}>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Tooltip title={"Actualiser les données"}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleActionRefresh}
                    startIcon={<FilterAltOutlined />}
                  >
                    <Typography> Filtrer</Typography>
                  </Button>
                </Tooltip>
                <Tooltip title={"Exporter les données en fichier Excel"}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    color="primary"
                    //onClick={handleTelechargerZip}
                    onClick={handleExportToexcelEvent}
                    startIcon={<GetAppOutlinedIcon />}
                  >
                    <Typography> Exporter</Typography>
                  </Button>
                </Tooltip>
              </div>
              <div style={{ textAlign: "center", width: "100%" }}>
                <Title>{titre}</Title>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid
                item
                xs={4}
                style={{ display: candidatIntern ? "" : "none" }}
              >
                <Autocomplete
                  onChange={handleChangeDirection}
                  options={direction}
                  getOptionLabel={(option) =>
                    option.name + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Direction"
                      name="direction"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: candidatIntern ? "" : "none" }}
              >
                <Autocomplete
                  onChange={handleChangeService}
                  options={service}
                  getOptionLabel={(option) =>
                    option.name + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Service"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                      name="service"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeFormation}
                  options={extraData.formation}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="formation"
                      fullWidth
                      label="Formation"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeDiplome}
                  options={extraData.diplome}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="diplome"
                      fullWidth
                      label="Diplôme"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeDomaineDeCompetence}
                  options={domaine_competence}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Domaine de competence"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                      name="domaine_comp"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeSousDomaine}
                  options={sous_domaine_competence || null}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Sous domaine de competence"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                      name="sous_domaine"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: !candidatIntern ? "" : "none" }}
              >
                <Autocomplete
                  onChange={handleObjetCandatureChange}
                  options={active_offre_emplois || null}
                  getOptionLabel={(option) => option.libelle_type}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Objet candidature"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={handleFilterActionUpdate}
                      name="objet_candidature"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: !candidatIntern ? "" : "none" }}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Mot clé de description"
                  variant="outlined"
                  name="mot_cle"
                  onChange={handleFilterActionUpdate}
                />
              </Grid>
            </Grid>
            <Grid>
              <CvList excel_export={excelExport} filter={filtre} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
