import { DataGrid } from "@material-ui/data-grid";
import { ArrowBackOutlined } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";

const QuestionnaireNonAssignees = ({
  row_datas,
  filtre,
  handleOpenform,
  handleSetCurrentQuestionnaire,
}) => {
  const handleOpenFormAjout = (questionnaire) => {
    handleSetCurrentQuestionnaire({
      ID_QUESTIONNAIRE: 0,

      ID_CRITERE: questionnaire.ID_CRITERE,
      QUESTION: questionnaire.QUESTION,

      ID_POSTE: filtre.fonction.function_id,
      ID_SAISON: filtre.saison.ID_SAISON,
      NOM_SESSION: filtre.saison.NOM_SESSION,
      name: filtre.fonction.name,
      NOTE_MIN: 0,
      NOTE_MAX: 10,
    });
    handleOpenform();
  };

  const columns = [
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour ajouter "}>
              <span>
                <LoadingButton
                  onClick={(e) => handleOpenFormAjout(params.row)}
                  disabled={!(filtre.saison && filtre.fonction)}
                  size="small"
                  color="primary"
                >
                  <ArrowBackOutlined />
                </LoadingButton>
              </span>
            </Tooltip>
          </div>
        );
      },
      width: 130,
    },
    {
      field: "QUESTION",
      headerName: "Question",
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "NOM_CATEGORY",
      headerName: "Categorie critère",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      <DataGrid
        rows={row_datas}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) =>
          row.ID_QUESTIONNAIRE +
          "~" +
          row.ID_CATEGORY +
          "~" +
          row.ID_CRITERE +
          "~" +
          row.ID_SAISON
        }
      />
    </>
  );
};

export default QuestionnaireNonAssignees;
