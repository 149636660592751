
export const dataset = [
  { region: 'ANALAMANGA', seoul: 0 },
  { region: 'VAKINANKARATRA', seoul: 0 },
  { region: 'ITASY', seoul: 0 },
  { region: 'BONGOLAVA', seoul: 0 },
  { region: 'HAUTE MATSIATRA', seoul: 0 },
  { region: 'AMORON I MANIA', seoul: 0 },
  { region: 'VATOVAVY FITOVINANY', seoul: 0 },
  { region: 'IHOROMBE', seoul: 0 },
  { region: 'ATSIMO ATSINANANA', seoul: 0 },
  { region: 'ATSINANANA', seoul: 0 },
  { region: 'ANALANJIROFO', seoul: 0 },
  { region: 'ALAOTRA MANGORO', seoul: 0 },
  { region: 'BOENY', seoul: 0 },
  { region: 'SOFIA', seoul: 0 },
  { region: 'BETSIBOKA', seoul: 0 },
  { region: 'MELAKY', seoul: 0 },
  { region: 'ATSIMO ANDREFANA', seoul: 0 },
  { region: 'ANDROY', seoul: 0 },
  { region: 'ANOSY', seoul: 0 },
  { region: 'MENABE', seoul: 0 },
  { region: 'DIANA', seoul: 0 },
  { region: 'SAVA', seoul: 0 },
];

export function valueFormatter(value: number | null) {
  return `${value}`;
}