import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ObjectifService = {
  async add(form,tdrid, id,sresults) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/objectif/saveStockObjectif/${id}`, {
          id: id,
          "description_objectif": form.description_objectif,
          "commentaire_objectif": form.commentaire_objectif,
          "libelle_mode_mesure": form.type_objectif,
          "taux_objectif": form.taux_objectif,
          "echelonne_objectif": form.echelonne_objectif,
          "qualitatif_objectif": form.qualitatif_objectif,
          "chiffre_objectif": form.chiffre_objectif,
          "unite_objectif": form.unite_objectif,
          "mode_mesure_id": form.mode_mesure_id,
          "result_id":form.resultat_attendu_id,
          "description_resultat": form.description_resultat,
          "commentaire_resultat": form.commentaire_resultat,
          "libelle_mode_mesure_resultat": form.type_resultat,
          "taux_resultat": form.taux_resultat,
          "echelonne_resultat": form.echelonne_resultat,
          "qualitatif_resultat": form.qualitatif_resultat,
          "chiffre_resultat": form.chiffre_resultat,
          "unite": form.unite,
          "mode_mesure_id_resultat":form.mode_mesure_id_resultat,
          // "mode_mesure_id_resultat": form.mode_mesure_id,
          "tdr_mission":tdrid,
          "sresults":sresults
        })
    
        :
        await axios.post(`${apiUrl}/objectif/saveStockObjectif`, {
          "description_objectif": form.description_objectif,
          "commentaire_objectif": form.commentaire_objectif,
          "libelle_mode_mesure": form.type_objectif,
          "taux_objectif": form.taux_objectif,
          "echelonne_objectif": form.echelonne_objectif,
          "qualitatif_objectif": form.qualitatif_objectif,
          "chiffre_objectif": form.chiffre_objectif,
          "unite_objectif": form.unite_objectif,
          "description_resultat": form.description_resultat,
          "commentaire_resultat": form.commentaire_resultat,
          "libelle_mode_mesure_reultat": form.type_resultat,
          "taux_resultat": form.taux_resultat,
          "echelonne_resultat": form.echelonne_resultat,
          "qualitatif_resultat": form.qualitatif_resultat,
          "chiffre_resultat": form.chiffre_resultat,
          "unite": form.unite,
          "tdr_mission":tdrid,
          "sresults":sresults
        });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }

  ,

  async insertion(id) {
    try {
      const result = await axios.get(`${apiUrl}/objectif/saveObjectif?id=${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getByIdMissionPlanning(id) {
    try {
      const result = await axios.get(`${apiUrl}/MissionRequestUserRequired/getAll/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getId(id) {
    try {
      const result = await axios.get(`${apiUrl}/objectif/getById/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getById(id) {
    try {
      const result = await axios.get(`${apiUrl}/objectif/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(id) {
    try {
      const result = await axios.delete(`${apiUrl}/objectif/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

}
export default ObjectifService;
