import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { useParams } from "react-router-dom";
import PersonalSettingService from "../../../services/PersonalSettingService";
import Message from "../../tools/Message";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import PlanningMissionService from "../../../services/PlanningMissionService";
import Autocomplete from "@material-ui/lab/Autocomplete";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 900,
    maxHeight:700,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function NombrePerDirAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({dir: "", interne:"",externe:""});
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const [direction,setDirection]=useState([]);
  const [dirdetail,setDirDetails]=useState([]);
  const personal_config_id = params.id;
  const navigate = useNavigate();

  useEffect(() => {
    PlanningMissionService.getDirection().then((result) => {
        if (result && result.data) {
            setDirection(result.data);
        }
    });
    if(personal_config_id) {
      CategoryMissionService.getlistByID(personal_config_id).then(({ data: personalSetting }) => {
        PlanningMissionService.getDirection().then((result) => {
            if (result && result.data) {
                const dirt = result.data.find(item => item.direction_id === personalSetting.direction_id); 
                setDirDetails([dirt]);
                setForm({
                    dir:personalSetting.direction_id, 
                    interne: personalSetting.nbr_interne,
                    externe: personalSetting.nbr_externe
                });
            }
        });
    });
    
    }

  }, []);

  function handleClick(){
     if (personal_config_id) {   
        CategoryMissionService.updatevalidation(form,personal_config_id).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_dir");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     } else {
        CategoryMissionService.insertnbrvalidation(form).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_dir");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     }
    
    
   }
    

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }



  function handleChangeDirdestination(event, value) {
    setDirDetails(value);
    const iddir = value.map(item => item.direction_id); 
    setForm({ ...form, dir: iddir[0] });
   }
  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Direction *</label>
          </Grid>
          <Grid item xs={8}>
                 <Autocomplete
                        onChange={handleChangeDirdestination}
                        multiple
                        value={dirdetail||null}
                        name="direction"
                        options={direction}
                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nombre de validateur interne *</label>
          </Grid>
          <Grid item xs={8}>
             <TextField fullWidth id="outlined-basic" type="number" size="small" label="Nombre de validateur interne" variant="outlined" value={form.interne} name="interne" onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
             <label className={classes.labelPosition}>Nombre de validateur externe *</label>
          </Grid>
          <Grid item xs={8}>
             <TextField fullWidth id="outlined-basic" type="number" size="small" label="Nombre de validateur externe" variant="outlined" value={form.externe} name="externe" onChange={handleChange} />
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default NombrePerDirAdd;