import { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import CritereGroupeeFiltre from './CritereGroupeeFiltre'

const CritereGroupeeList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Liste des criteres groupés"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<CritereGroupeeFiltre openForm={openForm} handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} />}
      />
    </MainContainer>
  )
}

export default CritereGroupeeList