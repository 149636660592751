export const desktopOS = [
  { label: 'ANALAMANGA', value: 0 },
  { label: 'VAKINANKARATRA', value: 0 },
  { label: 'ITASY', value: 0 },
  { label: 'BONGOLAVA', value: 0 },
  { label: 'HAUTE MATSIATRA', value: 0 },
  { label: 'AMORON I MANIA', value: 0 },
  { label: 'VATOVAVY FITOVINANY', value: 0 },
  { label: 'IHOROMBE', value: 0 },
  { label: 'ATSIMO ATSINANANA', value: 0 },
  { label: 'ATSINANANA', value: 0 },
  { label: 'ANALANJIROFO', value: 0 },
  { label: 'ALAOTRA MANGORO', value: 0 },
  { label: 'BOENY', value: 0 },
  { label: 'SOFIA', value: 0 },
  { label: 'BETSIBOKA', value: 0 },
  { label: 'MELAKY', value: 0 },
  { label: 'ATSIMO ANDREFANA', value: 0 },
  { label: 'ANDROY', value: 0 },
  { label: 'ANOSY', value: 0 },
  { label: 'MENABE', value: 0 },
  { label: 'DIANA', value: 0 },
  { label: 'SAVA', value: 0 }
];

const colors = [
  '#FF5733', '#33FF57', '#3357FF', '#F1C40F',
  '#8E44AD', '#E74C3C', '#3498DB', '#2ECC71',
  '#6F8FAF', '#34495E', '#F39C12', '#D35400',
  '#1ABC9C', '#2980B9', '#7F8C8D', '#C0392B',
  '#2C3E50', '#D5DBDB', '#FFC300','#89CFF0','#6082B6'
];

export const mobileAndDesktopOS = desktopOS.map((v, index) => ({
  ...v,
  color: colors[index % colors.length], 
}));

export const valueFormatter = (item: { value: number }) => `${item.value}%`;
