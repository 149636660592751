import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RequestObservationService = {

  async getAll() {
    try {
      const result = await axios.get(`${apiUrl}/MissionRequestObservation`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
 

}

export default RequestObservationService;
