import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@material-ui/core';
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import PlanningService from "../../../services/PlanningService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#425b8a",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
 
  

const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

const groupDaysByMonth = (dates) => {
    const months = {};
    dates.forEach(date => {
        // const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(1, '0')}`;
        const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 0).padStart(1, '0')}`;
        if (!months[monthKey]) {
            months[monthKey] = [];
        }
        months[monthKey].push(date.getDate());
    });
    
    
    return months;
};

function CalendarView({startDate, endDate,datatables,periode}) {
 
  
    const dates = generateDateRange(startDate, endDate);
    const monthsGrouped = groupDaysByMonth(dates);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [datatable,setDataTable]=useState([]);
    const [user,setUser]=useState(0);
    const [aff,setAff]=useState(true);

    const handleChangeTDR = (event) => {
      setSelectedDay({ ...selectedDay, statut: event.target.value });
    };
  
    const handleCellClick = (day,id) => {
      setUser(id);
      setSelectedDay(day);
      setDialogOpen(true);  
    };

    const handlevalide = () => {
        // Fermer le dialogue et réinitialiser les autres états
        setDialogOpen(false);
        setUser(0);
        setSelectedDay(null);
        

        setAff(false);
        PlanningService.validatemission(selectedDay,periode).then((result) => {
          if (result && result.data) {
           setDataTable(result.data);
           setAff(true);
          }
        });
    };
  
    const handleCLose=()=>{
      setDialogOpen(false);
    }
    
    const monthKeys = Object.keys(monthsGrouped).sort((a, b) => {
        const [yearA, monthA] = a.split('-').map(Number);
        const [yearB, monthB] = b.split('-').map(Number);
        return yearA === yearB ? monthA - monthB : yearA - yearB;
    });
     
  return (
      <React.Fragment>
           <div>
            {aff&&
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#ffffff00' }}></StyledTableCell>
                        {monthKeys.map((monthKey) => {
                        const [year, month] = monthKey.split('-').map(Number);
                        const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
                        const daysInMonth = monthsGrouped[monthKey].length;
                        return (
                            <StyledTableCell  colSpan={daysInMonth} key={monthKey} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0',color:'black' }}>
                               {monthName.toLowerCase()}
                            </StyledTableCell>
                        );
                        })}
                    </TableRow>
                    <TableRow>
                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#e0e0e0',position: 'sticky',left: 0,zIndex: 1}}>
                          Demandeur
                        </StyledTableCell>
                        {monthKeys.map((monthKey) => (
                            monthsGrouped[monthKey].map(day => (
                                <StyledTableCell key={day} align="center" style={{ padding: '10px', border: '1px solid #ddd' }}>
                                {day}
                                </StyledTableCell>
                            ))
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        const list = datatable.length > 0 ? datatable : datatables;

                        return list.map((row, rowIndex) => (
                          <StyledTableRow key={rowIndex}>
                           <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: 'sticky',
                                left: 0,
                                backgroundColor: 'white',
                                zIndex: 1,
                              }}
                            >
                              {row.name}
                            </StyledTableCell>

                            {monthKeys.map((monthKey) => (
                              monthsGrouped[monthKey].map((day, dayIndex) => {
                                let cellContent = null;

                                if (row.details.length > 0) {
                                  row.details.forEach((ts, tsIndex) => {
                                    const isHighlighted = day === parseInt(ts.debut, 10) &&
                                    parseInt(ts.mois_debut, 10) === parseInt(monthKey.split('-')[1], 10);

                                    const test = Array.from({ length: parseInt(ts.fin, 10) - parseInt(ts.debut, 10) }, (_, i) => (parseInt(ts.debut, 10) + 1 + i).toString());
                                   
                                      if (test.includes(day.toString())) {
                                        cellContent = <></>; 
                                      }
                                    
                                    if (isHighlighted) {
                                      cellContent = (
                                        <StyledTableCell
                                          key={`${monthKey}-${dayIndex}-${tsIndex}`}
                                          style={{
                                            border: '1px solid #ddd',
                                            backgroundColor: ts.statut === 'valide' ? '#78b785' : ts.statut === 'refuse' ? 'red' : '#87CEEB',
                                            color: 'white',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                          }}
                                          //onClick={() => handleCellClick(ts, row.user_id)}
                                          onClick={() => {
                                            if (ts.statut !== 'valide' && ts.statut !== 'refuse') {
                                              handleCellClick(ts, row.user_id);
                                            }
                                          }}
                                          onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#5B9BD5';
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = ts.statut === 'valide' ? '#78b785' : ts.statut === 'refuse' ? 'red' : '#87CEEB';
                                          }}
                                          colSpan={isHighlighted ? (parseInt(ts.fin, 10) - parseInt(ts.debut, 10)) + 1 : 1}
                                        >
                                          <Icon style={{ marginRight: '4px', fontSize: '15px' }}>{ts.code}</Icon>
                                        </StyledTableCell>
                                      );
                                    }
                                  });
                                }

                                if (!cellContent) {
                                  cellContent = (
                                    <StyledTableCell
                                      key={`${monthKey}-${dayIndex}`}
                                      style={{
                                        border: '1px solid #ddd',
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        textAlign: 'center',
                                        cursor: 'default',
                                        transition: 'background-color 0.3s',
                                      }}
                                      colSpan={1}
                                    >
                                  
                                    </StyledTableCell>
                                  );
                                }

                                return cellContent;
                              })
                            ))}
                          </StyledTableRow>
                        ));
                      })()}
                    </TableBody>

                  </Table>
                </TableContainer>
            }
           </div> 
           <div>
           {dialogOpen && (
                <Dialog
                open={dialogOpen}
                onClose={handleCLose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Validation Planning"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                           Intutilé :
                        </Grid>
                        <Grid item xs={8}>
                             {selectedDay.mission_title}
                        </Grid>
                        <Grid item xs={4}>
                            Date de départ:
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="date_begin"
                                size="small"
                                variant="outlined"
                                type="date"
                                value={selectedDay.date_begin}
                                name="date_begin"
                                onChange={(e) => {
                                  const newDate = e.target.value; 
                                  const day = (parseInt(newDate.split('-')[2], 10)).toString();
                                  const month= (parseInt(newDate.split('-')[1])).toString();
                                  setSelectedDay(prevSelectedDay => ({ 
                                      ...prevSelectedDay, 
                                      date_begin: newDate, 
                                      debut: day ,
                                      mois_debut:month
                                  }));
                              }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            Date de retour:
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="date_end"
                                size="small"
                                variant="outlined"
                                type="date"
                                value={selectedDay.date_end}
                                name="date_end"
                                onChange={(e) => {
                                  const newDate = e.target.value; 
                                  const day = (parseInt(newDate.split('-')[2], 10)).toString();
                                  const month= (parseInt(newDate.split('-')[1])).toString();
                                  setSelectedDay(prevSelectedDay => ({ 
                                      ...prevSelectedDay, 
                                      date_end: newDate, 
                                      fin: day ,
                                      mois_fin:month
                                  }));
                              }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                         
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup>
                                <FormControlLabel
                                control={<Checkbox checked={selectedDay.statut === 'valide'} onChange={() => handleChangeTDR({ target: { value: 'valide' } })} />}
                                label="Valider"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={selectedDay.statut === 'refuse'} onChange={() => handleChangeTDR({ target: { value: 'refuse' } })} />}
                                label="Réfuser"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                         
                        </Grid>
                        <Grid item xs={8}>
                          {selectedDay &&selectedDay.statut==='refuse'&&
                             <TextField
                             fullWidth
                             rows={3}
                             multiline
                             id="outlined-basic"
                             size="small"
                             label="Raison"
                             variant="outlined"
                             value={selectedDay.raison}
                             name="raison"
                             onChange={(e) => setSelectedDay({ ...selectedDay, raison: e.target.value })}
                           />
                          }
                        </Grid>
                    </Grid>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handlevalide} variant="outlined" >Valider</Button>
                <Button onClick={handleCLose} autoFocus variant="outlined">
                    Fermer
                </Button>
                </DialogActions>
             </Dialog>
            )}
           </div>
      </React.Fragment>
  );
}

export default CalendarView;