export const headerPersonnalEvalutaion = {
  title: "Evaluation du personnels",
  submodules: [
    createData(0, "Evaluation", "/personnel_evaluation"),
    createData(1, "Performance", "/personnel_performance"),
    createData(2, "Evaluation saison", "/evaluation_saison"),
    createData(3, "Critere d'evaluation", "/critere"),
    createData(4, "Critere categorie", "/critere_categorie"),
    createData(5, "Assigner/Critere", "/questionnaire"),
  ],
};

function createData(id, moduleName, ref) {
  return { id, moduleName, ref };
}
