import React, { useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { headerPersonnalEvalutaion } from '../../../data/modules/personnal-evaluation-header'
import AppPageForTableList from '../shared/AppPageForTableList'
import EvaluationSaisonFiltre from './EvaluationFuncitonsFiltre'
import EvaluationFuncitonsFiltre from './EvaluationFuncitonsFiltre'

const EvaluationFunctionList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  }
  const handleCloseDialog = () => {
    setOpenForm(false);
  }
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Liste des fonctions"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={<EvaluationFuncitonsFiltre handleCloseFormDialog={handleCloseDialog} handleOpenFormDialog={handleOpenDialog} openForm={openForm} />}
      />
    </MainContainer>
  )
}

export default EvaluationFunctionList