import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { useParams } from "react-router-dom";
import PersonalSettingService from "../../../services/PersonalSettingService";
import Message from "../../tools/Message";
import CategoryMissionService from "../../../services/CategoryMissionService";
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import ObservationAppuiList from "./ObservationAppuiList";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 800,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function ObservationAppuiAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({name: "", desc:""});
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const personal_config_id = params.id;
  const navigate = useNavigate();

  useEffect(() => {
    if(personal_config_id) {
      CategoryMissionService.getreponseByID(personal_config_id).then(({ data:personalSetting }) => {
        setForm({
          name: personalSetting.name,
          desc: personalSetting.description
        });
      });
    }
  }, []);

  function handleClick(){
     if (personal_config_id) {   
        CategoryMissionService.updateresponse(personal_config_id,form).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_reponse");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     } else {
        CategoryMissionService.insertresponse(form).then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/mission_reponse");
          }
          else{
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
          }
        });
     }
    
    
   }
    

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  

  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Intitulé *</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Intitulé" variant="outlined" value={form.name} name="name" onChange={handleChange} />
           
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description *</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Description" variant="outlined" value={form.desc} name="desc" onChange={handleChange} />
          </Grid>
         
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default ObservationAppuiAdd;