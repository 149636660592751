import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_questionnaire_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const QuestionnaireFormAjout = ({
  isOpen,
  current_questionnaire,
  handleClose,
}) => {
  const { Toast, showToast } = useToast();
  const [selected_questionnaire, setSelectedQuestionnaire] = useState({
    ID_QUESTIONNAIRE: 0,
    ID_CRITERE: 0,
    ID_POSTE: 0,
    ID_SAISON: 0,
    NOM_SESSION: "",
    name: "",
    QUESTION: "",
    /*    NOTE_MIN: 0,
    NOTE_MAX: 0 */
  });

  const getCurrentQuestionnaire = () => {
    return {
      ID_QUESTIONNAIRE: selected_questionnaire.ID_QUESTIONNAIRE,
      ID_CRITERE: selected_questionnaire.ID_CRITERE,
      ID_SAISON: selected_questionnaire.ID_SAISON,
      /* NOTE_MIN: selected_questionnaire.NOTE_MIN,
      NOTE_MAX: selected_questionnaire.NOTE_MAX, */
      ID_POSTE: selected_questionnaire.ID_POSTE,
    };
  };

  const handleChangeNote = (evt) => {
    const { name, value } = evt.target;
    if (value)
      setSelectedQuestionnaire({ ...selected_questionnaire, [name]: value });
  };

  const handleSaveAssignedQuestionnaire = () => {
    if (selected_questionnaire.ID_QUESTIONNAIRE > 0) {
      evaluation_questionnaire_service
        .updateAssignedQuestionnaire(getCurrentQuestionnaire())
        .then((res) => {
          if (res && res.success) {
            handleDialogClose();
            showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          } else {
            showToast(TYPE_NOTIFICATION.ERROR, res.message);
          }
        });
    } else {
      evaluation_questionnaire_service
        .insertAssignedQuestionnaire(getCurrentQuestionnaire())
        .then((res) => {
          if (res && res.success) {
            handleDialogClose();
            showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          } else {
            showToast(TYPE_NOTIFICATION.ERROR, res.message);
          }
        });
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (current_questionnaire) setSelectedQuestionnaire(current_questionnaire);
  }, [current_questionnaire]);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <DialogTitle id="dialog-title" className="entete-dialog">
              {selected_questionnaire.ID_QUESTIONNAIRE > 0
                ? "Modifier assignation"
                : "Nouvelle assignation"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ overflow: "auto" }}
            >
              <Grid item mt={0.5} display={"flex"} flexDirection={"column"}>
                <Typography>Session</Typography>
                <TextField
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selected_questionnaire.NOM_SESSION}
                />
                <Typography>Fonction</Typography>
                <TextField
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selected_questionnaire.name}
                />
                <Typography>Questionnaire</Typography>
                <TextField
                  multiline
                  rows={2}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selected_questionnaire.QUESTION}
                />
                <Grid container display={"none"}>
                  <Grid item xs={6}>
                    <Typography>Note min</Typography>
                    <TextField
                      size="small"
                      type="number"
                      name="NOTE_MIN"
                      onChange={(e) => handleChangeNote(e)}
                      value={selected_questionnaire.NOTE_MIN}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Note max</Typography>
                    <TextField
                      type="number"
                      size="small"
                      name="NOTE_MAX"
                      onChange={(e) => handleChangeNote(e)}
                      value={selected_questionnaire.NOTE_MAX}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions sx={{ mr: 2 }}>
              <LoadingButton
                onClick={handleSaveAssignedQuestionnaire}
                color="primary"
                variant="contained"
              >
                Enregistrer
              </LoadingButton>
              <Button
                className="form_control"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default QuestionnaireFormAjout;
