import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ResultatService = {
    async add(form,tdrid, id) {
         try {
             //const result = id !==undefined ?console.log("1"):console.log("2");
             const result = id !== undefined && id.trim() !== "" ?
             await axios.put(`${apiUrl}/resultat/saveStockResultat/${id}`, {
                 id: id,
                 "description_resultat": form.description_resultat,
                 "commentaire_resultat": form.commentaire_resultat,
                 "libelle_mode_mesure": form.type_resultat,
                 "taux_resultat": form.taux_resultat,
                 "echelonne_resultat": form.echelonne_resultat,
                 "qualitatif_resultat": form.qualitatif_resultat,
                 "chiffre_resultat": form.chiffre_resultat,
                 "unite": form.unite,
                 "mode_mesure_id": form.mode_mesure_id,
                 "tdr_mission": tdrid
             }) :
             await axios.post(`${apiUrl}/resultat/saveStockResultat/`, {
                 "description_resultat": form.description_resultat,
                 "commentaire_resultat": form.commentaire_resultat,
                 "libelle_mode_mesure": form.type_resultat,
                 "taux_resultat": form.taux_resultat,
                 "echelonne_resultat": form.echelonne_resultat,
                 "qualitatif_resultat": form.qualitatif_resultat,
                 "chiffre_resultat": form.chiffre_resultat,
                 "unite": form.unite,
                 "tdr_mission": tdrid
             });
     
         // Retournez les données de la réponse
         if (result) {
             return result.data;
         }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async insertion(id) {
        try {
            const result = await axios.get(`${apiUrl}/resultat/saveResultat?id=${id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async getId(id) {
        try {
            const result = await axios.get(`${apiUrl}/resultat/getById/${id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async getId(id) {
        try {
            const result = await axios.get(`${apiUrl}/Resultat/getById/${id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async getById(id) {
        try {
            const result = await axios.get(`${apiUrl}/Resultat/${id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async delete(id) {
        try {
            const result = await axios.delete(`${apiUrl}/Resultat/${id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export default ResultatService;
