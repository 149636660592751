import BarChart from "./BarChart";
import LineChart from "./LineCharts";
import PieChart from "./PieAnimation";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    float: 'rigth',
    width: '20%',
  },
  select: {
    width: '20%',
  },
}));

export default function StatistiquePlanning() {
  const classes = useStyles();

  return (
    <MainContainer {...headerDataMission}>
      <React.Fragment>
        {/* <Title>Listes des rubriques secondaires</Title> */}
          <LineChart />
          {/* <BarChart /> */}
          {/* <PieChart /> */}
      </React.Fragment>
    </MainContainer>

  );
}
