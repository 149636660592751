import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#425b8a",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const groupDaysByMonth = (dates) => {
    const months = {};
    dates.forEach(date => {
        const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 0).padStart(1, '0')}`;
        if (!months[monthKey]) {
            months[monthKey] = [];
        }
        months[monthKey].push(date.getDate());
    });
    
    return months;
};

const DateRangeTable = ({ startDate, endDate,datatable }) => {
    const dates = generateDateRange(startDate, endDate);
    const monthsGrouped = groupDaysByMonth(dates);
    
    const monthKeys = Object.keys(monthsGrouped).sort((a, b) => {
        const [yearA, monthA] = a.split('-').map(Number);
        const [yearB, monthB] = b.split('-').map(Number);
        return yearA === yearB ? monthA - monthB : yearA - yearB;
    });

    
    return (
        <div id="table-to-pdf">
            <TableContainer component={Paper}>
             <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{ width: '10%',backgroundColor:'#D3D3D3' }}></StyledTableCell>
                        {monthKeys.map((monthKey) => {
                            const [year, month] = monthKey.split('-').map(Number);
                            const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
                            const daysInMonth = monthsGrouped[monthKey].length;
                            return (
                                <StyledTableCell key={monthKey} colSpan={daysInMonth} style={{backgroundColor:'#D3D3D3'}}>
                                    {monthName.toLowerCase()}
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                    <TableRow>
                        <StyledTableCell style={{ width: '10%',border: '1px solid #ddd',backgroundColor:'#D3D3D3' }}></StyledTableCell>
                        {monthKeys.map((monthKey) => (
                            monthsGrouped[monthKey].map(day => (
                                <StyledTableCell style={{border: '1px solid #ddd',backgroundColor:'#D3D3D3'}}  key={`${monthKey}-${day}`}>
                                    {day}
                                </StyledTableCell>
                            ))
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                {datatable.map((ts, index) => {
                    const hasHighlight = monthKeys.some((monthKey) =>
                        monthsGrouped[monthKey].some((day) => 
                            day >= ts.debut && day <= ts.fin && parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10)
                        )
                    );

                    return hasHighlight ? (
                        <StyledTableRow key={`row-${index}`}>
                            <StyledTableCell component="th"
                              scope="row"
                              style={{
                                position: 'sticky',
                                left: 0,
                                backgroundColor: 'white',
                                zIndex: 1,
                            }}>
                                {ts.ligne}
                            </StyledTableCell>
                            {monthKeys.map((monthKey) => (
                                monthsGrouped[monthKey].map((day, dayIndex) => {
                                    const isHighlighted = day >= ts.debut && day <= ts.fin && parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10);
                                    const colors = (ts.state === "En attente de validation") ? "#1414b8" :
                                                (ts.state === "Planifié") ? "#78b785" :
                                                (ts.state === "Nouveau") ? "#87CEEB" : "red";

                                    return (
                                        <StyledTableCell
                                            key={`day-${monthKey}-${dayIndex}`}
                                            style={{
                                                backgroundColor: isHighlighted ? colors : 'transparent',
                                                color: isHighlighted ? 'white' : 'black',
                                                textAlign: 'center',
                                                border: '1px solid #ddd',
                                            }}
                                        >
                                            {isHighlighted ? ts.valeur : ''}
                                        </StyledTableCell>
                                    );
                                })
                            ))}
                        </StyledTableRow>
                    ) : ''; 
                 })}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    );
};

export default DateRangeTable;
